
<div  #myDOMElement fxLayout="row">
 <!--  <div class="laNueve">
    <h2 >
      CONSUMO DISCRIMINADO
    </h2>
  </div> -->

  <ejs-chart
    #chart8
    id="chart-container"
    [primaryXAxis]="state.axis2[0]"
    [primaryYAxis]="state.axis2[1]"
    [height]="alto"
    (window:resize)="onResize($event)"
    [zoomSettings]='zoom'
    [palettes]='colorScheme.domain'
    [tooltip]='tooltip'
    [animations]="animations"
  >
    <e-series-collection>
      <e-series
        [dataSource]="state.THEDATA[7][0].datos"
        type="Area"
        xName="name"
        yName="value"
        [name]="state.THEDATA[7][0].nombre"
        [animation]="animation"
      ></e-series>
       <e-series
        [dataSource]="state.THEDATA[7][1].datos"
        type="Area"
        xName="name"
        yName="value"
        [name]="state.THEDATA[7][1].nombre"
        [animation]="animation"
      ></e-series>
     <e-series
        [dataSource]="state.THEDATA[7][2].datos"
        type="Area"
        xName="name"
        yName="value"
        [name]="state.THEDATA[7][2].nombre"
        [animation]="animation"
      ></e-series>
    </e-series-collection> </ejs-chart
  >

</div>
