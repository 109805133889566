<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">

  <ejs-chart *ngIf="cual === 1" #chartaccum1 id="chargersaccum1" [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis" [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom'
    [palettes]='colorScheme.domain' [tooltip]='tooltip' [animations]="animations">
    <e-series-collection>
      
      <!--<e-series *ngFor="let chart of state.THEDATA[9][3]; index as i;" [dataSource]="chart.datos" type="Line"
        xName="name" yName="value" [name]="chart.nombre" legendShape="Rectangle" [animation]="animation">
      </e-series>-->
      <e-series  [dataSource]="state.THEDATA[9][3]" type="Line"
      xName="name" yName="value"  legendShape="Rectangle" [animation]="animation">
    </e-series>

    </e-series-collection>
  </ejs-chart>


  <ejs-chart #chartaccum2 *ngIf="cual === 2" id="chargersaccum2" [primaryXAxis]="primaryXAxis1"
    [primaryYAxis]="primaryYAxis" [height]="alto" (window:resize)="onResize($event)"
    [tooltip]='tooltip' [animations]="animations">
    <e-series-collection>
      <e-series *ngFor="let chargername of state.currentChargersNames; index as i;"
      [dataSource]="state.THEDATA[9][4]" type="StackingColumn" xName="hora" [yName]="chargername"
      [name]="chargername"></e-series>

      <!-- <e-series [dataSource]="state.THEDATA[9][4]" type="StackingColumn" xName="hora" yName="LA MARINA"
        name="LA MARINA"></e-series>
        <e-series [dataSource]="state.THEDATA[9][4]" type="StackingColumn" xName="hora" yName="CERES"
        name="CERES"></e-series> -->

    </e-series-collection>
  </ejs-chart>

<!--   <ejs-chart *ngIf="cual === 2" #chartaccum2 id="chargersaccum2" [primaryXAxis]="primaryXAxis1" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip'  [animations]="animations">
      <e-series-collection>
        <e-series
          *ngFor="let chart of state.THEDATA[9][4]; index as i;"
          [dataSource]="chart.datos"
          type="Column"
          xName="name"
          yName="value"
          [name]="chart.nombre"
          legendShape="Rectangle"
          [animation]="animation"
        >
        </e-series>

    </e-series-collection>
  </ejs-chart> -->




</div>
