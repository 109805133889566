import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService, SortService, GridComponent,ResizeService  } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-dashboard-optimizacionbateria',
  templateUrl: './dashboard-optimizacionbateria.component.html',
  styleUrls: ['./dashboard-optimizacionbateria.component.scss'],
  providers: [GroupService, SortService,ResizeService]

})
export class DashboardOptimizacionbateriaComponent implements OnInit {

  constructor(public state :StateService ) { }
  dataopt:any
  cargaCompleta=true;
  seleccionchart=1
  public transalteopt = this.state.dictTranslate.DashboardsGraph.optimizacion;
  public groupOptions: Object;
  public groupOptions2: Object;

  public refresh: Boolean;
  public alertDialog: DialogComponent;

  public refresh2: Boolean;
  public alertDialog2: DialogComponent;

  @ViewChild('grid')
  public grid: GridComponent;
  
  @ViewChild('grid2')
  public grid2: GridComponent;
  ngOnInit(): void {
    this.cargaCompleta = this.state.listaEstadosDashboard["optimizacion"]
    this.dataopt=this.state.listaCatalogoBateria
    this.groupOptions = { showGroupedColumn: true, columns: ['arquitecturacelda'] };
    this.groupOptions2 = { showGroupedColumn: true, columns: ['arquitecturacelda'] };

  }
  dataBound(args) {
    //this.grid.autoFitColumns()
    if (this.refresh) {
      this.grid.groupColumn('arquitecturacelda');
      this.refresh = false;
    }
  }
  dataBound2(args) {
    //this.grid.autoFitColumns()
    if (this.refresh2) {
      this.grid2.groupColumn('arquitecturacelda');
      this.refresh2 = false;
    }
  }
  load() {
    this.refresh = (<any>this.grid).refreshing;
  }
  created() {
    this.grid.on("columnDragStart", this.columnDragStart, this);
  }
  public columnDragStart(args: any) {
    if (args.column.field === "Mainfieldsofinvention") {
      this.alertDialog.show();
    }
  }
  irTablaOpt(){
    this.seleccionchart=1
  }
  irTablaModCom(){
    this.seleccionchart=2
  }

}
