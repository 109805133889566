<!-- <div
  class="app-splash-screen"
> -->
<!-- <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-loader"></div>
  </div> -->
<!-- </div> -->
<div class="barra">
    <!-- <div class="imagenContainer"> -->
    <img style="height: 90px; text-align: center; align-items: center; z-index: 99;"
      src="./../../../../assets/logios_blanco.png" alt="The Logios Logo" />
    <!-- </div> -->
  </div>
  <div class="demo_wrap">
    <img src="./../../../../assets/fondoBuses.jpg" />
    <div class="myBack"></div>
  </div>
  
  <div class="nueva">
    <!-- <mat-card> -->
  
    <!--<mat-card-content> -->
    <!-- <div class="card">  -->
    <form [formGroup]="form" style="
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: blue;
      ">
      <h2 class="cadena">CAMBIAR CONTRASEÑA</h2>
      <!-- <br /> -->
      
      <mat-form-field class="full-width-input" style="
          font-family: 'Poppins' !important;
          margin-top: 20px;
          width: 250px;
          height: 50px;
          color: blue;
        ">
        <input style="background-color: #D6D8E1" matInput type="password" placeholder="Contraseña"
          formControlName="password1" required />
        <span matPrefix>
          <mat-icon>lock</mat-icon>
        </span>
        <mat-error>
          Ingrese una contraseña
        </mat-error>
      </mat-form-field>
      
      
      <mat-form-field class="full-width-input" style="
          font-family: 'Poppins' !important;
          margin-top: 20px;
          width: 250px;
          height: 50px;
          color: blue;
        ">
        <input style="background-color: #D6D8E1" matInput type="password" placeholder="Contraseña"
          formControlName="password2" required />
        <span matPrefix>
          <mat-icon>lock</mat-icon>
        </span>
        <mat-error>
          Repuita  la contraseña
        </mat-error>
      </mat-form-field>

      <!-- <br > <br /> <br /> -->
      <button (click)="onSubmit()" type="submit" style="
          font-family: 'Poppins' !important;
          border-radius: 25px;
          margin-top: 40px;
          margin-bottom: 20px;
        " mat-raised-button>
        Cambiar contraseña
      </button>

    </form>
  </div>