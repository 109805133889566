<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.cargaCompleta==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.cargaCompleta==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Demanda en la Red Anual-->
                            <ejs-chart #chartvel2 title={{this.dataTCon.consumte.title[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisxDRA" [primaryYAxis]="axisyDRA" [height]="alto"
                                (window:resize)="onResize($event)" [palettes]="colorScheme.domain" [tooltip]="tooltip"
                                style="width:100%;height:100%;" (chartMouseClick)='chartMouseClick($event)'>
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.dataAnual; index as i;" [dataSource]='elchart'
                                        xName="x" yName="y" name={{this.listaPotencia[i]}} type='Column'></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.cargaCompleta==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Ciclos Anuales por Servicio-->
                            <ejs-chart #chartvel2 title={{this.dataTCon.cicloanualser.title[this.state.indiceTranslate]}}
                                 [height]="alto"
                                (window:resize)="onResize($event)" [palettes]="colorScheme.domain" [tooltip]="tooltip"
                                style="width:100%;height:100%;" (chartMouseClick)='chartMouseClick($event)'
                                [primaryXAxis]="axisxCAS" [primaryYAxis]="axisyCAS">
                                <e-series-collection>
                                    <e-series [dataSource]='promciclosdescarga' type='Line' xName='x' yName='y' name='Degradación con Rotacion' fill='grey' dashArray='5,5'width=5></e-series>
                                    <e-series *ngFor="let elchart of this.dataDescargaAnual; index as i;" [dataSource]='elchart'
                                        xName="x" yName="y" name={{this.listaPotencia[i]}} type='Column'></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 3 && this.cargaCompleta==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Vida Útil por Servicio -->
                            <ejs-chart #chartvel2 title={{this.dataTCon.vidautilser.title[this.state.indiceTranslate]}}
                                 [height]="alto"
                                (window:resize)="onResize($event)" [palettes]="colorScheme.domain" [tooltip]="tooltip"
                                style="width:100%;height:100%;" (chartMouseClick)='chartMouseClick($event)'
                                [primaryXAxis]="axisxVUS" [primaryYAxis]="axisyVUS">
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.dataVidaUtil; index as i;" [dataSource]='elchart'
                                        xName="x" yName="y" name={{this.listaPotencia[i]}} type='Column'></e-series>
                                    <e-series [dataSource]='promvidautil' type='Line' xName='x' yName='y' name='Degradación con Rotacion' fill='grey' dashArray='5,5'width=5></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTCon.menu.sitiocarga[this.state.indiceTranslate]}}</h2>
                        <div style="display: flex;align-items: center;flex-direction: row;"
                            *ngFor="let potencia of this.listaSitioCarga; index as i">
                            <ejs-radiobutton label="{{potencia}}" name="radioSitioCargaContable"
                                [checked]="this.selectedSC==i? true : false " style="margin-bottom: 10px;"
                                (click)="cambiarSitioCarga(i)" cssClass="e-info"></ejs-radiobutton>
                        </div>
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTCon.menu.graph[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTCon.menu.opcone[this.state.indiceTranslate]}} name="Graficos"
                            style="margin-bottom: 10px;" checked="true" cssClass="e-info" (click)="irConsumoTE()"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTCon.menu.opcicloanualservi[this.state.indiceTranslate]}} name="Graficos"
                            style="margin-bottom: 10px;"  cssClass="e-info" (click)="irDescargaAnual()"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTCon.menu.opvidautilservi[this.state.indiceTranslate]}} name="Graficos"
                            style="margin-bottom: 10px;"  cssClass="e-info" (click)="irCicloVida()"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>