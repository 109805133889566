import { Component, Input, OnInit, Output } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-charger-ids',
  templateUrl: './charger-ids.component.html',
  styleUrls: ['./charger-ids.component.scss']
})
export class ChargerIdsComponent implements OnInit {
  loschargernames: any = [];
  labelcolors = [];
  @Output() whichone: number = -1;
  constructor(public state: StateService) { }

  ngOnInit(): void {
    this.labelcolors = Array(this.state.currentChargersNames.length).fill('#000096');
   /*  this.state.allLocations.forEach((c: any) => {
      if (this.state.currentCollection === c.colName) { */
        this.loschargernames = [...this.state.currentChargersNames];

   /*    }
    }); */
    console.log('this.loschargernames: ', this.loschargernames);
  }

  showEvents(i: number){
    // console.log('THe cargador is number ', i);
    this.whichone = i;
    for (let jota = 0; jota < this.labelcolors.length; jota++){
      this.labelcolors[jota] = '#000096';
      if (jota === i){
        this.labelcolors[jota] = '#2ED2DB';
      }
    }
  }


}
