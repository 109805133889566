import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-card-info-bus',
  templateUrl: './card-info-bus.component.html',
  styleUrls: ['./card-info-bus.component.scss']
})
export class CardInfoBusComponent implements OnInit {

  constructor(public state: StateService) { }
  public data_electricmotors: any
  public data_energystorage: any
  public data_transmision: any
  public data_vehicleplatform: any
  public dataTpv = this.state.dictTranslate.TarjetaBusInfo.plataformavehicular
  public dataTtp = this.state.dictTranslate.TarjetaBusInfo.trenpotencia
  public dataTae = this.state.dictTranslate.TarjetaBusInfo.almacenamientoenergetico
  ngOnInit(): void {

  }

}
