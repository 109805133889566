<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #default_dashboard columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <div *ngIf="seleccionChart==1">
                                <ejs-chart #chartBusTime class="busTime" [primaryXAxis]="axisxBusTime"
                                    [primaryYAxis]="axisyBusTime" style="width:100%;height:100%;display: block; " [palettes]='colorScheme.domain'>
                                    <e-series-collection style="width:100%;height:100%">
                                        <e-series *ngFor="let chargerName of state.currentChargersNames"
                                            [dataSource]="state.THEDATA[9][5][chargerName][0].datos" type="Line"
                                            xName="name" yName="value" [name]="chargerName" legendShape='Rectangle'
                                            [animation]="animation" style="width:100%;height:100%;">
                                        </e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div *ngIf="seleccionChart==2">
                                <ejs-chart #chartPowerTime class="powerTime" [primaryXAxis]="axisxPowerTime"
                                    [primaryYAxis]="axisyPowerTime" [palettes]='colorScheme.domain'>
                                    <e-series-collection>
                                        <e-series *ngFor="let chargerName of state.currentChargersNames"
                                            [dataSource]="state.THEDATA[9][0][chargerName][0].datos" type="Line"
                                            xName="name" yName="value" [name]="chargerName" legendShape='Rectangle'
                                            [animation]="animation"></e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div *ngIf="seleccionChart==3">
                                <ejs-chart #chartEnergytime class="energyTime" [primaryXAxis]="axisxEnergyTime"
                                    [primaryYAxis]="axisyEnergyTime" [palettes]='colorScheme.domain'>
                                    <e-series-collection>
                                        <e-series *ngFor="let chargerName of state.currentChargersNames"
                                            [dataSource]="state.THEDATA[9][1][chargerName][0].datos" type="Line"
                                            xName="name" yName="value" [name]="chargerName" legendShape='Rectangle'
                                            [animation]="animation">
                                        </e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div *ngIf="seleccionChart==4">
                                <ejs-chart #chartChargersAccumRed class="chargersAccumRed"
                                    [primaryXAxis]="axisxChargersAccum" [primaryYAxis]="axisyChargersAccum" [palettes]='colorScheme.domain'>
                                    <e-series-collection>
                                        <e-series [dataSource]="state.THEDATA[9][3]" type="Line" xName="name"
                                            yName="value" legendShape="Rectangle" [animation]="animation">
                                        </e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                            <div *ngIf="seleccionChart==5">
                                <ejs-chart #chartChargersAccumHour class="chargersAccumHour"
                                    [primaryXAxis]="axisxChargersAccumHour" [primaryYAxis]="axisyChargersAccumHour" [palettes]='colorScheme.domain'>
                                    <e-series-collection>
                                        <e-series *ngFor="let chargername of state.currentChargersNames; index as i;"
                                            [dataSource]="state.THEDATA[9][4]" type="StackingColumn" xName="hora"
                                            [yName]="chargername" [name]="chargername"></e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <ejs-radiobutton label="Buses Cargando" name="default" style="margin-bottom: 10px;"
                            (click)="irBusesCargando()" checked="true" cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Potencia vs Tiempo" name="default" style="margin-bottom: 10px;"
                            (click)="irPotenciaTiempo()"  cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Energía vs Tiempo" name="default" style="margin-bottom: 10px;"
                            (click)="irEnergiaTiempo()"  cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Demanda en la Red" name="default" style="margin-bottom: 10px;"
                            (click)="irDemandaRed()"  cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Demanda por Hora" name="default" style="margin-bottom: 10px;"
                            (click)="irDemandaHora()"  cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>