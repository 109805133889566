import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-assigned-bus',
  templateUrl: './assigned-bus.component.html',
  styleUrls: ['./assigned-bus.component.scss']
})
export class AssignedBusComponent implements OnInit {
  loschargernames: any = [];
  items: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {
    /*
        this.state.allLocations.forEach((c: any) => {
          if (this.state.currentCollection === c.colName) {
            this.loschargernames = [...c.chargernames];
          }
        }); */

    /* this.state.allLocations.forEach((cole: any) => {
      if (this.state.currentCollection === cole.colName) { */
    this.state.currentChargersNames.forEach((c: any) => {
      this.items.push(
        {
          id: c.replace(/\s/g, ""),
        }
      );
    });
    /*       }
        }); */
    // console.log('this.loschargernames: ', this.loschargernames);
  }


}
