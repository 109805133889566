import { Component, Input, OnInit } from '@angular/core';
import { concatMap, lastValueFrom, of, single } from 'rxjs';
import { IotaService } from 'src/app/shared/iota/iota.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-charger-events',
  templateUrl: './charger-events.component.html',
  styleUrls: ['./charger-events.component.scss']
})
export class ChargerEventsComponent implements OnInit {
  // items: any = {};
  items: any = [];
  datos: any = {};
  singleview = false;
  puntosdecarga = [1];
  eventosdecarga = {};
  isopensite = [];

  @Input() whichone: number;
  constructor(public state: StateService,
    private iota: IotaService
  ) {
    this.items = Array(this.state.currentChargersNames.length).fill([]);

  }

  ngOnInit(): void {

    this.state.processing = true;

    //this.state.currentChargersNames.forEach(async (c: any, pos: number) => {
    //  console.log("imprimiendo c ",c);
    //  // console.log('Getting all IOTA EVENTS for charger ', c);
    //  console.log(this.state.currentCollection);
    //  this.iota.getAllEventsFromLocation(c)
    //    .pipe(concatMap(value => {
    //      // console.log(`${value}: first pipe operator (before promise)`);
    //      // return a promise that resolves with the specified value after 2 seconds
    //      console.log("imprimiendo pipe 1 :" ,value);
    //      return new Promise(resolve => resolve(value));
    //    }))
    //    .pipe(concatMap((value: any) => {
    //      // console.log(`${value.lista}: second pipe operator (after promise resolved)`);
    //      //console.log(JSON.stringify(value));
    //      console.log("imprimiendo pipe 2 :" ,value);
    //      return of(value);
    //    }))
    //    .subscribe((value: any) => {
    //      this.isopensite.push(false);
    //      // this.items.push(value.lista)
    //      // this.items[c] = value.lista;
    //      // console.log('All events location: ', value);
    //      // this.items.push(value[this.state.currentCollection + '_' + c]);   
    //      
    //      //Buscando charger Point Id.     
    //      console.log(value[this.state.currentCollection + '_' + c]);
    //      this.items[pos] = value[this.state.currentCollection + '_' + c];
    //      // console.log('items: ', this.items);
    //      if (this.state.currentChargersNames.length === this.isopensite.length) {
    //        this.state.processing = false;
    //      }
    //    }); //
//
    //});
    //console.log('ChargerEventsComponent items: ', this.items);
    this.state.currentChargersNames.forEach(async (c: any, pos: number) => {
      console.log("imprimiendo c ",c);
      console.log(this.state.currentCollection);
      let registros_cargador = await lastValueFrom(this.iota.getAllEventsFromLocation(c));
      console.log("lista de cargadores iota",registros_cargador);
      this.isopensite.push(false);

      // lista -> charge_point_id
      console.log(registros_cargador[this.state.currentCollection + '_' + c]);
      var lista = registros_cargador["lista"];

      this.items[pos] = lista;
      // console.log('items: ', this.items);
      if (this.state.currentChargersNames.length === this.isopensite.length) {
        this.state.processing = false;
      }
    
    });

    var test = 'CANTA CALLAO';
    this.iota.getAllDataFromEventsbyLocation(test)
    .pipe(concatMap(value=>{
      console.log("imprimiendo valor iota ", value);
      return new Promise(resolve => resolve(value));
    }))

  }

  showSite(i: number): void {
    this.isopensite[i] = !this.isopensite[i];
  }


  showSingleEvent(index: number, cargador: string) {

    console.log('index: ', index)
    this.datos = JSON.stringify(this.items[cargador][index]);
  }

}