import { Component, ViewEncapsulation, Inject, ViewChild, OnInit } from '@angular/core';
import { AccumulationTheme, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';

@Component({
  selector: 'app-dashboard-interaccionred',
  templateUrl: './dashboard-interaccionred.component.html',
  styleUrls: ['./dashboard-interaccionred.component.scss']
})
export class DashboardInteraccionredComponent implements OnInit {


  constructor(public dataSer: DataService, public authService: AuthService, public state: StateService) {
  }
  //Conf Graph Bus Time - Buses Cargando
  public axisxBusTime = {
    valueType: 'DateTime',
    intervalType: 'Hours',
    title: 'Hora del día',
    // rangePadding: 'Auto',
    interval: 4,
    //minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
    //maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 1, 0, 0 ,0),
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: 'HH:mm'
  };
  public axisyBusTime = {
    //labelFormat: '{value}',
    title: 'Buses Cargando',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    //maximum: 5,
    //interval: intervalo,
    rangePadding: 'Auto',
  };
  //Conf Graph Power Time - Potencia Tiempo
  public axisxPowerTime = {
    valueType: 'DateTime',
    intervalType: 'Hours',
    title: 'Hora del día',
    // rangePadding: 'Auto',
    interval: 4,
    //minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
    //maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 1, 0, 0 ,0),
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: 'HH:mm'
  };
  public axisyPowerTime = {
    labelFormat: '{value}',
    title: 'Potencia (kW)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
  };
  //Conf Graph Energy Time - Energia Red Tiempo
  public axisxEnergyTime = {
    valueType: 'DateTime',
    intervalType: 'Hours',
    title: 'Hora del día',
    // rangePadding: 'Auto',
    interval: 4,
    //minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
    //maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 2, 0, 0 ,0),
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: 'HH:mm'
  };
  public axisyEnergyTime = {
    labelFormat: '{value}',
    title: 'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
  };
  //Conf Graph Chargers Accum - Demanda en la Red
  public axisxChargersAccum = {
    valueType: 'DateTime',
    intervalType: 'Hours',
    title: 'Hora del día',
    // rangePadding: 'Auto',
    interval: 4,
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: 'HH:mm'
  };
  public axisyChargersAccum = {
    labelFormat: '{value}',
    title: 'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {

      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
  };
  public axisxChargersAccumHour = {
    title: 'Hora del día',
    // rangePadding: 'Auto',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: '{value}',
    valueType: 'Category',
  };
  public axisyChargersAccumHour = {
    labelFormat: '{value}',
    title: 'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {

      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
  };
  //Variables Globales
  public seleccionChart = 1;
  public animation: object = { enable: true };
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#800000','#4bc8fe'],
  };
  ngOnInit(): void {
  }
  irBusesCargando() { this.seleccionChart = 1 }
  irPotenciaTiempo() { this.seleccionChart = 2 }
  irEnergiaTiempo() { this.seleccionChart = 3 }
  irDemandaRed() { this.seleccionChart = 4 }
  irDemandaHora() { this.seleccionChart = 5 }


}
