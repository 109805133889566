import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService, SortService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';


L10n.load({
    'en-US': {
        grid: {
            EmptyRecord: "No Buses selected.",
            GroupDropArea: "Drag a column to group",
        }
    }
});
L10n.load({
    'es-ES': {
        grid: {
            EmptyRecord: "No hay Buses seleccionados.",
            GroupDropArea: "Arrastre una columna para agrupar",
        }
    }
});
@Component({
    selector: 'app-bus-ledger',
    templateUrl: './bus-ledger.component.html',
    styleUrls: ['./bus-ledger.component.scss'],
    providers: [GroupService, SortService]
})

export class BusLedgerComponent implements OnInit {
    pointColorMapping: any;
    public dataT = this.state.dictTranslate.Menu.Portafolio.graficos;
    public indice = this.state.indiceTranslate

    actionFailure(e: any): void {
        console.log(e.error)
    }
    constructor(public dataservice: DataService, public state: StateService) {
    }
    public dictHeader: any
    public databus = this.dataservice.listBusConfiguration;
    public groupOptions: Object;
    public pageSettings: Object;
    public refresh: Boolean;
    @ViewChild('grid', { static: true })
    public grid: GridComponent;
    @ViewChild('alertDialog', { static: true })
    public alertDialog: DialogComponent;
    public alertHeader: string = 'Grouping';
    public hidden: Boolean = false;
    public target: string = '.control-section';
    public alertWidth: string = '300px';
    public alertContent: string = 'Grouping is disabled for this column';
    public showCloseIcon: Boolean = false;
    public animationSettings: Object = { effect: 'None' };
    public alertDlgBtnClick = () => {
        this.alertDialog.hide();
    }
    public alertDlgButtons: Object[] = [{ click: this.alertDlgBtnClick.bind(this), buttonModel: { content: 'OK', isPrimary: true } }];
    public primaryXAxis?: Object;
    primaryYAxisdemandaenergia: any;
    primaryYAxistamaniobateria: any;
    primaryYAxisautonomia: any;
    primaryYAxisdegradacion: any;
    primaryYAxiscargadores: any;
    primaryYAxisdemandapotencia: any;
    primaryYAxiseventoscarga: any;
    primaryYAxisusocargadores: any;
    primaryYAxisenergiarenovableutilizada: any;

    public tooltip: Object = {
        enable: false
    };
    show = false

    colorScheme = {
        domain: ['#000096', '#88898C', '#F5821E', '#008000', '#FF0000'],
    };
    tipocargaSeleccionado=this.state.dataRating["tipocarga"][0]
    selectedRuta =this.state.dataRating["ruta"][0]
    listaDeBuses = this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["autonomia"]
    listaDeRutas = this.state.dataRating["ruta"]
    ngOnInit(): void {

        this.pointColorMapping='color'
        setTimeout(() => {
            this.show = true
        }, 1500);
        this.primaryXAxis = {
            valueType: 'Category',
            labelFormat: '{value}'
        };
        this.primaryYAxisdemandaenergia={
            labelFormat: '{value}',
            //labelFormat: '{value} kWh',
            minimum:0,
            interval:2500
        }
        this.primaryYAxistamaniobateria={
            labelFormat: '{value}',
            minimum:0,
            interval:50
        }   
        this.primaryYAxisautonomia={
            minimum:0,
            labelFormat: '{value}',
        }
        this.primaryYAxisdegradacion={
            labelFormat: '{value}',
        }
        this.primaryYAxiscargadores={
            minimum:0,
        }
        this.primaryYAxisdemandapotencia={
            labelFormat: '{value}',
            minimum:0,
            interval:500
        }
        this.primaryYAxiseventoscarga={

        }
        this.primaryYAxisusocargadores={
            labelFormat: '{value}',

        }
        this.primaryYAxisenergiarenovableutilizada={

        }
        //this.databus =JSON.stringify(this.dataservice.listBusConfiguration);
        this.dictHeader = this.state.dictTranslate.Menu.Portafolio.tablaportafolio
        console.log("this.data", this.databus, this.dictHeader)
        this.groupOptions = { showGroupedColumn: true, columns: ['busid'] };
        this.pageSettings = { pageCount: 10 };
        if (this.state.indiceTranslate == 0) {
            setCulture('en-US')
        } else {
            setCulture('es-ES')
        }
    }
    
    dataBound() {
        if (this.refresh) {
            this.grid.groupColumn('busid');
            this.refresh = false;
        }
    }
    load() {
        this.refresh = (<any>this.grid).refreshing;
    }
    created() {
        this.grid.on("columnDragStart", this.columnDragStart, this);
    }
    public columnDragStart(args: any) {
        if (args.column.field === "Mainfieldsofinvention") {
            this.alertDialog.show();
        }
    }
    cambiarTipoCarga(tipocarga: any) {
        this.tipocargaSeleccionado = tipocarga
    }
    cambiarRuta(ruta: any) {
        this.selectedRuta = ruta
    }

}

