<div class="supercard">
  <div [ngClass]=" mystate.currentRoute === 'None' ? 'tarjetaNuevaAlter' : 'tarjetaNueva'">

    <div style="width: 10vw !important; color: white; margin-right: 10px;">
      <mat-grid-list cols="1" rowHeight="55px">
        <!-- <mat-grid-tile style="border-color: #000096;"> </mat-grid-tile> -->

        <mat-grid-tile>
          Ruta 1
        </mat-grid-tile>
        <mat-grid-tile *ngFor="let bus of mystate.busNames; index as i" (click)="setCurrentRoute(bus, i)"
          [style.background]="routeBackground[i]" [style.color]="routeColor[i]" (mouseover)="hoverParaRuta(bus)"
          (mouseout)="hoverOutParaRuta(bus)" [style.cursor]="'pointer'">
          {{bus}}
        </mat-grid-tile>


      </mat-grid-list>
    </div>

    <div *ngIf="mystate.currentRoute !== 'None'" style="overflow-y: auto; overflow-x: hidden;">
      <div >
        <mat-grid-list [cols]="thecols" rowHeight="40px" style="width: 7vw !important">



          <mat-grid-tile *ngFor="let tilo of mystate.routeStops; index as i" class="mat-grid-tile opciones"
            [style.background]="cellBackground[i]" [style.cursor]="'pointer'" [style.color]="cellColor[i]"
            (click)="selectStop(i+1)">
            <div class="onetest">{{i + 1}}</div>
          </mat-grid-tile>


        </mat-grid-list>
        <div class="buttons">
          <button (click)="clearSelectedStops()" type="submit" style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 0px;
      " mat-raised-button>
            Borrar
          </button>
        </div>
      </div>
    </div>

    <!-- <app-charging-sites *ngIf="prevsecondselection !== -1"></app-charging-sites> -->

  </div>


</div>
