import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
// import { DataService } from '../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data/data-service.service';

@Component({
  selector: 'app-consumption-time',
  templateUrl: './consumption-time.component.html',
  styleUrls: ['./consumption-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionTimeComponent implements OnInit, AfterViewInit, OnChanges  {
  multi: any = [];
  initMulti: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  graphicSelected:number = 1;
  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#008000', '#FF0000'],
  };

  ancho = '';
  alto = '';
  icono = true;

  public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;
  public animation: object = { enable: false };
  public majorGridLines: Object;
  public lineStyle: Object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @Input() grande: boolean;

  @ViewChild('chart9')
  public chart!: ChartComponent;

  changeAnimation: Subscription | undefined;
  changeData: Subscription | undefined;

  constructor(
    public dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + '';
    // this.state.currentChart = 9;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  changeGraphic(newNumber:number){
    this.graphicSelected = newNumber;
    this.state.currentStats = newNumber;
  }

  print(){
    this.chart.print();
  }

  async ngOnInit(): Promise<void> {
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 0 };
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
    // this.state.currentChart = 9;
    this.marker = { visible: true };
    this.subscribeConfiguration();
     console.log('state.THEDATA: ', this.state.THEDATA[8]);
  }

  subscribeConfiguration(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        this.state.THEDATA[8] = [...this.state.THEDATA[8]];
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.changeData = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        this.state.THEDATA[2] = [...this.state.THEDATA[2]];
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    console.log("THEDATA[2]", this.state.THEDATA[2])
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  pointClick(args: IPointEventArgs): void {
    try {
      console.log('this.state.chartMapLongitude: ',this.state.chartMapLongitude);
      const lalon = this.state.chartMapLongitude[0][0][args.pointIndex];
      const lalat = this.state.chartMapLatitude[0][0][args.pointIndex];
      
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
    if (this.changeData) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeData.unsubscribe();
      this.changeData = undefined;
    }
    // console.log('MAPS component destroyed');
  }
}
