<div class="collection-card">
    <div class="section-name space-line">
        <div class="title"><span>Ingrese nombre de colección</span></div>
        <input type="text" placeholder="nombre" class="input-df">
    </div>
    <div class="section-simulation space-line">
        <span class="title" style="margin-bottom: 15px;">Tipo de simulación</span>
        <label *ngFor="let simulacion of simulaciones" class="lbl-input-txbx">
            <input class="input-txb-df" type="checkbox" [id]="simulacion.id" [value]="simulacion.valor"
                [(ngModel)]="simulacion.marcado" />
            {{ simulacion.nombre }}
        </label>
    </div>
    <div>
        <div class="section-file space-line"><span class="title">Cuenta con un cronograma</span><button
                class="btn-help">?</button> <input class="input-txb-df" type="checkbox" value="haveSchedule"></div>
        <div class="section-file space-line"><label for="file-upload" class="custom-file-upload">
                Subir archivo
            </label><input  id="file-upload" class="btn-file-up" type="file"></div>
    </div>
    <div>
        <div class="section-file space-line"><span class="title">Cargar archivo .kmz</span><button class="btn-help">?</button></div>
        <div class="section-file space-line"><label for="file-upload-kmz" class="custom-file-upload">
            Subir archivo
        </label><input  id="file-upload-kmz" class="btn-file-up" type="file"></div>
    </div>
    <div class="section-addcollection space-line">
        <button class="btn-add-collection">Añadir Rutas</button>
    </div>
</div>
<div>
    <div class="route-card">
        <div >
            <div class="section-name space-line">
                <span  class="title">Nombre de ruta :</span>
                <input type="text" class="input-df">
            </div>
            <div class="section-name space-line">
                <span class="title">Terminal vehiculo : </span>
                <input type="text" class="input-df">
            </div>
        </div>
        <div>
            <div class="section-name space-line">
                <span class="title">Perfil : </span>
                <select id="perfilType">
                    <option value="BTR">BTR</option>
                    <option value="Empirico">Empírico</option>
                </select>
            </div>
            <div class="section-name space-line">
                <span class="title">Número de buses : </span>
                <input type="number"  class="input-df">
            </div>
        </div>
    </div>
    <div>
        <div  class="route-card">
            <div class="section-name space-line">
                <span class="title">Nombre de Bus :</span>
                <select id="busType">
                    <option value="bus1">Bus1</option>
                    <option value="bus2">Bus2</option>
                </select>
            </div>
            <div class="section-name space-line">
                <span class="title">Tipo de Bus : </span>
                <input type="text" [disabled]="true" placeholder="##" class="input-disable">
            </div>
            <div class="section-name space-line">
                <span class="title">Tamaño de Bus : </span>
                <input type="text" [disabled]="true" placeholder="##" class="input-disable">
            </div>
            <div class="section-register-charger">
                <p class="lst-buses">[buses]</p>
                <button class="btn-add-collection">Añadir Bus</button>
            </div>
        </div>
        <div class="route-card">
            <div class="section-name space-line">
                <span class="title">Estación a simular : </span>
                <input type="text"  class="input-df">
            </div>
            <div class="section-name space-line">
                <span class="title">Tipo de cargador : </span>
                <select id="chargerType">
                    <option value="charger1">charger1</option>
                    <option value="charger2">charger2</option>
                </select>
            </div>
            <div class="section-simulation space-line">
                <p class="title">Potencias</p>
                <label *ngFor="let cargador of cargadores" class="lbl-input-txbx">
                    <input class="input-txb-df" type="checkbox" [id]="cargador.id" [value]="cargador.valor"
                        [(ngModel)]="cargador.marcado" />
                    {{ cargador.nombre }}
                </label>
            </div>
            <div class="section-register-charger">
                <p class="lst-buses">[Cargadores]</p>
                <button class="btn-add-collection">Añadir Cargador</button>
            </div>
        </div>
    </div>
</div>