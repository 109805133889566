<div fxFlex="5%" class="wrapper">
  <div class="one">
    <!-- <mat-icon> directions_bus</mat-icon> -->
    <p>Cargador</p>

    <p>{{ state.selectedCharger }}</p>
  </div>
  <div class="two">
    <br />
    Carga <br>
    {{ dataSer.chargersmethods[state.selectedCharger] }}

  </div>

  <div class="three">
    <br />
    Medio: <br />
    {{ dataSer.chargermedium[state.selectedCharger] }} <br />

    Potencia Nominal: <br />
    {{ dataSer.chargersnominalpower[state.selectedCharger] }} kW<br />

    <!-- INFO: 333 <br /> -->

  </div>
  <div class="four">
    <br />
    <p>
      Energía consumida  <br />
      {{ dataSer.lastconsumedcharger[state.selectedCharger] }} kWh
    </p>
  </div>
  <div class="five">

    <p>
      Número de rutas <br />
      {{ dataSer.howmanyroutesincharger[state.selectedCharger] }}
    </p>
  </div>
  <!-- <div class="six">Six</div> -->
</div>
