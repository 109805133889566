<div class="siteinfo" *ngIf="whichone != -1">

  <div class="thereports">
    <div class="ruta">
      <p>Sitio de carga</p>
      <p>{{state.currentChargersNames[whichone]}}</p>
    </div>
  </div>
  <br />

  <!-- <div *ngFor="let sitio of this.state.currentChargersNames; index as i" class="chargingsites"> -->
  <div class="chargingsites">
    <div class="chargingpoint">
      <span style="margin: 5px; cursor: pointer;"> PUNTO DE CARGA #1 </span>

      <!-- <span style="margin: 5px;"> Info 1: ###</span>
      <span style="margin: 5px;"> Info 2: ###</span>
      <span style="margin: 5px;"> Info 3: ###</span> -->
    </div>
    <div style="position: relative; left: 0%; width: 100%; justify-self: center;  height: 80%;
                overflow: auto; justify-content: space-around; display:  flex; color: white; ">
      <div>Evento #</div>
      <div>Hora de registro</div>
      <div>Locación</div>
      <div>Cargador</div>
      <div>Conector</div>
    </div>
    <div style="position: relative; left: 0%; width: 100%; justify-self: center;  height: 80%; overflow: auto;">
      <div class="singleevent" *ngFor="let punto of items[whichone]; index as j">
        <div style="display: flex; justify-content:space-between;">
          <div style="margin: auto;">{{ j + 1}}</div>
          <div style="display: flex; flex-direction: column; width: 80%;">
            <div style="display: flex; justify-content: space-around;">
              <span>{{punto.add_time}} </span>
              <span>{{punto.load_location}} </span>
              <span>{{punto.charge_point_id}} </span>
              <span>{{punto.connector_id}} </span>
              <!--  <span>Info 4: ### </span> -->
            </div>
            <!-- <div>
              <hr class="dos" />
            </div>
            <div style="display: flex; justify-content: space-between;">
              <span>Info 5: ### </span>
              <span>Info 6: ### </span>
              <span>Info 7: ### </span>
              <span>Info 8: ### </span>
            </div> -->
          </div>
        </div>
        <div>
          <hr class="tres" />
        </div>
      </div>
    </div>
    <div>
      <hr class="uno" />
    </div>
  </div>

  <div class="download">
    <span style="padding-right: 10px;">Descargar PDF</span>
  </div>
</div>


<!-- <div class="paradiv">
  <div style=" display: flex; flex-direction: row; height: 500px !important;">
    <div *ngFor="let item of state.currentChargersNames; index as i">
      <div
        style=" overflow: auto; display: flex; flex-direction: column; font-size: 20px !important; height: 100% !important;">
        <div *ngFor="let item2 of items[item]; index as j;"
          style="display: flex; height: 40px; cursor: pointer; color: white; background-color: rgba(255,255,255,0.0);
              border-top: 1px solid white; border-bottom: 1px solid white; border-radius: 0px;"
          (click)="showSingleEvent(j, item)">
          <span >Evento {{j + 1}}</span>
        </div>
      </div>
    </div>
  </div>


</div>

<div style=" padding: 10px !important; background-color: #000096;
color: white; position: fixed; right: 10% !important; bottom: 5% !important;
border: 1px solid white; cursor: pointer;"  *ngIf="singleview" cdkDrag >
<app-individual-event [datos]="datos"></app-individual-event>
</div> -->