import { CdkDragEnd } from '@angular/cdk/drag-drop';
//import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { element } from 'protractor';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { MapService } from 'src/app/shared/map/map.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-buses-options',
  templateUrl: './buses-options.component.html',
  styleUrls: ['./buses-options.component.scss']
})
export class BusesOptionsComponent implements OnInit {
  cadena_busquedafiltro='';
  break = '\n';
  positionX = 0;
  positionY = 0;
  names = [
    'Nocturno_Azul2',
    'Oportunidad_Azul2',
    'Oportunidad_Blanco2',
    'Nocturno_Blanco2',
    'configuracion-del-bus-azul',
    'configuracion-del-bus-blanco',
    'Save1',
    'Save2'
  ];
  stateofsize = '0';
  oportunosColor = 'white';
  nocturnosColor = 'white';
  oportunosFontColor = '#000096';
  nocturnosFontColor = '#000096';
  previousOportunity = -1;
  previousOvernight = -1;
  previousRoute = -1;

  selectedOpor = -1;
  selectedOver = -1;

  routeColor: any = [];
  routeBackground: any = [];
  ninemcolor = 'white';
  twelvemcolor = 'white';
  eighteenmcolor = 'white';
  ninefontcolor = '#000096';
  routefontcolor = '#000096';
  twelvefontcolor = '#000096';
  eighteenfontcolor = '#000096';
  paxreal = [{ op: 'LOW' }, { op: 'MID' }, { op: 'HIGH' }];
  drivingcyclelist = [{ op: 'Observado' }, { op: 'BRT' }];
  currentRouteSelected = '';
  /* pax12 = [{ op: 'LOW' }, { op: 'MID' }, { op: 'HIGH' }];
  pax18 = [{ op: 'LOW' }, { op: 'MID' }, { op: 'HIGH' }]; */
  oportunos = [
    //{ text: '76kWh', cols: 1, rows: 1, color: 'lightblue', icon: 'directions_bus' },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '55 kWh', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '12', battery: '55', viable: true },
    { text: '102 kWh', cols: 1, rows: 1, color: '#000096', icon: 'local_shipping', size: '18', battery: '102', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '63.5 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '63.5', viable: true },
    { text: '195 kWh', cols: 1, rows: 1, color: '#000096', icon: 'local_shipping', size: '18', battery: '195', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '76 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '76', viable: true },
    { text: '216 kWh', cols: 1, rows: 2, color: '#000096', icon: 'motorcycle', size: '18', battery: '216', viable: true },

  ];
  nocturnos = [
    // { text: '-', cols: 1, rows: 1, color: 'lightblue', icon: 'directions_bus' },
    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '315 kWh  ', cols: 1, rows: 1, color: '#000096', icon: 'directions_bus', size: '12', battery: '315.07', viable: true },
    { text: '544 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '544', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '322 kWh  ', cols: 1, rows: 1, color: '#000096', icon: 'directions_bus', size: '12', battery: '322', viable: true },
    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '250', viable: true },
    { text: '324 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '324', viable: true },
    { text: '640 kWh', cols: 1, rows: 1, color: '#000096', icon: 'local_shipping', size: '18', battery: '640', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '-', viable: true },
    { text: '348 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '348', viable: true },
    { text: '652 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '652', viable: true },

    //{ text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '-', viable: true },
    //{ text: '350 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '350.1', viable: true },
    //{ text: '-', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '-', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '-', viable: true },
    { text: '350 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '350', viable: true },
    { text: '-', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '-', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '-', viable: true },
    { text: '373 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '373.4', viable: true },
    { text: '-', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '-', viable: true },

    { text: '-', cols: 1, rows: 2, color: '#000096', icon: 'commute', size: '9', battery: '-', viable: true },
    { text: '475 kWh', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '12', battery: '475', viable: true },
    { text: '-', cols: 1, rows: 1, color: '#000096', icon: 'directions_car', size: '18', battery: '-', viable: true },

  ];
  gridColors = ["#30a5f8", "#2d9af2", "#298feb", "#2684e5", "#2379de", "#206ed8", "#1d63d1", "#1a58cb", "#164dc4", "#1342bd", "#1037b7", "#0d2cb0", "#0a21aa", "#0616a3", "#030b9d"];
  @ViewChild('selectPax') selectPax;
  proute = '';
  pbattery = '';
  pcollection = '';
  psize = '';
  ppax = '';
  phvac = '';
  actual_ruta;
  actual_pos;
  selectedBRT = false;
  selectedConf = false;


  constructor(public mystate: StateService, public data: DataService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    public auth: AuthService, public mapser: MapService) {

    for (const entry of this.names) {
      this.matIconRegistry.addSvgIcon(
        entry,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/SGV/' + entry + '.svg'
        )
      );
    }
  }

  ngOnInit(): void {
    this.mystate.currentPax = 'HIGH'
    console.log("state.busname", this.mystate.busNames)
    //this.data.drivingCycle = 'Real'
    //this.mystate.currentRoute='None'
    this.routeColor = new Array(this.mystate.busNames.length);
    this.routeColor.fill('white');
    this.routeBackground = new Array(this.mystate.busNames.length);
    this.routeBackground.fill('#000096');

    /* console.log('inicio bus options');
    console.log('this.mystate.currentType: ', this.mystate.currentType);
    console.log('this.mystate.currentBusSize: ', this.mystate.currentBusSize);
    console.log('this.mystate.currentBattery: ', this.mystate.currentBattery); */
    if (this.mystate.currentRoute !== 'None' && this.mystate.currentRoute !== '') {
      this.mystate.busNames.forEach((c: any, index: number) => {
        if (c === this.mystate.currentRoute) {
          this.routeColor[index] = '#000096';
          this.routeBackground[index] = 'white';
          this.previousRoute = index;
        }
      });
    }
    if (this.mystate.currentType !== '' && this.mystate.currentBattery !== '' && this.mystate.currentRoute !== 'None') {
      /* if (this.mystate.currentBusSize === '12') {
        this.paxreal = this.pax12;
      } else if (this.mystate.currentBusSize === '18') {
        this.paxreal = this.pax18;
      } */
      switch (this.mystate.currentType) {
        case 'Opportunity':
          this.oportunos.forEach((c: any, index: number) => {
            if (c.size === this.mystate.currentBusSize && c.battery === this.mystate.currentBattery) {
              c.color = '#4bc9ff';
              this.previousOportunity = index;
            }
          });
          this.oportunosColor = '#000096';
          this.oportunosFontColor = 'white';
          this.changeMetersColors(this.mystate.currentBusSize);
          break;
        case 'Overnight':
          this.nocturnos.forEach((c: any, index: number) => {
            if (c.size === this.mystate.currentBusSize && c.battery === this.mystate.currentBattery) {
              c.color = '#4bc9ff';
              this.previousOvernight = index;
            }
          });
          this.nocturnosColor = '#000096';
          this.nocturnosFontColor = 'white';
          this.changeMetersColors(this.mystate.currentBusSize);
          break;
        default:
          break;
      }
      this.setNonViableBuses();
    }
  }

  deleteSelectedConf() {
    this.selectedConf = !this.selectedConf;

  }
  checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
      return val === arrVal;
    });
  }
  async saveBusConfiguration(index: number, type: string): Promise<void> {
    console.log("Accion SaveBusConfiguration")
    console.log("this.auth.CurrentBusConfigurations", this.auth.CurrentBusConfigurations)

    setTimeout(async () => {
      // let entradas = {};
      if (type === 'Opportunity') {
        // console.log('Opportunity_' + this.oportunos[this.selectedOpor].battery + '_' + this.oportunos[this.selectedOpor].size + '_');
        this.mystate.currentType = 'Opportunity';
        
        this.selectedOpor = index;
        const data = {
          index: this.selectedOpor,
          type: 'Opportunity',
          size: this.oportunos[this.selectedOpor].size,
          battery: this.oportunos[this.selectedOpor].battery,
          pax: this.mystate.currentPax,
          hvac: this.mystate.currentHVAC,
          collection: this.mystate.currentCollection,
          route: this.mystate.currentRoute
        };
        //"limagrupopolo_101op2_Opportunity_12m_63.5kWh_hvacON_passHIGH"
        var cadena_portfolio = this.mystate.currentCollection + "_" + this.mystate.currentRoute + "_" + "Opportunity" + "_" + this.oportunos[this.selectedOpor].size + "m_" + this.oportunos[this.selectedOpor].battery + "kWh_" + this.mystate.currentHVAC + "_pass" + this.mystate.currentPax;
        var start_cadena = this.mystate.currentCollection + "_" + this.mystate.currentRoute + "_";
        var end_cadena = "_" + "Opportunity" + "_" + data.size + "m_" + data.battery + "kWh_" + this.mystate.currentHVAC + "_pass" + this.mystate.currentPax;

        console.log("cadena_portfolio", cadena_portfolio)
        const found_portfolio = this.data.listPortfolio.find(element => element["portfolioid"] == cadena_portfolio)
        console.log("found portfolio ", found_portfolio)
        var remplazo = false;
        if (this.data.listBusConfiguration.length < 1 || this.data.listBusConfiguration == undefined) {
          this.data.listBusConfiguration.push(found_portfolio);
        } else {
          for (let i = 0; i < this.data.listBusConfiguration.length; i++) {
            var element = this.data.listBusConfiguration[i];
            //console.log("correcion busoption",element["routeid"],found_portfolio["routeid"])
            if (element["routeid"] == found_portfolio["routeid"]) {
              remplazo = true;
              this.data.listBusConfiguration[i] = found_portfolio;
            }
          }
          if (remplazo == false) {
            this.data.listBusConfiguration.push(found_portfolio);
          }
        }

        var remplazo_query = false;
        if ( this.data.listStartQuery.length < 1 || this.data.listStartQuery == undefined) {
          this.data.listStartQuery.push(start_cadena)
          this.data.listEndQuery.push(end_cadena)
        } else {
          for (let i = 0; i < this.data.listStartQuery.length; i++) {
            const element = this.data.listStartQuery[i];
            if (element == start_cadena) {
              remplazo_query = true;
              this.data.listStartQuery[i]=start_cadena
              this.data.listEndQuery[i]=end_cadena
            }
          }
          if (remplazo_query == false) {
            this.data.listStartQuery.push(start_cadena)
            this.data.listEndQuery.push(end_cadena)
          }
        }

        //await this.data.getAllPotenciaAcum()
        await this.data.updateTHEDATAPotencia(data)



        this.auth.setBusConfiguration(this.mystate.currentCollection + this.mystate.currentRoute, data);
        const cadena = JSON.stringify(this.auth.currentBusConfigurations);
        await this.auth.saveBusConfig(this.mystate.currentCollection + this.mystate.currentRoute, cadena).subscribe((res: any) => {

        });
      } else if (type === 'Overnight') {

        this.mystate.currentType = 'Overnight';
        this.selectedOver = index;
        // console.log('Overnight_' + this.oportunos[this.selectedOver].battery + '_' + this.oportunos[this.selectedOver].size + '_');
        const data = {
          index: this.selectedOver,
          type: 'Overnight',
          size: this.nocturnos[this.selectedOver].size,
          battery: this.nocturnos[this.selectedOver].battery,
          pax: this.mystate.currentPax,
          hvac: this.mystate.currentHVAC,
          collection: this.mystate.currentCollection,
          route: this.mystate.currentRoute
        };
        console.log("data seleccion bus", data)
        var cadena_portfolio = this.mystate.currentCollection + "_" + this.mystate.currentRoute + "_" + "Overnight" + "_" + this.nocturnos[this.selectedOver].size + "m_" + this.nocturnos[this.selectedOver].battery + "kWh_" + this.mystate.currentHVAC + "_pass" + this.mystate.currentPax;
        console.log("cadena_portfolio", cadena_portfolio)
        var start_cadena = this.mystate.currentCollection + "_" + this.mystate.currentRoute + "_";
        var end_cadena = "_" + "Overnight" + "_" + data.size + "m_" + data.battery + "kWh_" + this.mystate.currentHVAC + "_pass" + this.mystate.currentPax;
        console.log("cadena para consulta", start_cadena, end_cadena)
        const found_portfolio = this.data.listPortfolio.find(element => element["portfolioid"] == cadena_portfolio)
        console.log("found portfolio ", found_portfolio)
        console.log("listbusconfiguration", this.data.listBusConfiguration)
        var remplazo = false;
        if (this.data.listBusConfiguration.length < 1 || this.data.listBusConfiguration == undefined || this.data.listBusConfiguration[0] == undefined) {
          console.log("Entrando a listbusconfigration undefined")
          this.data.listBusConfiguration.push(found_portfolio);
        } else {
          for (let i = 0; i < this.data.listBusConfiguration.length; i++) {
            var element = this.data.listBusConfiguration[i];
            console.log("element de busconfiguration", element)
            console.log("correcion busoption", element["routeid"], found_portfolio["routeid"])
            if (element["routeid"] == found_portfolio["routeid"]) {
              remplazo = true;
              this.data.listBusConfiguration[i] = found_portfolio;
            }
          }
          if (remplazo == false) {
            this.data.listBusConfiguration.push(found_portfolio);
          }
        }

        var remplazo_query = false;
        if (this.data.listStartQuery.length < 1 || this.data.listStartQuery == undefined) {
          this.data.listStartQuery.push(start_cadena)
          this.data.listEndQuery.push(end_cadena)
        } else {
          for (let i = 0; i < this.data.listStartQuery.length; i++) {
            const element = this.data.listStartQuery[i];
            if (element == start_cadena) {
              remplazo_query = true;
              this.data.listStartQuery[i]=start_cadena
              this.data.listEndQuery[i]=end_cadena
            }
          }
          if (remplazo_query == false) {
            this.data.listStartQuery.push(start_cadena)
            this.data.listEndQuery.push(end_cadena)
          }
        }
        //await this.data.getAllPotenciaAcum()
        await this.data.updateTHEDATAPotencia(data)

        this.auth.setBusConfiguration(this.mystate.currentCollection + this.mystate.currentRoute, data);
        const cadena = JSON.stringify(this.auth.currentBusConfigurations);
        await this.auth.saveBusConfig(this.mystate.currentCollection + this.mystate.currentRoute, cadena).subscribe((res: any) => {
          // console.log('Ya envie datos: ', res);
        });
      }

      ///////////// call vehicle configuration
      // this.data.getVehicleProperties(entradas);
      await this.data.getAllVehicles();

    }, 200);
    //this.mystate.dataChargerPotencia = this.mystate.THEDATA[9][0]
    //this.mystate.dataChargerPotencia(this.mystate.THEDATA[9][0])
    //this.mystate.dataChargerEnergia(this.mystate.THEDATA[9][1])
  }

  dragEnd($event: CdkDragEnd): void {
    const { offsetLeft, offsetTop } = $event.source.element.nativeElement;
    const { x, y } = $event.distance;
    this.positionX = offsetLeft + x;
    this.positionY = offsetTop + y;
    // this.showPopup = true;
    // console.log({ x: this.positionX, y: this.positionY });
    const lax = this.mystate.busOptionsPosition.x + x;
    const lay = this.mystate.busOptionsPosition.y + y;
    this.mystate.busOptionsPosition = { x: lax, y: lay };
  }

  changeMetersColors(entrada: string) {
    switch (entrada) {
      case '9':
        this.ninemcolor = '#000096';
        this.ninefontcolor = 'white';

        this.eighteenmcolor = 'white';
        this.eighteenfontcolor = '#000096';

        this.twelvemcolor = 'white';
        this.twelvefontcolor = '#000096';
        break;
      case '12':
        this.twelvemcolor = '#000096';
        this.twelvefontcolor = 'white';

        this.ninemcolor = 'white';
        this.ninefontcolor = '#000096';

        this.eighteenmcolor = 'white';
        this.eighteenfontcolor = '#000096';
        break;
      case '18':
        this.eighteenmcolor = '#000096';
        this.eighteenfontcolor = 'white';

        this.ninemcolor = 'white';
        this.ninefontcolor = '#000096';

        this.twelvemcolor = 'white';
        this.twelvefontcolor = '#000096';
        break;
      default:
        break;
    }
  }



  async setOpportunityOption(index: any, norefresh: number): Promise<void> {
    console.log("Accion SetOpportunity")
    console.log("revision datos set Opp", this.mystate.dataChargerEnergia, this.mystate.dataChargerPotencia, this.mystate.dataChargersInfo)
    // this.mystate.currentRoute = this.mystate.aux_route
    this.mystate.metodoCarga="Oportunidad"
    if (this.proute === this.mystate.currentRoute && this.pbattery === this.oportunos[index].battery
      && this.pcollection === this.mystate.currentCollection && this.psize === this.oportunos[index].size
      && this.ppax === this.mystate.currentPax && this.phvac === this.mystate.currentHVAC) {
      // console.log('nada a cambiado');
      return;
    } else {
      this.proute = this.mystate.currentRoute;
      this.pbattery = this.oportunos[index].battery;
      this.pcollection = this.mystate.currentCollection;
      this.psize = this.oportunos[index].size;
      this.ppax = this.mystate.currentPax;
      this.phvac = this.mystate.currentHVAC;

    }
    //limagrupopolo_101op3_Overnight_12m_350kWh_hvacON_passHIGH_150_CANTA CALLAO
    //101op2_195_limagrupopolo_18_HIGH_hvacOFF
    this.cadena_busquedafiltro= this.pcollection+'_'+this.proute+'_'+'Opportunity_'+this.psize+'m_'+this.pbattery+'kWh_'+this.phvac+'_pass'+this.ppax
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    console.log('Cadena Oportunidad', this.cadena_busquedafiltro);
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();


    if (this.previousOportunity !== index) {
      if (this.previousOportunity === -1) {
        this.previousOportunity = index;
      } else {
        this.oportunos[this.previousOportunity].color = '#000096';
        this.previousOportunity = index;
      }
    }
    if (this.previousOvernight !== -1) {
      this.nocturnos[this.previousOvernight].color = '#000096';
    }
    this.changeMetersColors(this.oportunos[index].size);
    this.oportunos[index].color = '#4bc9ff';
    this.oportunosColor = '#000096';
    this.nocturnosColor = 'white';
    this.nocturnosFontColor = '#000096';
    this.oportunosFontColor = 'white';


    this.mystate.currentType = 'Opportunity';
    this.mystate.currentBusSize = this.oportunos[index].size;
    this.mystate.currentBattery = this.oportunos[index].battery;
    if (this.mystate.currentBusSize !== this.stateofsize) {
      // this.selectPax.open();
      /* if (this.mystate.currentBusSize === '12')
      this.mystate.currentPax = '0'; */
      this.stateofsize = this.mystate.currentBusSize;
      
      // this.mystate.currentPax = 'MID';
    }

    for (let i = 0; i < this.mystate.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.mystate.THEDATA[i] = [];
    }

    this.mystate.changePax('nav');
    this.mystate.endAuto = '';
    this.mystate.maximumConsumption = '';
    if (this.mystate.currentHVAC !== '' && this.mystate.currentPax !== '' && norefresh === 1) {
      this.selectedOpor = index;
      this.selectedOver = -1;
      // console.log('oppor updatethedata');
      await this.data.updateTHEDATA2();
      // console.log('oppor end updatethedata');
      this.data.setLabels(); // */
      this.mystate.changeBattery(this.mystate.currentBattery);
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    }
    // console.log('setOpportunityOption ends ', norefresh);
    this.selectedOpor = index;
    const entradas = {
      chargingmethod: 'Opportunity',
      vehiclesize: parseInt(this.oportunos[this.selectedOpor].size),
      batteryenergy: parseFloat(this.oportunos[this.selectedOpor].battery),
      collection: this.mystate.currentCollection,
      route: this.mystate.currentRoute
    };
    this.data.getVehicleProperties(entradas);
    //Get ChargerAnalysisData
    
    //await this.data.getDataChargerAnalysis(params)
    console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9], this.mystate.dataChargerPotencia)
  }

  async newsetOvernightOption(): Promise<void> {
    //this.mystate.currentRoute = this.mystate.aux_route;
    console.log("Accion NewsetOvetnight")
    console.log("revision datos set Over", this.mystate.dataChargerEnergia, this.mystate.dataChargerPotencia, this.mystate.dataChargersInfo)
    this.mystate.metodoCarga="Nocturno"
    if (this.proute === this.mystate.currentRoute && this.pbattery === this.mystate.currentBattery
      && this.pcollection === this.mystate.currentCollection && this.psize === this.mystate.currentBusSize
      && this.ppax === this.mystate.currentPax && this.phvac === this.mystate.currentHVAC) {
      return;
    } else {
      this.proute = this.mystate.currentRoute;
      this.pbattery = this.mystate.currentBattery;
      this.pcollection = this.mystate.currentCollection;
      this.psize = this.mystate.currentBusSize;
      this.ppax = this.mystate.currentPax;
      this.phvac = this.mystate.currentHVAC;
    }
    this.cadena_busquedafiltro= this.pcollection+'_'+this.proute+'_'+'Overnight_'+this.psize+'m_'+this.pbattery+'kWh_'+this.phvac+'_pass'+this.ppax
    console.log('Cadena Nocturno', this.cadena_busquedafiltro);
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    //console.log('index: ', index);
    // console.log('setOvernightOption: ', norefresh);
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();
    if (this.previousOvernight !== 1) {
      if (this.previousOvernight === -1) {
        this.previousOvernight = 1;
      } else {
        this.nocturnos[this.previousOvernight].color = '#000096';
        this.previousOvernight = 1;
      }
    }
    if (this.previousOportunity !== -1) {
      this.oportunos[this.previousOportunity].color = '#000096';
    }
    this.changeMetersColors(this.mystate.currentBusSize);
    this.nocturnos[1].color = '#4bc9ff';
    this.nocturnosColor = '#000096';
    this.nocturnosFontColor = 'white';
    this.oportunosColor = 'white';
    this.oportunosFontColor = '#000096';
    this.mystate.currentType = 'Overnight';
    if (this.mystate.currentBusSize !== this.stateofsize) {
      this.stateofsize = this.mystate.currentBusSize;
    }
    for (let i = 0; i < this.mystate.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.mystate.THEDATA[i] = [];
    }
    this.mystate.changePax('nav');
    this.mystate.endAuto = '';
    this.mystate.maximumConsumption = '';
    if (this.mystate.currentHVAC !== '' && this.mystate.currentPax !== '' && 1 === 1) {
      this.selectedOpor = -1;
      this.selectedOver = 1;
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.mystate.changeBattery(this.mystate.currentBattery);
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    }
    this.selectedOver = 1;
    console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
    const entradas = {
      chargingmethod: 'Overnight',
      vehiclesize: parseInt(this.mystate.currentBusSize),
      batteryenergy: parseFloat(this.mystate.currentBattery),
      collection: this.mystate.currentCollection,
      route: this.mystate.currentRoute
    };
    this.data.getVehicleProperties(entradas);
    console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
  }
  async setOvernightOption(index: any, norefresh: number): Promise<void> {
    //this.mystate.currentRoute = this.mystate.aux_route;
    console.log("Accion setOvetnight")
    console.log("revision datos set Over", this.mystate.dataChargerEnergia, this.mystate.dataChargerPotencia, this.mystate.dataChargersInfo)
    this.mystate.metodoCarga="Nocturno"
    if (this.proute === this.mystate.currentRoute && this.pbattery === this.nocturnos[index].battery
      && this.pcollection === this.mystate.currentCollection && this.psize === this.nocturnos[index].size
      && this.ppax === this.mystate.currentPax && this.phvac === this.mystate.currentHVAC) {
      return;
    } else {
      this.proute = this.mystate.currentRoute;
      this.pbattery = this.nocturnos[index].battery;
      this.pcollection = this.mystate.currentCollection;
      this.psize = this.nocturnos[index].size;
      this.ppax = this.mystate.currentPax;
      this.phvac = this.mystate.currentHVAC;
    }
    this.cadena_busquedafiltro= this.pcollection+'_'+this.proute+'_'+'Overnight_'+this.psize+'m_'+this.pbattery+'kWh_'+this.phvac+'_pass'+this.ppax
    console.log('Cadena Nocturno', this.cadena_busquedafiltro);
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    //console.log('index: ', index);
    // console.log('setOvernightOption: ', norefresh);
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();


    if (this.previousOvernight !== index) {
      if (this.previousOvernight === -1) {
        this.previousOvernight = index;
      } else {
        this.nocturnos[this.previousOvernight].color = '#000096';
        this.previousOvernight = index;
      }
    }
    if (this.previousOportunity !== -1) {
      this.oportunos[this.previousOportunity].color = '#000096';
    }
    this.changeMetersColors(this.nocturnos[index].size);
    this.nocturnos[index].color = '#4bc9ff';
    this.nocturnosColor = '#000096';
    this.nocturnosFontColor = 'white';
    this.oportunosColor = 'white';
    this.oportunosFontColor = '#000096';


    this.mystate.currentType = 'Overnight';
    this.mystate.currentBusSize = this.nocturnos[index].size;
    this.mystate.currentBattery = this.nocturnos[index].battery;
    // const lista = ['0', '24', '48', '80'];
    // if (!lista.includes(this.mystate.currentPax))
    if (this.mystate.currentBusSize !== this.stateofsize) {
      // this.selectPax.open();
      // this.mystate.currentPax = '0';
      this.stateofsize = this.mystate.currentBusSize;
      /* if (this.mystate.currentBusSize === '12') {
        this.paxreal = this.pax12;

      } else if (this.mystate.currentBusSize === '18') {
        this.paxreal = this.pax18;

      } */
      // this.mystate.currentPax = 'MID';
    }


    for (let i = 0; i < this.mystate.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.mystate.THEDATA[i] = [];
    }

    this.mystate.changePax('nav');
    this.mystate.endAuto = '';
    this.mystate.maximumConsumption = '';
    if (this.mystate.currentHVAC !== '' && this.mystate.currentPax !== '' && norefresh === 1) {
      this.selectedOpor = -1;
      this.selectedOver = index;
      // console.log('overnite updatethedata');
      //console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
      await this.data.updateTHEDATA2();
      //console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
      // console.log('overnite end updatethedata');
      this.data.setLabels(); // */
      this.mystate.changeBattery(this.mystate.currentBattery);
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    }
    this.selectedOver = index;
    console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
    const entradas = {
      chargingmethod: 'Overnight',
      vehiclesize: parseInt(this.nocturnos[this.selectedOver].size),
      batteryenergy: parseFloat(this.nocturnos[this.selectedOver].battery),
      collection: this.mystate.currentCollection,
      route: this.mystate.currentRoute
    };
    this.data.getVehicleProperties(entradas);
    console.log("THEDATA[9] busesoption", this.mystate.THEDATA[9])
  }

  hoverParaRuta(entrada: any) {
    //console.log('Haciendo hover en ', entrada);
    this.mystate.changeRoute(entrada);
  }

  hoverOutParaRuta(entrada: any) {
    //console.log('Haciendo hover en ', entrada);
    this.mystate.changeRoute('EndHover');
  }

  getPositionRute(ruta: string) {
    for (let i = 0; i < this.mystate.busNames.length; i++) {
      if (ruta === this.mystate.busNames[i]) {
        this.mystate.currentRoutePosition = i;
      }
    }
  }

  public setCurrentRoute = async (route: any, pos: number) => {
    const datos = {
      whichone: 'clear',
    };
    //this.currentRouteSelected= route;
    this.mystate.currentRoute = 'None'
    this.data.isBRT = false;
    this.data.isHVAC = false;
    this.mystate.changeDetailsEvent(datos);
    this.data.drivingCycle = 'Observado'
    /*console.log('setCurrentRoute: ', route);
    console.log('this.mystate.currentRoute: ', this.mystate.currentRoute); // */
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();
    // this.state.currentType = '';
    // this.state.currentBusSize = '';
    // this.state.currentBattery = '';
    this.mystate.endAuto = '';
    this.mystate.maximumConsumption = '';
    // console.log('this.auth.currentBusConfigurations[route]: ', this.auth.currentBusConfigurations[this.mystate.currentCollection+route]);
    if (this.auth.currentBusConfigurations !== null && this.auth.currentBusConfigurations[this.mystate.currentCollection + route] !== undefined) {
      // console.log('this.auth.currentBusConfigurations[route]: ', this.auth.currentBusConfigurations[this.mystate.currentCollection+route]);
      // console.log('Sí hay configuraciones para ', route);
      if (this.auth.currentBusConfigurations[this.mystate.currentCollection + route].type === 'Overnight') {
        this.setOvernightOption(this.auth.currentBusConfigurations[this.mystate.currentCollection + route].index, 0);
      } else {
        this.setOpportunityOption(this.auth.currentBusConfigurations[this.mystate.currentCollection + route].index, 0);
      }
      this.mystate.currentPax = this.auth.currentBusConfigurations[this.mystate.currentCollection + route].pax;
      this.mystate.currentHVAC = this.auth.currentBusConfigurations[this.mystate.currentCollection + route].hvac;
    } else {

      this.mystate.currentBattery = '';
      this.mystate.currentBusSize = '';
      this.pbattery = '';
      this.proute = route;
      // console.log('No hay configuraciones para ', this.proute);
      if (this.previousOportunity !== -1) {
        this.oportunos[this.previousOportunity].color = '#000096';
        this.previousOportunity = -1;
      }
      if (this.previousOvernight !== -1) {
        this.nocturnos[this.previousOvernight].color = '#000096';
        this.previousOportunity = -1;
      }
    }
    // console.log('#3');
    if (this.mystate.currentRoute === route) {
      this.routeColor[pos] = 'white';
      this.routeBackground[pos] = '#000096';
      this.mystate.currentRoute = 'None';
      this.currentRouteSelected = route;
      //this.mystate.aux_route = 'None';
      // return;
    } else if (this.previousRoute !== -1) {
      this.routeColor[this.previousRoute] = 'white';
      this.routeBackground[this.previousRoute] = '#000096';
      this.mystate.currentRoute = route;
      this.currentRouteSelected = route;
      //this.mystate.aux_route = route;

      this.routeColor[pos] = '#000096';
      this.routeBackground[pos] = 'white';
      this.previousRoute = pos;
    } else {
      this.mystate.currentRoute = route;
      //this.mystate.aux_route = route;
      this.routeColor[pos] = '#000096';
      this.routeBackground[pos] = 'white';
      this.previousRoute = pos;
    }
    if (this.mystate.currentRoute === 'None') {
      this.mystate.currentRoutePosition = -1;
    } else {
      this.getPositionRute(this.mystate.currentRoute);
    }
    for (let i = 0; i < this.mystate.THEDATA.length; i++) {
      if (i !== 6)
        this.mystate.THEDATA[i] = [];
    }

    this.mystate.changePax('');
    this.mystate.processing = true;
    // console.log('getting route properties');
    await this.data.getRouteProperties(route, this.mystate.currentCollection);
    
    // console.log('done getting route properties');
    this.setAllViableBuses();
    if (this.mystate.currentRoute !== 'None') {
      this.setNonViableBuses();
    }
    //await this.data.getAllCyclesInputs();
    // console.log('procesando getAllCyclesInputsEvo: ');
    await this.data.getAllCyclesInputsEvo(route, this.mystate.currentCollection);
    // console.log('ending procesando getAllCyclesInputsEvo: ');
    // console.log('this.auth.currentBusConfigurations: ', this.auth.currentBusConfigurations);
    // console.log(this.mystate.currentBattery, this.mystate.currentPax, this.mystate.currentBusSize, this.mystate.currentType);
    if (this.mystate.currentBattery !== '' && this.mystate.currentPax !== '' &&
      this.mystate.currentBusSize !== '' && this.mystate.currentType !== '') {
      /* if (this.auth.currentBusConfigurations === null ||
        this.auth.currentBusConfigurations[this.mystate.currentCollection + route] === undefined) {
        this.mystate.currentPax = 'MID';
      } */
      // console.log('LLamando datos updateTHEDATA2');
      // console.log('this.mystate.currentPax: ', this.mystate.currentPax);
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      // console.log('done llamado updateTHEDATA2');
    }
    this.mystate.processing = false;
    this.mystate.changePax('nav');
    //}
    this.mystate.changeRoute(this.mystate.currentRoute);
    // this.onSidenavClose();
    this.mystate.cyclesListOnlyCC = this.data.hours.filter((c: any, index: number) => {
      return this.data.trayectos[index] === 'CC';
    });
    // console.log('this.mystate.cyclesListOnlyCC: ', this.mystate.cyclesListOnlyCC);
    this.mystate.currentCycle = 0;
    //this.mapser.addMarkerTest();    //await this.data.getAllPotenciaAcum()
    //console.log("MostrarCargadoresMapa")
    this.mapser.deleteAllChargersToMap()
    
    var selectechargerroute = this.data.allRouteProperties[route]
    //console.log("MostrarCargadoresMapa", selectechargerroute)
    await this.data.getPortfolio()
    this.mapser.addSelectedCharger(selectechargerroute.namechargers,selectechargerroute.latitudechargers, selectechargerroute.longitudechargers)

  };

  setAllViableBuses(): void {
    for (const iterator of this.oportunos) {
      if (iterator.color !== '#4bc9ff')
        iterator.color = '#000096';
      iterator.viable = true;
    }
    for (const iterator of this.nocturnos) {
      if (iterator.color !== '#4bc9ff')
        iterator.color = '#000096';
      iterator.viable = true;
    }
  }

  setNonViableBuses(): void {
    const thevalues = this.data.allRouteProperties[this.mystate.currentRoute].nonviablevehicle;
    // console.log('nonviablevehicle: ', thevalues);

    for (const iterator of this.oportunos) {
      let laquery: any;

      /* if (this.mystate.currentBattery !== '') {
        laquery = 'Opportunity_18m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
        if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {
          iterator.viable = false;
        }

        laquery = 'Opportunity_12m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
        if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {
          iterator.viable = false;
        }
      } */

      laquery = 'Opportunity_' + iterator.size + 'm_' + iterator.battery + 'kWh_' + this.mystate.currentHVAC + '_pass' + this.mystate.currentPax;

      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {
        iterator.viable = false;
      }
      /* let laquery: any;
      if (this.mystate.currentBusSize === '12') {
        laquery = 'Opportunity_18m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
      } else {
        laquery = 'Opportunity_12m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
      }

      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {

        iterator.viable = false;
      }

      laquery = 'Opportunity_' + iterator.size + 'm_' + iterator.battery + 'kWh_' + this.mystate.currentHVAC + '_pass' + this.mystate.currentPax;

      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {

        iterator.viable = false;
      } */
    }

    for (const iterator of this.nocturnos) {
      let laquery: any;

      /* if (this.mystate.currentBattery !== '') {
        laquery = 'Overnight_18m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
        if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {
          iterator.viable = false;
        }
        laquery = 'Overnight_12m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
        if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {
          iterator.viable = false;
        }
      } */
      //
      laquery = 'Overnight_' + iterator.size + 'm_' + iterator.battery + 'kWh_' + this.mystate.currentHVAC + '_pass' + this.mystate.currentPax;
      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {

        iterator.viable = false;
      }

      /* let laquery: any;
      if (this.mystate.currentBusSize === '12') {
        laquery = 'Overnight_18m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
      } else {
        laquery = 'Overnight_12m_' + iterator.battery + 'kWh_hvacON_pass' + this.paxreal[this.paxreal.length - 2].op;
      }

      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {

        iterator.viable = false;
      }
      //

      laquery = 'Overnight_' + iterator.size + 'm_' + iterator.battery + 'kWh_' + this.mystate.currentHVAC + '_pass' + this.mystate.currentPax;

      if (thevalues.includes(laquery) && iterator.color !== '#4bc9ff') {

        iterator.viable = false;
      } */
    }
  }

  public setCurrentHVAC = async (hvac: any) => {
    this.mystate.currentHVAC = hvac.value;
    // const pos = this.mystate.busNames.indexOf(this.mystate.currentRoute);
    // await this.setCurrentRoute(this.mystate.currentRoute, pos);
    this.cadena_busquedafiltro= this.pcollection+'_'+this.proute+'_'+'Opportunity_'+this.psize+'m_'+this.pbattery+'kWh_'+this.mystate.currentHVAC+'_pass'+this.ppax
    console.log('Cadena HVAC', this.cadena_busquedafiltro);
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    if (this.mystate.currentBattery !== '') {
      // this.data.updateTHEDATA();
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.mystate.changeHVAC(hvac.value);
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    } else {
      this.setAllViableBuses();
      this.setNonViableBuses();
    }

    // this.onSidenavClose();
  };

  public setCurrentPax = async (pax: any) => {
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();
    /* console.log('pax.value: ', pax.value);
    console.log('pax.value type: ', typeof (pax.value)); */
    this.mystate.currentPax = pax.value;
    this.cadena_busquedafiltro= this.pcollection+'_'+this.proute+'_'+'Opportunity_'+this.psize+'m_'+this.pbattery+'kWh_'+this.phvac+'_pass'+this.mystate.currentPax
    console.log('Cadena PAX', this.cadena_busquedafiltro);
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    if (this.mystate.currentBattery !== '') {
      // this.data.updateTHEDATA();
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    } else {
      this.setAllViableBuses();
      this.setNonViableBuses();
    }
  };
  public setCurrentDrivingCycle = async (dc: any) => {
    console.log("Cambiando valor de DrivingCycle :", dc)

    this.data.isBRT = !this.data.isBRT
    this.data.isHVAC = !this.data.isHVAC
    this.mystate.currentHVAC = "hvacON";
    this.mystate.currentPax = "HIGH";
    this.cadena_busquedafiltro= this.pcollection+'_BRT_'+this.proute+'_'+'Opportunity_'+this.psize+'m_'+this.pbattery+'kWh_'+this.mystate.currentHVAC+'_pass'+this.mystate.currentPax
    console.log('Cadena BRT', this.cadena_busquedafiltro);
    await this.getDataFromChargerAnalysis()
    await this.getDataFromEstrategiaCarga()
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();
    /* console.log('pax.value: ', pax.value);
    console.log('pax.value type: ', typeof (pax.value)); */
    this.data.drivingCycle = dc.value;
    console.log("this.currentRouteSelected", this.currentRouteSelected)
    await this.data.getAllCyclesInputsEvo(this.mystate.currentRoute, this.mystate.currentCollection);
    //await this.data.getRouteProperties(this.currentRouteSelected, this.mystate.currentCollection);
    //await this.data.updateTHEDATA2();

    if (this.mystate.currentBattery !== '') {
      // this.data.updateTHEDATA();
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.mystate.changePax('nav');
      this.setAllViableBuses();
      this.setNonViableBuses();
    } else {
      this.setAllViableBuses();
      this.setNonViableBuses();
    }
    //this.setCurrentRoute(this.actual_ruta,this.actual_pos)
  };

  public async getDataFromChargerAnalysis(){
    console.warn("Entrando Funcion Get Data Charger desde Buses Option")
    await this.data.getDataChargerAnalysis(this.cadena_busquedafiltro)
    await this.data.getDataChargerAnalysisCritic(this.cadena_busquedafiltro)
  }

  public async getDataFromEstrategiaCarga(){
    await this.data.getDataEstrategiaCarga(this.cadena_busquedafiltro)
  }
}
