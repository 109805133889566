import { Component, ViewEncapsulation, Inject, ViewChild, OnInit } from '@angular/core';
import { AccumulationTheme, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';

@Component({
  selector: 'app-dashboard-estadisticas',
  templateUrl: './dashboard-estadisticas.component.html',
  styleUrls: ['./dashboard-estadisticas.component.scss']
})
export class DashboardEstadisticasComponent implements OnInit {

  constructor(public dataSer: DataService, public authService: AuthService, public state: StateService) {
  }
  //Conf Panel
  public allowDragging: boolean = false;

  ngOnInit(): void {
  }

}
