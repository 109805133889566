import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from './shared/auth/auth-service.service';
// import { IconsService } from './shared/icons/icons.service';
import { StateService } from './shared/state/state.service';
import {datosTraducidos} from './auxiliar_components/datatranslate/faketranslate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'logiosMVP';
  public dicttranslate:any

  @Output() public sidenavToggle = new EventEmitter();

  constructor(public state: StateService,
              public authService: AuthService,
              
              /* private iconService: IconsService */){
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public onSidenavCloseCharts = () => {
    this.state.changeChart(0);
  }

  ngOnInit(): void{
    this.state.dictTranslate = datosTraducidos
    this.dicttranslate = this.state.dictTranslate.cargas
    this.state.indiceTranslate = 0
    //console.log("mostrar traduccion", this.state.dictTranslate)
    // this.getResolution();
  }

  /* getResolution() {
    alert("Your screen resolution is: " + window.screen.width * window.devicePixelRatio + "x" + window.screen.height * window.devicePixelRatio +
    " and its size is " + window.screen.width + "x" + window.screen.height);
  } */
}
