<div [ngClass]=" state.currentRoute === 'None' ? 'tarjetaNuevaAlter' : 'tarjetaNueva'">
  <div style="display: flex; flex-direction: column; width: 10vw !important; color: white;  margin-right: 10px">
    <mat-grid-list cols="1" rowHeight="62px" style="margin-bottom: 1px;">
      <mat-grid-tile>
        Ruta
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{bus}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="display: flex; flex-direction: column;">

    <mat-grid-list cols="15" rowHeight="20px" style="width: 75vw;">
      <!-- SIZE -->
      <mat-grid-tile colspan="9" rowspan="1">
        12m
      </mat-grid-tile>
      <mat-grid-tile colspan="6" rowspan="1">
        Articulado
      </mat-grid-tile>

      <!-- TYPE -->
      <mat-grid-tile colspan="3" rowspan="1">
        Oportunidad
      </mat-grid-tile>
      <mat-grid-tile colspan="6" rowspan="1">
        Nocturno
      </mat-grid-tile>
      <mat-grid-tile colspan="3" rowspan="1">
        Oportunidad
      </mat-grid-tile>
      <mat-grid-tile colspan="3" rowspan="1">
        Nocturno
      </mat-grid-tile>

      <!-- BATTERY X2 -->
      <mat-grid-tile colspan="1" rowspan="1">
        55
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        63.5
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        76
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        315
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        324
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        348
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        350
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        373
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        475
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        102
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        195
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        216
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        544
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        640
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        652
      </mat-grid-tile>
    </mat-grid-list>


    <!-- USER DATA-->
    <div style="display: flex;">
      <mat-grid-list cols="15" rowHeight="55px" style="width: 75vw; margin-top: 1px;">
        <!-- SIZE -->
        <span *ngFor="let bus of state.busNames; index as j">
          <mat-grid-tile colspan="1" rowspan="1" *ngFor="let bat of batteries; index as i" [style.color]="'#000096'" [style.background-color]="cellcolor[j][i]">
            {{cellcolor[j][i] === '#4bc9ff' ? '' : ''}}
          </mat-grid-tile>
        </span>
      </mat-grid-list>
    </div>

  </div>
  </div>
