<div class="paradivsin">
  <span style="color: white;  height: 50px;">Distancia recorrida</span>


  <!-- <ejs-circulargauge *ngFor="let item of items" [id]="item.id+'dsingle'" width="100" height="58"  [margin]='margin'
     background="rgba(255,255,255,0.0)" style="border-bottom: 1px solid white; border-top: 1px solid white; color: white;  border-radius: 0px;" #gaugedisingle>
    <e-axes>
      <e-axis [labelStyle]="labelStyle" [maximum]="item.maximum">
        <e-ranges>
          <e-range start=0 [end]="state.distanceGaugeValue[item.id]" color='#4434ed' startWidth=5 endWidth=5>
          </e-range>
        </e-ranges>
        <e-pointers>

  </e-pointers>
  </e-axis>
  </e-axes>
  </ejs-circulargauge> -->


  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let item of items; index as i" style="padding:0px">
    <mat-grid-tile
      style="color: white; background-color: rgba(255,255,255,0.0);  border-radius: 0px; border-bottom: 1px solid white; border-top: 1px solid white;">
      <ejs-circulargauge [id]="item.id+'dsingle'" width="100" height="58" [margin]='margin'
        background="rgba(255,255,255,0.0)"

        #gaugedisingle>
        <e-axes>
          <e-axis [labelStyle]="labelStyle">
            <e-annotations>
              <e-annotation zIndex='1' *ngIf="state.distanceGaugeValue[item.id] != -1"
              content='<div id="contento" style="color:white;font-size:15px;font-family:Segoe UI;font-weight:semibold;">{{state.distanceGaugeValue[item.id]}}</div>' angle=0 radius='0%'>
              </e-annotation>
            </e-annotations>
            <e-ranges>
              <e-range start=0 [end]="state.distanceGaugeValue[item.id]  == -1? 0 : state.distanceGaugePercentage[item.id]" color='#ff6600' startWidth=6 endWidth=6>
              </e-range>
            </e-ranges>
            <e-pointers>
              <!-- <e-pointer [value]="state.powerGaugeValue[item.id]"></e-pointer> -->
            </e-pointers>
          </e-axis>
        </e-axes>
      </ejs-circulargauge>
    </mat-grid-tile>
  </mat-grid-list>

</div>
