import { HostListener, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { AuthService } from '../auth/auth-service.service';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService  {

  thecollections: any = null;
  private chargeranalysis: any = null;
  public get _chargeranalysis(): any {
    return this.chargeranalysis;
  }
  public set _chargeranalysis(value: any) {
    this.chargeranalysis = value;
  }

  constructor(private http: HttpClient,private state:StateService,
  private auth: AuthService) { }
  private dataUrl = this.state.backendURL +'data/';
  private baseUrl = this.state.backendURL 
  //private dataUrl = 'http://localhost:8000/data/';
  //private baseUrl = 'http://localhost:8000/';
  get collections(): any{
    return this.thecollections;
  }

  set collections(x : any){
    this.thecollections = x;
  }

  getAllCollections(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };
    return this.http.get<any>(this.dataUrl, httpOptions).pipe(retry(1));
  }

  addNewCollection(query: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };

    return this.http.post<any>(this.dataUrl, query, httpOptions).pipe(retry(1));
  }

  getSingleCollection(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };

    return this.http.get<any>(this.dataUrl + id, httpOptions).pipe(retry(1));
  }

  updateCollectionData(id: number, values: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };
    return this.http
      .patch<any>(this.dataUrl + id, values, httpOptions)
      .pipe(retry(1));
  }

  updateCollectionBuses(id: number, buses: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };
    return this.http
      .patch<any>(this.dataUrl + id, buses, httpOptions)
      .pipe(retry(1));
  }

  deleteCollection(colId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.auth.getToken(),
      }),
    };

    return this.http
      .delete<any>(this.dataUrl + colId, httpOptions)
      .pipe(retry(1));
  }

  
  limpiar(){
    // console.log('COLLECTIONS component clean');
    this.collections = null;
  }


  
}
