<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<body>
  <div class="login-container">
    <div class="login-image">
    </div>
    <div class="login-form">
      <div class="center-image"></div>
      <div class="nueva">
        <form [formGroup]="form" style="
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            color: blue;
          ">
          <!-- <br /> -->
          <mat-error *ngIf="loginInvalid">
            {{this.dataTIS.errorinvalid[this.id]}}
          </mat-error>
          <mat-form-field class="full-width-input" style="
              font-family: 'Poppins' !important;
              margin-top: 30px;
              width: 250px;
              min-height: 50px;
              align-self: center;
            ">
            <input style="font-family: 'Poppins' !important; background-color: #D6D8E1;    font-size: 1.2em;" matInput
              placeholder={{this.dataTIS.correo[this.id]}} formControlName="username" required />
            <span matPrefix>
              <mat-icon> email</mat-icon>
            </span>
            <mat-error>
              {{this.dataTIS.alertemail[this.id]}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input" style="
                font-family: 'Poppins' !important;
                margin-top: 30px;
                width: 250px;
                min-height: 50px;
                align-self: center;
            ">
            <input style="background-color: #D6D8E1;font-size: 1.2em;" matInput type="password" placeholder={{this.dataTIS.pw[this.id]}}
              formControlName="password" required />
            <span matPrefix>
              <mat-icon>lock</mat-icon>
            </span>
            <mat-error>
              {{this.dataTIS.alertpasswd[this.id]}}
            </mat-error>
          </mat-form-field>
          <div style="display: inline-flex; align-items: center;padding-top:20px">
            <mat-checkbox style="
            font-family: 'Poppins' !important; font-size: 14px;
          ">{{this.dataTIS.recordar[this.id]}}</mat-checkbox>
            <div style="margin-left: 40px; font-family: 'Poppins' !important; font-size: 14px; cursor: pointer;"
              (click)="onSubmit('p')">
              {{this.dataTIS.recoverpw[this.id]}}
            </div>
          </div>
          <!-- <br > <br /> <br /> -->
          <button (click)="onSubmit('l')" type="submit" style="
              font-family: 'Poppins' !important;
              border-radius: 25px;
              margin-top: 40px;
              margin-bottom: 20px;
            " mat-raised-button>
            {{this.dataTIS.longin[this.id]}}
          </button>

          <button (click)="onSubmit('r')" type="submit" style="
              font-family: 'Poppins' !important;
              border-radius: 25px;
              margin-top: 0px;
              margin-bottom: 20px;
            " mat-raised-button>
            {{this.dataTIS.singin[this.id]}}
          </button>
          <div class="tlogin">
            <div> <img src={{this.dataTimg.iconimagen[this.idimg]}} style="width: 25px;height: 25px;margin-right: 7px;"
                alt=""> </div>
            <div (click)="cambiarIdioma()">{{this.idioma}}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</body>

</html>