<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">




  <ejs-chart #chart12 *ngIf="cual === 1" id="energycont2" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip' (pointClick)="pointClick($event)" [animations]="animations">

    <e-series-collection>

      <e-series *ngFor="let chargerName of state.currentChargersNames" [dataSource]="state.THEDATA[9][1][chargerName][0].datos" type="Line" xName="name" yName="value"
        [name]="chargerName" legendShape='Rectangle' [animation]="animation">
      </e-series>

      <!--
        <e-series [dataSource]="state.THEDATA[12][1].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[12][1].nombre" legendShape='Rectangle' [animation]="animation">
      </e-series>
      <e-series [dataSource]="state.THEDATA[12][2].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[12][2].nombre" legendShape='Rectangle' [animation]="animation">
      </e-series>
      -->


      <!--
        <e-series [dataSource]='state.THEDATA[8][3].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[8][4].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Patio <b>${point.x}</b>'> </e-series>
      -->
    </e-series-collection>
  </ejs-chart>

  <!-- <ejs-chart *ngIf="cual === 2" id="energycont1" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip' (pointClick)="pointClick($event)" [animations]="animations">
    <e-series-collection>

      <e-series [dataSource]="state.THEDATA[8][5].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][5].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[8][6].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][6].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[8][7].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][7].nombre" legendShape='Rectangle' [animation]="animation"></e-series>


      <e-series [dataSource]='state.THEDATA[8][3].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[8][4].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Patio <b>${point.x}</b>'> </e-series>
    </e-series-collection>
  </ejs-chart> -->


</div>
