
<app-layout>
  <div class="demo_wrap" *ngIf="this.state.processing">
    <div class="myFront2">
      <mat-spinner diameter="100"></mat-spinner>
    </div>
  </div>
  <div class="demo_wrap" *ngIf="state.whichprocessing === 1">
    <div class="myFront3">
      {{this.dicttranslate.loadcoleccion[this.state.indiceTranslate]}}      
    </div>
  </div>
  <div class="demo_wrap" *ngIf="state.whichprocessing === 2">
    <div class="myFront3">
      {{this.dicttranslate.loadcoleccion2[this.state.indiceTranslate]}}
    </div>
  </div>
  <app-lateral-bar (sidenavToggle)="sidenav.toggle()"></app-lateral-bar>
  <div fxLayout="column" fxFlex>
    <mat-sidenav-container autosize [hasBackdrop]="false">
      <mat-sidenav #sidenav role="navigation" [disableClose]="true">
        <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <!--in here all the content must reside. We will add a navigation header as well-->
        <!-- <app-header (sidenavToggle)="sidenav.toggle()"></app-header> -->
        <!-- <app-header></app-header> -->
        <main>
          <!-- <div class="demo_wrap" (click)="onSidenavCloseCharts()" *ngIf="this.state.currentChart !== 0">
            <div class="myFront"></div>
          </div> -->


          <app-splash-screen></app-splash-screen>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</app-layout>
