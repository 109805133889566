<div class="nueva">
  <form fxLayout="column" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h2 class="cadena">ACTUALIZAR COLLECCIÓN</h2>
    <h1 *ngIf="actualizado">¡Actualización exitosa!</h1>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <!-- <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Identificador"
          formControlName="id"
        />
      </mat-form-field> -->

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Nombre"
          formControlName="name"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Propietario"
          formControlName="owner"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Longitud"
          formControlName="longitud"
        />
      </mat-form-field>
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Latitud"
          formControlName="latitud"
        />
      </mat-form-field>
      <button
        style="
          font-family: 'Poppins' !important;
          border-radius: 25px;
          margin-top: 40px;
          margin-bottom: 20px;
          max-width: 200px;
          align-self: center;
        "
        mat-raised-button
      >
        Actualizar datos
      </button>
    </div>
  </form>

  <div
    class="input-row"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="20px"
    fxLayoutGap.lt-md="0px"
  >
    <mat-form-field fxFlex>
      <mat-label>Lista de buses</mat-label>
      <mat-select [(ngModel)]="currentRoute">
        <mat-option *ngFor="let bus of datos.buses" [value]="bus">
          {{ bus }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        height: 35px;
      "
      (click)="deleteBus()"
    >
      Eliminar bus
    </button>
    <mat-form-field fxFlex>
      <input
        matInput
        type="text"
        placeholder="Nueva ruta"
        [(ngModel)]="nuevoBus"
      />
    </mat-form-field>

    <button
      mat-raised-button
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        height: 35px;
      "
      (click)="addBus()"
    >
      Adicionar bus
    </button>
  </div>

  <div
    class="input-row"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="20px"
    fxLayoutGap.lt-md="0px"
  >
    <mat-form-field fxFlex>
      <mat-label>Lista de cargadores</mat-label>
      <mat-select [(ngModel)]="currentCharger">
        <mat-option *ngFor="let charger of datos.chargersid" [value]="charger">
          {{ charger }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        height: 35px;
      "

    >
      Eliminar cargador
    </button>
    <mat-form-field fxFlex>
      <input
        matInput
        type="text"
        placeholder="Nuevo cargador"
        [(ngModel)]="nuevoCargador"
      />
    </mat-form-field>

    <button
      mat-raised-button
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        height: 35px;
      "
      (click)="addCargador()"
    >
      Adicionar cargador
    </button>
  </div>
</div>
