import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-charger-comparison',
  templateUrl: './charger-comparison.component.html',
  styleUrls: ['./charger-comparison.component.scss']
})
export class ChargerComparisonComponent implements OnInit {
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#800000', '#4bc8fe'],
  };
  //
  public dataTIR = this.state.dictTranslate.DashboardsGraph.interaccionred;
  public listaPlotPotencia = [];
  public listaPlotEnergia = [];
  public listaPlotBus = [];
  public listaDemandaEnergia = [];
  public tituloDefault;
  public seEscogio;
  public seleccionchart

  
  public dataComparison = this.state.listaComparacionesData["results"]
  public dataComparisonCritic = this.state.listaComparacionesDataCritic["results"]
  public listaPotencia = this.dataComparison["potencias"]
  public listaPuntos = this.dataComparison["puntoscarga"];
  public listaPotenciaCritic = this.dataComparisonCritic["potencias"]
  public listaPuntosCritic = this.dataComparisonCritic["puntoscarga"];
  public listaNocturno: any;
  public listaOportunidad: any;
  public fillComparison = this.state.listaComparacionesData["results"]
  public fillComparisonCritic = this.state.listaComparacionesDataCritic["results"]
  public fillPotencia = this.dataComparison["potencias"]
  public fillPotenciaCritic = this.dataComparisonCritic["potencias"]


  public selectedFiltroBoton
  public mostrarFiltroBoton
  public isNew = true
  //Layout Dashboard
  public cellSpacing: number[] = [20, 20]
  public allowDragging: boolean = true;
  public cellAspectRatio: number = 80 / 85;
  //Propieadades Graficos
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };

  ancho = '';
  alto = '';
  public axisxPotencia = {
    valueType: 'DateTime',
    title: this.dataTIR.graficodp.ejex[this.state.indiceTranslate],//'Hora Del Día',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  public maximoP: any = 0
  public axisyPotencia: any
  public axisxEnergia = {
    valueType: 'DateTime',
    title: this.dataTIR.graficode.ejex[this.state.indiceTranslate],//'Hora Del Día',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  public maximoE: any = 0
  public axisyEnergia: any
  public axisxBus = {
    title: this.dataTIR.graficobc.ejex[this.state.indiceTranslate],//'Hora Del Día',
    valueType: 'DateTime',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  public maximoBus: any = 0
  public axisyBus: any
  public axisxCargadores = {
    valueType: 'Category',
    //labelFormat: '{value}kW',
    title: this.dataTIR.graficocr.ejex[this.state.indiceTranslate],//"Potencias (kW)",
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  };
  public maximoC: any = 0
  public axisyCargadores:any
  public maximoEH: any = 0
  public axisyDemandaEH: any

  public axisxDemandaEH = {
    title: this.dataTIR.graficodeh.ejex[this.state.indiceTranslate],//"Hora del Día",
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    interval: 1,
  };
  public cargo = false;
  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }
  public cargadorespaletas = ["#010097", "#f4831e", "#7e7e83", "#800000"];
  public estaCompleto = true
  //

  @ViewChild('myDOMElement1') public chart1: ChartComponent;
  @ViewChild('myDOMElement2') public chart2: ChartComponent;
  @ViewChild('myDOMElement3') public chart3: ChartComponent;
  @ViewChild('myDOMElement4') public chart4: ChartComponent;
  @ViewChild('myDOMElement5') public chart5: ChartComponent;

  constructor(private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) {

  }
  extraerPuntosCarga() {
    this.listaPuntos = this.dataComparison["puntoscarga"];
    console.log("listaPuntos comparison", this.listaPuntos)
  }


  ngOnInit(): void {
    console.log("dataComparison", this.dataComparison)
    this.estaCompleto = this.state.listaEstadosDashboard["interaccionred"]

    this.selectedFiltroBoton = 0
    this.cargarDatos(this.selectedFiltroBoton)
    this.getMayoresValores()
    this.axisyPotencia = {
      valueType: 'Double',
      title: this.dataTIR.graficodp.ejey[this.state.indiceTranslate],//'Potencia (kW)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      maximum: this.maximoP
    }
    this.axisyBus = {
      valueType: 'Double',
      labelFormat: 'n0',
      title: this.dataTIR.graficobc.ejey[this.state.indiceTranslate],//'Número de Buses Cargando',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      interval: 1,
      minimum: 0,
      maximum: this.maximoBus + 1
    }
    this.axisyEnergia = {
      valueType: 'Double',
      title: this.dataTIR.graficode.ejey[this.state.indiceTranslate],//'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      maximum: this.maximoE 
    }
    this.axisyDemandaEH = {
      //labelFormat: '{value}kW',
      title: this.dataTIR.graficodeh.ejey[this.state.indiceTranslate],//"Energía (kWh)",
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      maximum: this.maximoEH + 50
    };
    this.axisyCargadores = {
      title: this.dataTIR.graficocr.ejey[this.state.indiceTranslate],//"Número de Cargadores",
      valueType: 'Double',
      labelFormat: 'n0',
      interval: 1,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      maximum: this.maximoC + 1
    };
    setTimeout(() => {
      this.seleccionchart = 1
      this.cargo = true;
    }, 1500)

    //
  }
  getMayoresValores() {
    var listaPlotPotencia = []
    var listaPlotBus = []
    var listaPlotEnergia = []
    var listaDemandaEnergia = []
    var listaOportunidad = []
    var listaNocturno = []

    var punto_primero = this.listaPuntos[0]
    var datosFiltrados = this.dataComparison[punto_primero]
    for (let i = 0; i < this.listaPotencia.length; i++) {
      var element = datosFiltrados[this.listaPotencia[i]];
      listaPlotPotencia.push(element["potencia"])
      listaPlotBus.push(element["bus"])
      listaPlotEnergia.push(element["energia"])
      listaDemandaEnergia.push(element["red"])
    }
    listaOportunidad = datosFiltrados["ListaDiurno"]
    listaNocturno = datosFiltrados["ListaNocturno"]

    var flatListPotencia = listaPlotPotencia.reduce((acc, curr) => acc.concat(curr), []);
    var maxValP = Math.max(...flatListPotencia.map(obj => obj.axisy));

    var flatListBus = listaPlotBus.reduce((acc, curr) => acc.concat(curr), []);
    var maxValB = Math.max(...flatListBus.map(obj => obj.axisy));

    var flatListEnergia = listaPlotEnergia.reduce((acc, curr) => acc.concat(curr), []);
    var maxValE = Math.max(...flatListEnergia.map(obj => obj.axisy));

    var flatListDemandaEnergia = listaDemandaEnergia.reduce((acc, curr) => acc.concat(curr), []);
    var maxValDE = Math.max(...flatListDemandaEnergia.map(obj => obj.axisy));

    var flatListOportunidad = listaOportunidad.reduce((acc, curr) => acc.concat(curr), []);
    var maxValOP = Math.max(...flatListOportunidad.map(obj => obj.y));

    var flatListNocturno = listaNocturno.reduce((acc, curr) => acc.concat(curr), []);
    var maxValNO = Math.max(...flatListNocturno.map(obj => obj.y));


    var listaPlotPotenciac = []
    var listaPlotBusc = []
    var listaPlotEnergiac = []
    var listaDemandaEnergiac = []
    var listaOportunidadc = []
    var listaNocturnoc = []
    var punto_primeroc = this.listaPuntosCritic[0]
    var datosFiltradosc = this.dataComparisonCritic[punto_primeroc]
    for (let i = 0; i < this.listaPotenciaCritic.length; i++) {
      var element = datosFiltradosc[this.listaPotenciaCritic[i]];
      listaPlotPotenciac.push(element["potencia"])
      listaPlotBusc.push(element["bus"])
      listaPlotEnergiac.push(element["energia"])
      listaDemandaEnergiac.push(element["red"])
    }
    listaOportunidadc = datosFiltradosc["ListaDiurno"]
    listaNocturnoc = datosFiltradosc["ListaNocturno"]

    var flatListPotenciac = listaPlotPotenciac.reduce((acc, curr) => acc.concat(curr), []);
    var maxValPc = Math.max(...flatListPotenciac.map(obj => obj.axisy));

    var flatListBusc = listaPlotBusc.reduce((acc, curr) => acc.concat(curr), []);
    var maxValBc = Math.max(...flatListBusc.map(obj => obj.axisy));

    var flatListEnergiac = listaPlotEnergiac.reduce((acc, curr) => acc.concat(curr), []);
    var maxValEc = Math.max(...flatListEnergiac.map(obj => obj.axisy));

    var flatListDemandaEnergiac = listaDemandaEnergiac.reduce((acc, curr) => acc.concat(curr), []);
    var maxValDEc = Math.max(...flatListDemandaEnergiac.map(obj => obj.axisy));

    var flatListOportunidadc = listaOportunidadc.reduce((acc, curr) => acc.concat(curr), []);
    var maxValOPc = Math.max(...flatListOportunidadc.map(obj => obj.y));

    var flatListNocturnoc = listaNocturnoc.reduce((acc, curr) => acc.concat(curr), []);
    var maxValNOc = Math.max(...flatListNocturnoc.map(obj => obj.y));

    this.maximoP = Math.max(maxValPc, maxValP) + 100;

    this.maximoBus = Math.max(maxValBc, maxValB);

    this.maximoE = Math.max(maxValEc, maxValE) + 100;

    this.maximoEH = Math.max(maxValDEc, maxValDE);

    var vop = Math.max(maxValOPc, maxValOPc);

    var vnoc = Math.max(maxValNOc, maxValNO);
    this.maximoC = Math.max(vop,vnoc)
    console.log("maximo", vop, vnoc, this.maximoBus, this.maximoP, this.maximoE, this.maximoEH)


  }
  cambiarFiltro(numero: any) {
    console.log("Cambio de Filtro Comparison", numero)
    this.selectedFiltroBoton = numero;
    this.cargarDatos(numero)
  }
  ngAfterViewInit() {
    this.chart1.renderSeries();

  }
  cargarDataNueva() {
    this.selectedFiltroBoton = 0
    this.isNew = true
    this.cargarDatos(this.selectedFiltroBoton)
  }
  cargarDataFinvida() {
    this.selectedFiltroBoton = 0
    this.isNew = false
    this.cargarDatos(this.selectedFiltroBoton)
  }
  cargarDatos(numero: any) {
    this.listaPlotPotencia = []
    this.listaPlotBus = []
    this.listaPlotEnergia = []
    this.listaDemandaEnergia = []
    this.listaOportunidad = []
    this.listaNocturno = []
    if (this.isNew) {
      console.log("## es Nuevo ##")
      var punto_primero = this.listaPuntos[numero]
      var datosFiltrados = this.dataComparison[punto_primero]
      for (let i = 0; i < this.listaPotencia.length; i++) {
        var element = datosFiltrados[this.listaPotencia[i]];
        this.listaPlotPotencia.push(element["potencia"])
        this.listaPlotBus.push(element["bus"])
        this.listaPlotEnergia.push(element["energia"])
        this.listaDemandaEnergia.push(element["red"])
      }
      this.listaOportunidad = datosFiltrados["ListaDiurno"]
      this.listaNocturno = datosFiltrados["ListaNocturno"]
    } else {
      console.log("## fin de vida ##")
      var punto_primero = this.listaPuntosCritic[numero]
      var datosFiltrados = this.dataComparisonCritic[punto_primero]
      for (let i = 0; i < this.listaPotenciaCritic.length; i++) {
        var element = datosFiltrados[this.listaPotenciaCritic[i]];
        this.listaPlotPotencia.push(element["potencia"])
        this.listaPlotBus.push(element["bus"])
        this.listaPlotEnergia.push(element["energia"])
        this.listaDemandaEnergia.push(element["red"])
      }
      this.listaOportunidad = datosFiltrados["ListaDiurno"]
      this.listaNocturno = datosFiltrados["ListaNocturno"]
    }
  }
  setInitDataMenu(): void {
    var punto_primero = this.listaPuntos[0]
    console.log("punto primero", punto_primero)
    var datosFiltrados = this.dataComparison[punto_primero]
    console.log("datosFiltrados", datosFiltrados)
    for (let i = 0; i < this.listaPotencia.length; i++) {
      var element = datosFiltrados[this.listaPotencia[i]];
      this.listaPlotPotencia.push(element["potencia"])
      this.listaPlotBus.push(element["bus"])
      this.listaPlotEnergia.push(element["energia"])
      this.listaDemandaEnergia.push(element["red"])
    }
    this.listaOportunidad = datosFiltrados["ListaDiurno"]
    this.listaNocturno = datosFiltrados["ListaNocturno"]

  }
  onChange(arg: any): void {
    this.seEscogio = true;
    this.seleccionchart = 1;
    var index = this.listaPuntos.indexOf(arg.value);
    var nombre = arg.value;
    var datosFiltrados = this.dataComparison[nombre]
    for (let i = 0; i < this.listaPotencia.length; i++) {
      var element = datosFiltrados[this.listaPotencia[i]];
      this.listaPlotPotencia.push(element["potencia"])
      this.listaPlotBus.push(element["bus"])
      this.listaPlotEnergia.push(element["energia"])
    }
    console.log("listaPlotPotencia", this.listaPlotBus)
    console.log("ON CHANGE TERMINADO")
  }
  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
  irPotencia() {
    this.seleccionchart = 3
  }
  irBus() {
    this.seleccionchart = 2
  }
  irEnergia() {
    this.seleccionchart = 1
  }
  irCargadores() {
    this.seleccionchart = 4
  }
  irDemandaEnergia() {
    this.seleccionchart = 5
  }
  irPuntoCarga(puntocarga) {
    console.log("puntocarga", puntocarga)
  }

  resetFiltros() {


  }
  public filtroPotencia(e: Event): void {

    this.listaPlotPotencia = []
    this.listaPlotBus = []
    this.listaPlotEnergia = []
    var index = this.listaPotencia.indexOf("104")
    if (index > -1) {
      this.listaPotencia.splice(index, 1)
    }
    var punto_primero = this.listaPuntos[0]
    var datosFiltrados = this.dataComparison[punto_primero];
    delete datosFiltrados["104"];
    this.listaPotencia = this.listaPotencia
    console.log("Filtro Aplicado", this.listaPotencia)
    console.log("Filtro Aplicado", datosFiltrados)


    for (let i = 0; i < this.listaPotencia.length; i++) {
      var element = datosFiltrados[this.listaPotencia[i]];
      this.listaPlotPotencia.push(element["potencia"])
      this.listaPlotBus.push(element["bus"])
      this.listaPlotEnergia.push(element["energia"])
    }
    this.listaOportunidad = datosFiltrados["ListaDiurno"]
    this.listaNocturno = datosFiltrados["ListaNocturno"]
    console.log("Filtro Aplicado", this.listaPlotBus)


  }
}
