import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-charge-begin',
  templateUrl: './charge-begin.component.html',
  styleUrls: ['./charge-begin.component.scss']
})
export class ChargeBeginComponent implements OnInit {
  loschargernames: any = [];
  items: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {

    /*  this.state.allLocations.forEach((cole: any) => {
       if (this.state.currentCollection === cole.colName) { */
    this.state.currentChargersNames.forEach((c: any) => {
      this.items.push(
        {
          id: c.replace(/\s/g, ""),
        }
      );
    });
    /*       }
        }); */
    // console.log('this.loschargernames: ', this.loschargernames);
  }


}
