import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
// import { Data } from '@angular/router';
import { StateService } from 'src/app/shared/state/state.service';
import { AuthService } from '../../../shared/auth/auth-service.service';
import { DataService } from '../../../shared/data/data-service.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DexieService } from 'src/app/shared/dexie/dexie.service';
import { MapsComponent } from 'src/app/visualization/maps/maps.component';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { ChartsNavigationComponent } from 'src/app/visualization/charts-navigation/charts-navigation.component';
import { MapService } from 'src/app/shared/map/map.service';
import { Browser } from '@syncfusion/ej2-base';
import { CollectionsService } from 'src/app/shared/collections/collections-service.service';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CalidadService } from 'src/app/shared/data/calidad.service';



@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent implements OnInit {
  currentRoute = '';
  errorMessage = '';
  public dictMenuBotonesInicio: any
  public dictMenuBotonesColecciones: any
  public dictMenuBotonesConfBus: any
  public dictMenuBotonesDashboard: any
  public dictMenuBotonesAnimacion: any
  @Output() sidenavClose = new EventEmitter();
  names = [
    'configuracion_azul',
    'configuracion-blanco',
    'configuracion-del-bus-azul',
    'dashboard-azul',
    'dashboars_blanco',
    'usuario-azul',
    'usuario-blanco',
    'operativa-azul',
    'operativa-gris',
    'quienessomos-azul',
    'sali-blanco',
    'consumo de energia_blanco',
    'energia-disponible_blanco',
    'consupo-por-km_blanco',
    'consumo_tiempo_blanco',
    'potencia_tiempo',
    'consumo_tiempo_azul',
    'consupo-por-km_azul',
    'energia-disponible_azul',
    'consumo de energia_azul',
    'potencia_tiempo_azul',
    'ruta_azul',
    'ruta',
    'energia-disponible_azul',
    'consumo_discriminado_azul',
    'consumo_discriminado_banco',
    'bateria-azul',
    'bateria-blanco',
    'contable-azul',
    'contable-blanco',
    'energiarenovable-blanco',
    'energiarenovable-azul',
    'optimizacion-white',
    'optimizacion-blue'
  ];

  first = 'white';
  second = '#000096';
  third = '#000096';
  forth = '#000096';
  fifth = '#000096';
  sixth = '#000096';
  seventh = '#000096';
  color1 = '#000096';
  color2 = 'white';
  color3 = 'white';
  color4 = 'white';
  color5 = 'white';
  color6 = 'white';
  color7 = 'white';

  dash = 'dashboard-azul';
  quienes = 'quienesB';
  usuario = 'usuario-blanco';
  configuracion = 'configuracion-blanco';

  stateCadena = true;
  contenido = "este es mensaje desde sidenivelist";

  map: any;
  animationIsPlaying = 0;
  animationIsPlayingAllRoutes = 0;
  animationIsSeriesPlaying = false;
  public listaRutas = []
  public selectedRuta = ""
  public listaOperaciones = []
  public selectedOperacion = ""
  public operacionseleccionada=""
  public listaTamaniobus = [{ name: "12 metros", value: "12" }]
  public selectedTamanio = ""
  //  public listaBateriaNoc=["350kWh","322kWh","475kWh"]
  public listaBateriaNocViable = [{ x: "315kWh", y: false, value: "315.07" }, { x: "322kWh", y: true, value: "322" }, { x: "324kWh", y: false, value: "324" }, { x: "348kWh", y: false, value: "348" }, { x: "350kWh", y: true, value: "350" }, { x: "373kWh", y: true, value: "373.4" }, { x: "475kWh", y: true, value: "475" }]
  public selectedNoc = ""
  public listaBateriaOppViable = [{ x: "-", y: false, value: "-" }]
  //  public listaBateriaOpp=["195kWh","216kWh","76kWh"]
  public selectedOpp = ""
  public selectedBateria = "";
  public servicioCH = false;
  public tamanioCH = false;
  public cargadorCH = false;

  public servicioCHTittle = false;
  public tamanioCHTittle = false;
  public cargadorCHTittle = false;

  constructor(
    public coll: CollectionsService,
    public authService: AuthService,
    public state: StateService,
    public data: DataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private dexie: DexieService,
    private mapser: MapService,
    private calidad: CalidadService
    //public mapslogios : MapsComponent
  ) {
    for (const entry of this.names) {
      this.matIconRegistry.addSvgIcon(
        entry,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/SGV/' + entry + '.svg'
        )
      );
    }
    this.matIconRegistry.addSvgIcon(
      'quienesB',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/estrella.svg'
      )
    );
  }

  ngOnInit(): void {
    this.loadNameCollection()
    this.state.currentChart = 0;
    this.dictMenuBotonesInicio = this.state.dictTranslate.Menu.MenuInicio
    this.dictMenuBotonesColecciones = this.state.dictTranslate.Menu.Coleccion
    this.dictMenuBotonesConfBus = this.state.dictTranslate.Menu.Menuconfiguracionanalizis
    this.dictMenuBotonesDashboard = this.state.dictTranslate.Menu.Dashboard
    this.dictMenuBotonesAnimacion = this.state.dictTranslate.Menu.Animacion
    this.state.collectionSelected = false;
    this.selectedRuta = ""
    this.selectedOperacion = ""
    this.selectedTamanio = ""
    this.state.listaOperacion = []
    this.operacionseleccionada=null
    this.state.confbusSelected = false


  }
  /////
  loadNameCollection(){

  }
  abrirGrafico() {
    this.state.currentChart = 17
  }
  asignarRuta(selectruta: any) {
    this.state.rutaDP = selectruta.value;
    this.selectedRuta = selectruta.value;
    this.servicioCH = true;
    this.state.listaOperacion = []
    var listapreporcesar = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta]["Operaciones"]
    for (let i = 0; i < listapreporcesar.length; i++) {
      var element = listapreporcesar[i];
      if (element == 'global') {
        this.state.listaOperacion.push(element)
      } else {
        var auxchart = element + ' servicios - día'
        console.log(auxchart)
        this.state.listaOperacion.push(auxchart)
      }
    }
    //this.state.listaOperacion = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta]["Operaciones"]
    this.operacionseleccionada=null
    this.operacionseleccionada = this.state.listaOperacion[this.state.listaOperacion.length -1 ]
    this.selectedOperacion='Global'
    this.state.listaTamanio = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta][this.selectedOperacion]["Tamanios"]
    this.tamanioCH = true;
    this.servicioCHTittle = false;
    //this.selectedOperacion = selectruta.value
    //this.servicioCHTittle = true;
  }
  asignarServicio(selectserv: any) {
    var selectprocesado = selectserv.value
    this.operacionseleccionada = selectserv.value
    this.selectedOperacion = selectserv.value

    if (selectserv.value != 'Global servicios - día') {
      if (this.state.currentCollection == 'limagrupopolo') {
        selectprocesado = 'op' + selectprocesado.charAt(2)
        this.state.activeGlobal = false
        this.selectedOperacion = selectprocesado;

      } else {
        selectprocesado = selectprocesado.slice(0, selectprocesado.length - 16);
        this.selectedOperacion = selectprocesado;

      }
    } else {
      this.selectedOperacion='Global'
      this.state.activeGlobal = true
    }
    this.state.servicioDP = selectprocesado;
    //this.selectedOperacion = selectprocesado;
    this.tamanioCH = true;
    this.servicioCHTittle = false;
    this.state.listaTamanio = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta][this.selectedOperacion]["Tamanios"]
    this.selectedTamanio = ""

    //this.tamanioCHTittle = true;
  }
  asignarTamanio(selecttamanio: any) {
    this.state.tamaniobusDP = selecttamanio.value;
    this.selectedTamanio = selecttamanio.value;
    this.tamanioCHTittle = false;
    //this.cargadorCHTittle = true
    this.selectedBateria = "";
    this.selectedNoc = "";
    this.cargadorCH = true;
    this.state.listaConector = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta][this.selectedOperacion][this.selectedTamanio]
    this.state.listaConectorValue = this.state.listaConector["conectorvalue"]
    this.state.listaConectorName = this.state.listaConector["conectorname"]
    this.state.listaConectorViable = this.state.listaConector["conectorviable"]
    this.state.listaPantografo = this.state.listaMenuConfiguracionBus[this.state.currentCollection][this.selectedRuta][this.selectedOperacion][this.selectedTamanio]
    this.state.listaPantografoValue = this.state.listaPantografo["pantografovalue"]
    this.state.listaPantografoName = this.state.listaPantografo["pantografoname"]
    this.state.listaPantografoViable = this.state.listaPantografo["pantografoviable"]

  }
  async Buscar(name: any) {
    console.log("lista datos final menu", this.state.listaConector, this.state.listaPantografo,this.state.currentBattery)
    this.mapser.borrarRutasPreviasGenerales()
    this.state.processing = true;
    this.state.currentChart = 0
    this.state.currentButtomGraph = 0
    this.state.currentButtonAnimation = 0
    this.state.currentButtonLedger = 0
    this.cargadorCHTittle = false;
    this.state.currentRoute = this.selectedRuta + this.selectedOperacion;
    this.state.currentOnlyRoute = this.selectedRuta
    this.state.currentOperacion = this.selectedOperacion
    this.state.currentBusSize = this.selectedTamanio
    this.state.currentType = name
    this.state.typeName = name=='Opportunity'?'panto':'conector'
    this.state.currentHVAC = 'hvacON'
    this.state.currentCycle = ''
    this.state.currentBattery = this.selectedNoc == "" ? this.selectedOpp : this.selectedNoc
    this.state.currentPax = 'HIGH'
    var listaConfbus = this.state.typeName=="panto"?this.state.listaPantografo.pantografovalue:this.state.listaConector.conectorvalue
    var indice_confbus = listaConfbus.indexOf(this.state.currentBattery);
    this.state.configuracioModeloBus = this.state.typeName=="panto"?this.state.listaPantografo.pantografoname[indice_confbus]:this.state.listaConector.conectorname[indice_confbus]
    await this.data.getVehiclePlatform(this.state.configuracioModeloBus)
    await this.data.getUidModelBus(this.state.configuracioModeloBus)
    console.log("lista datos final menu", this.state.configuracioModeloBus)
    var cadena = this.state.currentCollection + "_" + this.state.currentRoute + "_" + this.state.currentOperacion + "_" + this.state.currentBusSize + "_" + this.state.currentHVAC + "_" + this.state.currentCycle + "_" + this.state.currentBattery + "_" + this.state.currentPax
    await this.data.getBateriaTablaDatos(this.state.currentBattery+'kWh')
    await this.data.newsetCurrentRoute();
    //conector_Battery_350kWh_12m
    this.state.id_model_bus =   "BEB_"+this.state.typeName+"_Battery_"+this.state.currentBattery+'kWh_'+ this.state.currentBusSize + 'm'
    var cadena2 = this.state.currentCollection + '_' +this.state.currentRoute + '_' + name +'_' + this.state.currentBusSize + 'm_' +this.state.currentBattery + 'kWh_' + this.state.currentHVAC + '_pass' + this.state.currentPax
    this.state.cadenabusquedaanimacion= cadena2
    console.log("cadena",cadena,cadena2)
    await this.data.getEnergiaRenovable(cadena2)
    await this.calidad.getEstadosDashboard(this.state.currentCollection + '_' +this.state.currentRoute + '_' + name +'_' + this.state.currentBusSize + 'm_' +this.state.currentBattery + 'kWh' )
    await this.calidad.getCatalogoBateria(this.state.currentCollection + '_' +this.selectedRuta + '_' + name +'_' + this.state.currentBusSize + 'm_' +this.state.currentBattery + 'kWh' )
    await this.data.getEnergia()
    await this.data.getDataAnimacionRuta(cadena2+'_1')
    this.state.processing = true;
    await this.data.getDataAnimacionResumen(cadena2)
    this.state.processing = true;
    await this.data.getDataCronograma(this.state.currentCollection + "_" + this.state.currentOnlyRoute )
    await this.data.getAmbienteDatos(this.state.currentCollection + "_" + this.state.currentOnlyRoute )
    await this.data.getContableDatos(cadena2 )
    this.state.processing = true;
    if (name == 'Opportunity') {
      await this.data.newsetOpportunityOption();
    } else {
      await this.data.newsetOvernightOption();
    }
    this.mapser.dibujarDepot(this.state.dataAllRouteProerties[this.state.currentRoute])
    this.state.confbusSelected = true;
    this.state.processing = true;
    this.state.processing = false;
    this.state.enable_info_bus = true
    this.onSidenavClose()
    //await this.data.dibujarTodosLosCargadores(this.state.coleccionActual+'_'+this.selectedRuta)

    //this.sidenavClose.emit();
  }
  irLedgerIota() {
    var a = this.state.currentChart
    if (a == 23) {
      this.state.currentChart = 0
      this.state.currentButtonAnimation = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 23
        this.state.currentButtonAnimation = 3
        //

        this.state.currentButtonLedger = 0
        this.state.currentButtomGraph = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irAnimacionRuta() {
    var a = this.state.currentChart
    console.log("a animacion", this.state.currentMenu)
    if (a == 25) {
      this.state.currentChart = 0
      this.state.currentButtonAnimation = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 25
        this.state.currentButtonAnimation = 1
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtomGraph = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irAnimacionCargadores() {
    var a = this.state.currentChart
    console.log("a animacion", this.state.currentMenu)
    if (a == 26) {
      this.state.currentChart = 0
      this.state.currentButtonAnimation = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 26
        this.state.currentButtonAnimation = 2
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtomGraph = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irEventos() {
    var a = this.state.currentChart
    console.log("a animacion", this.state.currentMenu)
    if (a == 27) {
      this.state.currentChart = 0
      this.state.currentButtonAnimation = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 27
        this.state.currentButtonAnimation = 3
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtomGraph = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irInteracionRed() {
    var a = this.state.currentChart

    if (a == 20) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 20
        this.state.currentButtomGraph = 4
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irEstrategiaCarga() {
    var a = this.state.currentChart

    if (a == 3) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 3
        this.state.currentButtomGraph = 3
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irRendimiento() {
    var a = this.state.currentChart
    if (a == 8) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 8
        this.state.currentButtomGraph = 2
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);
      }
    }
  }
  irOperaciones() {

    var a = this.state.currentChart
    if (a == 21) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0;
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 21
        this.state.currentButtomGraph = 1;
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irResumenRuta() {
    var a = this.state.currentChart

    if (a == 17) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 17
        this.state.currentButtomGraph = 5
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irBateria() {
    var a = this.state.currentChart

    if (a == 29) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 29
        this.state.currentButtomGraph = 7
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }


  irContable() {
    var a = this.state.currentChart

    if (a == 30) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 30
        this.state.currentButtomGraph = 8
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }

  irMicroSimu() {
    var a = this.state.currentChart

    if (a == 28) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 28
        this.state.currentButtomGraph = 6
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irEnergiaRenovable() {
    var a = this.state.currentChart

    if (a == 31) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 31
        this.state.currentButtomGraph = 9
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }
  irOptimizacionBateria() {
    var a = this.state.currentChart

    if (a == 32) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 32
        this.state.currentButtomGraph = 10
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }

  irEnergiaIntegral() {
    var a = this.state.currentChart

    if (a == 33) {
      this.state.currentChart = 0
      this.state.currentButtomGraph = 0
    } else {
      if (a == 0 || a != 0) {
        this.state.currentChart = 33
        this.state.currentButtomGraph = 11
        //
        this.state.currentButtonLedger = 0
        this.state.currentButtonAnimation = 0
        setTimeout(() => {
          this.onSidenavClose()
        }, 500);

      }
    }
  }

  retroceso() {
    if (this.state.seleccionNav <= 2) {
      this.state.seleccionNav = 2
      this.sidenavClose.emit();
    } else {
      this.state.seleccionNav -= 1
    }
  }

  //////
  cleanCache() {
    this.dexie.deleteDatabase();
    this.authService.deleteBusConfigBackend().subscribe((res: any) => {
      console.log('Deleted configuration at backend');
    })
  }
  setProcessingFalse() {
    this.state.processing = false;
  }
  cerrarSpinner() {
    setTimeout(() => {
      this.state.processing = false;
    }, 1000);
  }

  public setCurrentCollection = async (cole: any) => {
    this.setProcessingFalse()
    this.state.confbusSelected = false
    this.selectedRuta = ""
    this.selectedOperacion = ""
    this.selectedTamanio = ""
    this.cargadorCH = false
    this.tamanioCH = false
    this.servicioCH = false
    this.state.currentChart=0
    this.state.listaMarkerAnimacionRuta = []
    //this.state.listaMarkerIntervalos=[]
    if (this.state.listaMarkerIntervalos.length !=0) {
      for (let i = 0; i < this.state.listaMarkerIntervalos.length; i++) {
        var busmarker = this.state.listaMarkerIntervalos[i];
        busmarker.remove()
      }
    }
    clearInterval(this.state.intervaloanimacion)

    this.state.listaHoraInicioAnimacion = []
    console.log("SET CURRENT COLLECTION", cole.value,this.state.datosCollection);
    this.state.coleccionActual = this.state.datosCollection["name"]
    this.state.datosCurrentCollection = this.state.datosCollection.find(objeto => objeto.collectiontitle === cole.value);
    var namecollectionselected = this.state.datosCurrentCollection["name"] 
    console.log("SET CURRENT COLLECTION RESULTADO", this.state.datosCurrentCollection,namecollectionselected)
    switch (namecollectionselected) {
      case "limagrupopolo":
        this.state.currentCity = 'Lima'
        break;
      case "LimaEBcorridors":
        this.state.currentCity = 'Lima'
        break;
      case "CairoBRT":
        this.state.currentCity = 'Cairo'
        break;
      case "quitocorredorsuroccidental":
        this.state.currentCity = 'Quito'
        break;
      default:
        this.state.currentCity = ''
        break;
    }

    //
    console.log("currentBusConfigurations", this.authService.currentBusConfigurations)
    if (Object.keys(this.authService.currentBusConfigurations).length >= 1) {
      console.log("Entrando condicion lleno currentBusConfigurations", this.authService.currentBusConfigurations)
      this.data.listSavedBusConfiguration = [];
      for (let variable in this.authService.currentBusConfigurations) {
        const data = {
          index: this.authService.currentBusConfigurations[variable]["index"],
          type: this.authService.currentBusConfigurations[variable]["type"],
          size: this.authService.currentBusConfigurations[variable]["size"],
          battery: this.authService.currentBusConfigurations[variable]["battery"],
          pax: this.authService.currentBusConfigurations[variable]["pax"],
          hvac: this.authService.currentBusConfigurations[variable]["hvac"],
          collection: this.authService.currentBusConfigurations[variable]["collection"],
          route: this.authService.currentBusConfigurations[variable]["route"]
        };
        var cadena_start = data.collection + "_" + data.route + "_";
        var cadena_end = "_" + data.type + "_" + data.size + "m_" + data.battery + "kWh_" + data.hvac + "_pass" + data.pax
        this.data.listStartQuery.push(cadena_start)
        this.data.listEndQuery.push(cadena_end)
        this.data.listSavedBusConfiguration.push(data)
        await this.data.updateTHEDATAPotencia(data)
      }
      console.log("Lista Saved Bus Configuration", this.data.listSavedBusConfiguration)
      //await this.data.getAllPotenciaAcum()
      //await this.data.updateTHEDATAPotencia(data)
    } else {
      console.log("the currentBusCOnfigurations esta vacio al inicio")
    }

    await this.data.getPortfolio();
    // CARGA DATOS IOTA
    //this.data.drivingCycle = 'Observado';
    // this.state.allRoutesShown = false;
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    if (this.state.showBusesOptions) {
      this.state.showBusesOptions = false;
    }
    this.currentRoute = this.state.currentRoute;
    this.state.currentRoute = 'None';
    this.state.currentType = '';
    this.state.currentBusSize = '';
    this.state.currentBattery = '';
    this.state.SohCritico = this.setSoHCritico();
    this.state.PotenciaCarga = this.setPotencicarga();
    this.state.currentRoutePosition = -1;
    if (this.state.allRoutesShown) {
      this.state.allRoutesShown = false;
      this.state.changeAllCoordinates('');
    }
    this.state.currentCollection = namecollectionselected;
    this.state.processing = true;
    this.state.changeCollection(namecollectionselected);
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      // if (i !== 8)
      this.state.THEDATA[i] = [];
    }
    this.onSidenavClose();
    this.state.processing = true;
    this.state.whichprocessing = 1;

    await this.data.setBusNames(namecollectionselected);
    // this.state.processing = false;

    // this.data.getAllStops();
    this.data.getAllCoordinates(); // .then(() => {
    //setTimeout(() => {
    this.state.changeSeriesUp('');
    //}, 2000);
    // });
    this.data.datasourceriota = await this.data.getAllDataIotaLocation()

    if (!this.state.allRoutesShown) {
      this.state.allRoutesShown = true;
      this.state.changeAllCoordinates('');
    }



    if (this.authService.currentBusConfigurations === undefined || this.authService.currentBusConfigurations === null)
      this.authService.currentBusConfigurations = {}
    const keys = Object.keys(this.authService.currentBusConfigurations);
    for (const llave of keys) {
      const datos = this.authService.currentBusConfigurations[llave];

      if (datos.collection === namecollectionselected) {

        const params = {
          type: datos.type,
          bus: datos.route,
          load: datos.hvac,
          pax: datos.pax,
          size: datos.size,
          battery: datos.battery,
          currentCollection: datos.collection
        }

        this.state.whichprocessing = 2;
        try {
          // console.log('Getting data of selected configurations and adding them to animations');
          this.state.currentType = datos.type;

          await this.data.getRouteProperties(datos.route, datos.collection);

          //await this.data.getAllCyclesInputsEvo(datos.route, datos.collection);
          console.log("params setCurrentCollection", params)
          await this.data.getAllData(params);
          const entradas = {
            chargingmethod: datos.type,
            vehiclesize: parseInt(datos.size),
            batteryenergy: parseFloat(datos.battery),
            collection: datos.collection,
            route: datos.route
          };
          console.log("await buses option 7")
          await this.data.getVehicleProperties(entradas);
        } catch (error) {
          console.log('Could not get the data in maps component...');

        }
      }
      console.log("esto son los datos : ", datos)
    }
    this.state.whichprocessing = 0;


    //llenar this.data.listBusConfiguration con this.data.listPortfolio
    for (const llave of keys) {

      var _collection, _route, _size, _battery, _hvac, _pax, _type;
      _collection = this.authService.currentBusConfigurations[llave].collection
      _route = this.authService.currentBusConfigurations[llave].route
      _size = this.authService.currentBusConfigurations[llave].size
      _battery = this.authService.currentBusConfigurations[llave].battery
      _type = this.authService.currentBusConfigurations[llave].type
      _hvac = this.authService.currentBusConfigurations[llave].hvac
      _pax = this.authService.currentBusConfigurations[llave].pax
      var cadena_portfolio_inicio = _collection + "_" + _route + "_" + _type + "_" + _size + "m_" + _battery + "kWh_" + _hvac + "_pass" + _pax;
      //console.log("llenando Portfolio al inicio : ", cadena_portfolio_inicio)
      const found_portfolio = this.data.listPortfolio.find(element => element["portfolioid"] == cadena_portfolio_inicio)
      //console.log("found portfolio ", found_portfolio)
      var remplazo = false;
      if (this.data.listBusConfiguration.length < 1 || this.data.listBusConfiguration == undefined) {
        this.data.listBusConfiguration.push(found_portfolio);
      } else {
        for (let i = 0; i < this.data.listBusConfiguration.length; i++) {
          var element = this.data.listBusConfiguration[i];
          if (element["routeid"] == found_portfolio["routeid"]) {
            remplazo = true;
            this.data.listBusConfiguration[i] = found_portfolio;
          }
        }
        if (remplazo == false) {
          this.data.listBusConfiguration.push(found_portfolio);
        }
      }
    }
    console.log("Informacion ListBusConfiguration", this.data.listBusConfiguration)
    //Dibujar los cargadores
    console.log("allLocations en setCurrentLocation", this.state.allLocations)
    // Get Data Charger Analysis
    //await this.data.getDataChargerAnalysis();
    console.log("Current Collection", this.state.currentCollection)
    await this.data.getMenuConfiguracionBus(this.state.currentCollection);
    console.log("currentCollection", this.state.currentCollection)
    this.state.listaRuta = this.state.listaMenuConfiguracionBus[this.state.currentCollection]["Rutas"]
    console.log("Configuración Menu", this.state.listaMenuConfiguracionBus)

    //DIBUJAR RUTA Y ESTACIONES ATENS DE SELECCIONAR UNA CONF DE BUS
    // await this.data.newsetCurrentRoute();

    //await this.data.dibujarTodosLosCargadores(namecollectionselected)
    await this.calidad.getRatingData(namecollectionselected)
    this.cerrarSpinner()
    this.state.seleccionNav = 2
    this.state.collectionSelected = true;
    this.mapser.dibujarSoloRutaGeneral()
    console.log("Spinner Apagado")
  };

  setSoHCritico() {
    var value = this.state.SohCritico;
    console.log("value SoHCritico", value)
    return parseInt(value).toFixed(0);
  }
  setPotencicarga() {
    var value = this.state.PotenciaCarga;
    return parseInt(value).toFixed(0);
  }
  getPositionRute(ruta: string) {
    for (let i = 0; i < this.state.busNames.length; i++) {
      if (ruta === this.state.busNames[i]) {
        this.state.currentRoutePosition = i;
      }
    }
  }

  /* public setCurrentRoute = async (route: any) => {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    // this.state.currentType = '';
    // this.state.currentBusSize = '';
    // this.state.currentBattery = '';
    this.state.endAuto = '';
    this.state.maximumConsumption = '';

    this.state.currentRoute = route.value;
    if (route.value === 'None') {
      this.state.currentRoutePosition = -1;
    } else {
      this.getPositionRute(route.value);
    }
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      if (i !== 6)
        this.state.THEDATA[i] = [];
    }
    this.state.changePax('');
    this.state.processing = true;
    await this.data.getRouteProperties();
    //await this.data.getAllCyclesInputs();
    await this.data.getAllCyclesInputsEvo();
    if (this.state.currentBattery !== '' && this.state.currentPax !== '' &&
      this.state.currentBusSize !== '' && this.state.currentType !== '') {
      await this.data.updateTHEDATA2();
      this.data.setLabels();
    }
    this.state.processing = false;
    this.state.changePax('nav');
    // console.log('before the update data2');
    //}
    // this.data.setLabels();
    this.state.changeRoute(route.value);
    // this.onSidenavClose();
  }; */

  public setCurrentType = async (type: any) => {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    this.state.currentBusSize = '';
    this.state.currentBattery = '';
    this.state.endAuto = '';
    this.state.maximumConsumption = '';
    this.state.currentType = type.value;
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.state.THEDATA[i] = [];
    }
    /* if (type.value === 'Overnight') {
      this.state.currentBattery = '348';
    } else {
      this.state.currentBattery = '55';
      this.state.currentBusSize = '12';
    } */
    this.state.changeType(type.value);
    // this.onSidenavClose();
  };

  public setCurrentBusSize = async (busSize: any) => {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    this.state.currentBattery = '';
    this.state.endAuto = '';
    this.state.maximumConsumption = '';
    this.state.currentBusSize = busSize.value;
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.state.THEDATA[i] = [];
    }
    /* if (busSize.value === '18') {
      this.state.currentBattery = '652';
    } else if (
      busSize.value === '12' &&
      this.state.currentType === 'Overnight'
    ) {
      this.state.currentBattery = '348';
    } */
    // this.state.changeBusSize(busSize.value);
    this.onSidenavClose();
  };



  public setCurrentBattery = async (bat: any) => {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    this.state.currentBattery = bat.value;
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      if (i !== 6 && i !== 3 && i !== 4)
        this.state.THEDATA[i] = [];
    }

    this.state.changePax('nav');
    this.state.endAuto = '';
    this.state.maximumConsumption = '';
    if (this.state.currentHVAC !== '' && this.state.currentPax !== '') {

      await this.data.updateTHEDATA2();
      this.data.setLabels(); // */
      this.state.changeBattery(bat.value);
      this.state.changePax('nav');
    }
    // this.onSidenavClose();
  };

  public setCurrentHVAC = async (hvac: any) => {
    this.state.currentHVAC = hvac.value;
    if (this.state.currentBattery !== '' && this.state.currentPax !== '') {
      // this.data.updateTHEDATA();
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.state.changeHVAC(hvac.value);
      this.state.changePax('nav');
    }

    // this.onSidenavClose();
  };

  public setCurrentPax = async (pax: any) => {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    this.state.currentPax = pax.value;
    if (this.state.currentBattery !== '' && this.state.currentHVAC !== '') {
      // this.data.updateTHEDATA();
      await this.data.updateTHEDATA2();
      this.data.setLabels();
      this.state.changePax('nav');
    }
  };

  public onSidenavClose = () => {
    // console.log('Estoy cerrando!');
    this.state.one[0] = '#D6D8E1';
    this.state.one[1] = '#D6D8E1';
    this.state.one[2] = '#D6D8E1';
    this.state.one[3] = '#D6D8E1';
    this.state.one[4] = '#D6D8E1';
    this.state.one[5] = '#D6D8E1';
    this.state.one[6] = '#D6D8E1';

    this.state.abierto = false;
    this.state.currentMenu = 0;
    this.sidenavClose.emit();
  };

  public onSidenavCloseCharts = () => {
    this.state.changeChart(0);
  };

  async procesar(): Promise<void> {
    this.state.allRoutesShown = !this.state.allRoutesShown;
    this.state.changeAllCoordinates('');
    // this.state.changeAllStops('');
  }

  showBusesOptions(): void {
    this.state.showBusesOptions = !this.state.showBusesOptions;
  }
  async dibujarRuta(ruta:any){
    this.mapser.dibujarRutaPrevia(ruta+'Global')


  }

  public async salir(): Promise<void> {
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    this.state.currentMenu = 0;
    this.state.areAnimationsDone = 2;
    this.state.isAnimationON = false;
    this.state.showBusesOptions = false;
    this.state.currentChart = 0;
    this.state.collectionSelected = true;

    // if (this.abierto) {
    this.onSidenavClose();
    // }
    this.state.inMap = false;
    await this.authService.SignOut();
    this.router.navigate(['login']);
    this.state.currentCollection = '';
    this.data.clean();
  }

  pres(x: number): void {
    this.state.whichButton = x;
    if (x === 1) {
      this.first = 'white';
      this.third = '#000096';
      this.second = '#000096';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.sixth = '#000096';
      this.seventh = '#000096';
      this.state.inMap = true;
      this.state.showBusesOptions = false;
      this.color1 = '#000096';
      this.color2 = 'white';
      this.color3 = 'white';
      this.color4 = 'white';
      this.color5 = 'white';
      this.color6 = 'white';
      this.color7 = 'white';
      this.dash = 'dashboard-azul';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion-blanco';
    }
    if (x === 2) {
      this.first = '#000096';
      this.third = '#000096';
      this.second = 'white';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.sixth = '#000096';
      this.seventh = '#000096';
      this.state.inMap = false;
      this.state.showBusesOptions = false;
      this.color1 = 'white';
      this.color2 = '#000096';
      this.color3 = 'white';
      this.color4 = 'white';
      this.color5 = 'white';
      this.color6 = 'white';
      this.color7 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienessomos-azul';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion-blanco';
    }
    if (x === 3) {
      this.first = '#000096';
      this.third = 'white';
      this.second = '#000096';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.sixth = '#000096';
      this.seventh = '#000096';
      this.color1 = 'white';
      this.color3 = '#000096';
      this.color2 = 'white';
      this.color4 = 'white';
      this.color5 = 'white';
      this.color6 = 'white';
      this.color7 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-azul';
      this.configuracion = 'configuracion-blanco';
      this.state.inMap = false;
      this.state.showBusesOptions = false;
    }
    if (x === 4) {
      this.first = '#000096';
      this.third = '#000096';
      this.second = '#000096';
      this.forth = 'white';
      this.fifth = '#000096';
      this.sixth = '#000096';
      this.seventh = '#000096';
      this.color1 = 'white';
      this.color4 = '#000096';
      this.color2 = 'white';
      this.color3 = 'white';
      this.color5 = 'white';
      this.color6 = 'white';
      this.color7 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion_azul';
      this.state.inMap = false;
      this.state.showBusesOptions = false;
    }
    if (x === 5) {
      this.first = '#000096';
      this.third = '#000096';
      this.second = '#000096';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.sixth = '#000096';
      this.seventh = '#000096';
      this.color1 = 'white';
      this.color5 = 'white';
      this.color2 = 'white';
      this.color4 = 'white';
      this.color3 = 'white';
      this.color6 = 'white';
      this.color7 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion-blanco';
      this.salir();
    }
    if (x === 6) {
      this.first = '#000096';
      this.third = '#000096';
      this.second = '#000096';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.sixth = 'white';
      this.seventh = '#000096';
      this.color1 = 'white';
      this.color5 = 'white';
      this.color2 = 'white';
      this.color4 = 'white';
      this.color3 = 'white';
      this.color6 = '#000096';
      this.color7 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion-blanco';
      this.state.inMap = false;
      this.state.showBusesOptions = false;
    }
    if (x === 7) {
      this.first = '#000096';
      this.third = '#000096';
      this.second = '#000096';
      this.forth = '#000096';
      this.fifth = '#000096';
      this.seventh = 'white';
      this.sixth = '#000096';
      this.color1 = 'white';
      this.color5 = 'white';
      this.color2 = 'white';
      this.color4 = 'white';
      this.color3 = 'white';
      this.color7 = '#000096';
      this.color6 = 'white';
      this.dash = 'dashboars_blanco';
      this.quienes = 'quienesB';
      this.usuario = 'usuario-blanco';
      this.configuracion = 'configuracion-blanco';
      this.state.inMap = false;
      this.state.showBusesOptions = false;
    }
  }
}
