<div #myDOMElementCK fxLayout="row" style="margin-top: 0px; padding-top: 0px;">

  <ejs-chart #chart15 id="chart-container" [primaryXAxis]="axisX" [primaryYAxis]="axisY" [height]='alto'
    (window:resize)="onResize($event)" [tooltip]='tooltip'  [enableSideBySidePlacement]='placement' [chartArea]='chartArea'>
    <e-series-collection>
      <e-series  *ngFor="let elchart of listcargadores; index as i;"
      [dataSource]="datosgrafico[elchart]"
      type="Column" yName="value" xName="time"  [name]="elchart"
      columnWidth="0.2"></e-series>
    </e-series-collection>


  </ejs-chart>


</div>
