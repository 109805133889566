import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss']
})
export class AlertListComponent implements OnInit {
  nuevosBusNames: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {
    this.nuevosBusNames =  [...this.state.busNames];
    // this.sortBy();

    // this.subscribeToOrder();
  }

  checkBus(bus: any, index: number): void {

    // console.log('Showing bus ', bus, ' at index ', index);
    if (this.state.showSingleBusAnimation === bus) {
      this.state.showSingleBusAnimation = '';
    } else {
      if (this.state.showAllBusesAnimation){
        this.state.showAllBusesAnimation = false;
      }
      this.state.showSingleBusAnimation = '';
      setTimeout(() => {
        this.state.showSingleBusAnimation = bus;
      }, 50);

    }
  }

  ordenamiento: any = {
  'red': 0,
  'orange': 1,
  'yellow': 2,

  'blue': 3,
  'limegreen': 4,
  'none': 5
  };

  sortBy(): any {

    return this.nuevosBusNames.sort((uno: any, dos: any) => {
      return this.ordenamiento[this.state.animationAlerts[this.state.currentCollection + uno].worstcolor]
        >=
        this.ordenamiento[this.state.animationAlerts[this.state.currentCollection + dos].worstcolor] ? 1 : -1;
    });

  }

  subscribeToOrder(){
    this.state.updateAlertsOrderEvent.subscribe(() => {
      console.log('Actualizando ordenamiento');
      this.sortBy();
    });
  }

  showAllBusesAnimation(): void {
    this.state.showSingleBusAnimation = '';
    this.state.showAllBusesAnimation = !this.state.showAllBusesAnimation;
  }

}
