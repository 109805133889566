import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-accum-energy',
  templateUrl: './accum-energy.component.html',
  styleUrls: ['./accum-energy.component.scss']
})
export class AccumEnergyComponent implements OnInit {
  loschargernames: any = [];
  items: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {

    this.state.currentChargersNames.forEach((c: any) => {
      this.items.push(
        {
          id: c.replace(/\s/g, ""),
        }
      );
    });
    // console.log('this.loschargernames: ', this.loschargernames);
  }


}
