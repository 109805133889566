import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from 'src/app/shared/state/state.service';
import { LocalstorageService } from 'src/app/shared/storage/localstorage.service';
import { AuthService } from '../../../shared/auth/auth-service.service';
import { CustomValidators } from '../register/custom-validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    password1: new FormControl('', [Validators.required]),
    password2: new FormControl('', [Validators.required])
  });

  private returnUrl = '';
  enviado = false;
  errores = false;
  uid = this.actRoute.snapshot.params.uid;
  token = this.actRoute.snapshot.params.token;

  constructor(
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private state: StateService,
    private lStore: LocalstorageService
  ) {
    /* console.log('Iniciando login token: ', this.authService.getToken());
    console.log('Iniciando login userStore: ', JSON.parse(this.authService.getUserStore())); */
  }

  ngOnInit(): void {
    this.returnUrl = 'animation';
    this.form = this.fb.group({
      /* password1: new FormControl('', [Validators.required]),
      password2: new FormControl('', [Validators.required]), */
      password1: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // CustomValidators.patternValidator(/[@#$?\^%&!"/()=¡'*¿]/, { hasSpecialCharacters: true}),
        Validators.minLength(8),
      ]),
      password2: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // CustomValidators.patternValidator(/[@#$?\^%&!"/()=¡'*¿]/, { hasSpecialCharacters: true}),
        Validators.minLength(8),
      ]),
    });

    const ctrl = new FormControl('some value');
    // console.log(ctrl.value);     // 'some value'
    // console.log('Name:' + this.form.get('username').value);


    if (this.authService.isLoggedIn) {
      this.authService.restartUserData();
      this.router.navigate([this.returnUrl]);
    }
  }

  get passw1(): any {
    return this.form.get('password1');
  }

  get passw2(): any {
    return this.form.get('password2');
  }


  async onSubmit(): Promise<void> {
    this.errores = false;
    if (this.form.valid) {
      try {
        const json = {
          'uid': this.uid,
          'token': this.token,
          'new_password1': this.passw1.value,
          'new_password2': this.passw2.value,

        };
        await this.authService.ChangePassword(json).then(() => {
          this.enviado = true;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        });
      } catch (err) {
        console.log('No se puede cambiar contraseña, no coinciden');
        this.errores = true;

      }
    }
  }
}
