<div class="paradiv" >
<!--   <span  style="color: white; height: 50px; justify-content: center;
  align-items: center; text-align: center; vertical-align: middle; ">Ids</span> -->
  <!-- <mat-icon style="align-self: center;"> directions_bus </mat-icon> -->
  <mat-grid-list cols="7" rowHeight="65px"   style="padding: 0px">
    <!-- <mat-grid-tile>
      <mat-icon style="align-self: center;"> directions_bus </mat-icon>
    </mat-grid-tile> -->
    <mat-grid-tile *ngFor="let charger of loschargernames; index as i"
    style="cursor: pointer; color: white;  border-top: 1px solid white; border-bottom: 1px solid white; border-radius: 0px; "
    (click)="showEvents(i)"  [style.background-color]="labelcolors[i]">
      {{charger}}
    </mat-grid-tile>
  </mat-grid-list>
</div>
