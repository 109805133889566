import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-rutas',
  templateUrl: './sidenav-rutas.component.html',
  styleUrls: ['./sidenav-rutas.component.scss']
})
export class SidenavRutasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
