import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '../register/custom-validators';
import { DataService } from 'src/app/shared/data/data-service.service';
import { CollectionsService } from 'src/app/shared/collections/collections-service.service';
// import { User } from '../../models/User';
// import { UserInfoComponent } from '../user-info/user-info.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  actualizado = false;
  id = this.actRoute.snapshot.params.id;
  userData: any = {};
  form = this.fb.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    email2: new FormControl('', [Validators.email, Validators.required]),
    /* emailBackUp: new FormControl('', [Validators.email, Validators.required]),
    emailBackUp2: new FormControl('', [Validators.email, Validators.required]), */
    password1: new FormControl('', [Validators.required]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    contactname: new FormControl('', [Validators.required]),
    organization: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    super: new FormControl('', [Validators.required]),
    activoPlan: new FormControl('', [Validators.required]),
    activoTrack: new FormControl('', [Validators.required]),
    currentCollection: new FormControl('', []),
    addedCollection: new FormControl('', []),
  });
  loginInvalid = false;
  collections: any = [];
  // currentCollection = '';

  constructor(
    private auth: AuthService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private collec: CollectionsService
  ) {
    /* this.userData = {
      key: '1',
      user: {
        id: 0,
        password: '',
        last_login: '',
        email: '',
        backupEmail: '',
        organization: '',
        phone: '',
        address: '',
        contactname: '',
        country: '',
        state: '',
        city: '',
        joinedDate: '',
        is_staff: false,
        is_active: false,
        is_superuser: false,
      },
    }; */
  }

  async ngOnInit(): Promise<void> {
    try {
      const data = await this.auth.getUser(this.id).toPromise();
      this.userData = data;
      // console.log('La user data: ', this.userData);
      const actPlan = this.userData.is_activePlan
        ? 'activoPlan'
        : 'noActivoPlan';
      const actTrack = this.userData.is_activeTrack
        ? 'activoTrack'
        : 'noActivoTrack';
      const sup = this.userData.is_superuser ? '1' : '0';
      // this.collections = this.userData.authCollections;
      this.form = this.fb.group({
        email: new FormControl(this.userData.email, [
          Validators.email,
          Validators.required,
        ]),
        email2: new FormControl(this.userData.email, [
          Validators.email,
          Validators.required,
        ]),
        /* emailBackUp: new FormControl(this.userData.backupEmail, [
          Validators.email,
          Validators.required,
        ]),
        emailBackUp2: new FormControl(this.userData.backupEmail, [
          Validators.email,
          Validators.required,
        ]), */
        password1: new FormControl('', [
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
        ]),
        password2: new FormControl('', [
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
        ]),
        contactname: new FormControl(this.userData.contactname, [
          Validators.required,
        ]),
        organization: new FormControl(
          { value: this.userData.organization, disabled: true },
          [Validators.required]
        ),
        phone: new FormControl(this.userData.phone, [Validators.required]),
        address: new FormControl(this.userData.address, [Validators.required]),
        country: new FormControl(this.userData.country, [Validators.required]),
        state: new FormControl(this.userData.state, [Validators.required]),
        city: new FormControl(this.userData.city, [Validators.required]),
        super: new FormControl(sup, [Validators.required]),
        activoPlan: new FormControl(actPlan, [Validators.required]),
        activoTrack: new FormControl(actTrack, [Validators.required]),
        currentCollection: new FormControl('', []),
        addedCollection: new FormControl('', []),
      });
      // });
    } catch (error) {
      console.log('No se pudo obtener datos de usuario: ', error);
    }
    if (this.collec.collections !== null) {
      console.log('collections ya en cache (collection list)');
      this.collections = this.collec.collections.filter((el: any) => {
        // this.userData.trackingCollections.includes(el);
        return !this.userData.authCollections.includes(el.name);
      });
    } else {
      try {

        const response = await this.collec.getAllCollections().toPromise();
        // const response = await this.collec.getAllCollections();
        // console.log('collections received');
        // console.log(response);
        this.collections = response.filter((el: any) => {
          // this.userData.trackingCollections.includes(el);
          return !this.userData.authCollections.includes(el.name);
        });
      } catch (error) {
        console.log('No se pudo obtener colecciones: ', error);
      }
    }

  }

  get userEmail(): any {
    return this.form.get('email');
  }

  get userEmail2(): any {
    return this.form.get('email2');
  }

  /* get backupEmail(): any {
    return this.form.get('emailBackUp');
  }

  get backupEmail2(): any {
    return this.form.get('emailBackUp2');
  } */

  get password1(): any {
    return this.form.get('password1');
  }

  get password2(): any {
    return this.form.get('password2');
  }

  get organization(): any {
    return this.form.get('organization');
  }

  get contactname(): any {
    return this.form.get('contactname');
  }

  get address(): any {
    return this.form.get('address');
  }

  get phone(): any {
    return this.form.get('phone');
  }

  get country(): any {
    return this.form.get('country');
  }

  get state(): any {
    return this.form.get('state');
  }

  get city(): any {
    return this.form.get('city');
  }

  get super(): any {
    return this.form.get('super');
  }

  get activoPlan(): any {
    return this.form.get('activoPlan');
  }

  get activoTrack(): any {
    return this.form.get('activoTrack');
  }

  get currentCollection(): any {
    return this.form.get('currentCollection');
  }

  get addedCollection(): any {
    return this.form.get('addedCollection');
  }

  /* async onSubmit(): Promise<void> {
    console.log('usuario: ', this.userName.value);
    console.log('pass: ', this.userPass.value);
    if (this.form.get('username') === null) { return; }

  }*/

  async onSubmit(val: string): Promise<void> {
    if (val === 'a') {
      // console.log('Eliminando visibilidad de colección');
      if (this.currentCollection.value.trim() !== '') {
        const ccc = this.collections;
        ccc.push({ name: this.currentCollection.value });
        const tempCol = this.userData.authCollections.filter(
          (value: any) => {
            return value !== this.currentCollection.value;
          }
        );
        const query = {
          authCollections: tempCol,
        };
        this.auth.updateUser(this.userData.id, query).then((res: any) => {
          // console.log('Eliminada visibilidad');
          this.userData.authCollections = tempCol;
          this.collections = ccc;
        });
      }
    } else if (val === 'b') {
      // console.log('Adicionando visibilidad de colección');
      if (this.addedCollection.value.trim() !== '') {
        const temCol = this.userData.authCollections;
        temCol.push(this.addedCollection.value);
        const ccc = this.collections.filter((el: any, index: number) => {
          // console.log('');
          return el.name !== this.addedCollection.value;
        });
        const query = {
          authCollections: temCol,
        };
        this.auth.updateUser(this.userData.id, query).then((res: any) => {
          // console.log('Adicionada visibilidad');
          this.userData.authCollections = temCol;
          this.collections = ccc;
        });
      }
    } else {
      // console.log('Actualizando usuario!');
      if (this.form.valid) {
        console.log('it is valid!');

        if (this.userEmail.value !== this.userEmail2.value) {
          this.loginInvalid = true;
          return;
        }
        /* if (this.backupEmail.value !== this.backupEmail2.value) {
          console.log('It is not okay email Back');
          this.loginInvalid = true;
          return;
        } */
        if (this.password1.value !== this.password2.value) {
          console.log('Not is okay pass');
          this.loginInvalid = true;
          return;
        }
        let iS = false;
        let iAPlan = false;
        let iATrack = false;
        let iSu = false;
        if (this.super.value === '1') {
          iS = iSu = iAPlan = iATrack = true;
        }
        if (this.activoPlan.value === 'activoPlan') {
          iAPlan = true;
        }
        if (this.activoTrack.value === 'activoTrack') {
          iATrack = true;
        }
        const superUser = this.super.value === '0' ? false : true;
        console.log('Sí es super usuario? ', superUser);
        const usuario = {
          email: this.userEmail.value,
          backupEmail: 'dummyvalue@logios.global',
          // organization: this.organization.value,
          phone: this.phone.value,
          address: this.address.value,
          contactname: this.contactname.value,
          country: this.country.value,
          state: this.state.value,
          city: this.city.value,
          is_staff: iS,
          is_activePlan: iAPlan,
          is_activeTrack: iATrack,
          is_superuser: iSu,
        };
        console.log('Enviando usaurio al auth serv: ', usuario);
        try {
          this.auth.updateUser(this.userData.id, usuario).then(() => {
            this.actualizado = true;
            setTimeout(() => {
              this.router.navigate(['/user-list']);
            }, 1000);
          });
          // this.form.reset();
        } catch (err) {
          this.loginInvalid = true;
        }
      } else {
        this.loginInvalid = true;
      }

      return;
    }
  }
}
