import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
// import { DataService } from '../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bus-time',
  templateUrl: './bus-time.component.html',
  styleUrls: ['./bus-time.component.scss']
})
export class BusTimeComponent implements  OnInit, AfterViewInit, OnChanges, OnDestroy {
  cual = 1;
  multi: any = [];
  initMulti: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#008000', '#FF0000'],
  };

  ancho = '';
  alto = '';
  icono = true;


  axisInferior = [
    'Potencia (kW)',
    'Energía (kWh)',
  ];
  uno: any;
  dos: any;


  public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;
  public animation: object = { enable: false };
  public majorGridLines: Object;
  public lineStyle: Object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @Input() grande: boolean;

  @ViewChild('chart15')
  public charta!: ChartComponent;
 /*  @ViewChild('chart11b')
  public chartb!: ChartComponent; */

  changeAnimation: Subscription | undefined;
  changeData: Subscription | undefined;

  constructor(
    // private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + '';
    // this.state.currentChart = 11;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  print(){
    if (this.cual === 1){
      this.charta.print();
    }else {
      ;
    }
  }

  async ngOnInit(): Promise<void> {
    //console.log("this.state.THEDATA[9][5][this.state.currentRoute][0].datos",this.state.THEDATA[9][5])
    //console.log("this.state.THEDATA[9][5][this.state.currentRoute][0].datos",this.state.currentChargersNames)
    var valor =0;
    var intervalo;
    for (let i = 0; i < this.state.currentChargersNames.length; i++) {
      const ele = this.state.currentChargersNames[i];
      var aux = this.state.THEDATA[9][5][ele][0].datos;
      aux.forEach(element => {
        if (valor<=element["value"]  ) {
          valor = element["value"]  
        }
      });

    }
    console.log("valor es :", valor)
    if(valor<5){
      intervalo = 1;
    }else{
      if(valor<15){
        intervalo = 3;
      }else{
        if(valor < 25){
          intervalo=5;
        }else{
          intervalo = 10
        }
        
      }
    }

    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.primaryYAxis = {
      //labelFormat: '{value}',
      title: 'Buses Cargando',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      //maximum: 5,
      interval: intervalo,
      rangePadding: 'Auto',
    };
    const hoy = new Date();
    this.primaryXAxis = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      title: 'Hora del día',
      // rangePadding: 'Auto',
      interval: 4,
      //minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
      //maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 1, 0, 0 ,0),
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: 'HH:mm'
    };
    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 0 };
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
    this.state.currentStats = 1;
    // this.state.currentChart = 11;
    this.marker = { visible: true };
    this.subscribeConfiguration();
  }

  subscribeConfiguration(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        this.state.THEDATA[8] = [...this.state.THEDATA[8]];
        this.changeDetector.detectChanges();
        // this.chart.refresh();
      }
    );
  }

  getData(whichOne: number): void {
    // console.log('my data en power time', this.state.currentRoute, ' whichone: ', whichOne);
    if (whichOne === -1) {
      whichOne = this.cual;
    }

    this.cual = whichOne;
    this.state.currentStats = whichOne;
    // this.elTitulo = this.titulos[whichOne - 1];
    this.yAxisLabel = this.axisInferior[whichOne - 1];
    const hoy = new Date();
    this.primaryXAxis = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      title: 'Hora del día',
      // rangePadding: 'Auto',
      interval: 4,
      minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
      maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 1, 0, 0 ,0),
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: 'HH:mm'
    };

    this.primaryYAxis = {
      labelFormat: '{value}',
      // title: 'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      title: this.axisInferior[whichOne - 1],
      /* labelFormat: '{value}',
      title: this.axisInferior[whichOne - 1],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
      }, */
    };
    this.changeDetector.detectChanges();
    // this.chart.refresh();
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  pointClick(args: IPointEventArgs): void {
    const lalon = this.state.chartMapLongitude[0][0][args.pointIndex];
    const lalat = this.state.chartMapLatitude[0][0][args.pointIndex];
    this.state.changePointIndex([lalon, lalat]);
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
    if (this.changeData) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeData.unsubscribe();
      this.changeData = undefined;
    }
    // console.log('MAPS component destroyed');
  }

}
