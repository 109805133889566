<div #myDOMElement class="eldiv">

  <ejs-chart #chart10 id="chart-container" [primaryXAxis]="state.axis1[0]" [primaryYAxis]="state.axis1[1]"
    [height]="alto" (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]='domain'
    [tooltip]="tooltip" (pointClick)="pointClick($event)" (chartMouseClick)='chartMouseClick($event)' [axes]='axis' [animations]="animations">
    <!-- <e-axes>
      <e-axis rowIndex=0 name='yAxis1' opposedPosition='true' title='Elevación (m)'  [majorGridLines]='majorGridLines' [lineStyle]='lineStyle' labelFormat='{value}'>
      </e-axis>
    </e-axes> -->
    <e-series-collection>
      <e-series *ngFor="let chart of state.THEDATA[0]; index as i;" [dataSource]="chart.datos" type="Line" xName="name"
        yName="value" [name]="chart.nombre" legendShape="Rectangle" [visible]="false" [animation]="animation" tooltipFormat='Consumo <br /> <b>${point.x} : ${point.y}</b>' >
      </e-series>
      <e-series *ngFor="let chart2 of state.THEDATA[0]; index as j;" [dataSource]="chart2.regen" type="Line" xName="name"
        yName="value" [visible]="false" [animation]="animation"  tooltipFormat='Regeneración <br /> <b>${point.x} : ${point.y}</b>'>
      </e-series>
      <e-series *ngFor="let chart3 of state.THEDATA[0]; index as k;" [dataSource]="chart3.slope" type="Spline" xName="name"
        yName="value" [animation]="animation" [visible]="false" yAxisName='yAxis' tooltipFormat='Pendiente <br /> <b>${point.x} : ${point.y}</b>'>
      </e-series>
      <!--<e-series *ngFor="let chart3 of state.THEDATA[0]; index as k;" [dataSource]="chart3.altitud" type="Line" xName="name"
        yName="value" [animation]="animation" [visible]="false" yAxisName='yAxis' tooltipFormat='Altitud <br /> <b>${point.x} : ${point.y}</b>'>
      </e-series>-->
    </e-series-collection>
  </ejs-chart>
</div>
