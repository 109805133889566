import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root'
})
export class DexieService {
  rProperties: Dexie.Table<IRouteProperties, number>;
  inputs: Dexie.Table<IInputs, number>;
  outputs: Dexie.Table<IOutputs, number>;
  vehicleprop: Dexie.Table<IVehicleProp, number>;
  chargersdata: Dexie.Table<IVehicleProp, number>;
  chargingsites: Dexie.Table<IVehicleProp, number>;
  db: DexieDB;
  db2:DexieDB


  constructor(private state:StateService) {
    this.db = new DexieDB();
    this.rProperties = this.db.table('rproperties');
    this.inputs = this.db.table('inputs');
    this.outputs = this.db.table('outputs');
    this.vehicleprop = this.db.table('vehicleprop');
    this.chargersdata = this.db.table('chargersdata');
    this.chargingsites = this.db.table('chargingsites');
  }

  async createDataBaseCollection(name:string){
    if (Dexie.exists(name)) {
      
    }
  }

  async getChargingSites(key: string): Promise<any> {
    const x = await this.chargingsites.where("id").equals(key).toArray();
    // console.log('x: ', x)
    return x;
  }

  async addChargingSites(key: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.chargingsites.add({ id: key, data: cadena }).then(() => {
      return 'Saved chargingsites to dexieDB';
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save chargingsites to dexieDB: ', error);
    });
  }

  async getChargersData(key: string): Promise<any> {
    const x = await this.chargersdata.where("id").equals(key).toArray();
    // console.log('x: ', x)
    return x;
  }

  async addChargersData(key: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.chargersdata.add({ id: key, data: cadena }).then(() => {
      return 'Saved ChargersData to dexieDB';
      // return this.rProperties.where("id").equals(collection).toArray();
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save ChargersData to dexieDB: ', error);
    });
  }

  async getRouteProperties(collection: string): Promise<any> {
    const x = await this.rProperties.where("id").equals(collection).toArray();
    // console.log('x: ', x)
    return x;
  }

  async addRouteProperties(collection: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.rProperties.add({ id: collection, data: cadena }).then(() => {
      return 'Saved rProperties to dexieDB';
      // return this.rProperties.where("id").equals(collection).toArray();
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save rProperties to dexieDB: ', error);
    });
  }


  async getVehicleProperties(theid: string): Promise<any> {
    const x = await this.vehicleprop.where("id").equals(theid).toArray();
    // console.log('x: ', x)
    return x;
  }

  async addVehicleProperties(theid: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.vehicleprop.add({ id: theid, data: cadena }).then(() => {
      return 'Saved vehicleprop to dexieDB';
      // return this.rProperties.where("id").equals(collection).toArray();
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save addVehicleProperties to dexieDB: ', error);
    });
  }



  async getInputs(key: string): Promise<any> {
    const x = await this.inputs.where("id").equals(key).toArray();
    // console.log('Inputs of ', key, ' are ', x)
    return x;
  }

  async addInputs(key: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.inputs.add({ id: key, data: cadena }).then(() => {
      return 'Saved inputs to dexieDB';
      // return this.inputs.where("id").equals(key).toArray();
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save Inputs to dexieDB: ', error);
    });
  }

  async getOutputs(key: string): Promise<any> {
    const x = await this.outputs.where("id").equals(key).toArray();
    // console.log('Outputs of ', key, ' are ', x)
    return x;
  }

  async addOutputs(key: string, thedata: any): Promise<void> {
    const cadena = JSON.stringify(thedata);
    await this.outputs.add({ id: key, data: cadena }).then(() => {
      return 'Saved outputs to dexieDB';
      // return this.outputs.where("id").equals(key).toArray();
    }).then((result: any) => {
      // console.log(result);
    }).catch((error: any) => {
      console.log('Could not save outputs to dexieDB: ', error);
    });
  }

  deleteDatabase() {

    this.inputs.clear();
    this.outputs.clear();
    this.rProperties.clear();
    this.vehicleprop.clear();
    this.chargersdata.clear();
    this.chargingsites.clear();
    /* this.rProperties.count().then((res: any) => {
      console.log('size of rProperties es: ', res);
    });
    this.inputs.count().then((res: any) => {
      console.log('size of inputs es: ', res);
    });
    this.outputs.count().then((res: any) => {
      console.log('size of outputs es: ', res);
    });
    this.vehicleprop.count().then((res: any) => {
      console.log('size of vehicleprop es: ', res);
    }); */
    console.log('Tables in database cleared!'); // */
    /* this.db.delete().then(() => {
      console.log("Database successfully deleted");
    }).catch((err) => {
      console.error("Could not delete database");
    }).finally(() => {
      // Do what should be done next...
    }); */
  }

  /* addGetUserDexie(thename: string, theage: number): void {
    this.users.add({ name: thename, age: theage }).then(() => {
      return this.users.where("age").below(100).toArray();
    }).then(youngFriends => {
      console.log("My young users: " + JSON.stringify(youngFriends));
    }).catch(e => {
      console.log("error: " + e.stack || e);
    });
  } */


}

export class DexieDB extends Dexie {
  // users: Dexie.Table<IUser, number>;

  constructor() {
    // console.log('Building dexie DB');
    super('NgDexieMirati');
    this.version(4).stores({
      rproperties: 'id, data',
      inputs: 'id, data',
      outputs: 'id, data',
      vehicleprop: 'id, data',
      chargersdata: 'id, data',
      chargingsites: 'id, data'
    });
    // this.users = this.table("users");
  }
}

/* interface IUser {
  id?: number,
  name: string,
  age: number
} */

interface IRouteProperties {
  id?: string,
  data: string
}

interface IInputs {
  id?: string,
  data: string
}

interface IOutputs {
  id?: string,
  data: string
}

interface IVehicleProp {
  id?: string,
  data: string
}
