<div #myDOMElement fxLayout="row">
  <!-- <div class="laNueve">
    <h2>
      VELOCIDAD PROMEDIO POR SERVICIO
    </h2>
  </div> -->
  <!-- <ejs-chart
    id="chart-container"
    [primaryXAxis]="state.axis5[0]"
    [primaryYAxis]="state.axis5[1]"
    [width]="ancho"
    [height]="alto"
    (window:resize)="onResize($event)"
    [palettes]="colorScheme.domain"
    [tooltip]="tooltip"
  >
    <e-series-collection>
      <e-series
      *ngFor="let chart of state.THEDATA[4]"
      [dataSource]="state.THEDATA[1]"
        type="Column"
        xName="hora"
        yName="velocidad"
        name="hora"
      ></e-series>
    </e-series-collection>
  </ejs-chart> -->
  <ejs-chart
    #chart5
    id="chart-container"
    [primaryXAxis]="state.axis7[1]"
    [primaryYAxis]="state.axis7[0]"
    [height]='alto'
    (window:resize)="onResize($event)"
    [palettes]='colorScheme.domain'
    [tooltip]='tooltip'
  >
    <e-series-collection>
      <e-series
        [dataSource]="state.THEDATA[4]"
        type="Column"
        xName="hora"
        yName="velocidad"
      ></e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<!-- *ngFor="let chart of state.THEDATA[4]"
        [dataSource]="[chart]" -->
