import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/User';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '../register/custom-validators';
import { StateService } from 'src/app/shared/state/state.service';
import { AccumulationDataLabelService } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  actualizado = false;
  id = this.auth.UserId;
  userData: any = {};
  form = this.fb.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    email2: new FormControl('', [Validators.email, Validators.required]),
    /* emailBackUp: new FormControl('', [Validators.email, Validators.required]),
    emailBackUp2: new FormControl('', [Validators.email, Validators.required]), */
    password1: new FormControl('', [Validators.required]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    contactname: new FormControl('', [Validators.required]),
    organization: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    super: new FormControl('', [Validators.required]),
    activo: new FormControl('', [Validators.required]),
  });
  loginInvalid = false;
  elError = '';
  collections: any = [];

  constructor(
    private auth: AuthService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private stateSer: StateService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const data = await this.auth.getUser(this.id).toPromise();
      this.userData = data;
      // console.log('La user data: ', this.userData);
      this.collections = this.userData.authCollections;
      // this.collections.push('mi house');
      this.form = this.fb.group({
        email: new FormControl(this.userData.email, [
          Validators.email,
          Validators.required,
        ]),
        email2: new FormControl(this.userData.email, [
          Validators.email,
          Validators.required,
        ]),
        /* emailBackUp: new FormControl(this.userData.backupEmail, [
          Validators.email,
          Validators.required,
        ]),
        emailBackUp2: new FormControl(this.userData.backupEmail, [
          Validators.email,
          Validators.required,
        ]), */
        password1: new FormControl('', [
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
        ]),
        password2: new FormControl('', [
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
        ]),
        contactname: new FormControl(this.userData.contactname, [
          Validators.required,
        ]),
        organization: new FormControl(
          { value: this.userData.organization, disabled: true },
          [Validators.required]
        ),
        phone: new FormControl(this.userData.phone, [Validators.required]),
        address: new FormControl(this.userData.address, [Validators.required]),
        country: new FormControl(this.userData.country, [Validators.required]),
        state: new FormControl(this.userData.state, [Validators.required]),
        city: new FormControl(this.userData.city, [Validators.required]),
      });
    } catch (error) {
      console.log('No se pudo obtener información del usuario: ', error);
    }
  }

  get userEmail(): any {
    return this.form.get('email');
  }

  get userEmail2(): any {
    return this.form.get('email2');
  }

  /* get backupEmail(): any {
    return this.form.get('emailBackUp');
  }

  get backupEmail2(): any {
    return this.form.get('emailBackUp2');
  } */

  get password1(): any {
    return this.form.get('password1');
  }

  get password2(): any {
    return this.form.get('password2');
  }

  get organization(): any {
    return this.form.get('organization');
  }

  get contactname(): any {
    return this.form.get('contactname');
  }

  get address(): any {
    return this.form.get('address');
  }

  get phone(): any {
    return this.form.get('phone');
  }

  get country(): any {
    return this.form.get('country');
  }

  get state(): any {
    return this.form.get('state');
  }

  get city(): any {
    return this.form.get('city');
  }

  get super(): any {
    return this.form.get('super');
  }


  async onSubmit(): Promise<void> {
    // console.log('Actualizando usuario!');
    if (this.form.valid) {
      if (this.userEmail.value !== this.userEmail2.value) {
        this.loginInvalid = true;
        this.elError =
          'Debe repetir los mismos correos tanto en el correo principal como en el secundario';
        return;
      }
      /* if (this.backupEmail.value !== this.backupEmail2.value) {
        this.elError =
          'Debe repetir los mismos correos tanto en el correo principal como en el secundario';
        this.loginInvalid = true;
        return;
      } */
      if (this.password1.value !== this.password2.value) {
        this.elError = 'La contraseña debe ser la misma en ambos campos';
        this.loginInvalid = true;
        return;
      }
      const usuario = {
        email: this.userEmail.value,
        // backupEmail: this.backupEmail.value,
        // organization: this.organization.value,
        phone: this.phone.value,
        address: this.address.value,
        contactname: this.contactname.value,
        country: this.country.value,
        state: this.state.value,
        city: this.city.value,
      };
      // console.log('Enviando usaurio al auth serv: ', usuario);
      try {
        this.auth.updateUser(this.userData.id, usuario).then(() => {
          // this.router.navigate(['/user-info']);
          this.actualizado = true;
          setTimeout(() => {
            this.actualizado = false;
            this.elError = '';
          }, 1500);
        });
        // this.form.reset();
      } catch (err) {
        this.loginInvalid = true;
        this.elError = 'Actualización fallida, revise sus datos';
      }
    } else {
      this.loginInvalid = true;
      this.elError = 'Por favor llene todos los campos.';
    }

    return;
  }

  async updatePassword(): Promise<void> {

    if (this.password1.value !== this.password2.value) {
      this.elError = 'La contraseña debe ser la misma en ambos campos';
      return;
    }
    if (this.password1.value.trim() === '') {
      this.elError = 'Ingrese una contraseña válida de al menos 8 caracteres';
      return;
    }
    const contra = {
      password: this.password1.value
    };
    console.log('Enviando pass: ', contra);
    try {
      this.auth.UpdatePassword(contra).then(() => {
        console.log('Contraseña actualizada');
        this.actualizado = true;
        setTimeout(() => {
          this.actualizado = false;
          this.elError = '';
        }, 1500);
      });
      // this.form.reset();
    } catch (err) {
      this.loginInvalid = true;
      this.elError = 'Actualización fallida, revise sus datos';
    }

    return;
  }
}
