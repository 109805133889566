import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-vehicle-properties',
  templateUrl: './vehicle-properties.component.html',
  styleUrls: ['./vehicle-properties.component.scss']
})
export class VehiclePropertiesComponent implements OnInit {

  constructor(public state: StateService, public data: DataService) { }

  ngOnInit(): void {
  }

}
