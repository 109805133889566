import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth-service.service';


@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(
    private router: Router,
    private authService: AuthService
    ) { }


    canActivate(): boolean {
      // Check to see if a user has a valid token
      if (this.authService.isLoggedIn) {
          // If they do, return true and allow the user to load app
          return true;
      }

      // If not, they redirect them to the login page
      this.router.navigate(['/login']);
      return false;
  }

}
