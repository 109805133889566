<!-- <div class="nueva"> -->
    <div class="nueva" *ngIf="collections.length !== 0">
        <!-- <h3 class="mb-3 text-center">Clientes</h3> -->
      
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <!-- <th scope="col">Id</th> -->
                <th scope="col">Nombre</th>
                <th scope="col">Propietario</th>
                <th scope="col">Buses</th>
                <!-- <th scope="col">GpsId con buses</th>                 -->
                <th scope="col">Ubicación</th>
                <th scope="col">Editar</th>
                <th scope="col">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let col of collections">
                <!-- <td>{{ col.id }}</td> -->
                <td>{{ col.name }}</td>
                <td>{{ col.owner }}</td>
                <td>{{ col.buses }}</td>
                <td>[ {{ col.longitud }}, {{ col.latitud }}]</td>
                <td>
                  <button mat-raised-button routerLink="/edit-collection/{{ col.id }}">
                    Editar
                  </button>
                </td>
                <td>
                  <button mat-raised-button (click)="deleteCollection(col.id)">
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-top: 30px; width: 100%;">
        <form fxLayout="column" [formGroup]="form" (ngSubmit)="onSubmit()">
          <h2 class="cadena">Adicionar nueva collección</h2>
          <h1 *ngIf="actualizado">¡Adición exitosa!</h1>
          <div
            class="input-row"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px"
          >
            <mat-form-field fxFlex>
              <input
                matInput
                type="text"
                placeholder="Nombre de la collección"
                formControlName="name"
              />
            </mat-form-field>
      
            <mat-form-field fxFlex>
              <input
                matInput
                type="text"
                placeholder="Propietario"
                formControlName="owner"                
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                type="text"
                placeholder="Lista de buses separados por ','"
                formControlName="rutas"
              />
            </mat-form-field>

            <mat-form-field fxFlex>
              <input
                matInput
                type="text"
                placeholder="Longitud"
                formControlName="longitud"                
              />
            </mat-form-field>
            <mat-form-field fxFlex>
              <input
                matInput
                type="text"
                placeholder="Latitud"
                formControlName="latitud"
              />
            </mat-form-field>
          </div>
      
          
      <!-- 
          <mat-error *ngIf="loginInvalid">
            Formulario inválido: {{ elError }}
          </mat-error> -->
      
          <button
            style="
              font-family: 'Poppins' !important;
              border-radius: 25px;
              margin-top: 40px;
              margin-bottom: 20px;
              max-width: 200px;
              align-self: center;
            "
            mat-raised-button
          >
            Adicionar
          </button>
        </form>
      </div>
      </div>
      <!-- </div> -->
      