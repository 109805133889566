<div class="container" *ngIf="this.state.show_info_bus==true">
    <!-- Card 1: Plataforma Vehicular -->
    <div class="card">
      <div class="card-header">
        {{this.dataTpv["titulo"][this.state.indiceTranslate]}}
      </div>
      <div class="card-body">
        <p>{{this.dataTpv["rfevehiculo"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["vehicleplatform"]["vehicleplatformid"]}}</p>
        <p>{{this.dataTpv["tipocarga"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["vehicleplatform"]["chargingtype"]}}</p>
        <p>{{this.dataTpv["pesoplataforma"][this.state.indiceTranslate]}} (kg): {{this.state.vehicle_platform_data["vehicleplatform"]["weight"]}}</p>
        <p>{{this.dataTpv["tamaniovehiculo"][this.state.indiceTranslate]}} (m): {{this.state.vehicle_platform_data["vehicleplatform"]["lenght"]}}</p>
        <p>{{this.dataTpv["pass"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["vehicleplatform"]["numpassangers"]}}</p>
        <p>{{this.dataTpv["sillas"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["vehicleplatform"]["chairnumber"]}}</p>
        <p>{{this.dataTpv["consumoaux"][this.state.indiceTranslate]}} (W): {{this.state.vehicle_platform_data["vehicleplatform"]["auxconsumption"]}}</p>
      </div>
    </div>
  
    <!-- Card 2: Tren de Potencia -->
    <div class="card">
      <div class="card-header">
        {{this.dataTtp["titulo"][this.state.indiceTranslate]}}
      </div>
      <div class="card-body">
        <p>{{this.dataTtp["tipomotor"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["electricmotors"]["motortype"]}}</p>
        <p>{{this.dataTtp["conf"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["electricmotors"]["config"]}}</p>
        <p>{{this.dataTtp["pesomotor"][this.state.indiceTranslate]}} (kg): {{this.state.vehicle_platform_data["electricmotors"]["weight"]}}</p>
        <p>{{this.dataTtp["potencianominal"][this.state.indiceTranslate]}} (kW): {{this.state.vehicle_platform_data["electricmotors"]["nompower"]}}</p>
        <p>{{this.dataTtp["maxrpm"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["electricmotors"]["maxrpm"]}}</p>
        <p>{{this.dataTtp["maxtorque"][this.state.indiceTranslate]}} (Nm): {{this.state.vehicle_platform_data["electricmotors"]["maxtorque"]}}</p>
        <p>{{this.dataTtp["gearbox"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["transmision"]["gbk"]}}</p>
      </div>
    </div>
  
    <!-- Card 3: Almacenamiento Energético -->
    <div class="card">
      <div class="card-header">
        {{this.dataTae["titulo"][this.state.indiceTranslate]}}
      </div>
      <div class="card-body">
        <p>{{this.dataTae["quimicabat"][this.state.indiceTranslate]}}: {{this.state.vehicle_platform_data["energystorage"]["chemistry"]}}</p>
        <p>{{this.dataTae["energia"][this.state.indiceTranslate]}} (kWh): {{this.state.vehicle_platform_data["energystorage"]["batteryenergy"]}}</p>
        <p>{{this.dataTae["capacidad"][this.state.indiceTranslate]}} (Ah): {{this.state.vehicle_platform_data["energystorage"]["batterycapacity"]}}</p>
        <p>{{this.dataTae["maxcorriente"][this.state.indiceTranslate]}} (A): {{this.state.vehicle_platform_data["energystorage"]["batterymaxcurrent"]}}</p>
        <p>{{this.dataTae["maxvoltaje"][this.state.indiceTranslate]}} (V): {{this.state.vehicle_platform_data["energystorage"]["batterymaxvoltage"]}}</p>
        <p>{{this.dataTae["pesobateria"][this.state.indiceTranslate]}} (kg): {{this.state.vehicle_platform_data["energystorage"]["batteryweight"]}}</p>
      </div>
    </div>
  </div>