<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #default_dashboard columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%;">
                    <div class="e-panel-container" >
                        <ejs-chart #chartenergia title="Energía de Red  vs Hora del Día"
                            style="width:100%;height:100%;display: block; ">
                            <e-series-collection style="width:100%;height:100%">
                                <e-series style="width:100%;height:100%;">
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                    </div>
                </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">

                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>