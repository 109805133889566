<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">
  <ejs-chart #chart6a *ngIf="cual === 4" id="chart-container" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]="alto" (window:resize)="onResize($event)" [palettes]="colorScheme.domain" [tooltip]="tooltip"
    (tooltipRender)="ontooltipRender($event)">
    <e-series-collection>
      <e-series [dataSource]="state.THEDATA[6]" type="Column" xName="name" yName="value" legendShape='Rectangle'>
      </e-series>
    </e-series-collection>
  </ejs-chart>


  <ejs-chart #chart6b *ngIf="cual === 6" id="chart-container" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]="alto" (window:resize)="onResize($event)" [palettes]="colorScheme2.domain" [tooltip]="tooltip"
    (tooltipRender)="ontooltipRender($event)" [legendSettings]="legendSettings">
    <e-series-collection>
      <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn" xName="ruta" yName="baselineconsumption"></e-series>
      <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn" xName="ruta" yName="passconsumption"
        name="Impacto de Pasajeros"></e-series>
      <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn" xName="ruta" yName="hvacconsumption"
        name="Impacto de HVAC"></e-series>
    </e-series-collection>
  </ejs-chart>

  
<!--  <ejs-chart #chart6b *ngIf="cual === 6" id="chart-container" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]="alto" (window:resize)="onResize($event)" [palettes]="colorScheme2.domain" [tooltip]="tooltip"
    (tooltipRender)="ontooltipRender($event)" [legendSettings]="legendSettings">
    <e-series-collection>
      <e-series [dataSource]="this.dataSer.listaCollection2[0]" type="StackingColumn" xName="routes" yName="baselineconsumption"></e-series>
      <e-series [dataSource]="state.THEDATA[5]" type="StackingColumn" xName="ruta" yName="value2"
        name="Impacto de Pasajeros"></e-series>
      <e-series [dataSource]="state.THEDATA[5]" type="StackingColumn" xName="ruta" yName="value3"
        name="Impacto de HVAC"></e-series>
    </e-series-collection>
  </ejs-chart>
-->

</div>
