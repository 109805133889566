<div fxLayout="row" style="margin-top: 0px; padding-top: 0px;">

  <ejs-chart #chartsites id="chart-container" [primaryXAxis]="axisx" [primaryYAxis]="axisy" [height]='alto'
    (window:resize)="onResize($event)" [palettes]='colorScheme.domain' [tooltip]='tooltip'>
    <e-series-collection>
      <e-series [dataSource]="state.THEDATA[11][0]" type="Column" xName="stop" yName="type"></e-series>
      <e-series [dataSource]='state.THEDATA[11][1]' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]="state.THEDATA[11][2]" type="Line" xName="x" yName="y"></e-series>

    </e-series-collection>
  </ejs-chart>


</div>
