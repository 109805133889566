<div [ngClass]=" state.currentRoute === 'None' ? 'tarjetaNuevaAlter' : 'tarjetaNueva'">
  <div style="width: 10vw !important; color: white;  margin-right: 10px">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Ruta
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{bus}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 10vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Método de carga
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].chargingmethod}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 5vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Tamaño
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].vehiclesize}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 7vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Peso
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].vehicleweight}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 8vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Máximo de pasajeros
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].passengers}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 7vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Tamaño de batería
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].batteryenergy}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 7vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Tipo de batería
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].batterytype}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 7vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Poder de carga
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].chargepower}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div style="width: 8vw !important; color: white;">
    <mat-grid-list cols="1" rowHeight="55px">
      <mat-grid-tile>
        Poder de tracción
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of state.busNames; index as i">
        {{data.allVehicleProperties[state.currentCollection + '_' + bus].tractionpower}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>

</div>
