import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-discriminated',
  templateUrl: './discriminated.component.html',
  styleUrls: ['./discriminated.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscriminatedComponent implements OnInit, AfterViewInit, OnChanges {
  multi: any = [];
  initMulti: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  icono = true;

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;

  colorScheme = {
    // domain: ['#21d3f3', '#E44D25', '#CFC0BB', '#1bb713']
    domain: ['#88898C', '#F5821E', '#000096'],
  };
  public animation: object = { enable: false };
  ancho = '';
  alto = '';

  public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @Input() grande: boolean;
  changeAnimation: Subscription | undefined;
  chartChange: Subscription | undefined;
  paxChange: Subscription | undefined;

  @ViewChild('chart8')
  public chart!: ChartComponent;

  constructor(
    private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    /* this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + ''; */
    // this.state.currentChart = 8;
    this.matIconRegistry.addSvgIcon(
      'descargar1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );

  }

  print(){
    this.chart.print();
  }

  async ngOnInit(): Promise<void> {
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
    // this.state.currentChart = 8;
    this.subscribeConfiguration();
    /* console.log('discriminated[0].datos size: ', this.state.THEDATA[7][0].datos.length);
    console.log('discriminated[1].datos size: ', this.state.THEDATA[7][1].datos.length);
    console.log('discriminated[2].datos size: ', this.state.THEDATA[7][2].datos.length); */
  }

  subscribeConfiguration(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        // console.log('Updating chart');
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.chartChange = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        // console.log('Updating chart');
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.paxChange = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        // console.log('Updating chart');
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
    if (this.paxChange) {
      // console.log('Unsubscribing from routes in destroy');
      this.paxChange.unsubscribe();
      this.paxChange = undefined;
    }
    if (this.chartChange) {
      // console.log('Unsubscribing from routes in destroy');
      this.chartChange.unsubscribe();
      this.chartChange = undefined;
    }
    // console.log('MAPS component destroyed');
  }
}
