import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-chargers-card',
  templateUrl: './chargers-card.component.html',
  styleUrls: ['./chargers-card.component.scss']
})
export class ChargersCardComponent implements OnInit {

  constructor(public state: StateService, public dataSer: DataService) { }

  ngOnInit(): void {
  }

}
