import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LinearGaugeComponent } from '@syncfusion/ej2-angular-lineargauge';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-power-ceiling',
  templateUrl: './power-ceiling.component.html',
  styleUrls: ['./power-ceiling.component.scss']
})
export class PowerCeilingComponent implements OnInit {
  public animation: object;
  public pointers5: Object[];
  public items: any = [];
  public container: Object;
  public majorTicks: object;
  public minorTicks: object;
  public margin: Object;
  public line: Object;
  public label: Object;
  public labelStyle: object;
  @ViewChild('gaugepower')
  public gaugepower!: LinearGaugeComponent;
  subscription0: Subscription | undefined;

  constructor(public state: StateService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.labelStyle = {
      font: {
        color: 'white',
        size: '0px',
        fontWeight: 'Bold'
      }
    };
    this.animation = {
      enable: false
    };
    this.majorTicks = {
      // height: 10,
      color: 'white',
      // interval: 0
    };
    this.minorTicks = {
      // height: 5
      color: 'white',
    };

    this.container = {
      width: 50,
      type: "RoundedRectangle",
      backgroundColor: "#ff0000"
    };
    this.line = {
      // height: 150,
      width: 2,
      color: '#4286f4',
      offset: 2
    };
    this.label = {
      position: "Inside"
    };
    this.margin = { left: 0, top: 0, right: 0, bottom: 0 }
    /* this.items.push({id: 'soc_1', socGaugeMax: this.state.socGaugeMax, color: '#ff0000', maximum: 500, current: 100, ending: 400 });
    this.items.push({id: 'soc_2', socGaugeMax: 30, color: '#00FF00', maximum: 350, current: 150, ending: 300 });
    this.items.push({id: 'soc_3', socGaugeMax: 30, color: '#00FF00', maximum: 350, current: 50, ending: 100 }); */
    /* this.state.allLocations.forEach((cole: any) => {
      if (this.state.currentCollection === cole.colName) { */
    this.state.currentChargersNames.forEach((c: any) => {
      this.items.push(
        {
          id: c.replace(/\s/g, ""),
          color: '#ff0000',
          maximum: 1000,
        }
      );
    });
    /*       }
        }); */
    // console.log('items: ', this.items);
    /*  this.state.busNames.forEach((c: any, index: number) => {
       this.items.push({
         id: this.state.currentCollection + c,
         color: '#ff0000',
         maximum: 500,
       });

     }); */

    // this.subscribeConfiguration();
  }

  enteroDe(x: number): number {
    return parseInt(x.toFixed(0));
  }

  subscribeConfiguration(): void {
    this.subscription0 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        // console.log('updating gauhe soc');
        this.changeDetector.detectChanges();
        this.gaugepower.refresh();
      }
    );
  }

  get laSubs(): any {
    return this.subscription0;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.laSubs)
      this.laSubs.unsubscribe();

  }
}
