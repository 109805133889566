import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, IPointEventArgs, StackingAreaSeriesService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-bateria',
  templateUrl: './dashboard-bateria.component.html',
  styleUrls: ['./dashboard-bateria.component.scss']
})
export class DashboardBateriaComponent implements OnInit {
  public dataTBat = this.state.dictTranslate.DashboardsGraph.bateria;
  iframeSrc: SafeResourceUrl;
  iframeCeldaSrc: SafeResourceUrl;


  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E'],
  };
  public tooltip = { enable: true };
  public animations = false;
  public animation: object = { enable: false };
  ancho = '';
  alto = '';

  public seleccionchart

  axisX = {
    valueType: 'Double',
    labelFormat: 'n0',
    //title:"Distancia (Km)",
    title: this.state.dictTranslate.DashboardsGraph.bateria.graficoconsumpbtms.ejex[this.state.indiceTranslate],//'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0.00,
    rangePadding: 'Auto',
    /*interval: maxxx / 10, */
  };
  axisY = {
    title: this.state.dictTranslate.DashboardsGraph.bateria.graficoconsumpbtms.ejey[this.state.indiceTranslate],//'Energía (kWh)',
    //title : this.state.dictTranslate.DashboardsGraph.bateria.graficoconsumpbtms.ejey[this.state.indiceTranslate],//'Energía (kWh)',
    labelFormat: 'n1',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0.00,
    rangePadding: 'Auto',
    /* interval: myIntervalo, */
  };
  public imgRefNamePack = ""
  public dataTable:any
  public estaCompleto =true
  constructor(private sanitizer: DomSanitizer,private changeDetector: ChangeDetectorRef, private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) { }

  ngOnInit(): void {

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://sketchfab.com/models/'+this.state.uid_model_bus["uidbus"]+'/embed?autostart=1&camera=0');
    this.iframeCeldaSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://sketchfab.com/models/'+this.state.uid_model_bus["uidcelda"]+'/embed?autostart=1&camera=0');

    this.estaCompleto = this.state.listaEstadosDashboard["bateria"]
    if (this.state.currentType == 'Opportunity') {
      this.imgRefNamePack ='BEB_panto_Battery_'+this.state.currentBattery+'kWh_'+this.state.currentBusSize+'m.png'
      console.log("bateria",this.imgRefNamePack)
    }else{
      this.imgRefNamePack='BEB_conector_Battery_'+this.state.currentBattery+'kWh_'+this.state.currentBusSize+'m.png'
      console.log("bateria",this.imgRefNamePack)
    }
    this.dataTable=this.state.listaDatosBateriaTabla
  }

  ngAfterViewInit(): void {




    setTimeout(() => {
      this.seleccionchart = 1
    }, 1500)


  }

  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  irGraficoBateria() {
    this.seleccionchart = 1
  }
  irImgBateria() {
    this.seleccionchart = 2
  }
  irImgCelda() {
    this.seleccionchart = 3
  }
}
