<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <!-- Energia Neta Disponible-->
                    <div *ngIf="this.seleccionchart == 1 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement1 title={{this.dataTer.energianeta.title[this.state.indiceTranslate]}} [tooltip]='tooltip'
                            [primaryXAxis]="axisxDED"[primaryYAxis]="axisyDED"
                                style="width:100%;height:100%; " [zoomSettings]='zoom' [palettes]='colorScheme.domain'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series [dataSource]="this.dataDED" xName="x" yName="y" type='Column'
                                        style="width:100%;height:100%; ">
                                    </e-series>
                                    <e-series [dataSource]="this.lineEnergiaNeta" xName="x" yName="y" type='Line' dashArray='5,5'width=5></e-series>

                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!-- Disponibilidad de Potencia-->
                    <div *ngIf="this.seleccionchart == 2 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement2 title={{this.dataTer.disponibilidadpot.title[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                style="width:100%;height:100%; " [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                [primaryXAxis]='axisxDvsP' [primaryYAxis]="axisyDvsP">
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series [dataSource]="this.dataDvsPcharger" xName="x" yName="y" type='Line'
                                        name={{this.dataTer.disponibilidadpot.ejex1[this.state.indiceTranslate]}} style="width:100%;height:100%; ">
                                    </e-series>
                                    <e-series [dataSource]="this.dataDvsPremanente" xName="x" yName="y" type='Line'
                                        name={{this.dataTer.disponibilidadpot.ejex2[this.state.indiceTranslate]}} style="width:100%;height:100%; ">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!-- Energia Renovable Acumulada-->
                    <div *ngIf="this.seleccionchart == 3 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement2 title={{this.dataTer.energia.title[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                style="width:100%;height:100%; " [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                [primaryXAxis]='axisxDvsE' [primaryYAxis]="axisyDvsE">
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series [dataSource]="this.dataDvsEcharger" xName="x" yName="y" type='Line'
                                        name={{this.dataTer.energia.ejex1[this.state.indiceTranslate]}} style="width:100%;height:100%; ">
                                    </e-series>
                                    <e-series [dataSource]="this.dataDvsEremanente" xName="x" yName="y" type='Line'
                                        name={{this.dataTer.energia.ejex2[this.state.indiceTranslate]}} style="width:100%;height:100%; ">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTer.menu.grafico[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTer.menu.rbttenergianeta[this.state.indiceTranslate]}} name="graficos"
                            style="margin-bottom: 10px;" (click)="irDED()" checked="true"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTer.menu.rbtdisponibilidadpo[this.state.indiceTranslate]}} name="graficos" style="margin-bottom: 10px;"
                            (click)="irPvsT()" cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTer.menu.tbrenergiarenovableacum[this.state.indiceTranslate]}} name="graficos" style="margin-bottom: 10px;"
                            (click)="irEvsT()" cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>