import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
  NgModule,
} from '@angular/core';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { MapService } from 'src/app/shared/map/map.service';
import { Browser } from '@syncfusion/ej2-base';
import { ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { AccumulationTheme, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import * as mapboxgl from 'mapbox-gl';
import { timestamp } from 'rxjs';


@Component({
  selector: 'app-animacionruta',
  templateUrl: './animacionruta.component.html',
  styleUrls: ['./animacionruta.component.scss']
})
export class AnimacionrutaComponent implements OnInit {

  constructor(public data: DataService, public state: StateService, public mapser: MapService) { }
  public hide = true
  public intervaloanicion: any;
  public dataTAR = this.state.dictTranslate.DashboardsGraph.tablaanimacion;
  // Circule Gauge
  public margin: Object | undefined;
  public majorTicks: object | undefined;
  public minorTicks: object | undefined;
  public labelStyle: object | undefined;
  public colorer: any;
  public colorsoc: any;
  public colored: any;
  public colorrei: any;
  public animacionactiva: any
  public indiceanimacion = 0
  public indiceanimacioncoord = 0
  public animacionid: any
  public indiceseleccionado: any
  public contadorBuses = 1
  public mapamarker: any;
  public listaCardInfo: any = []
  public indiceBusSelect = 0
  public showResumen = false
  public terminoAnimacion = false
  public plantillaAResumen: any = {}
  public contornoCG: any
  public indiceboton =3
  public dataTL=this.state.dictTranslate.DashboardsGraph.tablaanimacion;

  public datosgeneralesAnimacion = {
    'coorestacion': [],
    'eficienciabus': 1.0570884470111688,
    'tamaniobateriabus': 350,
    'socanterior': 100.00,
    'regeneracioninterno': 0
  }
  ngOnDestroy() {
    cancelAnimationFrame(this.animacionid);
    this.state.listaMarkerAnimacionRuta.forEach(element => {
      element.remove()
    });
    this.state.listaMarkerAnimacionRuta = []
    this.mapamarker.removeSource('markers')

  }
  async ngOnInit(): Promise<void> {
    // Circule Gauge init
    this.showResumen=false
    this.margin = { left: 0, top: 0, right: 0, bottom: 0 }
    this.contornoCG = {
      width: 1.5,
      color: '#000096',
    }
    this.majorTicks = {
      // height: 10,
      color: '#000096',
      width: 2,

      // interval: 0
    };
    this.minorTicks = {
      // height: 5
      color: '#000096',
      width: 2,

    };

    this.labelStyle = {
      font: {
        color: '#000096',
        size: '0px',
        fontWeight: 'Bold'
      }
    };

    this.animacionactiva = true;
    // Inicializacion
    this.state.listaMarkerAnimacionRuta = []
    if (this.state.listaMarkerAnimacionRuta.length != 0) {
      for (let i = 0; i < this.state.listaMarkerAnimacionRuta.length; i++) {
        var busmarker = this.state.listaMarkerAnimacionRuta[i];
        busmarker.remove()
      }
    }
    this.state.listaHoraInicioAnimacion = []
    clearInterval(this.state.intervaloanimacion)

    this.mapamarker = this.mapser.map.addSource('markers', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [] // Array vacío para almacenar los marcadores
      }
    });
    var dataanimacionruta = this.state.listaDatosAnimacionRuta
    console.log("data animacion ruta", dataanimacionruta)
    this.datosgeneralesAnimacion.coorestacion = [this.state.listaDatosAnimacionRuta["latitud"][0][0], this.state.listaDatosAnimacionRuta["longitud"][0][0]]
    await this.dibujarBuses(dataanimacionruta["busesid"], dataanimacionruta["latitud"], dataanimacionruta["longitud"])
    await this.getHoraInicio(dataanimacionruta)
    //var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
    //markerselected.togglePopup()
    this.mapser.map.flyTo({
      center: [this.datosgeneralesAnimacion.coorestacion[1], this.datosgeneralesAnimacion.coorestacion[0]],
      zoom: 12,
      speed: 0.3,
      pitch: 40,
    });
    this.iniciarAnimacionSegundo()

  }
  trackByFn(index: number, item: any): number {
    return item.serialNumber;
  }
  dibujarBuses(buses, lat, long) {
    console.log("buses animacion", buses)
    for (let i = 0; i < buses.length; i++) {
      var bus = buses[i];
      var buslong = long[i][0];
      var buslat = lat[i][0];
      const ele = document.createElement('div');
      ele.className = 'busanimado';
      ele.style.backgroundImage =
        'url(../../../assets/busiconNormal.png)';
      ele.style.backgroundSize = '100%';
      var markerbus = new mapboxgl.Marker(ele)
        // Long - Lat
        .setLngLat([buslong, buslat])
        .addTo(this.mapamarker)
      
      //const popup = new mapboxgl.Popup({
      //  offset: 25,
      //  closeButton: false,
      //  closeOnClick: true,
      //}).setHTML('<h2> ' + 'Bus ID : ' + bus + '</h2>');

      // Asignar el popup al marker
      //markerbus.setPopup(popup);
      //.setPopup(
      //new mapboxgl.Popup({
      //  offset: 25,
      //  closeButton: false,
      //  closeOnClick: true,
      //}).setHTML(
      //  '<h2> ' + 'Bus ID : ' + bus + '</h2>'
      //)
      //)      
      this.state.listaMarkerAnimacionRuta[i] = markerbus
    }
    console.log("buses marker", this.state.listaMarkerAnimacionRuta)


  }
  ejemplo(i) {
    console.log("## Ejemplo ##", i)
  }
  async iniciarAnimacionSegundo() {
    // Consultar los paquetes
    //  revisar el numero de grupos
    console.log("iniciar animacion", this.contadorBuses)
    if (this.contadorBuses <= 24) {
      var charcontador = '_' + this.contadorBuses.toString()
      this.state.listaDatosAnimacionRuta =[]
      await this.data.getDataAnimacionRuta(this.state.cadenabusquedaanimacion + charcontador)
      this.datosgeneralesAnimacion.coorestacion = [this.state.listaDatosAnimacionRuta["latitud"][0][0], this.state.listaDatosAnimacionRuta["longitud"][0][0]]
      // Volar al mapa

      this.animacionid = requestAnimationFrame(() => this.animar(this.state.listaMarkerAnimacionRuta));
      this.contadorBuses = this.contadorBuses + 1
    } else {
      return;
    }
    console.log("animacion finalizado")
    this.showResumen=true


  }
  animar(listamarker) {
    // Actualizar la animación
    var plantillaDAR = {}
    var dataprincipal = this.state.listaDatosAnimacionRuta
    var boolInicioCarga = Array(50).fill(false);

    //console.log(this.indiceanimacion, dataprincipal["longitud"][0].length)
    if (this.indiceanimacion >= dataprincipal["longitud"][0].length) {
      // Detener la animación
      console.log("Detener Animacion")
      this.indiceanimacion = 0
      cancelAnimationFrame(this.animacionid);
      this.iniciarAnimacionSegundo()
      return;
    }
    for (let i = 0; i < listamarker.length; i++) {
      const busmarker = listamarker[i];
      busmarker.setLngLat([dataprincipal["longitud"][i][this.indiceanimacion], dataprincipal["latitud"][i][this.indiceanimacion]]);
    }
    // Llenar datos para el Card
    //if (this.indiceBusSelect >= 0) {
    plantillaDAR["busesid"] = dataprincipal["busesid"][this.indiceBusSelect]
    var estado
    if (dataprincipal["costo"][this.indiceBusSelect][this.indiceanimacion] > 0 && dataprincipal["velocidad"][this.indiceBusSelect][this.indiceanimacion] < 0) {
      //standby
      estado = "Stand By"
    } else {
      if (dataprincipal["costo"][this.indiceBusSelect][this.indiceanimacion] > 0) {
        //Cargando
        estado = this.dataTAR.estadocarga[this.state.indiceTranslate]
      } else {
        //transito
        estado = this.dataTAR.estadotransit[this.state.indiceTranslate]
      }
    }
    plantillaDAR["estado"] = estado
    //console.log("========",this.state.listaHoraInicioAnimacion[this.indiceBusSelect])
    plantillaDAR["horainicio"] = this.state.listaHoraInicioAnimacion[this.indiceBusSelect].substr(11, 5)
    plantillaDAR["energiadisponible"] = (dataprincipal["soc"][this.indiceBusSelect][this.indiceanimacion] / 100) * this.datosgeneralesAnimacion["tamaniobateriabus"]
    this.colored = this.colorBateriaestado(plantillaDAR["energiadisponible"], this.datosgeneralesAnimacion["tamaniobateriabus"])

    // Tiempo Transcurrido
    //var horainiciovalue = this.diffTiempoTranscurrido(this.state.listaHoraInicioAnimacion[this.indiceBusSelect], dataprincipal["timeday"][this.indiceBusSelect][this.indiceanimacion])
    //plantillaDAR["tiempotranscurrido"] = horainiciovalue[0] == '-' ? '--:--' : horainiciovalue
    // Distancia 
    plantillaDAR["distancia"] = dataprincipal["ditanciakm"][this.indiceBusSelect][this.indiceanimacion]
    // Distancia recorrida
    plantillaDAR["distanciarecorrida"] = this.DistanciaCritica(dataprincipal["latitud"][this.indiceBusSelect][this.indiceanimacion], dataprincipal["longitud"][this.indiceBusSelect][this.indiceanimacion], this.datosgeneralesAnimacion["coorestacion"][0], this.datosgeneralesAnimacion["coorestacion"][1])
    // Autonomia
    plantillaDAR["autonomia"] = dataprincipal["soc"][this.indiceBusSelect][this.indiceanimacion] / this.datosgeneralesAnimacion["eficienciabus"]
    // Energia Remanente
    plantillaDAR["energiaremanente"] = (this.state.currentBusDistance - plantillaDAR["distanciarecorrida"]) * this.datosgeneralesAnimacion["eficienciabus"] //falta restar por la distancia total
    this.colorer = this.colorEnergiaRemanente(plantillaDAR["energiaremanente"], plantillaDAR["energiadisponible"])
    //SoC %
    plantillaDAR["soc"] = dataprincipal["soc"][this.indiceBusSelect][this.indiceanimacion]
    this.colored = this.colorBateriaestado(plantillaDAR["soc"], 100)
    // Regeneración de Energia bus Interno
    plantillaDAR["regeneracioninterno"] = this.RegeneracionEnergia(dataprincipal["soc"][this.indiceBusSelect][this.indiceanimacion], this.datosgeneralesAnimacion["socanterior"], this.datosgeneralesAnimacion["tamaniobateriabus"], this.datosgeneralesAnimacion["regeneracioninterno"], dataprincipal["velocidad"][this.indiceBusSelect][this.indiceanimacion])
    this.state.listaDatosTabla[0] = plantillaDAR
    //}else{
    this.plantillaAResumen["numerobusesservicio"] = this.state.listaDatosAnimacionRutaResumen["numerobusesservicio"][this.indiceanimacion]
    this.plantillaAResumen["numerobusescargando"] = this.state.listaDatosAnimacionRutaResumen["numerobusescargando"][this.indiceanimacion]
    this.plantillaAResumen["numerobusesstandby"] = this.state.listaDatosAnimacionRutaResumen["numerobusesstandby"][this.indiceanimacion]
    this.plantillaAResumen["kilometrosrecorridos"] = this.state.listaDatosAnimacionRutaResumen["kilometrosrecorridos"][this.indiceanimacion].toFixed(0)
    this.plantillaAResumen["cargadoresdisponibles"] = this.state.listaDatosAnimacionRutaResumen["cargadoresdisponibles"][this.indiceanimacion]
    this.plantillaAResumen["cargadoresocupados"] = this.state.listaDatosAnimacionRutaResumen["cargadoresocupados"][this.indiceanimacion]
    //this.plantillaAResumen["eventoscargacompletados"] = this.state.listaDatosAnimacionRutaResumen["eventoscargacompletados"][this.indiceanimacion]
    this.plantillaAResumen["energiaconsumidaservicio"] = this.state.listaDatosAnimacionRutaResumen["energiaconsumidaservicio"][this.indiceanimacion].toFixed(0)
    this.plantillaAResumen["energiaconsumidarecargas"] = this.state.listaDatosAnimacionRutaResumen["energiaconsumidarecargas"][this.indiceanimacion].toFixed(0)
    this.plantillaAResumen["costoenergia"] = this.state.listaDatosAnimacionRutaResumen["costoenergia"][this.indiceanimacion]
    //this.plantillaAResumen["eficienciapromedio"] = this.state.listaDatosAnimacionRutaResumen["eficienciapromedio"][this.indiceanimacion]
    this.plantillaAResumen["numerobusesosccritico"] = this.state.listaDatosAnimacionRutaResumen["numerobusesosccritico"][this.indiceanimacion]
    //}
    // Incrementar el contador
    this.indiceanimacion++;

    this.animacionid = requestAnimationFrame(() => this.animar(listamarker));
  }
  derecha() {
    //var marker =  this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
    //marker.popup.remove();
    //if (this.indiceBusSelect < 0) {
    //  this.showResumen = false
    //}else{
    //  var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
    //  markerselected.togglePopup()
    //}
    console.log("===",this.state.listaMarkerAnimacionRuta.length, this.indiceBusSelect)
    if (this.indiceBusSelect >= -1 && this.indiceBusSelect <= this.state.listaMarkerAnimacionRuta.length-2) {
      var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect].getElement()
      markerselected.style.backgroundImage = 'url(../../../assets/busiconNormal.png)';
      markerselected.style.className = 'busanimado';
      markerselected.style.width = '18px';
      markerselected.style.height = '23.5px';
      markerselected.style.paddingBottom = '0px';
      markerselected.style.backgroundRepeat = 'no-repeat';
      this.indiceBusSelect = this.indiceBusSelect + 1

      if (this.indiceBusSelect <= this.state.listaMarkerAnimacionRuta.length) {              
      var markernew = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect].getElement()
      markernew.style.className = 'busanimado2';
      markernew.style.width = '60px';
      markernew.style.height = '60px';
      markernew.style.paddingBottom = '55px';
      markernew.style.backgroundRepeat = 'no-repeat';
      markernew.style.backgroundImage = 'url(../../../assets/gpsbusnew.png)';
      console.log(this.indiceBusSelect)
      }

    }
  }
  izquierda() {
    //var marker =  this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
    //marker.popup.remove();
    //if (this.indiceBusSelect < 0) {
    //  this.showResumen = true
    //}else{
    //  var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
    //    markerselected.togglePopup()
    //}
    console.log(this.indiceBusSelect)
    if (this.indiceBusSelect > -1) {
      var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect].getElement()
      markerselected.style.backgroundImage = 'url(../../../assets/busiconNormal.png)';
      markerselected.style.className = 'busanimado';
      markerselected.style.width = '18px';
      markerselected.style.height = '23.5px';
      markerselected.style.paddingBottom = '0px';
      markerselected.style.backgroundRepeat = 'no-repeat';
      if(this.indiceBusSelect!=0){
      this.indiceBusSelect = this.indiceBusSelect - 1
      }
      if (this.indiceBusSelect != -1) {
        var markernew = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect].getElement()
        markernew.style.className = 'busanimado2';
        markernew.style.width = '60px';
        markernew.style.height = '60px';
        markernew.style.paddingBottom = '55px';
        markernew.style.backgroundRepeat = 'no-repeat';
        markernew.style.backgroundImage = 'url(../../../assets/gpsbusnew.png)';
        console.log(this.indiceBusSelect)
      }
      //if (this.indiceBusSelect != -1) {
      //  console.log(this.indiceBusSelect)
      //  var markerselected = this.state.listaMarkerAnimacionRuta[this.indiceBusSelect];
      //  markerselected.togglePopup()
      //}
    }
  }
  iniciarAnimacion() {
    var plantillaDAR = {}
    var listadistanciarecorrida = []
    var dataprincipal = this.state.listaDatosAnimacionRuta
    var boolInicioCarga = Array(50).fill(false);

    var datosgenerales = {
      'coorestacion': [dataprincipal["latitud"][0][0], dataprincipal["longitud"][0][0]],
      'eficienciabus': 1.0570884470111688,
      'tamaniobateriabus': 350,
      'socanterior': 100.00,
      'regeneracioninterno': 0
    }
    this.getHoraInicio(dataprincipal)
    console.log("hora de inicio lista ", this.state.listaHoraInicioAnimacion)
    console.log(dataprincipal)
    this.state.intervaloanimacioncoord = setInterval(() => {
      if (dataprincipal["longitud"][0].length - 1 == this.indiceanimacioncoord) {
        console.log("TERMINO ANIMACION")
        clearInterval(this.state.intervaloanimacioncoord)
      }
      for (let h = 0; h < dataprincipal["busesid"].length; h++) {
        console.log("animando")
        const busmarker = this.state.listaMarkerAnimacionRuta[h];
        busmarker.setLngLat([dataprincipal["longitud"][h][this.indiceanimacioncoord], dataprincipal["latitud"][h][this.indiceanimacioncoord]])
      }
      this.indiceanimacioncoord = this.indiceanimacioncoord + 1
      console.log(this.indiceanimacioncoord)
    }, 150)
    this.state.intervaloanimacion = setInterval(() => {
      //if (dataprincipal["longitud"][0].length - 1 == this.indiceanimacion) {
      //  clearInterval(this.state.intervaloanimacion)
      //}
      // Get hora inicio Carga
      //this.state.listaHoraInicioCargaAnimacion
      for (let h = 0; h < dataprincipal["busesid"].length; h++) {
        var datos = dataprincipal["busesid"][h]
        if (this.indiceanimacioncoord < dataprincipal["timeday"][h].length) {
          if (dataprincipal["latitud"][h][this.indiceanimacioncoord] != null) {
            // Get hora inicio Carga
            //this.state.listaHoraInicioCargaAnimacion
            if (dataprincipal["costo"][h][this.indiceanimacion] > 0 && boolInicioCarga[h] == false) {
              this.state.listaHoraInicioCargaAnimacion[h] = dataprincipal["timeday"][h][this.indiceanimacion].substr(11, 5)
              boolInicioCarga[h] = true
            }
            if (dataprincipal["costo"][h][this.indiceanimacion] <= 0 && boolInicioCarga[h] == true) {
              this.state.listaHoraFinalCargaAnimacion[h] = dataprincipal["timeday"][h][this.indiceanimacion].substr(11, 5)
              boolInicioCarga[h] = false
            }

            // actualización de datos - 15 datos
            // Bus ID
            plantillaDAR["busesid"] = dataprincipal["busesid"][h]
            // Estado de Bus - Funcion check Velocidad y Carindicea
            var estado
            if (dataprincipal["costo"][h][this.indiceanimacion] > 0 && dataprincipal["velocidad"][h][this.indiceanimacion] < 0) {
              //standby
              estado = "Stand By"
            } else {
              if (dataprincipal["costo"][h][this.indiceanimacion] > 0) {
                //Cargando
                estado = this.dataTAR.estadocarga[this.state.indiceTranslate]
              } else {
                //transito
                estado = this.dataTAR.estadotransit[this.state.indiceTranslate]
              }
            }
            plantillaDAR["estado"] = estado
            // Hora Inicio
            plantillaDAR["horainicio"] = this.state.listaHoraInicioAnimacion[h].substr(this.state.listaHoraInicioAnimacion[h].length - 8, 5)
            // Tiempo Transcurrido
            var horainiciovalue = this.diffTiempoTranscurrido(this.state.listaHoraInicioAnimacion[h], dataprincipal["timeday"][h][this.indiceanimacion])
            plantillaDAR["tiempotranscurrido"] = horainiciovalue[0] == '-' ? '--:--' : horainiciovalue
            // Distancia 
            plantillaDAR["distancia"] = dataprincipal["ditanciakm"][h][this.indiceanimacion]
            // Distancia recorrida
            plantillaDAR["distanciarecorrida"] = this.DistanciaCritica(dataprincipal["latitud"][h][this.indiceanimacion], dataprincipal["longitud"][h][this.indiceanimacion], datosgenerales["coorestacion"][0], datosgenerales["coorestacion"][1])
            // Autonomia
            plantillaDAR["autonomia"] = dataprincipal["soc"][h][this.indiceanimacion] / datosgenerales["eficienciabus"]
            // Energia Disponible
            plantillaDAR["energiadisponible"] = (dataprincipal["soc"][h][this.indiceanimacion] / 100) * datosgenerales["tamaniobateriabus"]
            this.colored = this.colorBateriaestado(plantillaDAR["energiadisponible"], datosgenerales["tamaniobateriabus"])
            // Energia Remanente
            plantillaDAR["energiaremanente"] = (this.state.currentBusDistance - plantillaDAR["distanciarecorrida"]) * datosgenerales["eficienciabus"] //falta restar por la distancia total
            this.colorer = this.colorEnergiaRemanente(plantillaDAR["energiaremanente"], plantillaDAR["energiadisponible"])
            //SoC %
            plantillaDAR["soc"] = dataprincipal["soc"][h][this.indiceanimacion]
            this.colored = this.colorBateriaestado(plantillaDAR["soc"], 100)
            // Regeneración de Energia bus Interno
            plantillaDAR["regeneracioninterno"] = this.RegeneracionEnergia(dataprincipal["soc"][h][this.indiceanimacion], datosgenerales["socanterior"], datosgenerales["tamaniobateriabus"], datosgenerales["regeneracioninterno"], dataprincipal["velocidad"][h][this.indiceanimacion])


            // EVENTO DE CARGA
            // Costo
            plantillaDAR["costo"] = dataprincipal["costo"][h][this.indiceanimacion]
            // Sitio de Carga        
            plantillaDAR["sitiocarga"] = dataprincipal["nombreestacion"][h][0]
            // Conector
            plantillaDAR["conector"] = dataprincipal["nombreconector"][h][0]
            // Equipo
            // Energia Recargada
            plantillaDAR["energiarecargada"] = dataprincipal["recargaenergia"][h][this.indiceanimacion]
            // Perdidia
            plantillaDAR["perdida"] = dataprincipal["recargaenergia"][h][this.indiceanimacion] * (0.95)

            this.state.listaDatosTabla[h] = plantillaDAR
            plantillaDAR = {}
            // Actualizar posicionamiento de los buses.
            //const busmarker = this.state.listaMarkerAnimacionRuta[h];
            //busmarker.setLngLat([dataprincipal["longitud"][h][this.indiceanimacion], dataprincipal["latitud"][h][this.indiceanimacion]])
            //for (let b = 0; b < this.state.listaMarkerAnimacionRuta.length; b++) {
            //  const busmarker = this.state.listaMarkerAnimacionRuta[b];
            //  busmarker.setLngLat([dataprincipal["longitud"][h][indice], dataprincipal["latitud"][h][indice]])
            //}
          } else {
            console.log("se detecto un nulo", this.indiceanimacion)
          }

        } else {
          clearInterval(this.state.intervaloanimacion)
          console.log("TERMINO ANIMACION")
        }
      }
      console.log(this.indiceanimacion)
      this.indiceanimacion = this.indiceanimacion + 1

    }, 150)

  }
  abrirmarker(i) {
    this.indiceseleccionado = i
    var markerselected = this.state.listaMarkerAnimacionRuta[i]
    markerselected.togglePopup()
    this.mapser.map.flyTo({
      center: [markerselected._lngLat.lng, markerselected._lngLat.lat],
      zoom: 18,
      speed: 0.7,
      pitch: 40,
    });

  }
  colorEnergiaRemanente(energiare, energiaactual) {
    if (energiare > energiaactual) {
      return '#F9431D'
    }
    if (energiare < energiaactual - 100) {
      return '#03FC1C'
    } else {
      return '#FFEC00'
    }
  }
  colorBateriaestado(energia, total) {
    var orignal = energia
    //50
    var ciencuenta = (total * 50) / 100
    //30
    var treinta = (total * 30) / 100
    //20
    var veinte = (total * 20) / 100
    //menor
    if (energia > ciencuenta) {
      return '#03FC1C'
    } else {
      if (energia <= ciencuenta && energia > treinta) {
        return '#FFEC00'
      } else {
        if (energia <= treinta && energia > veinte) {
          return '#FAA219'
        } else {
          return '#F9431D'
        }
      }
    }
  }
  getHoraInicio(data) {
    console.log("data hora inicio",data)
    for (let i = 0; i < data["busesid"].length; i++) {
      console.log(i)
      var element = data["busesid"][i];
      loopinterno: for (let j = 0; j < data["velocidad"][i].length; j++) {
        var velocidad = data["velocidad"][i][j];
        //console.log(velocidad)
        if (velocidad >= 0) {
        
          console.log(i,velocidad)
          this.state.listaHoraInicioAnimacion[i] = data["timeday"][i][j]
          break loopinterno
        }
      }
    }
    console.log(this.state.listaHoraInicioAnimacion)
  }
  diffTiempoTranscurrido(fechaInicio: string, fechaFin: string) {
    const inicio: Date = new Date(fechaInicio);
    const fin: Date = new Date(fechaFin);

    const tiempoTranscurridoMs: number = fin.getTime() - inicio.getTime();
    const tiempoTranscurridoMin: number = Math.floor(tiempoTranscurridoMs / 60000); // Convertir a minutos
    const horas: number = Math.floor(tiempoTranscurridoMin / 60);
    const minutos: number = tiempoTranscurridoMin % 60;

    const tiempoTranscurrido: string = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

    return tiempoTranscurrido;
  }
  DistanciaCritica(lat, lon, lat_est, lon_est) {

    var Point1 = [lat, lon]
    var Point2 = [lat_est, lon_est]


    var d = this.Distance(Point1, Point2)

    var DistanciaCritica = d
    return DistanciaCritica
  }

  Distance(point1: number[], point2: number[]): number {
    const earthRadius: number = 6371;

    const lat1: number = point1[0] * (Math.PI / 180);
    const lat2: number = point2[0] * (Math.PI / 180);
    const lon1: number = point1[1] * (Math.PI / 180);
    const lon2: number = point2[1] * (Math.PI / 180);

    const deltaLat: number = lat2 - lat1;
    const deltaLon: number = lon2 - lon1;

    // Haversine formula
    const a: number = Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d: number = earthRadius * c; // linear distance to each point

    return d;
  }

  RegeneracionEnergia(SoC, SoC_anterior, Battery_Size, RegeneracionEnergia_anterior, Velocidad) {
    if ((SoC / 100) > (SoC_anterior / 100) && Velocidad > 0) {
      var Regen = ((SoC / 100) - (SoC_anterior / 100)) * Battery_Size
    } else {
      var Regen = 0
    }
    var RegeneracionEnergia = Regen + RegeneracionEnergia_anterior
    return RegeneracionEnergia
  }

  hideshow() {
    this.hide = !this.hide
  }
  async playanimacion() {
    this.indiceboton = 3
    if (this.state.listaMarkerAnimacionRuta.length<=0) {
      this.mapamarker = this.mapser.map.addSource('markers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [] // Array vacío para almacenar los marcadores
        }
      });
      var dataanimacionruta = this.state.listaDatosAnimacionRuta
      await this.dibujarBuses(dataanimacionruta["busesid"], dataanimacionruta["latitud"], dataanimacionruta["longitud"])
      this.getHoraInicio(dataanimacionruta)      
      this.mapser.map.flyTo({
        center: [this.datosgeneralesAnimacion.coorestacion[1], this.datosgeneralesAnimacion.coorestacion[0]],
        zoom: 12,
        speed: 0.3,
        pitch: 40,
      });
    }
    this.iniciarAnimacionSegundo()
  }
  pauseanimacion(){
    this.indiceboton = 4
    console.log("contados buses",this.contadorBuses)
    cancelAnimationFrame(this.animacionid);
    if(this.contadorBuses!=0){
      this.contadorBuses=this.contadorBuses-1
    }

  }
  resetanimacion() {
    //clearInterval(this.state.intervaloanimacion)
    //clearInterval(this.state.intervaloanimacioncoord)
    //this.iniciarAnimacion()
    this.indiceboton = 1
    if (this.terminoAnimacion == true) {
      this.indiceanimacion = 0
      this.contadorBuses = 1
      this.iniciarAnimacionSegundo()
      this.datosgeneralesAnimacion = {
        'coorestacion': [],
        'eficienciabus': 1.0570884470111688,
        'tamaniobateriabus': 350,
        'socanterior': 100.00,
        'regeneracioninterno': 0
      }
    } else {
      this.contadorBuses = 1
      this.indiceanimacion = 0
      this.indiceanimacioncoord = 0
    }
  }
  stopanimacion() {
    clearInterval(this.state.intervaloanimacion)
    clearInterval(this.state.intervaloanimacioncoord)
  }
  closeanimacion() {
    this.indiceboton = 2
    this.terminoAnimacion = true
    
    cancelAnimationFrame(this.animacionid);
    this.state.listaMarkerAnimacionRuta.forEach(element => {
      element.remove()
    });
    this.state.listaMarkerAnimacionRuta = []
    this.mapamarker.removeSource('markers')
    this.state.listaHoraInicioAnimacion =[]
    this.contadorBuses = 1
    this.indiceanimacion = 0
    
  }

}

