import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-charger-names',
  templateUrl: './charger-names.component.html',
  styleUrls: ['./charger-names.component.scss']
})
export class ChargerNamesComponent implements OnInit {
  loschargernames: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {

   /*  this.state.allLocations.forEach((c: any) => {
      if (this.state.currentCollection === c.colName) { */
        this.loschargernames = [...this.state.currentChargersNames];
   /*    }
    }); */
    // console.log('this.loschargernames: ', this.loschargernames);
  }


}
