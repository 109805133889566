<div class="paradiv" >
  <span  style="color: white; height: 50px; justify-content: center;
  align-items: center; text-align: center; vertical-align: middle; ">Pérdidas</span>
  <!-- <mat-icon style="align-self: center;"> directions_bus </mat-icon> -->
  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let item of items; index as i"  style="padding:0px">
    <!-- <mat-grid-tile>
      <mat-icon style="align-self: center;"> directions_bus </mat-icon>
    </mat-grid-tile> -->
    <mat-grid-tile style="color: white; background-color: rgba(255,255,255,0.0);  border-top: 1px solid white; border-bottom: 1px solid white; border-radius: 0px; ">
      {{state.lostEnergy[item.id]}}
    </mat-grid-tile>
  </mat-grid-list>
</div>
