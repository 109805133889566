
<div class="panelAnimacion" *ngIf="showResumen">
    <div class="cardBusesInfo3">      
        <i class="spn-close" (click)="closeanimacion()" matTooltip={{this.dataTAR.btnclose[this.state.indiceTranslate]}} [ngClass]="this.indiceboton===2? 'spn-close1':'spn-close'"
            matTooltipClass="custom-tooltip"></i>
        <i class="spn-play" (click)="playanimacion()" matTooltip={{this.dataTAR.btnstart[this.state.indiceTranslate]}} [ngClass]="this.indiceboton===3? 'spn-play1':'spn-play'"
            matTooltipClass="custom-tooltip"></i>
        <i class="spn-pause" (click)="pauseanimacion()" matTooltip={{this.dataTAR.btnstop[this.state.indiceTranslate]}} [ngClass]="this.indiceboton===4? 'spn-pause1':'spn-pause'"
            matTooltipClass="custom-tooltip"></i>
    </div>
    <div class="cardBusesInfo2">
        <div class="cardContenido">
            <div class="infoHomeContenido">
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRbusser[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["numerobusesservicio"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRbuscharg[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["numerobusescargando"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRbusstand[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["numerobusesstandby"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRdistanace[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["kilometrosrecorridos"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRchargersfree[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["cargadoresdisponibles"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRchargersfull[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["cargadoresocupados"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRenergycs[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["energiaconsumidaservicio"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRenergycr[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["energiaconsumidarecargas"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRenergyc[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["costoenergia"]}}</div></div>
                <div class="ordenDatosTabla"><div> {{this.dataTL.TRbussoc[this.state.indiceTranslate]}}</div><div>{{this.plantillaAResumen["numerobusesosccritico"]}}</div></div>
            </div>
        </div>
    </div>
    <div class="cardBusesInfo">
        <div class="cardContenido">
            <button class="arrow-left" (click)="izquierda()"><span>&#9664;</span></button>
            <button class="arrow-right" (click)="derecha()"><span>&#9654;</span></button>
            <div class="infoContenido">
                <div *ngFor="let item of this.state.listaDatosTabla; index as i;trackBy: trackByFn">
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THid[this.state.indiceTranslate]}}</div><div>{{this.state.listaDatosTabla[i]["busesid"]}}</div></div>
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THstate[this.state.indiceTranslate]}}</div><div>{{this.state.listaDatosTabla[i]["estado"]}}</div></div>
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THhorainit[this.state.indiceTranslate]}}</div><div> {{this.state.listaDatosTabla[i]["horainicio"]}}</div></div>                    
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THdistance[this.state.indiceTranslate]}}</div><div>{{this.state.listaDatosTabla[i]["distancia"].toFixed(1)}}</div></div>
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THsitiocarga[this.state.indiceTranslate]}}</div><div> {{this.state.listaDatosTabla[i]["distanciarecorrida"].toFixed(1)}}</div></div>
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THautonomia[this.state.indiceTranslate]}}</div><div>{{this.state.listaDatosTabla[i]["autonomia"].toFixed(1)}}</div></div>
                    <div style="padding: 8px;" class="ordenDatosTabla"><div>{{this.dataTL.THregeneracion[this.state.indiceTranslate]}}</div><div>{{this.state.listaDatosTabla[i]["regeneracioninterno"].toFixed(1)}}</div></div>
                    <div style="padding: 8px;" class="circulargaugeformat">
                        <div>{{this.dataTL.THsoc[this.state.indiceTranslate]}}</div>
                        <ejs-circulargauge class="hijoscuadro" width="75" height="55" background="rgba(255,255,255,0.0)"
                            style=" color: #000096;  border-radius: 0px; padding: 0px; margin: 0px;" [margin]='margin'>
                            <e-axes>
                                <e-axis [majorTicks]="majorTicks" [minorTicks]="minorTicks" [labelStyle]="labelStyle"
                                    [lineStyle]="this.contornoCG" maximum=100>
                                    <e-annotations>
                                        <e-annotation zIndex='1'
                                            content='<div id="contento" style="color:#000096;font-size:15px;font-family:Segoe UI;font-weight:semibold;">{{this.state.listaDatosTabla[i]["soc"].toFixed(0)}}</div>'
                                            angle=0 radius='0%'>
                                        </e-annotation>
                                    </e-annotations>
                                    <e-ranges>
                                        <e-range start=0 [end]="this.state.listaDatosTabla[i]['soc']" startWidth=7
                                            endWidth=7></e-range>
                                    </e-ranges>
                                    <e-pointers>
                                    </e-pointers>
                                </e-axis>
                            </e-axes>
                        </ejs-circulargauge>
                    </div>
                    <div style="padding: 8px;" class="circulargaugeformat">
                        <div>{{this.dataTL.THenergiadip[this.state.indiceTranslate]}}</div>
                    <ejs-circulargauge class="hijoscuadro" width="75" height="55" background="rgba(255,255,255,0.0)"
                            style=" color: #000096;  border-radius: 0px; padding: 0px; margin: 0px;" [margin]='margin'>
                            <e-axes>
                                <e-axis [majorTicks]="majorTicks" [minorTicks]="minorTicks" [labelStyle]="labelStyle"
                                    [lineStyle]="this.contornoCG" maximum=350>
                                    <e-annotations>
                                        <e-annotation zIndex='1'
                                            content='<div id="contento" style="color:#000096;font-size:15px;font-family:Segoe UI;font-weight:semibold;">{{this.state.listaDatosTabla[i]["energiadisponible"].toFixed(0)}}</div>'
                                            angle=0 radius='0%'>
                                        </e-annotation>
                                    </e-annotations>
                                    <e-ranges>
                                        <e-range start=0 [end]="this.state.listaDatosTabla[i]['energiadisponible']"
                                            startWidth=7 endWidth=7 color={{this.colored}}></e-range>
                                    </e-ranges>
                                    <e-pointers>
                                    </e-pointers>
                                </e-axis>
                            </e-axes>
                        </ejs-circulargauge>
                    </div>
                    <div style="padding: 8px;" class="circulargaugeformat">
                        <div>{{this.dataTL.THsitiocargaenergia[this.state.indiceTranslate]}}</div>
                        <ejs-circulargauge class="hijoscuadro" width="75" height="55" background="rgba(255,255,255,0.0)"
                            style=" color: #000096;  border-radius: 0px; padding: 0px; margin: 0px;" [margin]='margin'>
                            <e-axes>
                                <e-axis [majorTicks]="majorTicks" [minorTicks]="minorTicks" [labelStyle]="labelStyle"
                                    [lineStyle]="this.contornoCG" maximum=350>
                                    <e-annotations>
                                        <e-annotation zIndex='1'
                                            [ngClass]="{'tddefault':this.indiceseleccionado!==i,'tdselected':this.indiceseleccionado===i}"
                                            content='<div id="contento" style="color:#000096;font-size:15px;font-family:Segoe UI;font-weight:semibold;">{{this.state.listaDatosTabla[i]["energiaremanente"].toFixed(0)}}</div>'
                                            angle=0 radius='0%'>
                                        </e-annotation>
                                    </e-annotations>
                                    <e-ranges>
                                        <e-range start=0 [end]="this.state.listaDatosTabla[i]['energiaremanente']"
                                            startWidth=7 endWidth=7 color={{this.colorer}}></e-range>
                                    </e-ranges>
                                    <e-pointers>
                                    </e-pointers>
                                </e-axis>
                            </e-axes>
                        </ejs-circulargauge>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
