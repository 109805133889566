export const environment = {
  production: false,
  urlAddress: 'http://localhost:5000',
  mapbox: {
    //  accessToken: 'pk.eyJ1IjoiYW5mZWxiYXIiLCJhIjoiY2tpZzdsY3k3MDRldTJybHB1YWN6OWw2MyJ9.358KHqDlw_Z569s4Aq2_vw',
    accessToken: 'pk.eyJ1IjoiZ2NvbGwyMDA2IiwiYSI6ImNqbW13NTdmMTBuaWIzcHBrcTk0cTBxaWUifQ.yHjs58VBEq_YK8qc6bgz_Q'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
