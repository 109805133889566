import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-punto-carga',
  templateUrl: './punto-carga.component.html',
  styleUrls: ['./punto-carga.component.scss']
})
export class PuntoCargaComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
  }

}
