import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth-service.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  users: any = [];

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.iniciar();
  }

  async iniciar(): Promise<void> {
    try {
      const response = await this.auth.getUserList().toPromise();

      /* console.log('response received');
      console.log(response); */
      this.users = response;
    } catch (error) {
      console.log('No se pudo obtener lista de usuarios: ', error);
    }
  }

  deleteUser(userId: number): void {
    try {
      const res = this.auth.deleteUser(userId).toPromise();
      // console.log('Usuario eliminado: ', res);
      this.users = this.users.filter((value: any, index: number, arr: any) => {
        return value.id !== userId;
      });
    } catch (error) {
      console.log('No se pudo eliminar usuario: ', error);
    }
  }
}
