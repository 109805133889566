<div class="paradiv">
  <span style="color: white;  height: 50px; text-align: center; vertical-align: middle;">Fin carga</span>
  <!-- <mat-icon style="align-self: center;"> directions_bus </mat-icon> -->
  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let bus of state.busNames; index as i" style="padding:0px">
    <mat-grid-tile
      style="color: white; background-color: rgba(255,255,255,0.0);  border-radius: 0px; border-bottom: 1px solid white; border-top: 1px solid white;">
      {{this.state.finDeCarga[state.currentCollection + bus]}}
    </mat-grid-tile>
  </mat-grid-list>
</div>
