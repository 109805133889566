<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.cargaCompleta==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.cargaCompleta==true" style="width:100%;height:100%; ">
                        <div class="control-section">
                            <ejs-grid #grid [dataSource]='dataopt' allowSorting="true" [allowGrouping]="true"
                                [groupSettings]="groupOptions" (dataBound)='dataBound($event)' (created)='created()'
                                  height="100%">
                                <e-columns>
                                    <e-column field='arquitecturacelda' [headerText]=this.transalteopt.dataopt.arquitecturacelda[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>                 
                                    <e-column field='numceldas' [headerText]=this.transalteopt.dataopt.numceldas[this.state.indiceTranslate] width='125' textAlign="Center"></e-column>
                                    <e-column field='pesobateria' [headerText]=this.transalteopt.dataopt.pesobateria[this.state.indiceTranslate] width='125' textAlign="Center"></e-column>
                                    <e-column field='tamaniobateria' [headerText]=this.transalteopt.dataopt.tamaniobateria[this.state.indiceTranslate] width='125' textAlign="Center"></e-column>
                                    <e-column field='anios' [headerText]=this.transalteopt.dataopt.anios[this.state.indiceTranslate] width='125' textAlign="Center"></e-column>
                                    <e-column field='ciclos' [headerText]=this.transalteopt.dataopt.ciclos[this.state.indiceTranslate] width='125' textAlign="Center"></e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.cargaCompleta==true" style="width:100%;height:100%; ">
                        <div class="control-section">
                        <ejs-grid #grid2 [dataSource]='dataopt' allowSorting="true" [allowGrouping]="true"
                        [groupSettings]="groupOptions2" (dataBound)='dataBound2($event)' 
                          height="100%">
                            <e-columns>
                                <e-column field='arquitecturacelda' [headerText]=this.transalteopt.dataopt.arquitecturacelda[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='numeromodulos' [headerText]=this.transalteopt.dataopt.numeromodulos[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='proveedorpack' [headerText]=this.transalteopt.dataopt.proveedorpack[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='referenciapack' [headerText]=this.transalteopt.dataopt.referenciapack[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='arquitecturamodulos' [headerText]=this.transalteopt.dataopt.arquitecturamodel[this.state.indiceTranslate] width='220' textAlign="Center"></e-column>
                                <e-column field='energiapack' [headerText]=this.transalteopt.dataopt.energiapack[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='pesopack' [headerText]=this.transalteopt.dataopt.pesopack[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                                <e-column field='dimensionespack' [headerText]=this.transalteopt.dataopt.dimensionespack[this.state.indiceTranslate] width='225' textAlign="Center"></e-column>
                                <e-column field='energiatotal' [headerText]=this.transalteopt.dataopt.energiatotal[this.state.indiceTranslate] width='175' textAlign="Center"></e-column>
                                <e-column field='pesototal' [headerText]=this.transalteopt.dataopt.pesototal[this.state.indiceTranslate] width='175' textAlign="Center"></e-column>
                                <e-column field='volumentotal' [headerText]=this.transalteopt.dataopt.volumentotal[this.state.indiceTranslate] width='200' textAlign="Center"></e-column>
                            </e-columns>
                        </ejs-grid>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                    flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            Gráficos</h2>
                        <ejs-radiobutton label={{this.transalteopt.rdbTBO[this.state.indiceTranslate]}} name="Graficos" style="margin-bottom: 10px;"
                            checked="true" cssClass="e-info" (click)="irTablaOpt()"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.transalteopt.rdbTMC[this.state.indiceTranslate]}} name="Graficos" style="margin-bottom: 10px;"
                            cssClass="e-info" (click)="irTablaModCom()"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>