import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ChartComponent,
  IMouseEventArgs,
  IPointEventArgs,
} from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-velocity-profile',
  templateUrl: './velocity-profile.component.html',
  styleUrls: ['./velocity-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VelocityProfileComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  multi: any = [];
  initMulti: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  icono = true;
  previousTarget = null;

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Tiempo (minutos)';
  yAxisLabel = 'Velocidad (km/h)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  colorScheme = {
    domain: ['#F5821E', '#88898C', '#000096'],
  };
  public animation: object = { enable: false };
  ancho = '';
  alto = '';

  // public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  // public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chartvel1')
  public chartvel1!: ChartComponent;
  @ViewChild('chartvel2')
  public chartvel2!: ChartComponent;

  @Input() grande: boolean;

  subscription0: Subscription | undefined;
  subscription1: Subscription | undefined;
  subscription2: Subscription | undefined;
  subscription3: Subscription | undefined;
  subscription4: Subscription | undefined;
  subscription5: Subscription | undefined;
  subscription6: Subscription | undefined;

  visible: any = [];

  public primaryXAxis: Object = {
    minimum: 100,
    maximum: 220,
    majorGridLines: { width: 0 },
    edgeLabelPlacement: 'Shift',
    title: 'Height in Inches'
  };
  public primaryYAxis: Object = {
    majorTickLines: {
      width: 0
    },
    lineStyle: {
      width: 0
    },
    minimum: 0,
    maximum: 100,
    interval: 10,
    title: 'Weight in Pounds',
    rangePadding: 'None'
  };
  public marker1: Object = {
    visible: false,
    width: 28,
    height: 20,
    shape: 'Rectangle',
    dataLabel: { visible: true, position: 'Inner' }
  };

  constructor(
    private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + '';
    // this.state.currentChart = 4;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  async ngOnInit(): Promise<void> {
    // this.state.currentChart = 4;
    this.subscribeConfiguration();
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.state.axis4[0] = {
      // interval: 10,
      title: 'Paradas',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: '{value}',
      minimum: 0,
      rangePadding: 'Auto',
    };
    this.state.axis4[1] = {
      labelFormat: '{value}',
      title: 'Velocidad (km/h)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      //maximum:100,
      //maximum: this.state.maxVelocity,
      /* interval: elvalorin, */
    };

    this.state.axis4[2] = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      // interval: 10,
      title: 'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      rangePadding: 'Auto',
      labelFormat: 'HH:mm'
    };



    this.tooltip = { enable: true };
    const arre = this.state.THEDATA[3];
    this.visible = new Array(arre.length).fill(false);
    this.visible[0]=true;
    /*  if (this.dataSer.completeDay[0] === undefined || this.dataSer.completeDay[0].maxconsumcycle === undefined) {
       // console.log('visible la cero');
       this.visible[0] = true;
       this.state.chosenSeries = 0;
       // this.previousTarget = 0;
     }
     else {
       // console.log('visible la ', this.dataSer.completeDay[0].maxconsumcycle - 1);
       this.visible[this.dataSer.completeDay[0].maxconsumcycle - 1] = true;
       this.state.chosenSeries = this.dataSer.completeDay[0].maxconsumcycle - 1;
       // this.previousTarget = this.dataSer.completeDay[0].maxconsumcycle - 1;
     } */
  }

  subscribeConfiguration(): void {
    // if this chart is not been visualized then do nothing
    if (this.state.currentChart !== 4) {
      return;
    }
    this.subscription0 = this.state.allSeriesUp.subscribe(
      async (value: any) => {
        // console.log('Showing all series');
        if (this.state.currentVelocity === 1) {
          for (let i = 0; i < this.chartvel1.series.length; i++) {
            this.chartvel1.series[i].visible = true;
          }
          for (let j = 0; j < this.visible.length; j++) {
            this.visible[j] = true;
          }
          this.changeDetector.detectChanges();
          this.chartvel1.refresh();
        } else {
          for (let i = 0; i < this.chartvel2.series.length; i++) {
            this.chartvel2.series[i].visible = true;
          }
          for (let j = 0; j < this.visible.length; j++) {
            this.visible[j] = true;
          }
          this.changeDetector.detectChanges();
          this.chartvel2.refresh();
        }
      }
    );
    this.subscription1 = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        this.visible = new Array(arre.length).fill(false);
        this.visible[this.dataSer.completeDay[0].maxconsumcycle - 1] = true;
        this.changeDetector.detectChanges();
        // console.log('Detectied changes in route');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
    /* this.subscription2 = this.state.currentTypeChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        // this.visible = new Array(arre.length).fill(false);
        // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
        // this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in type');
        this.chart.refresh();
      }
    ); */
    this.subscription3 = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        if (value === 'nav') {
          const arre = this.state.THEDATA[3];
          this.visible = new Array(arre.length).fill(false);
          this.visible[this.dataSer.completeDay[0].maxconsumcycle - 1] = true;
          this.state.chosenSeries = this.dataSer.completeDay[0].maxconsumcycle - 1;
        }

        // this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in Pax');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
    this.subscription4 = this.state.currentHVACChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        // this.visible = new Array(arre.length).fill(false);
        // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
        // this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in HVAC');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
    /* this.subscription5 = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        this.visible = new Array(arre.length).fill(false);
        // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
        this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in Battery');
        this.chart.refresh();
      }
    ); */
    this.subscription6 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        // const arre = this.state.THEDATA[3];

        //this.visible = new Array(arre.length).fill(false);
        // console.log('Previous target: ', this.previousTarget);
        // this.visible[this.previousTarget] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in BusSize');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
  }

  get laSubs0(): any {
    return this.subscription0;
  }

  get laSubs1(): any {
    return this.subscription1;
  }

  get laSubs2(): any {
    return this.subscription2;
  }
  get laSubs3(): any {
    return this.subscription3;
  }
  get laSubs4(): any {
    return this.subscription4;
  }
  get laSubs5(): any {
    return this.subscription5;
  }
  get laSubs6(): any {
    return this.subscription6;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    // console.log('unsuscribing to type');
    this.laSubs0.unsubscribe();
    this.laSubs1.unsubscribe();
    // this.laSubs2.unsubscribe();
    this.laSubs3.unsubscribe();
    this.laSubs4.unsubscribe();
    // this.laSubs5.unsubscribe();
    this.laSubs6.unsubscribe();
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 75) + '';
  }

  setOffRest(index: number) {
    if (this.state.currentVelocity === 1) {
      this.chartvel1.series[index].visible = true;
      for (let i = 0; i < this.chartvel1.series.length; i++) {
        if (i !== index) {
          this.chartvel1.series[i].visible = false;
        }
      }
    } else {
      this.chartvel2.series[index].visible = true;
      for (let i = 0; i < this.chartvel2.series.length; i++) {
        if (i !== index) {
          this.chartvel2.series[i].visible = false;
        }
      }
    }

  }

  activateAll() {
    if (this.state.currentVelocity === 1) {
      for (let i = 0; i < this.chartvel1.series.length; i++) {
        // this.visible[i] = true;
        this.chartvel1.series[i].visible = true;
      }
    } else {
      for (let i = 0; i < this.chartvel2.series.length; i++) {
        // this.visible[i] = true;
        this.chartvel2.series[i].visible = true;
      }
    }
  }

  /*   updateData() {
      console.log('refreshing velocity');

    } */

  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      if (this.state.currentVelocity === 1) {
        for (var i = 0; i < this.chartvel1.series.length; i++) {
          this.chartvel1.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = false;
          } else {
            this.visible[i] = true;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel1.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel1.series.length; j++) {
            this.chartvel1.series[j].visible = true;
            this.visible[i] = true;
          }

          this.chartvel1.series[ids].visible = false;
          // this.visible[ids]= false;

          this.previousTarget = null;
          flag = true;
        }
      } else {
        for (var i = 0; i < this.chartvel2.series.length; i++) {
          this.chartvel2.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = false;
          } else {
            this.visible[i] = true;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel2.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel2.series.length; j++) {
            this.chartvel2.series[j].visible = true;
            this.visible[i] = true;
          }

          this.chartvel2.series[ids].visible = false;
          // this.visible[ids]= false;
          this.previousTarget = null;
          flag = true;
        }
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };

  public chartMouseClick2(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      if (this.state.currentVelocity === 1) {
        for (var i = 0; i < this.chartvel1.series.length; i++) {
          this.chartvel1.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = true;
          } else {
            this.visible[i] = false;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel1.series[i].visible = true;
          } */
          console.log(this.chartvel1.series[i].visible);
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel1.series.length; j++) {
            this.chartvel1.series[j].visible = true;
            this.visible[j] = true;
            console.log(this.chartvel1.series[j].visible);
          }

          this.chartvel1.series[ids].visible = false;
          // this.visible[ids]= false;

          this.previousTarget = null;
          flag = true;
          console.log(this.chartvel1.series[ids].visible);
        }

      } else {
        for (var i = 0; i < this.chartvel2.series.length; i++) {
          this.chartvel2.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = true;
          } else {
            this.visible[i] = false;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel2.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel2.series.length; j++) {
            this.chartvel2.series[j].visible = true;
            this.visible[j] = true;
          }

          this.chartvel2.series[ids].visible = false;
          // this.visible[ids]= false;
          this.previousTarget = null;
          flag = true;
        }
      }
      if (!flag)
        this.previousTarget = ids;

      console.log('visible ', this.visible);
    }
  };



  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 75) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  pointClick(args: IPointEventArgs): void {

    try {
      let latitudeone = this.dataSer.allCyclesInputs[this.dataSer.hours[args.seriesIndex]].latitude;
      let longitudeone = this.dataSer.allCyclesInputs[this.dataSer.hours[args.seriesIndex]].longitude;

      //const lontotal = longitudeone.concat(longitudetwo);
      //const lattotal = latitudeone.concat(latitudetwo);
      const lontotal = longitudeone;
      const lattotal = latitudeone;

      //const lalon = lontotal[paradasindexone[args.pointIndex]];
      //const lalat = lattotal[paradasindexone[args.pointIndex]];
      const lalon = lontotal[args.pointIndex];
      const lalat = lattotal[args.pointIndex];
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }

  pointClickByBusStop(args: IPointEventArgs) {
    let latitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].latitude;
    let longitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].longitude;
    let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].indexstops;
    //console.log('this.state.cyclesListOnlyCC[args.seriesIndex]: ',this.state.cyclesListOnlyCC[args.seriesIndex])
    //console.log('paradasindexone: ', paradasindexone);

    const lontotal = longitudeone;
    const lattotal = latitudeone;
    const lalon = lontotal[paradasindexone[args.pointIndex]];
    const lalat = lattotal[paradasindexone[args.pointIndex]];
    this.state.changePointIndex([lalon, lalat]);
  }

  print() {
    if (this.state.currentVelocity === 1) {
      this.chartvel1.print();
    } else {
      this.chartvel2.print();
    }
  }

}
