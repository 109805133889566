<div class="paradivdis">
  <span style="color: white;  height: 50px;">Distancia crítica</span>

  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let item of itoms; index as i" style="padding:0px">

    <mat-grid-tile
      style="color: white; background-color: rgba(255,255,255,0.0); border-bottom: 1px solid white; border-top: 1px solid white; border-radius: 0px;">
      <ejs-chart id="{{item.id}}-dismain" [primaryXAxis]="axisX" [primaryYAxis]="allAxis[item.id]" [palettes]='colorScheme'
        height='65' width='250' background="rgba(255,255,255,0.0)" [animations]="animations" #gaugedis>
        <e-series-collection>
          <e-series [dataSource]="this.state.distanceChartValue[item.id]" type="Bar" xName="hora" yName="type"
            pointColorMapping='color' [marker]='marker' [animation]="animation"></e-series>

        </e-series-collection>
      </ejs-chart>
    </mat-grid-tile>
  </mat-grid-list>

</div>
