<!-- <div
  class="app-splash-screen"
> -->
<!-- <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-loader"></div>
  </div> -->
<!-- </div> -->
<div class="barra">
    <!-- <div class="imagenContainer"> -->
    <img
      style="height: 90px; text-align: center; align-items: center; z-index: 99;"
      src="./../../../../assets/logios_blanco.png"
      alt="The Logios Logo"
    />
    <!-- </div> -->
  </div>
  <div class="demo_wrap">
    <img src="./../../../../assets/fondoBuses.jpg" />
    <div class="myBack"></div>
  </div>

  <div class="nueva">
    <!-- <mat-card> -->

    <!--<mat-card-content> -->
    <!-- <div class="card">  -->
    <form
      [formGroup]="form"

      style="
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: blue;
      "
    >
      <h2 class="cadena">RECUPERAR CONTRASEÑA</h2>

      <div *ngIf="!enviado">
        Se enviará un enlace de recuperación a la dirección de correo asociada.
      </div>
      <div *ngIf="enviado">
        Se envió un enlace de recuperación a su correo.
      </div>
      <div *ngIf="errores">
        Se envió un enlace de recuperación a su correo.
      </div>

      <mat-form-field
        class="full-width-input"
        style="
          font-family: 'Poppins' !important;
          margin-top: 20px;
          width: 250px;
          min-height: 50px;
          align-self: center;
        "
      >
        <input
          style="font-family: 'Poppins' !important; background-color: #D6D8E1;"
          matInput
          placeholder="Correo"
          formControlName="username"
          required
        />
        <span matPrefix><mat-icon> email</mat-icon></span>
        <mat-error>
          Ingrese un correo válido
        </mat-error>
      </mat-form-field>

    <br />
      <button (click)="onSubmit()" type="submit"
        style="
          font-family: 'Poppins' !important;
          border-radius: 25px;
          margin-top: 0px;
          margin-bottom: 20px;
        "
        mat-raised-button
      >
        Recuperar
      </button>
    </form>
  </div>

