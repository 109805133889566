import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';*/
import { IMouseEventArgs, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-regeneracion',
  templateUrl: './regeneracion.component.html',
  styleUrls: ['./regeneracion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegeneracionComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  multi: any = [];
  initMulti: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  // colors: ColorHelper | undefined;
  chartNames: any[] = [];
  icono = true;
  _grande = false;
  // public marker: any = {};

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = 'Years';
  roundDomains = true;
  autoScale = true;
  tooltipDisabled = false;
  mostrarAltitud = false;
  vacios = {
    nombre: 'Altitud'
  };

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  public previousTarget = null;

  colorScheme = {
    domain: [],
  };

  domain: any = [];

  public crosshair: Object = { enable: true, lineType: "Vertical" };
  public annotations: any = [
    {
      content:
        '<div id="chart_annotation" style="font-family:Segoe UI; font-size:13px; height:100%"></div>',
      x: "125%",
      y: "21%"
    }
    /* { content: '<div id="chart_annotation' + 10 +
        '" style="margin-left: -10px !important; margin-top: -10px !important;"><svg height="15" width="15"><circle cx="10" cy="10" r="5" style="fill:red " /></svg></div>',
      x: 20,
      y: 20,
      coordinateUnits: "Point"
    } */
  ];
  public animation: object = { enable: false };

  cadena = 'CONSUMO DE ENERGÍA';
  cadena2 = '';

  ancho = '';
  alto = '';

  public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;
  public legendSettings: Object;
  public majorGridLines: Object;
  public lineStyle: Object;

  public axis: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    title: 'Pendiente (###°)',
    name: 'yAxis',
    labelFormat: 'n1',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw',
    },
    labelStyle: {
      labelFormat: 'n1',
      size: '1.1vw',
      fontFamily: 'Poppins',
    }
  }];

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chart10')
  chart: ChartComponent;

  @Input() grande: boolean;

  subscription1: Subscription | undefined;
  subscription2: Subscription | undefined;
  subscription3: Subscription | undefined;
  subscription4: Subscription | undefined;
  subscription5: Subscription | undefined;
  subscription6: Subscription | undefined;
  subscriptions: Subscription[] = [];

  visible: any = [];
  visibleRegen: any = [];

  changeAnimation: Subscription | undefined;

  /*   @Input()
    set grande(g: boolean) {
      this._grande = g;
    }
    get grande(): boolean { return this._grande; } */

  constructor(
    private dataSer: DataService,
    public state: StateService,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    // this.state.currentChart = 10;

  }

  print(){
    this.chart.print();
  }

  ngOnInit(): void {
    // console.log('Init regeneracion');
    // this.state.currentChart = 10;
    this.subscribeConfiguration();
    this.marker = { visible: true };
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.legendSettings = { toggleVisibility: true };
    this.marker = { visible: true };
    this.tooltip = { enable: true, header: '', format: '<b>${point.x} : ${point.y}</b>' };
    const arre = this.state.THEDATA[0];
    this.visible = new Array(arre.length).fill(false);
    this.visibleRegen = new Array(arre.length).fill(false);
    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 0 };
    // this.subscribeToAnimation();
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    const cuantos = Math.floor(this.chart.series.length / 3);
    // ['#F5821E', '#88898C', '#000096']
    for (let i = 0; i < cuantos; i++) {
      this.domain.push('#F5821E');
    }
    for (let i = 0; i < cuantos; i++) {
      this.domain.push('#000096');
    }
    for (let i = 0; i < cuantos; i++) {
      this.domain.push('#88898C');
    }

    /* this.chart.series[this.dataSer.dailyStatistics.maxconsumCycle - 1].visible = true;
    this.chart.series[(this.dataSer.dailyStatistics.maxconsumCycle - 1) + cuantos].visible = true;
    this.chart.series[(this.dataSer.dailyStatistics.maxconsumCycle - 1) + (cuantos * 2)].visible = true;
    this.previousTarget = this.dataSer.dailyStatistics.maxconsumCycle - 1;
    this.state.chosenSeries = this.dataSer.dailyStatistics.maxconsumCycle - 1; */
    this.chart.series[this.dataSer.completeDay[0].maxconsumcycle - 1].visible = true;
    this.chart.series[(this.dataSer.completeDay[0].maxconsumcycle - 1) + cuantos].visible = true;
    this.chart.series[(this.dataSer.completeDay[0].maxconsumcycle - 1) + (cuantos * 2)].visible = true;
    this.previousTarget = this.dataSer.completeDay[0].maxconsumcycle - 1;
    this.state.chosenSeries = this.dataSer.completeDay[0].maxconsumcycle - 1;

  }

  /* subscribeToAnimation(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        // console.log('Updating chart');
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
  } */

  subscribeConfiguration(): void {
    // if this chart is not been visualized then do nothing
    if (this.state.currentChart !== 10) {
      return;
    }
    this.subscription1 = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        // this.visible[0] = this.visible[1] = this.visible[2] = true;

        this.changeDetector.detectChanges();
        // console.log('Detectied changes in route');
        this.chart.refresh();
      }
    );
    this.subscription2 = this.state.currentTypeChange.subscribe(
      async (value: any) => {

        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription3 = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        // const arre = this.state.THEDATA[0];
        const cuantos = Math.floor(this.chart.series.length / 3);
        if (!this.chart.series[this.state.chosenSeries].visible) {
          this.chart.series[this.state.chosenSeries].visible = true;
          this.chart.series[(this.state.chosenSeries) + cuantos].visible = true;
          this.chart.series[(this.state.chosenSeries) + (cuantos * 2)].visible = true;
        }
        // this.visible = new Array(arre.length).fill(false);
        this.domain = [];

        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#F5821E');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#000096');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#88898C');
        }

        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription4 = this.state.currentHVACChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in HVAC');
        this.chart.refresh();
      }
    );
    this.subscription5 = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in Battery');
        this.chart.refresh();
      }
    );
    this.subscription6 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        /* console.log('Buszie in regen');
        const cuantos = Math.floor(this.chart.series.length / 3);
        this.chart.series[this.state.chosenSeries].visible = true;
        this.chart.series[(this.state.chosenSeries) + cuantos].visible = true;
        this.chart.series[(this.state.chosenSeries) + (cuantos * 2)].visible = true;
        this.domain = [];

        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#F5821E');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#000096');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#88898C');
        } */
        this.changeDetector.detectChanges();

        this.chart.refresh();
      }
    );
  }

  get laSubs1(): any {
    return this.subscription1;
  }

  get laSubs2(): any {
    return this.subscription2;
  }
  get laSubs3(): any {
    return this.subscription3;
  }
  get laSubs4(): any {
    return this.subscription4;
  }
  get laSubs5(): any {
    return this.subscription5;
  }
  get laSubs6(): any {
    return this.subscription6;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    // console.log('unsuscribing to type');
    this.laSubs1.unsubscribe();
    this.laSubs2.unsubscribe();
    this.laSubs3.unsubscribe();
    this.laSubs4.unsubscribe();
    this.laSubs5.unsubscribe();
    this.laSubs6.unsubscribe();
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      for (var i = 0; i < this.chart.series.length; i++) {
        this.chart.series[i].visible = false;
      }
      const suma = parseInt(ids) + Math.floor(this.chart.series.length / 3);
      const suma2 = parseInt(ids) + (Math.floor(this.chart.series.length / 3) * 2);
      this.chart.series[suma].visible = true;
      this.chart.series[suma2].visible = true;
      // this.chart.series[ids].visible = true;

      let cuantosVisibles = 0;
      this.chart.series.forEach((current: any, index: number) => {
        // console.log('El valor es: ', current.visible, ' para index ', index);
        if (current.visible) {
          cuantosVisibles++;

        }
      });
      // console.log('Hay ', cuantosVisibles);
      if (!flag)
        this.previousTarget = ids;
      this.state.chosenSeries = parseInt(ids);;
    } /// */
    this.chart.refresh();


  }

  pointClick(args: IPointEventArgs): void {
    try {
      const lalon = this.state.chartMapLongitudeCycles[args.seriesIndex][args.pointIndex];
      const lalat = this.state.chartMapLatitudeCycles[args.seriesIndex][args.pointIndex];
      /* console.log(
        'lalon : ' +
        lalon +
        ' . ' +
        'lalat : ' +
        lalat
      ); */
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }

  /*  public trackItem(index: number, item: any): any {
    return item.trackId;
  } */
}
