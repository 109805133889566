import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from './custom-validators';
import { AuthService } from '../../../shared/auth/auth-service.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public dataTR = this.mystate.dictTranslate.Registro
  public idioma='ES'
  public form: FormGroup = this.fb.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    email2: new FormControl('', [Validators.email, Validators.required]),
    /* emailBackUp: new FormControl('', [Validators.email, Validators.required]),
    emailBackUp2: new FormControl('', [Validators.email, Validators.required]), */
    password1: new FormControl('', [Validators.required]),
    password2: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    contactname: new FormControl('', [Validators.required]),
    organization: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
  });
  public loginInvalid = false;
  formSubmitAttempt = false;
  returnUrl = '';
  registrado = false;
  elError = '';
  recuerde = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public mystate:StateService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.mystate.indiceTranslate==0) {
      this.idioma='Español'
    }else{
      this.idioma='English'
    }
    this.returnUrl = 'animation';
    this.form = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      email2: new FormControl('', [Validators.email, Validators.required]),
      /* emailBackUp: new FormControl('', [Validators.email, Validators.required]),
      emailBackUp2: new FormControl('', [
        Validators.email,
        Validators.required,
      ]), */
      password1: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // CustomValidators.patternValidator(/[@#$?\^%&!"/()=¡'*¿]/, { hasSpecialCharacters: true}),
        Validators.minLength(8),
      ]),
      password2: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // CustomValidators.patternValidator(/[@#$?\^%&!"/()=¡'*¿]/, { hasSpecialCharacters: true}),
        Validators.minLength(8),
      ]),
      contactname: new FormControl('', [Validators.required]),
      organization: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
    /*  this.form = this.fb.group({
      username: ['', Validators.email],
      password1: ['', Validators.required],
      password2: ['', Validators.required]
    }); */

    /* const ctrl = new FormControl('some value');
    console.log(ctrl.value);      */
    // 'some value'
    // console.log('Name:' + this.form.get('username').value);

    /*if (await this.authService.isLoggedIn) {
      await this.router.navigate([this.returnUrl]);
    }*/
  }

  get userEmail(): any {
    return this.form.get('email');
  }

  get userEmail2(): any {
    return this.form.get('email2');
  }

  /* get backupEmail(): any {
    return this.form.get('emailBackUp');
  }

  get backupEmail2(): any {
    return this.form.get('emailBackUp2');
  } */

  get password1(): any {
    return this.form.get('password1');
  }

  get password2(): any {
    return this.form.get('password2');
  }

  get organization(): any {
    return this.form.get('organization');
  }

  get contactname(): any {
    return this.form.get('contactname');
  }

  get address(): any {
    return this.form.get('address');
  }

  get phone(): any {
    return this.form.get('phone');
  }

  get country(): any {
    return this.form.get('country');
  }

  get state(): any {
    return this.form.get('state');
  }

  get city(): any {
    return this.form.get('city');
  }
arIdioma(){
    if (this.state.indiceTranslate==0) {
      this.mystate.indiceTranslate=1  
      this.idioma='Español'
    }
    if(this.mystate.indiceTranslate==1){
      this.mystate.indiceTranslate=0
      this.idioma='English'
    }
    
  }
  async onSubmit(val: string): Promise<void> {
    if (val === 'l') {
      this.router.navigate(['/login']);
      return;
    }
    console.log('Registrando usuario!');
    if (this.form.valid) {
      // console.log('it is valid!');

      if (this.userEmail.value !== this.userEmail2.value) {
        this.loginInvalid = true;
        this.elError =
          'Debe repetir los mismos correos tanto en el correo principal como en el secundario';
        return;
      }
      if (this.password1.value !== this.password2.value) {
        this.elError =
          'La contraseña debe ser la misma en ambos campos';
        this.loginInvalid = true;
        return;
      }
      /* if (this.backupEmail.value !== this.backupEmail2.value) {
        this.elError =
          'Debe repetir los mismos correos tanto en el correo principal como en el secundario';
        this.loginInvalid = true;
        return;
      } */

      // console.log('It is okay');
      /* const superUser = this.super.value === '0' ? false : true;
      console.log('Sí es super usuario? ', superUser); */
      const usuario = {
        email: this.userEmail.value,
        backupEmail: 'dummyvalue@logios.global',
        organization: this.organization.value,
        phone: this.phone.value,
        address: this.address.value,
        contactname: this.contactname.value,
        country: this.country.value,
        state: this.state.value,
        city: this.city.value,
        authCollections: ['Logios'],
        trackingCollections: ['Logios'],
        password1: this.password1.value,
        password2: this.password2.value,
        is_activePlan: false,
        is_activeTrack: false,
      };
      // console.log('Enviando usaurio al auth serv: ', usuario);
      try {
        await this.authService.SignUp(usuario).then(async () => {
          // console.log('Res de signup: ', res);
          this.registrado = true;
          this.loginInvalid = false;
          const userinfo = {
            email: this.userEmail.value,
            organization: this.organization.value,
            phone: this.phone.value,
            address: this.address.value,
            contactname: this.contactname.value,
            country: this.country.value,
            state: this.state.value,
            city: this.city.value,
            authCollections: ['Logios'],
            trackingCollections: ['Logios'],
            busconfigselections: ""
          };
          await this.authService.CreateUserInfoData(userinfo).then((res: any) => {
            // console.log('creada información de usuario ', res)
          });
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        });
        // this.form.reset();
      } catch (err) {
        this.loginInvalid = true;
        this.elError = 'Usuario ya existente.';
        this.recuerde = '';
        /* setTimeout(() => {
        }, 1000); */
      }
    } else {
      this.loginInvalid = true;
      this.elError = 'Por favor llene todos los campos.';
      this.recuerde = 'Use un correo válido y 8 caractéres para la contraseña (mínimo un número, un caracter en mayúscula, un caracter en minúscula).';
    }

    return;
  }
}
