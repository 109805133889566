import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
// import { DataService } from '../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { Router } from '@angular/router';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-average-velocity',
  templateUrl: './average-velocity.component.html',
  styleUrls: ['./average-velocity.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AverageVelocityComponent
  implements OnInit, AfterViewInit, OnChanges {
  multi: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  icono = true;

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Servicio';
  yAxisLabel = 'Velocidad media (km/h)';
  timeline = false;
  legendTitle = '';
  autoScale = true;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;

  colorScheme = {
    domain: ['#F5821E'],
  };
  public title!: string;
  public primaryXAxis!: object;
  public primaryYAxis!: object;
  public tooltip!: object;
  ancho = '';
  alto = '';

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chart5')
  public chart!: ChartComponent;

  @Input() grande: boolean;

  constructor(
    // private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    // private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    /* this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + ''; */
    // this.state.currentChart = 5;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  print(){
    this.chart.print();
  }

  async ngOnInit(): Promise<void> {
    // this.subscribeConfiguration();
    // this.state.currentChart = 5;
    /* this.primaryYAxis = {
      labelFormat: '{value}',
      title: 'Velocidad media (km/h)',
      titleStyle: {
        fontFamily: 'Poppins',
      },
      labelStyle: {
        fontFamily: 'Poppins',
      },
    };
    this.primaryXAxis = {
      valueType: 'Category',
      title: 'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
      },
      labelStyle: {
        fontFamily: 'Poppins',
      },
    }; */
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
  }

  reloadComponent(): void {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    // this.state.selectedPoint =
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
