import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concatMap, of } from 'rxjs';
// import { IotaService } from 'src/app/shared/iota/iota.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-individual-event',
  templateUrl: './individual-event.component.html',
  styleUrls: ['./individual-event.component.scss']
})
export class IndividualEventComponent implements OnInit {
  id: any;
  // datos: any;
  @Input() datos: any = {};
  constructor(public state: StateService, // private iota: IotaService,
    private actRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    /* this.id = this.actRoute.snapshot.params.id;
    this.iota.getSingleEvent(this.id)
      .pipe(concatMap(value => {
        // console.log(`${value}: first pipe operator (before promise)`);
        // return a promise that resolves with the specified value after 2 seconds
        return new Promise(resolve => resolve(value));
      }))
      .pipe(concatMap((value: any) => {
        // console.log(`${value.lista}: second pipe operator (after promise resolved)`);

        return of(value);
      }))
      .subscribe((value: any) => {
        // this.items.push(value.lista)
        this.datos = value.lista;
      });
 */

  }

}
