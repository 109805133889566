import { Injectable, NgZone } from '@angular/core';
// import { User } from './user';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
// import { DataService } from '../data/data-service.service';

import { Observable, of, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { User } from '../../auxiliar_components/models/User';
import { LocalstorageService } from '../storage/localstorage.service';
import { DexieService } from '../dexie/dexie.service';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: User; // Save logged in user data
  dentro = false;

  y: Observable<User[]> = of([]);
  correo = '';
  public CurrentBusConfigurations: any = {};

  constructor(
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    // public data: DataService,
    private lStore: LocalstorageService,
    private http: HttpClient,
    private dexie: DexieService,
    private state: StateService
  ) {
    this.userData = {
      key: '1',
      user: {
        id: 0,
        password: '',
        last_login: '',
        email: '',
        backupEmail: '',
        organization: '',
        phone: '',
        address: '',
        contactname: '',
        country: '',
        state: '',
        city: '',
        joinedDate: '',
        authCollections: [],
        is_staff: false,
        is_active: true,
        is_superuser: false,
        is_activePlan: false,
        is_activeTrack: false,
      },
    };
  }
  url = this.state.backendURL

  handleError(error: HttpErrorResponse): any {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  async SignIn(email: string, password: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const usuario = {
      email,
      password,
    };

    await this.http
      .post<User>(this.url + 'dj-rest-auth/login/', usuario, httpOptions)
      .toPromise()
      .then(async (data) => {
        if (data.user.is_activePlan) {
          // El usuario tiene un plan activo dentro de Mirati
          this.userData = data;
          this.setUserStore(JSON.stringify(data));
          this.dentro = true;
          // Asignacion del Token para el acceso a la consulta de datos de la API-Logios
          this.setToken(data.key);
          await this.getBusConfig().subscribe((res: any) => {
            if (res.busconfigselections !== '') {
              this.currentBusConfigurations = JSON.parse(res.busconfigselections);
              this.lStore.setItem('busconfig', res.busconfigselections);
            } else {
              this.currentBusConfigurations = {};
              this.lStore.setItem('busconfig', res.busconfigselections);
            }
          });

        }
      })
      .catch((error) => this.handleError(error));

    return;
  }

  restartUserData(): void {
    this.dentro = true;
    this.userData = JSON.parse(this.getUserStore());
    // console.log('this.lStore.getItem(busconfig): ', this.lStore.getItem('busconfig'));
    if (this.lStore.getItem('busconfig') === '') {
      this.currentBusConfigurations = JSON.parse('{}');
    } else {
      this.currentBusConfigurations = JSON.parse(this.lStore.getItem('busconfig'));
    }

    // console.log('Restarted user data: ', this.userData);
  }

  // Sign up with email/password
  async SignUp(usuario: any): Promise<void> {
    // console.log(' usuario:', usuario);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post<any>(this.url + 'dj-rest-auth/registration/', usuario, httpOptions)
      .toPromise()
      .then((data: any) => {
        console.log('sign up!: ', data);
      })
      .catch((error) => this.handleError(error));
  }

  async CreateUserInfoData(usuario: any): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .put<any>(this.url + 'updateuserinfo/', usuario, httpOptions)
      .toPromise()
      .then((data: any) => {
        // console.log('inforuser data created!: ', data);
      })
      .catch((error) => this.handleError(error));
  }

  async SignOut(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
        // Authorization: 'my-auth-token'
      }),
    };

    return this.http
      .post<any>(this.url + 'dj-rest-auth/logout/', httpOptions)
      .toPromise()
      .then((data) => {
        // console.log('signout!: ', data);
        this.dentro = false;
        this.deleteToken();
        this.deleteUserStore();
        this.clearBusConfiguration();
        this.lStore.clear();
        // this.dexie.deleteDatabase();

      })
      .catch((error) => this.handleError(error));
  }

  async ResetPassword(datos: any): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .post<any>(this.url + 'resetpassword/', datos)
      .toPromise()
      .then((data) => {
        // console.log('user is reset pass: ', data);

      })
      .catch((error) => this.handleError(error));
  }

  async ChangePassword(datos: any): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this.getToken(),
      }),
    };
    // console.log('changepass ',  datos.uid + '/' + datos.token + '/', datos);
    return this.http
      .post<any>(this.url + 'changepassword/' + datos.uid + '/' + datos.token + '/', datos)
      .toPromise()
      .then((data) => {
        console.log('user has changed the pass: ', data);
      })
      .catch((error) => this.handleError(error));
  }

  async UpdatePassword(datos: any): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .put<any>(this.url + 'update_password/', datos, httpOptions)
      .toPromise()
      .then((data) => {
        // console.log('user has changed the pass: ', data);
      })
      .catch((error) => this.handleError(error));
  }

  async GetUserDetails(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .get<any>(this.url + 'dj-rest-auth/user/', httpOptions)
      .toPromise()
      .then((data) => {
        // console.log('user is supperuserrr: ', data.is_superuser);
        /* this.dentro = true;
        this.setToken(data.key); */
      })
      .catch((error) => this.handleError(error));
  }

  getUser(id: number): Observable<User[]> {
    // console.log('Getting user ', id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .get<User[]>(this.url + 'users/' + id, httpOptions)
      .pipe(retry(1));
  }

  async updateUser(id: number, usuario: any): Promise<void> {
    // console.log(' usuario:', id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .patch<any>(
        this.url + 'users/' + id + '/',
        usuario,
        httpOptions
      )
      .toPromise()
      .then((data) => {
        // console.log('update success!: ', data);
      })
      .catch((error) => this.handleError(error));
  }

  getUserList(): Observable<User[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .get<User[]>(this.url + 'users/', httpOptions)
      .pipe(retry(1));
  }

  deleteUser(userId: number): Observable<User> {
    // console.log('Getting user ', userId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };

    return this.http
      .delete<User>(this.url + 'users/' + userId, httpOptions)
      .pipe(retry(1));
  }

  setBusConfiguration(route: string, datos: any): void {
    this.currentBusConfigurations[route] = datos;
  }
  deleteBusSelectedConfiguration(route: string) {

  }
  clearBusConfiguration(): void {
    this.currentBusConfigurations = {};
    this.lStore.removeItem('busconfig');
  }

  saveBusConfig(id: string, cadena: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };
    const data = {
      email: this.userData.user.email,
      organization: this.userData.user.organization,
      busconfigselections: cadena,

    };
    // console.log('Making post savebusconfig with: ', data);
    return this.http.put(this.url + 'updateuserinfo/', data, httpOptions).pipe(retry(1));
    // return new Observable;
  }

  deleteBusConfigBackend(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };
    const data = {
      email: this.userData.user.email,
      organization: this.userData.user.organization,
      busconfigselections: '',

    };
    // console.log('Making post savebusconfig with: ', data);
    return this.http.put(this.url + 'updateuserinfo/', data, httpOptions).pipe(retry(1));
    // return new Observable;
  }

  getBusConfig(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this.getToken(),
      }),
    };
    const data = {
      Email: this.userData.user.email
    };
    // console.log('Getting busconfig for user: ', data);
    return this.http.post(this.url + 'queryuserinfo/', data, httpOptions).pipe(retry(1));
    // return new Observable;
  }

  get isLoggedIn(): boolean {
    // return true;
    if (this.getUserStore() === null) {
      return false;
    } else {
      return true;
    }
    /* const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null; */
  }

  setToken(token: string): void {
    this.lStore.setItem('token', token);
  }

  getToken(): string {
    return this.lStore.getItem('token');
  }

  setUserStore(token: string): void {
    this.lStore.setItem('user', token);
  }

  getUserStore(): string {
    return this.lStore.getItem('user');
  }


  deleteToken(): void {
    this.lStore.removeItem('token');
  }

  deleteUserStore(): void {
    this.lStore.removeItem('user');
  }

  restoreBusOptions(busconfigselections: any): void {
    this.lStore.setItem('busconfig', busconfigselections);
  }
  isSuperUser(): boolean {
    return this.userData.user.is_superuser;
  }
  isActivePlan(): boolean {
    return this.userData.user.is_active;
  }

  get UserId(): number {
    return this.userData.user.id;
  }

  get UserEmail(): string {
    return this.userData.user.email;
  }

  get currentBusConfigurations(): any {
    return this.CurrentBusConfigurations;
  }

  set currentBusConfigurations(bI: any) {
    this.CurrentBusConfigurations = bI;
  }

}
