import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../../shared/state/state.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts'; */
import { Subscription } from 'rxjs';
import { ConsumptionDistanceComponent } from '../charts/consumption-distance/consumption-distance.component';
import { StatisticsComponent } from '../charts/statistics/statistics.component';
import { DataService } from 'src/app/shared/data/data-service.service';
import { PowerTimeComponent } from '../charts/power-time/power-time.component';
import { VelocityProfileComponent } from '../charts/velocity-profile/velocity-profile.component';
import { EnergyTimeComponent } from '../charts/energy-time/energy-time.component';
import { ConsumptionKilometerComponent } from '../charts/consumption-kilometer/consumption-kilometer.component';
import { AvailableEnergyComponent } from '../charts/available-energy/available-energy.component';
import { AverageVelocityComponent } from '../charts/average-velocity/average-velocity.component';
import { RegeneracionComponent } from '../charts/regeneracion/regeneracion.component';
import { DiscriminatedComponent } from '../charts/discriminated/discriminated.component';
import { ConsumptionTimeComponent } from '../charts/consumption-time/consumption-time.component';
import { ChargerIdsComponent } from '../iota-events/charger-ids/charger-ids.component';
import { ChargersdataComponent } from '../charts/chargersdata/chargersdata.component';
import { ChargersAccumComponent } from '../charts/chargers-accum/chargers-accum.component';
import { NewChartPowerComponent } from '../charts/new-chart-power/new-chart-power.component';
import { BusTimeComponent } from '../charts/bus-time/bus-time.component';
import { ComparisonBusComponent } from '../charts/comparison-bus/comparison-bus.component';
// import { IotaService } from 'src/app/shared/iota/iota.service';
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-charts-navigation',
  templateUrl: './charts-navigation.component.html',
  styleUrls: ['./charts-navigation.component.scss'],
})
export class ChartsNavigationComponent implements OnInit, OnDestroy {
  // whichone : number = -1;
  @ViewChild('chargerid')
  public chargerid!: ChargerIdsComponent;
  visibleButtons = false;
  grande = false;
  chartsarray: boolean[] = [];
  activadoarray: boolean[] = [];
  backgroundarray: string[] = [];
  verdad = true;
  animationControl = false;
  //animationIsPlaying 0 for non playing, 1 for playing, 2 for pause
  animationIsPlaying = 0;
  animationIsPlayingAllRoutes = 0;
  animationIsSeriesPlaying = false;

  playIcon = 'play_arrow';
  playSeriesIcon = 'timeline';
  copiaTHEDATA: any = [];
  whichPosition: number = 0;
  serie = 0

  color13 = '#000096';
  docDefinition: any;

  info = false;
  laRuta = 'XXX';
  previous = 1;
  names = [
    'configuracion-del-bus-azul',
    'consumo de energia_azul',
    'consumo de energia_blanco',
    'consumo_discriminado_azul',
    'consumo_discriminado_banco',
    'consupo-por-km_azul',
    'consupo-por-km_blanco',
    'energia-disponible_azul',
    'energia-disponible_blanco',
    'estadisticas_azul',
    'estadisticas_blanco',
    'perfil de velocidad_azul',
    'perfil de velocidad_blanco',
    'Velocidad promedio_azul',
    'Velocidad promedio_blanco',
    'consumo_tiempo_azul',
    'consumo_tiempo_blanco',
    'topography',
    'topography_azul',
    'ruta',
    'ruta_azul',
    'potencia_tiempo',
    'potencia_tiempo_azul',
    'play_azul',
    'play_blanco',
    'play_azulizq',
    'play_blancoizq',
    'animarruta_azul',
    'animarruta_blanco',
    'chartsbottomazul',
    'chartsbottomblanco',
    'configuracion_azul',
    'configuracion-blanco'

  ];
  nombreIcono = 'Velocidad promedio_blanco';
  @ViewChild('myDOMElement1')
  MyDOMElement1!: ElementRef;
  @ViewChild('myDOMElement2')
  MyDOMElement2!: ElementRef;
  @ViewChild('myDOMElement3')
  MyDOMElement3!: ElementRef;
  @ViewChild('myDOMElement4')
  MyDOMElement4!: ElementRef;
  @ViewChild('myDOMElement5')
  MyDOMElement5!: ElementRef;
  @ViewChild('myDOMElement6')
  MyDOMElement6!: ElementRef;
  @ViewChild('myDOMElement7')
  MyDOMElement7!: ElementRef;
  @ViewChild('myDOMElement8')
  MyDOMElement8!: ElementRef;
  @ViewChild('myDOMElement9')
  MyDOMElement9!: ElementRef;
  @ViewChild('myDOMElement10')
  MyDOMElement10!: ElementRef;
  @ViewChild('myDOMElement11')
  MyDOMElement11!: ElementRef;
  @ViewChild('myDOMElement12')
  MyDOMElement12!: ElementRef;
  @ViewChild('myDOMElement13')
  MyDOMElement13!: ElementRef;
  @ViewChild('myDOMElement15')
  MyDOMElement15!: ElementRef;
  @ViewChild('myDOMElement16')
  MyDOMElement16!: ElementRef;
  @ViewChild('myDOMElement17')
  MyDOMElement17!: ElementRef;
  //Comparacion Carga
  @ViewChild('myDOMElement18')
  MyDOMElement18!: ElementRef;
  @ViewChild('myDOMElement19')
  MyDOMElement19!: ElementRef;
  @ViewChild('myDOMElement20')
  MyDOMElement20!: ElementRef;
  @ViewChild('myDOMElement21')
  MyDOMElement21!: ElementRef;
  @ViewChild('myDOMElement22')
  MyDOMElement22!: ElementRef;
  @ViewChild('myDOMElement23')
  MyDOMElement23!: ElementRef;
  @ViewChild('myDOMChargers')
  MyDOMElement25!: ElementRef;
  @ViewChild('myDOMChargers')
  MyDOMElement26!: ElementRef;
  @ViewChild('myDOMChargers')
  MyDOMElement27!: ElementRef;
  @ViewChild('myDOMChargers')
  MyDOMElement28!: ElementRef
  @ViewChild('myDOMChargers')
  MyDOMElement29!: ElementRef
  @ViewChild('myDOMChargers')
  MyDOMElement30!: ElementRef
  @ViewChild('myDOMChargers')
  MyDOMChargers!: ElementRef;

  @ViewChild('childConsum') childConsum: ConsumptionDistanceComponent;
  @ViewChild('childKilo') childKilo: ConsumptionKilometerComponent;
  @ViewChild('childAvail') childAvail: AvailableEnergyComponent;
  @ViewChild('childStatistics') childStatistics: StatisticsComponent;
  @ViewChild('childVelocity') childVelocity: VelocityProfileComponent;
  @ViewChild('childAvrVel') childAvrVel: AverageVelocityComponent;
  @ViewChild('childTopo') childTopo: RegeneracionComponent;
  @ViewChild('childDiscri') childDiscri: DiscriminatedComponent;
  @ViewChild('childTime') childTime: ConsumptionTimeComponent;
  @ViewChild('childPower') childPower: PowerTimeComponent;
  @ViewChild('childEnergy') childEnergy: EnergyTimeComponent;
  @ViewChild('childchargers') childchargers: ChargersdataComponent;
  @ViewChild('childChargersAccum') childChargersAccum: ChargersAccumComponent;
  @ViewChild('childBusTime') childBusTime: BusTimeComponent;
  @ViewChild('comparisionBus') comparisionBus: ComparisonBusComponent;

  ChartsSubs: Subscription | undefined;
  CollectionsSubs: Subscription | undefined;
  RouteSubs: Subscription | undefined;

  IotaSubs: Subscription | undefined;

  miTimeout: ReturnType<typeof setTimeout>;
  activado12: boolean;
  cadenachargerschart = 'POTENCIA';
  purplemessage = '';
  redmessage = '';
  orangemessage = '';
  yellowmessage = '';
  bluemessage = '';
  greenmessage = '';
  redmessage2: string;
  orangemessage2: string;
  yellowmessage2: string;

  constructor(
    public mystate: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private data: DataService,
    // private iota: IotaService
  ) /* private iconService: IconsService */ {
    for (const entry of this.names) {
      this.matIconRegistry.addSvgIcon(
        entry,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/SGV/' + entry + '.svg'
        )
      );
    }
    this.mystate.areAnimationsDone = 2;
    this.mystate.showChargersData = false;
  }

  clickMe() {

  }
  ngOnInit(): void {
    this.chartsarray = new Array(26).fill(false);
    this.activadoarray = new Array(26).fill(false);
    this.backgroundarray = new Array(26).fill('#000096');
    this.subscribeToStopAnimation();

  }

  showBusesOptions(): void {

    this.mystate.showVehicleProperties = false;
    this.mystate.isEventsON = false;
    this.mystate.showChargersData = false;
    if (this.mystate.isChargingSitesON) {
      this.mystate.isChargingSitesON = false;
      this.mystate.currentRoute = 'None';
      this.mystate.showCharginSiteChart = false;
    }
    this.mystate.showBusesOptions = !this.mystate.showBusesOptions;
    if (this.mystate.showBusesOptions) {
      this.mystate.changeAnimation(['StopAnimation', 0]);
      this.animationIsPlayingAllRoutes = 0;
    }
  }

  showVehicleProperties() {
    this.mystate.showVehicleProperties = !this.mystate.showVehicleProperties;
    this.mystate.showBusesOptions = false;
    this.mystate.isEventsON = false;
    this.mystate.isChargingSitesON = false;
    this.mystate.showCharginSiteChart = false;
  }

  showAllEvents(): void {
    this.mystate.isEventsON = !this.mystate.isEventsON;
    this.mystate.showBusesOptions = false;
    this.mystate.showVehicleProperties = false;
    this.mystate.isChargingSitesON = false;
    this.mystate.showCharginSiteChart = false;
  }

  showChargingSites(): void {
    this.mystate.showBusesOptions = false;
    this.mystate.currentRoute = 'None';
    this.mystate.isChargingSitesON = !this.mystate.isChargingSitesON;
    this.mystate.showVehicleProperties = false;
    this.mystate.isEventsON = false;
    this.mystate.showCharginSiteChart = false;
  }



  getPositionRute(ruta: string) {
    for (let i = 0; i < this.mystate.busNames.length; i++) {
      if (ruta === this.mystate.busNames[i]) {
        this.mystate.currentRoutePosition = i;
      }
    }
  }

  showDetailsButtons(): void {

    if (this.mystate.currentRoute !== 'None') {
      this.mystate.showDetails = !this.mystate.showDetails;

    }
  }

  showDetailsVelEne(whichone: string): void {
    if (whichone === 'vel') {
      this.mystate.showDetailsVel = !this.mystate.showDetailsVel;
      console.log('Esto esta dentro de showDetailsVelEne:', {
        whichone,
        show: this.mystate.showDetailsVel,
        whichcycle: this.mystate.currentCycle
      });
      if (this.mystate.showDetailsVel) {
        const datos = {
          whichone,
          show: this.mystate.showDetailsVel,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
        this.redmessage = '45km/h < velocidad';
        this.yellowmessage = '30km/h';
        this.yellowmessage2 = 'velocidad  < 45km/h';
        this.bluemessage = 'velocidad < 30km/h';
      } else {
        const datos = {
          whichone: 'clear',
        };
        this.mystate.changeDetailsEvent(datos);
      }
    } else { // whichone = 'ene'
      this.mystate.showDetailsEne = !this.mystate.showDetailsEne;
      if (this.mystate.showDetailsEne) {
        const datos = {
          whichone,
          show: this.mystate.showDetailsEne,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
        this.purplemessage = 'kWh/km < 0';
        this.redmessage = '0  < kWh/km ';
        this.redmessage2 = '0.5';
        this.orangemessage = '0.5  < kWh/km';
        this.orangemessage2 = '1.0';
        this.yellowmessage = '1.0  < kWh/km';
        this.yellowmessage2 = '1.5';
        this.greenmessage = '1.5 < kWh/km';
      } else {
        const datos = {
          whichone: 'clear',
        };
        this.mystate.changeDetailsEvent(datos);
      }
    }
  }

  aumentarCiclo() {
    // console.log('aumentando ciclo');
    if (this.mystate.currentCycle + 1 < this.mystate.cyclesListOnlyCC.length) {
      this.mystate.currentCycle++;
      if (this.mystate.showDetailsVel) {
        const datos = {
          whichone: 'vel',
          show: this.mystate.showDetailsVel,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
      } else if (this.mystate.showDetailsEne) {
        const datos = {
          whichone: 'ene',
          show: this.mystate.showDetailsEne,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
      }
    }
  }

  disminuirCiclo() {
    // console.log('disminuyendo ciclo');
    if (this.mystate.currentCycle - 1 >= 0) {
      this.mystate.currentCycle--;
      if (this.mystate.showDetailsVel) {
        const datos = {
          whichone: 'vel',
          show: this.mystate.showDetailsVel,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
      } else {
        const datos = {
          whichone: 'ene',
          show: this.mystate.showDetailsEne,
          whichcycle: this.mystate.currentCycle
        };
        this.mystate.changeDetailsEvent(datos);
      }
    }
  }

  showDetailsEne(): void {

  }


  public setCurrentRoute = async (route: any) => {
    if (this.mystate.isAnimationON)
      this.mystate.eventStopAnimation();
    // this.state.currentType = '';
    // this.state.currentBusSize = '';
    // this.state.currentBattery = '';
    this.mystate.endAuto = '';
    this.mystate.maximumConsumption = '';

    this.mystate.currentRoute = route.value;
    if (route.value === 'None') {
      this.mystate.currentRoutePosition = -1;
    } else {
      this.getPositionRute(route.value);
    }
    for (let i = 0; i < this.mystate.THEDATA.length; i++) {
      if (i !== 6)
        this.mystate.THEDATA[i] = [];
    }
    this.mystate.changePax('');
    this.mystate.processing = true;
    await this.data.getRouteProperties(route.value, this.mystate.currentCollection);
    //await this.data.getAllCyclesInputs();
    await this.data.getAllCyclesInputsEvo(route.value, this.mystate.currentCollection);
    if (this.mystate.currentBattery !== '' && this.mystate.currentPax !== '' &&
      this.mystate.currentBusSize !== '' && this.mystate.currentType !== '') {
      await this.data.updateTHEDATA2();
      this.data.setLabels();
    }
    this.mystate.processing = false;
    this.mystate.changePax('nav');
    // console.log('before the update data2');
    //}
    // this.data.setLabels();
    this.mystate.changeRoute(route.value);
    // this.onSidenavClose();
  };

  subscribeToStopAnimation(): void {
    this.mystate.stopAnimationEvent.subscribe(() => {
      this.stopAnimation();
    });
  }

  /* subscribeToIOTA(): void{
    this.iota.listen('T-800').subscribe((res: any) => {
      console.log('LLegan datos de IOTA: ', res);
    });
  } */

  playPauseAnimation(): void {
    if (this.mystate.chosenSeries !== -1 && !this.animationIsSeriesPlaying) {
      if (this.animationIsPlaying === 0) {// it is stopped then play
        this.playIcon = 'pause';
        this.animationIsPlaying = 1;
        if (this.mystate.chartMapPointIndex !== [-1, -1]) {
          this.mystate.chartMapPointIndex = [-1, -1];
          this.mystate.changePointIndex([-1, -1]);
        }
        this.animar();
      } else if (this.animationIsPlaying === 1) { // it is playing then pause
        clearTimeout(this.miTimeout);
        this.mystate.changeAnimation(['Pause']);
        this.playIcon = 'play_arrow';
        this.animationIsPlaying = 2;
      } else if (this.animationIsPlaying === 2) {// it is paused then resume
        this.animationIsPlaying = 1;
        this.playIcon = 'pause';
        this.resumeAnimation();
      }
    }
  }

  playPauseAnimationSeries(): void {
    // if (this.mystate.chosenSeries !== -1) {
    if (this.animationIsPlaying === 0) {// it is stopped then play
      // this.playSeriesIcon = 'stop';
      this.animationIsPlaying = 1;
      this.animationIsSeriesPlaying = true;
      this.animarAllSeries();

    } /* else if (this.animationIsPlaying === 1) {
      clearTimeout(this.miTimeout);
      this.mystate.changeAnimation(['StopAnimation']);
      this.stopAnimation();
      this.playSeriesIcon = 'timeline';
      this.animationIsPlaying = 2;
    } */
    // }
  }

  playAllRoutes(): void {
    // console.log('playing all routes');
    this.mystate.isEventsON = false;
    if (this.animationIsPlayingAllRoutes === 1) {
      this.animationIsPlayingAllRoutes = 0;
      this.mystate.changeAnimation(['StopAnimation', 0]);
      this.mystate.showPowerGauge = [false, ''];
      this.mystate.showSingleBusAnimation = '';
      this.mystate.showAllBusesAnimation = false;
    } else {
      /* console.log('this.mystate.routesAnimationData: ', this.mystate.routesAnimationData);
      if (this.mystate.routesAnimationData){
        return;
      } */
      // console.log('playing all routes start');
      this.mystate.showAllChargersAnimation = false;
      this.mystate.showBusesOptions = false;
      this.mystate.showVehicleProperties = false;
      this.mystate.isEventsON = false;
      // console.log('Animando todas las rutas!');
      this.mystate.busNames.forEach((c: any) => {
        this.mystate.socGaugeValue[this.mystate.currentCollection + c] = -1;
        this.mystate.distanceGaugeValue[this.mystate.currentCollection + c] = -1;
        this.mystate.distanceGaugePercentage[this.mystate.currentCollection + c] = 0;
        this.mystate.powerGaugePercentage[this.mystate.currentCollection + c] = 0;
        //Descriptions length should be the number of sensor (alerts)
        //descriptions positions
        // 0 --> SOC
        // 1 --> Temperature
        // 2 --> ?
        const fecha = new Date();
        const fecha2 = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0);
        this.mystate.animationAlerts[this.mystate.currentCollection + c] =
        {
          worstcolor: 'none',
          descriptions: [
            ['OK', fecha2.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'limegreen'],
            ['OK', fecha2.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'limegreen']
          ],
          colors: ['limegreen'],
          type: ['General']
        };
        this.mystate.distanceChartValue[this.mystate.currentCollection + c] = [
          {
            hora: 'Autonomía',
            type: 0,
            color: '#cccc00'
          },
          {
            hora: 'A carga',
            type: 0,
            color: '#00ccff'
          }

        ];

        this.mystate.puntoDeCarga[this.mystate.currentCollection + c] = 0;
        this.mystate.inicioDeCarga[this.mystate.currentCollection + c] = '00:00';
        this.mystate.finDeCarga[this.mystate.currentCollection + c] = '00:00';

      });
      // console.log('before calling animation in maps');
      this.mystate.changeAnimation(['AllRoutes', 0]);

      /* this.mystate.allLocations.forEach((cole: any) => {
        if (this.mystate.currentCollection === cole.colName) {
          cole.chargernames.forEach((c: any) => {
            this.mystate.powerGaugeValue[c.replace(/\s/g, "")] = 0;
            this.mystate.chargerAssignedBus[c.replace(/\s/g, "")] = '';
            this.mystate.chargerInit[c.replace(/\s/g, "")] = '';
            this.mystate.chargerEnd[c.replace(/\s/g, "")] = '';
            this.mystate.chargedEnergy[c.replace(/\s/g, "")] = 0;
            this.mystate.accumChargedEnergy[c.replace(/\s/g, "")] = [0];
          });
        }
      }); */
      // console.log('before calling RemoveRoute in maps');
      this.animationIsPlayingAllRoutes = 1;
      this.mystate.changeAnimation(['RemoveRoute', 0]);
    }
    // this.mystate.isAnimationON = !this.mystate.isAnimationON;
    // console.log('this.mystate.animationAlerts in chartsnav: ', this.mystate.animationAlerts);
  }

  playAllChargers(): void {
    this.mystate.isEventsON = false;
    if (this.mystate.showAllChargersAnimation) {
      this.mystate.showAllChargersAnimation = false;
      this.mystate.changeAnimation(['StopAnimation', 0]);
      this.mystate.showAllBusesAnimation = false;
      this.animationIsPlayingAllRoutes = 0;
    } else {
      this.mystate.showAllBusesAnimation = false;
      this.mystate.showBusesOptions = false;
      this.mystate.busNames.forEach((c: any) => {
        this.mystate.socGaugeValue[this.mystate.currentCollection + c] = -1;
        this.mystate.distanceGaugeValue[this.mystate.currentCollection + c] = -1;
        //Descriptions length should be the number of sensor (alerts)
        //descriptions positions
        // 0 --> SOC
        // 1 --> Temperature
        // 2 --> ?
        const fecha = new Date();
        const fecha2 = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0);
        this.mystate.animationAlerts[this.mystate.currentCollection + c] =
        {
          worstcolor: 'none',
          descriptions: [
            ['OK', fecha2.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'limegreen'],
            ['OK', fecha2.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'limegreen']
          ],
          colors: ['limegreen'],
          type: ['General']
        };
        this.mystate.distanceChartValue[this.mystate.currentCollection + c] = [
          {
            hora: 'Autonomía',
            type: 0,
            color: '#cccc00'
          },
          {
            hora: 'A carga',
            type: 0,
            color: '#00ccff'
          }

        ];

        this.mystate.puntoDeCarga[this.mystate.currentCollection + c] = 0;
        this.mystate.inicioDeCarga[this.mystate.currentCollection + c] = '00:00';
        this.mystate.finDeCarga[this.mystate.currentCollection + c] = '00:00';
      });
      this.mystate.changeAnimation(['AllRoutes', 0]);
      this.mystate.allLocations.forEach((cole: any) => {
        if (this.mystate.currentCollection === cole.colName) {
          cole.chargernames.forEach((c: any) => {
            this.mystate.powerGaugeValue[c.replace(/\s/g, "")] = 0;
          });
        }
      });
      this.mystate.showAllChargersAnimation = true;
      this.animationIsPlayingAllRoutes = 1;
      this.mystate.changeAnimation(['RemoveRoute', 0]);
    }

  }

  resumeAnimation() {
    switch (this.mystate.currentChart) {
      case 1: // energy consumption
        this.increaseOneByOne(0, this.mystate.chosenSeries, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['Cycles', this.whichPosition]);
        break;
      case 3: // available energy
        this.increaseOneByOneCompleteDay(2, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['CompleteDay', this.whichPosition]);
        break;
      case 4: // velocity profile
        this.increaseOneByOne(3, this.mystate.chosenSeries, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['Cycles', this.whichPosition]);
        break;
      case 8: // discriminated
        this.increaseOneByOneCompleteDay(7, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['CompleteDay', this.whichPosition]);
        break;
      case 9: // time
        this.increaseOneByOneCompleteDay(8, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['CompleteDay', this.whichPosition]);
        break;
      case 10: // time
        this.increaseOneByOne(0, this.mystate.chosenSeries, this.copiaTHEDATA, this.whichPosition);
        this.mystate.changeAnimation(['Cycles', this.whichPosition]);
        break;
      default:
        console.log('No animation posible');
        break
    }
  }

  stopAnimation(): void {
    if (this.animationIsPlaying || this.mystate.areAnimationsDone < 2) {

      clearTimeout(this.miTimeout);
      this.mystate.changeAnimation(['StopAnimation', 0]);
      this.mystate.areAnimationsDone++;
      if (this.mystate.currentChart === 1 || this.mystate.currentChart === 4) {
        // this.mystate.THEDATA[this.mystate.currentChart - 1][this.mystate.chosenSeries].datos = [...this.copiaTHEDATA[this.mystate.chosenSeries].datos];
        for (let i = 0; i < this.mystate.THEDATA[this.mystate.currentChart - 1].length; i++) {
          this.mystate.THEDATA[this.mystate.currentChart - 1][i].datos = [...this.copiaTHEDATA[i].datos];
        }
      }
      if (this.mystate.currentChart === 10) {
        /* for (let i = 0; i < this.mystate.THEDATA[0].length; i++) {
          this.mystate.THEDATA[0][i].datos = this.copiaTHEDATA[i].datos;
          this.mystate.THEDATA[0][i].altitud = this.copiaTHEDATA[i].altitud;
          this.mystate.THEDATA[0][i].regen = this.copiaTHEDATA[i].regen
        } */
        // is.mystate.THEDATA[0] = this.copiaTHEDATA;
        this.mystate.THEDATA[0][this.mystate.chosenSeries].datos = [...this.copiaTHEDATA[this.mystate.chosenSeries].datos];
        // this.mystate.THEDATA[0][this.mystate.chosenSeries].altitud = [...this.copiaTHEDATA[this.mystate.chosenSeries].altitud];
        this.mystate.THEDATA[0][this.mystate.chosenSeries].regen = [...this.copiaTHEDATA[this.mystate.chosenSeries].regen];
        // this.mystate.THEDATA[0] = [...this.mystate.THEDATACOPY[0]];
        /*  this.mystate.THEDATA[0][this.mystate.chosenSeries].datos = [];
         this.mystate.THEDATACOPY[0][this.mystate.chosenSeries].datos.forEach((val: any) => this.mystate.THEDATA[0][this.mystate.chosenSeries].datos.push(Object.assign({}, val))); */
        // this.mystate.THEDATA[0][this.mystate.chosenSeries].datos = this.mystate.THEDATA[0][this.mystate.chosenSeries].datos;
      }
      if (this.mystate.currentChart === 3 || this.mystate.currentChart === 8 || this.mystate.currentChart === 9) {
        this.mystate.THEDATA[this.mystate.currentChart - 1][0].datos = [...this.copiaTHEDATA[0].datos];
        this.mystate.THEDATA[this.mystate.currentChart - 1][1].datos = [...this.copiaTHEDATA[1].datos];
        this.mystate.THEDATA[this.mystate.currentChart - 1][2].datos = [...this.copiaTHEDATA[2].datos];
      }
      this.mystate.changePax('');
      if (this.animationIsSeriesPlaying) {
        this.mystate.changeBusSize('');
        this.animationIsSeriesPlaying = false;
      }
      this.mystate.animationSpeed = 0;
      this.playIcon = 'play_arrow';
      this.animationIsPlaying = 0;
      this.mystate.showPowerGauge = [false, ''];

    }
  }

  volumneDown(): void {
    if (this.mystate.animationSpeed > 0) {
      this.mystate.animationSpeed = this.mystate.animationSpeed - 10;
      // console.log(this.mystate.animationSpeed);
    }
  }

  volumeUp(): void {
    if (this.mystate.animationSpeed < 100) {
      this.mystate.animationSpeed = this.mystate.animationSpeed + 10;
      // console.log(this.mystate.animationSpeed);
    }
  }

  increaseOneByOne(chart: number, chosenSeries: number, originArray: any, pos: number): void {
    if (pos < originArray[chosenSeries].datos.length) {
      this.mystate.THEDATA[chart][chosenSeries].datos.push(originArray[chosenSeries].datos[pos]);
      if (this.mystate.currentChart === 10) {
        // this.mystate.THEDATA[chart][chosenSeries].altitud.push(originArray[chosenSeries].altitud[pos]);
        this.mystate.THEDATA[chart][chosenSeries].regen.push(originArray[chosenSeries].regen[pos]);
      }
      this.mystate.changePax('');

      this.miTimeout = setTimeout(() => {
        pos += 1;
        this.whichPosition = pos;
        this.increaseOneByOne(chart, chosenSeries, originArray, pos);
      }, 10);
      // console.log('Next timeoit: ', 100 - this.mystate.animationSpeed);
    } else {
      // console.log('Termino de animar grafico');
      this.mystate.areAnimationsDone++;
      this.playIcon = 'play_arrow';
      this.animationIsPlaying = 0;
    }
  }

  increaseOneByOneCompleteDay(chart: number, originArray: any, pos: number): void {
    if (pos < originArray[0].datos.length) {
      this.mystate.THEDATA[chart][0].datos.push(originArray[0].datos[pos]);
      this.mystate.THEDATA[chart][0].datos = [...this.mystate.THEDATA[chart][0].datos];
      this.mystate.THEDATA[chart][1].datos.push(originArray[1].datos[pos]);
      this.mystate.THEDATA[chart][1].datos = [...this.mystate.THEDATA[chart][1].datos];
      this.mystate.THEDATA[chart][2].datos.push(originArray[2].datos[pos]);
      this.mystate.THEDATA[chart][2].datos = [...this.mystate.THEDATA[chart][2].datos];
      // this.mystate.THEDATA[chart] = [...this.mystate.THEDATA[chart]];
      this.mystate.changePax('');
      this.miTimeout = setTimeout(() => {
        pos += 1;
        this.whichPosition = pos;
        this.increaseOneByOneCompleteDay(chart, originArray, pos);
      }, 10);
    } else {
      // console.log('Termino de animar grafico completeday: ', this.mystate.THEDATA[chart][0].datos);
      this.mystate.areAnimationsDone++;
      this.playIcon = 'play_arrow';
      this.animationIsPlaying = 0;
    }
  }

  increaseAllSeriesCycles(chart: number, source): void {
    // for (; this.serie < source.length; this.serie++, this.whichPosition = 0) {
    if (this.serie < source.length) {
      let pos = this.whichPosition;
      // If current series is not done, then continue processing ths seires in index pos
      if (pos < source[this.serie].datos.length) {
        this.mystate.THEDATA[chart][this.serie].datos.push(source[this.serie].datos[pos]);
        if (this.mystate.currentChart === 10) {
          this.mystate.THEDATA[chart][this.serie].altitud.push(source[this.serie].altitud[pos]);
          this.mystate.THEDATA[chart][this.serie].regen.push(source[this.serie].regen[pos]);
        }
        this.mystate.changePax('');
        if (this.animationIsPlaying !== 2) {
          this.miTimeout = setTimeout(() => {
            pos += 1;
            this.whichPosition = pos;
            this.increaseAllSeriesCycles(chart, source);
          }, 0);
        }
      } // The series is done, so increment series number and make recursive call
      else {
        this.serie++;
        this.whichPosition = 0;

        this.increaseAllSeriesCycles(chart, source);


      }
    } else {
      // console.log('Termino de animar grafico');
      this.mystate.areAnimationsDone++;
      // this.playIcon = 'play_arrow';
      this.animationIsPlaying = 0;
      this.animationIsSeriesPlaying = false;
    }
  }

  async animar(): Promise<void> {

    if (this.mystate.areAnimationsDone >= 2) {
      if (this.mystate.chosenSeries !== -1) {
        this.copiaTHEDATA = [];
        switch (this.mystate.currentChart) {
          case 1: // energy consumption
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[0].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[0].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[0][this.mystate.chosenSeries].datos = [];
            this.mystate.changePax('');
            this.increaseOneByOne(0, this.mystate.chosenSeries, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['Cycles', 0]);
            break;
          case 3: // available energy
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[2].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[2].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[2][0].datos = [];
            this.mystate.THEDATA[2][1].datos = [];
            this.mystate.THEDATA[2][2].datos = [];
            this.mystate.THEDATA[2][3].datos = [];
            this.mystate.THEDATA[2][4].datos = [];
            // this.mystate.changePax('');
            this.increaseOneByOneCompleteDay(2, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['CompleteDay', 0]);
            break;
          case 4: // velocity profile
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[3].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[0].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[3][this.mystate.chosenSeries].datos = [];
            this.mystate.changePax('');
            this.increaseOneByOne(3, this.mystate.chosenSeries, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['Cycles', 0]);
            break;
          case 8: // discriminated
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[7].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[2].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[7][0].datos = [];
            this.mystate.THEDATA[7][1].datos = [];
            this.mystate.THEDATA[7][2].datos = [];
            // this.mystate.changePax('');
            this.increaseOneByOneCompleteDay(7, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['CompleteDay', 0]);
            break;
          case 9: // time
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[8].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[2].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[8][0].datos = [];
            this.mystate.THEDATA[8][1].datos = [];
            this.mystate.THEDATA[8][2].datos = [];
            // this.mystate.changePax('');
            this.increaseOneByOneCompleteDay(8, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['CompleteDay', 0]);
            break;
          case 10: // topografia
            this.mystate.THEDATACOPY[0] = [];
            this.mystate.THEDATA[0].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
            this.mystate.THEDATA[0].forEach((val: any) => {
              this.mystate.THEDATACOPY[0].push(Object.assign({}, val))
            });
            this.mystate.THEDATA[0][this.mystate.chosenSeries].datos = [];
            // this.mystate.THEDATA[0][this.mystate.chosenSeries].altitud = [];
            this.mystate.THEDATA[0][this.mystate.chosenSeries].regen = [];
            this.mystate.changePax('');

            this.increaseOneByOne(0, this.mystate.chosenSeries, this.copiaTHEDATA, 0);
            this.mystate.areAnimationsDone = 0;
            this.mystate.changeAnimation(['Cycles', 0]);
            break;
          default:
            console.log('No animation posible');
            this.animationIsPlaying = 0;
            this.playIcon = 'play_arrow';
            alert('Este gráfico no anima...');
            break;
        }
      } else {
        alert('Debe seleccionar una y solo una serie para animar');
      }
    } else {
      alert('Animación en curso!');
    }
  }

  async animarAllSeries(): Promise<void> {

    if (this.mystate.areAnimationsDone >= 2) {

      this.copiaTHEDATA = [];
      switch (this.mystate.currentChart) {
        case 1: // energy consumption
          this.mystate.THEDATACOPY[2] = [];
          this.mystate.THEDATA[0].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
          this.mystate.THEDATA[2].forEach((val: any) => {
            this.mystate.THEDATACOPY[2].push(Object.assign({}, val))
          });
          for (let i = 0; i < this.mystate.THEDATA[0].length; i++) {
            this.mystate.THEDATA[0][i].datos = [];
          }
          this.serie = 0;
          this.whichPosition = 0;
          this.mystate.areAnimationsDone = 0;
          this.mystate.chosenSeries = 0;
          this.mystate.changeSeriesUp('');
          this.mystate.changeAnimation(['CyclesAll', 0]);
          this.increaseAllSeriesCycles(0, this.copiaTHEDATA);
          break;
        case 4: // velocity profile
          this.mystate.THEDATA[3].forEach((val: any) => this.copiaTHEDATA.push(Object.assign({}, val)));
          for (let i = 0; i < this.mystate.THEDATA[3].length; i++) {
            this.mystate.THEDATA[3][i].datos = [];
          }

          this.serie = 0;
          this.whichPosition = 0;
          this.mystate.areAnimationsDone = 0;
          this.mystate.chosenSeries = 0;
          this.mystate.changeSeriesUp('');
          this.mystate.changeAnimation(['CyclesAll', 0]);
          this.increaseAllSeriesCycles(3, this.copiaTHEDATA);
          break;
        default:
          console.log('No animation posible');
          this.animationIsPlaying = 0;
          this.animationIsSeriesPlaying = false;
          this.mystate.areAnimationsDone = 2;
          alert('Este gráfico no anima todas sus series...');

          break;
      }

    } else {
      alert('Animación en curso!');
    }
  }

  getData(whichOne: number): void {
    this.childStatistics.getData(whichOne);
  }

  setCurrentVelocity(whichOne: number) {
    // this.childVelocity.setVelocity(whichOne);
    this.mystate.currentVelocity = whichOne;
    /* setTimeout(() => {
      this.childVelocity.updateData();
    }, 1000); */

  }

  setCurrentEnergyAvailable(whichOne: number) {
    this.childAvail.changeGraphic(whichOne);
  }

  setCurrentChargeAndDischarge(whichOne: number) {
    this.childTime.changeGraphic(whichOne);
  }

  powerGetData(whichOne: number): void {
    this.childPower.getData(whichOne);
  }

  bustimeGetData(whichOne: number): void {
    this.childBusTime.getData(whichOne);
  }

  //  comparisonBusGetData(whichOne: number): void {
  //    this.comparisionBus.getData(whichOne);
  //  }
  //

  energyGetData(whichOne: number): void {
    this.childEnergy.getData(whichOne);
  }

  impactGetData(whichOne: number) {
    this.childChargersAccum.getData(whichOne);
  }

  chargersGetData(whichOne: number): void {
    this.childchargers.getData(whichOne);
    if (whichOne === 1) {
      this.cadenachargerschart = 'POTENCIA';
    } else if (whichOne === 2) {
      this.cadenachargerschart = 'ENERGÍA';
    } else {
      this.cadenachargerschart = 'ACUMULADO'
    }
  }

  ocultar(): void {
    console.log('ocultar');
  }



  newactivate(whichone: number) {
    if (this.backgroundarray[whichone] === 'white') {
      this.activadoarray[whichone] = true;
      this.mystate.currentChart = 0;
      this.backgroundarray[whichone] = '#000096';
      this.visibleButtons = false;
      this.grande = false;
      if (this.mystate.chartMapPointIndex !== [-1, -1]) {
        this.mystate.chartMapPointIndex = [-1, -1];
        this.mystate.changePointIndex([-1, -1]);
      }
    } else {
      this.activadoarray.fill(true);
      this.activadoarray[whichone] = false;
      this.backgroundarray.fill('#000096');
      this.backgroundarray[whichone] = 'white';
      this.mystate.currentChart = whichone + 1;
    }
  }



  smallToBig(): void {
    this.grande = !this.grande;
    // this.child.smallToBig();
  }

  public showInfo(): void {
    this.info = !this.info;
  }





  async downloadChart(): Promise<void> {
    // this.mystate.iconoVisible = false;
    await this.createPDF();
  }

  async createPDF(): Promise<void> {
    // console.log(this.MyDOMElement1);
    // this.MyDOMElement.nativeElement.innerHTML = "I am changed by ElementRef & ViewChild";
    let ele: any;
    switch (this.mystate.currentChart) {
      case 1:
        // ele = this.MyDOMElement1.nativeElement;
        this.childConsum.print();
        // console.log('ya imprimi');
        break;
      case 2:
        // ele = this.MyDOMElement2.nativeElement;
        this.childKilo.print();
        break;
      case 3:
        // ele = this.MyDOMElement3.nativeElement;
        this.childAvail.print();
        break;
      case 4:
        // ele = this.MyDOMElement4.nativeElement;
        this.childVelocity.print();
        break;
      case 5:
        // ele = this.MyDOMElement5.nativeElement;
        this.childAvrVel.print();
        break;
      case 6:
        // ele = this.MyDOMElement6.nativeElement;
        this.childStatistics.print();
        break;
      /*       case 7:
              ele = this.MyDOMElement7.nativeElement;
              this.childTopo.print();
              break; */
      case 8:
        // ele = this.MyDOMElement8.nativeElement;
        this.childDiscri.print();
        break;
      case 9:
        // ele = this.MyDOMElement9.nativeElement;
        this.childTime.print();
        break;
      case 10:
        // ele = this.MyDOMElement10.nativeElement;
        this.childTopo.print();
        break;
      case 11:
        // ele = this.MyDOMElement11.nativeElement;
        this.childPower.print();
        break;
      case 12:
        // ele = this.MyDOMElement12.nativeElement;
        this.childEnergy.print();
        break;
      case 19:
      // ele = this.MyDOMElement12.nativeElement;
      //this.comparisionBus.print();
      case 20:

        break;
      default:
        // ele = null;
        break;
    }

  }

  showButtons(): void {
    this.visibleButtons = !this.visibleButtons;
  }

  get collectionsSubs(): any {
    return this.CollectionsSubs;
  }

  set collectionsSubs(x: any) {
    this.CollectionsSubs = x;
  }

  get chartsSubs(): any {
    return this.ChartsSubs;
  }

  set chartsSubs(x: any) {
    this.ChartsSubs = x;
  }

  get routeSubs(): any {
    return this.RouteSubs;
  }

  set routeSubs(x: any) {
    this.RouteSubs = x;
  }

  get iotaSubs(): any {
    return this.IotaSubs;
  }

  set iotaSubs(x: any) {
    this.IotaSubs = x;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.chartsSubs) {
      // console.log('Unsubscribing from charts in destroy');
      this.chartsSubs.unsubscribe();
      this.chartsSubs = undefined;
    }
    if (this.collectionsSubs) {
      // console.log('Unsubscribing from collections in destroy');
      this.collectionsSubs.unsubscribe();
      this.collectionsSubs = undefined;
    }
    if (this.routeSubs) {
      // console.log('Unsubscribing from routes in destroy');
      this.routeSubs.unsubscribe();
      this.routeSubs = undefined;
    }

    if (this.iotaSubs) {
      // console.log('Unsubscribing from routes in destroy');
      this.iotaSubs.unsubscribe();
      this.iotaSubs = undefined;
    }
    // console.log('Charts-navigation component destroyed');
  }
}
