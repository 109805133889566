import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LinearGaugeComponent } from '@syncfusion/ej2-angular-lineargauge';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-gauge-distance',
  templateUrl: './gauge-distance.component.html',
  styleUrls: ['./gauge-distance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeDistanceComponent implements OnInit, OnDestroy {
  public animation: object;
  public axisY: object;
  public axisX: object;
  public pointers5: Object[];
  public itoms: any = [];
  public majorTicks: object;
  public minorTicks: object;
  animations = false;
  margin: { left: number; top: number; right: number; bottom: number; };
  @ViewChild('gaugedis')
  public gauge!: LinearGaugeComponent;
  subscription0: Subscription | undefined;
  marker: object;
  colorScheme: string[];
  allAxis: any = {};
  /* public colorScheme = {
    domain: ['#FF0000', '#0000ff'],
  }; */

  constructor(public state: StateService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.animation = {
      enable: false
    };
    this.margin = { left: 0, top: -20, right: 0, bottom: -50 }
    this.axisY = {
      labelFormat: '{value} km',
      valueType: 'Double',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '0vw',
        color: 'white'
      },

    };

    this.colorScheme = ['#FF0000', '#008000'];
    this.majorTicks = {
      height: 0,
      color: '#ffffff'
      // interval: 0
    };
    this.minorTicks = {
      height: 0,
      color: '#ffffff'
    };
    this.axisX = {
      valueType: 'Category',

      titleStyle: {
        fontFamily: 'Poppins',
        size: '0vw',
        color: '#ffffff'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '13px',
        color: '#ffffff'

      },

      color: '#ffffff'
    };
    this.marker = {
      dataLabel: {
        visible: true, position: 'Outer',
        font: {
          color: 'white',
          fontWeight: 'normal',
          size: '10px',
          fontFamily: 'Poppins'
        }
      }
    };

    this.state.busNames.forEach((c: any, index: number) => {
      this.itoms.push({
        id: this.state.currentCollection + c,
        color: ['#00FFFF', '#FF0000'],

      });
      let maximum: number = 100;
      const returnedTarget: any = Object.assign({}, this.axisY);
      if (this.state.routesAnimationData[this.state.currentCollection + c] !== undefined && this.state.routesAnimationData[this.state.currentCollection + c].autonomy !== undefined) {
        if (this.state.routesAnimationData[this.state.currentCollection + c].type === 'Opportunity') {
          returnedTarget.maximum = parseInt(this.state.routesAnimationData[this.state.currentCollection + c].autonomy[0].toFixed(0)) + 10;
          maximum =  parseInt(this.state.routesAnimationData[this.state.currentCollection + c].autonomy[0].toFixed(0)) + 10;
        } else {
          returnedTarget.maximum = parseInt(this.state.routesAnimationData[this.state.currentCollection + c].totalautonomy) + 10;
          maximum = parseInt(this.state.routesAnimationData[this.state.currentCollection + c].totalautonomy) + 10;
          // console.log('maximum', returnedTarget.maximum);
        }

      }
      this.allAxis[this.state.currentCollection + c] = {
        labelFormat: '{value} km',
        valueType: 'Double',
        titleStyle: {
          fontFamily: 'Poppins',
          size: '0vw'
        },
        labelStyle: {
          fontFamily: 'Poppins',
          size: '0vw',
          color: 'white'
        },
        maximum: maximum,
        minimum: 0,
      }
    });
    this.subscribeConfiguration();
  }

  subscribeConfiguration(): void {
    this.subscription0 = this.state.currentHVACChange.subscribe(
      (value: any) => {
        // console.log('updating gauhe dis');
        /* this.items = [];
        this.state.busNames.forEach((c: any, index: number) => {
          this.items.push({
            id: this.state.currentCollection + c,
            socGaugeMax: this.state.socGaugeMax,
            color: '#ff0000',
            maximum: 800 * (index + 1),
            current: this.state.socGaugeValue[this.state.currentCollection + c],
            ending: 900
          });
        }); */
        this.changeDetector.detectChanges();
        this.gauge.refresh();
        // this.items = [...this.items];
      }
    );
  }



  get laSubs(): any {
    return this.subscription0;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    // console.log('unsuscribing to type');
    if (this.laSubs)
      this.laSubs.unsubscribe();

  }

}
