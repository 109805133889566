<div id="mapita" class="match-parent"></div>

  <mat-icon [ngClass]="styleNormal ? 'icono' : 'icono2'" inline="true" [matTooltip]="'Cambiar vista'"
    matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'" *ngIf="!state.isAnimationON"
    (click)="satelliteClick()">
    satellite</mat-icon>

  <mat-icon [ngClass]="styleNormal ? 'icono' : 'icono2'" [matTooltip]="'Cambiar vista'" matTooltipClass="custom-tooltip"
    [matTooltipPosition]="'right'" *ngIf="!state.isAnimationON" (click)="satelliteClick()" [svgIcon]="whichicon">
  </mat-icon>

  <button *ngIf="this.state.enable_info_bus==true" (click)="showInfoBus()" [ngClass]="{'circular-button':this.state.show_info_bus==false,'circular-button-white':this.state.show_info_bus==true}">
    <span class="sr-only"></span>
  </button>
<!--<button class="bototranslate" (click)="cambiarIdioma()">
  <div> <img src={{this.dataTimg.iconimagen[this.state.indiceTranslate]}} style="width: 25px;height: 25px;" alt=""> </div>
  <div>{{this.idioma}}</div>
</button>-->