<p>comparison-power works!</p>
<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">
    <ejs-chart style='display:block' [primaryXAxis]='axisx' [primaryYAxis]="axisy"
    [height]="alto" (window:resize)="onResize($event)"
     [zoomSettings]="zoom"[tooltip]="tooltip" 
    [annotations]="annotations" [crosshair]="crosshair">
        <e-series-collection>
            <!--<e-series [dataSource]='datos' type='Line' xName='x' yName='y'legendShape="Rectangle"> </e-series>-->
            <e-series *ngFor="let potencia of this.listaPotencia; index as i" [dataSource]="this.listaDatosplot[i]" type="Line" xName="axisx" yName="axisy"
            [name]="potencia" legendShape='Rectangle'>
          </e-series>
        </e-series-collection>
    </ejs-chart>
</div>