import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'; import { AccumulationTheme, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import panelData from './panels-data';
import { ILoadedEventArgs, ChartComponent, ChartTheme } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';


//Consumo de Energias y Numero de Cargadores.
//

@Component({
  selector: 'app-dashboardsync',
  templateUrl: './dashboardsync.component.html',
  styleUrls: ['./dashboardsync.component.scss']
})
export class DashboardsyncComponent {

  @ViewChild('default_dashboard')
  public dashboard: DashboardLayoutComponent;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chartcontainer')
  public chart!: ChartComponent;
  @ViewChild('chartcontainer1')
  public chart1!: ChartComponent;
  @ViewChild('chartcontainer2')
  public chart2!: ChartComponent;
  @ViewChild('chartcontainer3')
  public chart3!: ChartComponent;
  @ViewChild('chartcontainer4')
  public chart4!: ChartComponent;

  constructor(private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) {

  }
  public dataTRm = this.state.dictTranslate.DashboardsGraph.resumen;
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#800000'],
  };
  tipobateria = ''
  maxpasajeros = ''
  currentBateria:any
  public allowDragging: boolean = false;
  public count: number = 8;
  public cellSpacing: number[] = [5, 5];
  public ciudadTable: any
  public totalenergydayconsumption;
  public totalstationcharger;
  public totalsumenergyconsumption;
  public totalconectors;
  public totalenergianominal

  public maxvaluepowerdemand;
  public listaFiltrada = [];
  public listaExtendida;
  public listaEstaciones = [];
  public prueba = 0
  public numero = 1
  public listaDatosEstacionesIndependiente = []
  ancho = '';
  alto = '';
  public soloUnPatio;
  public patioUnico;
  public operacionValor;
  public flota;
  //
  public primaryXAxis: Object;
  public title: string;
  public primaryYAxis: Object;
  public data: Object[];
  public converEndAuto: any
  //
  public dataPotencia = []
  public dataEnergia = []
  public dataConectores = []
  public dataBus = []
  public dataParadas = []
  public marker: Object;
  public tooltip: Object;
  public tooltipkw: Object;
  public tooltipkwh: Object;
  public cargado = false;
  public nuevaRuta;
  public cargaMetodo;
  public ciclos;
  public axisx = {
    valueType: 'Category',
    labelRotation: -45,
    labelFormat: 'n1',
    title: this.dataTRm.infografico2.ejex[this.state.indiceTranslate],//'Servicio',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },

  };
  public estaCompleto = true
  // panel solar
  potenciaInstalada =0
  generacion=0
  superficie=0
  // tokens
  COdiabus=0
  COdiaflota=0
  COaniobus=0
  COanioflota=0
  tokenbus=0
  tokenflota=0

  datosEstacionesxFiltro(arreglo) {
    console.log("datos estaciones filtro", arreglo)
    var listestaciones = arreglo[0].chargingsitesname
    console.log("lista de estaciones previo - dashboard", listestaciones)
    this.listaEstaciones = listestaciones
    var datos;
    for (let i = 0; i < listestaciones.length; i++) {
      datos = {
        'energyconsumida': arreglo[0].gridenergyconsumption[i],
        'maxenergiademandada': arreglo[0].maxpowerdemand[i],
        'cargadores': arreglo[0].chargersnumber[i],
        'conectores': arreglo[0].chargerconnectors[i],
        'energianominal': arreglo[0].chargernominalpower[i]
      }
      this.listaDatosEstacionesIndependiente.push(datos)
      datos = {}
    }
    console.log("lista de estaciones - dashboard", this.listaEstaciones)
    console.log("lista datos estaciones independientes - dashboard", this.listaDatosEstacionesIndependiente)
  }

  filterData(arreglo) {
    const currentSeleccion = this.state.currentCollection + '_' + this.state.currentRoute + '_' + this.state.currentType + '_' + this.state.currentBusSize + 'm_' + this.state.currentBattery + 'kWh_' + this.state.currentHVAC + '_pass' + this.state.currentPax
    console.log("Ruta - dashboard", currentSeleccion, this.state.currentType, this.state.currentBusSize)
    this.listaFiltrada = arreglo.filter(word => word.portfolioid.includes(currentSeleccion));
    console.log("Lista Filtrada - dashboard", this.listaFiltrada)
    this.ciclos = this.listaFiltrada[0].ciclos
  }
  sumCharStation(arreglo) {
    var valoresestaciones = arreglo[0].chargersnumber
    console.log("Estaciones de Carga - dashboard", valoresestaciones)
    var resultado = valoresestaciones.reduce((valoranterior, valoractual) => valoranterior + valoractual, 0)
    return resultado;
  }
  sumEnergyConsumption(arreglo) {
    var resultado = arreglo.reduce((valoranterior, valoractual) => valoranterior + valoractual.totalgridenergyconsumption, 0)
    console.log("Total de Energia Consumida", resultado)
    return resultado
  }
  maxPowerDemand(arreglo) {
    var listamaxpower = arreglo[0].maxpowerdemand
    var maxvaluepower = Math.max(...listamaxpower)
    console.log("Maximo valor de energia - dashboard", maxvaluepower)
    return maxvaluepower;
  }
  creacionDatosGeneral() {
    this.maxvaluepowerdemand = this.maxPowerDemand(this.listaFiltrada);
    this.totalsumenergyconsumption = this.sumEnergyConsumption(this.listaFiltrada)
    this.totalstationcharger = this.sumCharStation(this.listaFiltrada)
    this.listaEstaciones.push('General')
    var datos = {
      'energyconsumida': this.totalsumenergyconsumption,
      'maxenergiademandada': this.maxvaluepowerdemand,
      'cargadores': this.totalstationcharger
    }
    this.listaDatosEstacionesIndependiente.push(datos)
  }
  creacionListaExtendida() {
    this.datosEstacionesxFiltro(this.listaFiltrada)
  }
  resetear() {
    this.maxvaluepowerdemand = 0;
    this.totalsumenergyconsumption = 0;
    this.totalstationcharger = 0;
    this.totalconectors = 0;
    this.totalenergianominal = 0;
    this.listaEstaciones = [];
    this.listaFiltrada = [];
  }

  ngOnInit() {
    console.log(this.state.endAuto, typeof (this.state.endAuto))
    this.converEndAuto = parseFloat(this.state.endAuto)
    this.estaCompleto= this.state.listaEstadosDashboard["resumen"]
    //
    this.potenciaInstalada = this.state.listaDatosEnergiaRenovable["potenciainstalada"].toFixed(1)
    this.generacion = this.state.listaDatosEnergiaRenovable["generacion"].toFixed(1)
    this.superficie = this.state.listaDatosEnergiaRenovable["superficie"].toFixed(1)

    this.primaryXAxis = {
      title: 'Potencias',
      valueType: 'Category',
      labelFormat: '{value}kW',
      //labelPlacement: 'betweenTicks',
      //labelPosition:'inside',
      //opposedPosition: true,
      //startAngle:85,
      //titleRotation:90
      labelPadding: 1,

    };
    this.primaryYAxis = {
    };
    this.tooltip = { enable: true, };
    this.tooltipkw = { enable: true, format: '${point.x}: ${point.y} kW ' }
    this.tooltipkwh = { enable: true, format: '${point.x}: ${point.y} kWh ' }
    this.marker = { visible: true, width: 10, height: 10 };
    var vhpropertie = this.dataSer.allVehicleProperties
    console.log(vhpropertie)
    this.tipobateria = vhpropertie[this.state.currentCollection + '_' + this.state.currentRoute]["batterytype"]
    this.maxpasajeros = vhpropertie[this.state.currentCollection + '_' + this.state.currentRoute]["passengers"]
    this.currentBateria = vhpropertie[this.state.currentCollection + '_' + this.state.currentRoute]["batteryenergy"]
    //
    this.resetear();
    console.log("Cargando Dashboard")
    console.log("Dashboard ", this.dataSer.listPortfolio)
    //buses Analizados
    this.filterData(this.dataSer.listPortfolio)
    this.creacionListaExtendida()
    this.revisarUnPatio();
    this.getBuses();
    this.getOperacion();
    this.getMetodoCarga();
    this.CargaDatosPolar();
    this.calcularCAT()
    setTimeout(() => {

      this.cargado = true;
    }, 1000);
    //this.creacionDatosGeneral()
  }
  getMetodoCarga() {
    if (this.state.metodoCarga == "Oportunidad") {
      if (this.state.indiceTranslate == 0) {
        this.cargaMetodo = "Pantógrafo"
      } else {
        this.cargaMetodo = "Pantograph"
      }
    } else {
      if (this.state.indiceTranslate == 0) {
        this.cargaMetodo = "Conector"
      } else {
        this.cargaMetodo = "Connector"
      }
    }
  }
  CargaDatosPolar() {
    for (let i = 0; i < this.state.listaPuntosCarga.length; i++) {
      const element = this.state.listaPuntosCarga[i];
      this.dataParadas.push(element)
      var seleccion = this.state.listaResumen[element]
      console.log("seleccion polar", seleccion)
      this.dataPotencia.push(seleccion["Potencia"].map(obj => {
        return {
          x: obj.x,
          y: parseFloat(obj.y.toFixed(0))
        };
      }))
      this.dataEnergia.push(seleccion["Energia"].map(obj => {
        return {
          x: obj.x,
          y: parseFloat(obj.y.toFixed(0))
        };
      }))
      if (this.state.metodoCarga == 'Nocturno') {
        this.dataConectores.push(seleccion["Conectores"])
      } else {
        this.dataConectores.push(seleccion["ConectoresOpp"])
      }
      this.dataBus.push(seleccion["Cargadores"])

    }
    console.log("polar datos", this.state.listaPuntosCarga)
    console.log("polar datos", this.dataParadas)
    console.log("polar datos", this.dataPotencia)
    console.log("polar datos", this.dataEnergia)
    console.log("polar datos", this.dataConectores)
    console.log("polar datos", this.dataBus)
  }
  AfterViewInit() {
    this.chart.renderSeries();
    this.chart1.renderSeries();
    this.chart2.renderSeries();
    this.chart3.renderSeries();
    this.chart4.renderSeries();
  }
  getBuses() {
    this.flota = this.listaFiltrada[0].analyzedbusesnumber
  }
  getOperacion() {
    var ruta = this.listaFiltrada[0].routeid
    if (ruta.length <= 3) {
      this.nuevaRuta = ruta;
      this.operacionValor = 1;
    } else {
      var lastChar = ruta.substr(ruta.length - 1);
      var firstthree = ruta.slice(0, 3);
      this.nuevaRuta = this.state.currentOnlyRoute.replace(/([A-Z])/g, ' $1').trim();;


      if (this.state.activeGlobal) {

        this.operacionValor = 'Global'
      } else {
        this.operacionValor = this.state.currentOperacion.substr(2, 1) + ' ' + this.dataTRm.info1.servicio[this.state.indiceTranslate]
        if (this.state.currentCollection == 'limagrupopolo') {
          this.operacionValor = parseInt(lastChar) + ' ' + this.dataTRm.info1.opmedicion[this.state.indiceTranslate]
        }
      }
    }
  }
  revisarUnPatio() {
    if (this.listaEstaciones.length == 1) {
      this.soloUnPatio = true;
      this.patioUnico = this.listaEstaciones[0]
      var datosnuevos = this.listaDatosEstacionesIndependiente[0]
      this.maxvaluepowerdemand = datosnuevos.maxenergiademandada
      this.totalsumenergyconsumption = datosnuevos.energyconsumida
      this.totalstationcharger = datosnuevos.cargadores
      this.totalconectors = datosnuevos.conectores
      this.totalenergianominal = datosnuevos.energianominal
    } else {
      this.soloUnPatio = false;
    }
  }
  onChange(arg): void {
    console.log("arg de dropbottom - dashboard", arg.value)
    var index = this.listaEstaciones.indexOf(arg.value);
    console.log("Index de lista estaciones - dashboard", index)
    console.log("Index de lista estaciones - dashboard", this.listaDatosEstacionesIndependiente[index])
    var datosnuevos = this.listaDatosEstacionesIndependiente[index]
    this.maxvaluepowerdemand = datosnuevos.maxenergiademandada
    this.totalsumenergyconsumption = datosnuevos.energyconsumida
    this.totalstationcharger = datosnuevos.cargadores
    this.totalconectors = datosnuevos.conectores
    this.totalenergianominal = datosnuevos.energianominal
  }

  onResize(event: any): void {

    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
  calcularCAT(){
/**COdiabus
COdiaflota
COaniobus
COanioflota
tokenbus
tokenflota**/
var factor =0.8
this.converEndAuto
this.COdiabus = this.converEndAuto*factor/1000
this.COdiaflota = (this.converEndAuto*factor/1000)*this.flota
this.COaniobus = this.COdiabus*300
this.COanioflota = this.COdiaflota*300
this.tokenbus = Math.floor(this.COaniobus * 10)
this.tokenflota = Math.floor(this.COanioflota * 10)
  }
}
