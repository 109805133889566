import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-charging-time',
  templateUrl: './charging-time.component.html',
  styleUrls: ['./charging-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChargingTimeComponent implements OnInit, AfterViewInit, OnChanges {
  public chartArea: Object = {
    border: {
        width: 0
    }
};
public placement: boolean = false;
  multi: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  public title!: string;
  public axisX!: object;
  public axisY!: object;
  public tooltip!: object;
  ancho = '';
  alto = '';

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Servicio';
  yAxisLabel = 'Consumo (kWh/km)';
  timeline = false;
  legendTitle = '';
  autoScale = true;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  datosgrafico = [];
  listcargadores = []

  colorScheme = {
    domain: ['#88898C', '#000096'],
  };

  @ViewChild('myDOMElementCK')
  MyDOMElementCK!: ElementRef;
  icono: boolean = true;

  @ViewChild('chart2')
  public chart!: ChartComponent;

  @Input() grande: boolean;

  constructor(
    private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef
  ) {
    /* this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + ''; */
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  async ngOnInit(): Promise<void> {
    this.datosgrafico = this.state.THEDATA[13][0].todoslostiemposdecarga
    this.listcargadores = this.state.THEDATA[13][0].loscargadores
    console.log("chargers accum ", this.datosgrafico, this.listcargadores)
    this.tooltip = { enable: true };
    this.axisX =
    {
      valueType: 'DateTime',
      intervalType: 'Hours',
      interval: 4,
      title: 'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      // rangePadding: 'Auto',
      labelFormat: 'HH:mm',
      //minimum: 0
      lineBreakAlignment: 'Center' 
    };
    this.axisY = {
      // interval: 10,
      title: 'Tiempo de carga (minutos)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: '{value}',
      minimum: 0,
      rangePadding: 'Auto',
    };
  }

  print() {
    this.chart.print();
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    // this.state.selectedPoint =
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
