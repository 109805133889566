<div #myDOMElement fxLayout="row">
  <ejs-chart *ngIf="graphicSelected == 1" id="chart-container" [primaryXAxis]="state.axis8[0]" [primaryYAxis]="state.axis8[1]" [height]='alto'
    (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
    (pointClick)="pointClick($event)" [animations]="animations" #chart9>
    <e-series-collection>
      <e-series *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as i;"
        [dataSource]="state.THEDATA[8][3].datos[busname]" type="Line" xName="name" yName="value"
        [name]="'100% SOH '+busname" legendShape='Rectangle' [animation]="animation"></e-series>
        <e-series [dataSource]="state.THEDATA[8][2].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series>
    </e-series-collection>
  </ejs-chart>
  <ejs-chart *ngIf="graphicSelected == 2" id="chart-container" [primaryXAxis]="state.axis8[0]" [primaryYAxis]="state.axis8[1]" [height]='alto'
  (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
  (pointClick)="pointClick($event)" [animations]="animations" #chart9>
  <e-series-collection>
    <e-series *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as j;"
      [dataSource]="state.THEDATA[8][4].datos[busname]" type="Line" xName="name" yName="value"
      [name]="'FDV '+busname" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[8][2].datos" type="Line" xName="name" yName="value"
      [name]="state.THEDATA[8][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series>
  </e-series-collection>
</ejs-chart>
</div>
