import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent, IAccTooltipRenderEventArgs, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-chargers-accum',
  templateUrl: './chargers-accum.component.html',
  styleUrls: ['./chargers-accum.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargersAccumComponent implements OnInit, OnChanges, OnDestroy {
  cual = 1;
  multi: any = [];
  initMulti: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  datosaccum: any = [];

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#008000', '#FF0000'],
  };

  ancho = '';
  alto = '';
  icono = true;


  axisInferior = [
    'Potencia (kW)',
    'Energía (kWh)',
  ];
  uno: any;
  dos: any;


  public primaryXAxis: object = {};
  public primaryXAxis1: object = {};
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;
  public animation: object = { enable: false };
  public majorGridLines: Object;
  public lineStyle: Object;
  public ontooltipRender: any;
  public legendSettings: any;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @Input() grande: boolean;

  @ViewChild('chartaccum1')
  public charta!: ChartComponent;
  @ViewChild('chartaccum2')
  public chartb!: ChartComponent;

  changeAnimation: Subscription | undefined;
  changeData: Subscription | undefined;

  constructor(
    // private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + '';
    // this.state.currentChart = 11;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  print() {
    if (this.cual === 1) {
      this.charta.print();
    } else {
      ;
    }
  }

  async ngOnInit(): Promise<void> {
    console.log('data 9 3',this.state.THEDATA[9][3]);
    console.log('data 9 4', this.state.THEDATA[9][4]);
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.primaryYAxis = {
      labelFormat: '{value}',
      title: 'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
    };
    // const hoy = new Date();
    this.primaryXAxis = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      title: 'Hora del día',
      // rangePadding: 'Auto',
      interval: 4,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: 'HH:mm'
    };
    this.primaryXAxis1 = {
      title: 'Hora del día',
      // rangePadding: 'Auto',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: '{value}',
      valueType: 'Category',
    };
    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 0 };
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
    this.marker = { visible: true };
    this.subscribeConfiguration();
    //this.state.currentStats = 1;
    // console.log('Termine ongin: ', this.state.THEDATA[9][this.state.selectedCharger]);

    // this.buildAccumData();
    this.legendSettings = {

      position: 'Custom',
      location: { x: 70, y: 5 },
    };
    this.ontooltipRender = this.elrendertooltop;
    // console.log('state.thedata[5]: ', this.state.THEDATA[5]);
  }

  elrendertooltop = (args: IAccTooltipRenderEventArgs): void => {
    const losdatos = this.state.THEDATA[5].find((c: any) => {
      return c.ruta === args.point.x;
    });
    /* console.log('losdatos: ', losdatos);
    console.log('args.point.y: ', args.point); */
    // This is temporary as it only works when the first, second and third value are different
    if (losdatos.value2 === args.point.y){
      args.text = args.point.x + '' + ':' + (args.point.y + losdatos.value1)+ '';
    }
    if (losdatos.value3 === args.point.y){
      args.text = args.point.x + '' + ':' + (args.point.y + losdatos.value1 + losdatos.value2)+ '';
    }

  };

  subscribeConfiguration(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        this.state.THEDATA[9] = [...this.state.THEDATA[9]];
        this.changeDetector.detectChanges();
        // this.chart.refresh();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  getData(whichOne: number): void {
    // console.log('my data en power time', this.state.currentRoute, ' whichone: ', whichOne);
    if (whichOne === -1) {
      whichOne = this.cual;
    }
    if (whichOne === 2 || whichOne === 3){
      this.primaryYAxis['title'] = 'Energía (kWh)';
    } else {
      this.primaryYAxis['title'] = 'Energía (kWh)';
    }
    this.cual = whichOne;
    this.state.currentStats = whichOne;
    this.changeDetector.detectChanges();
  }


  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
    if (this.changeData) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeData.unsubscribe();
      this.changeData = undefined;
    }
    // console.log('MAPS component destroyed');
  }
}
