import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  windowWidth = '';
  showSplash = true;

  name = 'Angular';
  @ViewChild('videoPlayer', { static: false })
  videoplayer!: ElementRef;
  // isPlay = true;

  ngOnInit(): void {
    // this.showSplash = !this.showSplash;
    setTimeout(() => {
      this.windowWidth = '-' + window.innerWidth + 'px';

      setTimeout(() => {
        this.showSplash = !this.showSplash;
      }, 500);
    }, 700);
  }
}
