import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auxiliar_components/access/login/login.component';
import { RegisterComponent } from './auxiliar_components/access/register/register.component';
import { AnimationComponent } from './auxiliar_components/animation/animation.component';
import { RouteGuardService } from './shared/guards/route-guard.service';
import { UserInfoComponent } from './auxiliar_components/access/user-info/user-info.component';
import { UserListComponent } from './auxiliar_components/access/user-list/user-list.component';
import { EditUserComponent } from './auxiliar_components/access/edit-user/edit-user.component';
import { CollectionsListComponent } from './auxiliar_components/collections/collections-list/collections-list.component';
import { EditCollectionComponent } from './auxiliar_components/collections/edit-collection/edit-collection.component';
import { RecoverPasswordComponent } from './auxiliar_components/access/recover-password/recover-password.component';
import { ChangePasswordComponent } from './auxiliar_components/access/change-password/change-password.component';
import { RegisterCollectionComponent } from './auxiliar_components/access/register-collection/register-collection.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  {
    path: 'register',
  // canActivate: [RouteGuardService],
    component: RegisterComponent
  },
  {
    path: 'recover',
  // canActivate: [RouteGuardService],
    component: RecoverPasswordComponent
  },
  {
    path: 'changepassword/:uid/:token',
  // canActivate: [RouteGuardService],
    component: ChangePasswordComponent
  },
  {
    path: 'user-info',
    canActivate: [RouteGuardService],
    component: UserInfoComponent
  },
  {
    path: 'user-list',
    canActivate: [RouteGuardService],
    component: UserListComponent
  },
  { path: 'edit-user/:id',
    canActivate: [RouteGuardService],
    component: EditUserComponent
  },
  { path: 'collections-list',
    canActivate: [RouteGuardService],
    component: CollectionsListComponent
  },
  { path: 'edit-collection/:id',
    canActivate: [RouteGuardService],
    component: EditCollectionComponent
  },
  {
    path: 'animation',
    canActivate: [RouteGuardService],
    component: AnimationComponent
  },
  {
    path: 'register-collection',
    canActivate: [RouteGuardService],
    component: RegisterCollectionComponent
  },
  {
    path: 'visualization',
    loadChildren: () => import('./visualization/visualization.module').then(m => m.VisualizationModule)
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
