import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-single-bus-view',
  templateUrl: './single-bus-view.component.html',
  styleUrls: ['./single-bus-view.component.scss']
})
export class SingleBusViewComponent implements OnInit {

  public animation: object;
  public pointers5: Object[];
  public items: any = [];
  public container: Object;
  public majorTicks: object;
  public minorTicks: object;
  public margin: Object;
  public line: Object;
  public label: Object;
  constructor(public state: StateService) { }

  ngOnInit(): void {
    this.animation = {
      enable: false
    };
    this.majorTicks = {
      height: 10,
      // interval: 0
    };
    this.minorTicks = {
      height: 5
    };
    this.pointers5 = [
      {
        radius: "100%",
        animation: { enable: false },
        value: this.state.socGaugeValue,
        pointerWidth: 6,
        cap: { radius: 7 },
        needleTail: { length: "7%", color: "#923C99" }
      }
    ];
    this.container = {
      width: 50,
      type: "RoundedRectangle",
      backgroundColor: "#ff0000"
    };
    this.line = {
      // height: 150,
      width: 2,
      color: '#4286f4',
      offset: 2
    };
    this.label = {
      position: "Inside"
    };
    this.margin = { left: 20, top: -20, right: 20, bottom: -40 }


    this.items.push({
      id: this.state.currentCollection + this.state.showSingleBusAnimation,
      color: '#ff0000',
      maximum: 500,
    });
    // this.state.animationAlerts[this.state.currentCollection + this.state.showSingleBusAnimation]
  }

  removeAlert(whichone: number, id: string){
    this.state.animationAlerts[id].descriptions[whichone] = ['OK', '', 'limegreen'];
  }

}
