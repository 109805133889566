
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-stops-chargers',
  templateUrl: './stops-chargers.component.html',
  styleUrls: ['./stops-chargers.component.scss']
})
export class StopsChargersComponent implements OnInit {
  routeColor: any = [];
  routeBackground: any = [];
  thecols = 0;
  previousRoute = -1;
  cellBackground: any = [];
  cellColor: any = [];
  previouscells = [-1, -1];
  currentOptimalStops = [-1, -1];
  howmanystopsselected = 0;
  currentgetChargingSitesData: any = [];
  prevsecondselection = -1;
  opcs: any = [];

  @Input() grande: boolean;

  constructor(public mystate: StateService, public data: DataService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    public auth: AuthService) { }

  ngOnInit(): void {
    this.routeColor = new Array(this.mystate.busNames.length);
    this.routeColor.fill('white');
    this.routeBackground = new Array(this.mystate.busNames.length);
    this.routeBackground.fill('#000096');

    this.thecols = 3;
    if (this.mystate.currentRoute !== 'None' && this.mystate.currentRoute !== '') {
      this.mystate.busNames.forEach((c: any, index: number) => {
        if (c === this.mystate.currentRoute) {
          this.routeColor[index] = '#000096';
          this.routeBackground[index] = 'white';
          this.previousRoute = index;
        }
      });
    }

    // console.log('this.mystate.routeStops: ', this.mystate.routeStops);
  }

  async selectStop(index: number): Promise<void> {

    if (this.auth.currentBusConfigurations[this.mystate.currentCollection + this.mystate.currentRoute] !== undefined) {
      const thedata = this.auth.currentBusConfigurations[this.mystate.currentCollection + this.mystate.currentRoute];
      const params = {
        type: thedata.type,
        bus: thedata.route,
        load: thedata.hvac,
        pax: thedata.pax,
        size: thedata.size,
        battery: thedata.battery,
        currentCollection: this.mystate.currentCollection
      };

      if (this.howmanystopsselected === 0) {
        this.prevsecondselection = -1;
        if (this.previouscells !== [-1, -1]) {
          this.cellBackground[this.previouscells[0]] = '#000096';
          this.cellBackground[this.previouscells[1]] = '#000096';
        }
        this.cellBackground[this.currentOptimalStops[0] - 1] = 'white';
        this.cellBackground[this.currentOptimalStops[1] - 1] = 'white';
        this.cellColor[this.currentOptimalStops[0] - 1] = '#000096';
        this.cellColor[this.currentOptimalStops[0] - 1] = '#000096';
        this.currentgetChargingSitesData = await this.data.getChargingSitesOneStop(params, index);
        this.cellBackground[index - 1] = '#28FF3B';
        this.cellBackground[this.currentgetChargingSitesData[0].optimalchargingcomplement - 1] = 'yellow';
        this.previouscells = [index - 1, this.currentgetChargingSitesData[0].optimalchargingcomplement - 1];
        this.howmanystopsselected++;
      } else if (this.howmanystopsselected === 1) {
        if (this.previouscells[0] === index - 1) {
          return;
        }
        if (this.prevsecondselection === -1) {
          this.prevsecondselection = index;
          this.cellBackground[index - 1] = '#28FF3B';
          this.mystate.showCharginSiteChart = true;

        } else {
          if (this.prevsecondselection !== index) {
            this.cellBackground[this.prevsecondselection - 1] = '#000096';
            this.repaintopcs();
            this.cellBackground[index - 1] = '#28FF3B';
            if (this.prevsecondselection === this.currentgetChargingSitesData[0].optimalchargingcomplement){
              this.cellBackground[this.currentgetChargingSitesData[0].optimalchargingcomplement - 1] = 'yellow';
            }
            this.prevsecondselection = index;

          } else {
            this.cellBackground[this.prevsecondselection - 1] = '#000096';
            this.prevsecondselection = -1;
            this.mystate.showCharginSiteChart = false;
            this.cellBackground[this.opcs[0] - 1] = 'white';
            this.cellBackground[this.opcs[1] - 1] = 'white';
            this.cellColor[this.opcs[0] - 1] = '#000096';
            this.cellColor[this.opcs[1] - 1] = '#000096';
            return;
          }

        }
        //Get data for this.previoscels[0] and index
        const query = this.mystate.currentCollection + '_' + params.bus + '_' + params.type +
          '_' + params.size + 'm_' + params.battery + 'kWh_' + (this.previouscells[0] + 1) + '_' + (index);
        // console.log('the query for new chart is ', query);
        const elfiltrado = this.currentgetChargingSitesData.filter((cur: any) => {
          return cur.chargingsitelocationid === query;
        });
        // console.log('Los datos buscados son: ', elfiltrado);
        const grafica = [];
        elfiltrado[0].stopnumber.forEach((cur: any, eli: number) => {
          grafica.push({
            stop: cur,
            type: elfiltrado[0].energyavailableperstop[eli]
          });
        });

        const puntos = [];
        puntos.push({
          x: elfiltrado[0].stopnumber[this.previouscells[0] + 1],
          y: elfiltrado[0].energyavailbeforecharging[0],
          size: 0.1
        });
        puntos.push({
          x: elfiltrado[0].stopnumber[index - 1],
          y: elfiltrado[0].energyavailbeforecharging[1],
          size: 0.1
        });


        const elsoc = elfiltrado[0].socminbat.map((cur: any, eli: number) => {
          return {
            x: elfiltrado[0].stopnumber[eli],
            y: cur
          };
        });

        // console.log('grafica: ', grafica);
        this.mystate.THEDATA[11] = [grafica, puntos, elsoc];
      }
    }
  }

  clearSelectedStops() {

    if (this.howmanystopsselected !== 0) {
      this.cellBackground[this.previouscells[0]] = '#000096';
      this.cellBackground[this.previouscells[1]] = '#000096';
      if (this.prevsecondselection !== -1) {
        this.cellBackground[this.prevsecondselection - 1] = '#000096';
      }
    }

    this.repaintopcs();
    this.howmanystopsselected = 0;
    this.prevsecondselection = -1;
    this.mystate.showCharginSiteChart = false;
  }

  repaintopcs() {
    this.cellBackground[this.opcs[0] - 1] = 'white';
    this.cellBackground[this.opcs[1] - 1] = 'white';
    this.cellColor[this.opcs[0] - 1] = '#000096';
    this.cellColor[this.opcs[1] - 1] = '#000096';
  }

  async setCurrentRoute(route: string, pos: number): Promise<void> {
    // this.mystate.currentRoute = route;

    if (this.mystate.currentRoute === route) {
      this.routeColor[pos] = 'white';
      this.routeBackground[pos] = '#000096';
      this.mystate.currentRoute = 'None';
      // return;
    } else if (this.previousRoute !== -1) {
      this.routeColor[this.previousRoute] = 'white';
      this.routeBackground[this.previousRoute] = '#000096';
      this.mystate.currentRoute = route;

      this.routeColor[pos] = '#000096';
      this.routeBackground[pos] = 'white';
      this.previousRoute = pos;
      /* const aleatorio = Math.floor(Math.random() * 200);
      this.mystate.routeStops = Array.from({ length: aleatorio }, (_, i) => i + 1); */
    } else {
      this.mystate.currentRoute = route;
      this.routeColor[pos] = '#000096';
      this.routeBackground[pos] = 'white';
      this.previousRoute = pos;

      /* const aleatorio = Math.floor(Math.random() * 200);
      this.mystate.routeStops = Array.from({ length: aleatorio }, (_, i) => i + 1); */
    }

    if (this.auth.currentBusConfigurations[this.mystate.currentCollection + route] !== undefined) {
      const thedata = this.auth.currentBusConfigurations[this.mystate.currentCollection + route];
      const newparams = {
        type: thedata.type,
        bus: thedata.route,
        load: thedata.hvac,
        pax: thedata.pax,
        size: thedata.size,
        battery: thedata.battery,
        currentCollection: this.mystate.currentCollection
      };
      this.opcs = await this.data.getChargingSitesFirst(newparams);
      // console.log('optimalchargingsites: ', opcs);
      // console.log('numberstop : ', this.data.allRouteProperties[route].numberstop);
      this.mystate.routeStops = this.data.allRouteProperties[route].numberstop;
      this.cellBackground = new Array(this.mystate.routeStops.length);
      this.cellBackground.fill('#000096');
      this.cellColor = new Array(this.mystate.routeStops.length);
      this.cellColor.fill('white');

      this.cellBackground[this.opcs[0] - 1] = 'white';
      this.cellBackground[this.opcs[1] - 1] = 'white';
      this.cellColor[this.opcs[0] - 1] = '#000096';
      this.cellColor[this.opcs[1] - 1] = '#000096';

      this.currentOptimalStops = this.opcs;
    } else {
      this.mystate.routeStops = [];
    }


  }

  hoverParaRuta(entrada: any) {
    //console.log('Haciendo hover en ', entrada);
    this.mystate.changeRoute(entrada);
  }

  hoverOutParaRuta(entrada: any) {
    //console.log('Haciendo hover en ', entrada);
    this.mystate.changeRoute('EndHover');
  }

}
