import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './auxiliar_components/material/material.module';
import { LayoutComponent } from './auxiliar_components/layout/layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { WelcomeComponent } from './auxiliar_components/welcome/welcome.component';
// import { RoutingModule } from './routing/routing.module';
import { SidenavListComponent } from './auxiliar_components/navigation/sidenav-list/sidenav-list.component';

import { AuthService } from './shared/auth/auth-service.service';
import { RouteGuardService } from './shared/guards/route-guard.service';
import { DataService } from './shared/data/data-service.service';
import { CollectionsService } from './shared/collections/collections-service.service';


import { LoginComponent } from './auxiliar_components/access/login/login.component';
import { RegisterComponent } from './auxiliar_components/access/register/register.component';
import { SplashScreenComponent } from './auxiliar_components/splash-screen/splash-screen.component';
import { AnimationComponent } from './auxiliar_components/animation/animation.component';
import { VisualizationModule } from './visualization/visualization.module';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StateService } from './shared/state/state.service';
// import { OverlayModule } from '@angular/cdk/overlay';
import { LateralBarComponent } from './auxiliar_components/lateral-bar/lateral-bar.component';
import { UserInfoComponent } from './auxiliar_components/access/user-info/user-info.component';
import { UserListComponent } from './auxiliar_components/access/user-list/user-list.component';
import { EditUserComponent } from './auxiliar_components/access/edit-user/edit-user.component';
import { CollectionsListComponent } from './auxiliar_components/collections/collections-list/collections-list.component';
import { EditCollectionComponent } from './auxiliar_components/collections/edit-collection/edit-collection.component';
import { LocalstorageService } from './shared/storage/localstorage.service';
import { RecoverPasswordComponent } from './auxiliar_components/access/recover-password/recover-password.component';
import { ChangePasswordComponent } from './auxiliar_components/access/change-password/change-password.component';
import { DexieService } from './shared/dexie/dexie.service';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { LinearGaugeModule } from '@syncfusion/ej2-angular-lineargauge';
import { CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { EditService, GridModule } from '@syncfusion/ej2-angular-grids';
import { DialogModule } from '@syncfusion/ej2-angular-popups';



import { DetailRowService, GroupService, SortService, PageService, FilterService, ToolbarService, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';


import {
  CategoryService, DateTimeService, ScrollBarService, ColumnSeriesService, LineSeriesService,MultiColoredLineSeriesService,
  ChartAnnotationService, RangeColumnSeriesService, StackingColumnSeriesService, LegendService, TooltipService,PolarSeriesService,
  SplineAreaSeriesService
} from '@syncfusion/ej2-angular-charts';
import { MapsComponent } from './visualization/maps/maps.component';
import { SidenavRutasComponent } from './auxiliar_components/navigation/sidenav-rutas/sidenav-rutas.component';
import { RegisterCollectionComponent } from './auxiliar_components/access/register-collection/register-collection.component';

@NgModule({
  imports: [
    BrowserModule,
    DialogModule,
    //MapsComponent,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    DashboardLayoutModule,
    MaterialModule,
    // RoutingModule,
    DropDownListAllModule,
    LinearGaugeModule,
    CircularGaugeModule,
    HttpClientModule,
    VisualizationModule,
    // NgxChartsModule,
    // OverlayModule,
    // ChartModule,
    BrowserModule,
    ChartModule
  ],
  declarations: [
    AppComponent,

    LayoutComponent,
    SidenavListComponent,
    LoginComponent,
    RegisterComponent,
    SplashScreenComponent,
    AnimationComponent,
    LateralBarComponent,
    UserInfoComponent,
    UserListComponent,
    EditUserComponent,
    CollectionsListComponent,
    EditCollectionComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent,
    SidenavRutasComponent,
    RegisterCollectionComponent,

  ],
  providers: [
    RouteGuardService,
    //FilterService,
    //ToolbarService,
    //ExcelExportService,
    //PageService,
    //GroupService,
    //SortService,
    //DetailRowService,
    AuthService,
    DataService,
    StateService,
    CollectionsService,
    LocalstorageService,
    DexieService,
    CategoryService,
    DateTimeService,
    ScrollBarService,
    LineSeriesService,
    MultiColoredLineSeriesService,
    ColumnSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    StackingColumnSeriesService,
    PolarSeriesService,
    LegendService,
    TooltipService,
    SplineAreaSeriesService, ToolbarService, EditService, PageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
