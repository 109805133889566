<!-- <div class="nueva"> -->
<div class="nueva" *ngIf="users.length !== 0">
  <!-- <h3 class="mb-3 text-center">Clientes</h3> -->

  <div class="col-md-12">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Contacto</th>
          <th scope="col">Organización</th>
          <th scope="col">Correo</th>
          <th scope="col">Teléfono</th>
          <th scope="col">País</th>
          <th scope="col">Estado</th>
          <th scope="col">Ciudad</th>
          <th scope="col">¿Está Activo?</th>
          <th scope="col">Editar</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cliente of users">
          <td>{{ cliente.contactname }}</td>
          <td>{{ cliente.organization }}</td>
          <td>{{ cliente.email }}</td>
          <td>{{ cliente.phone }}</td>
          <td>{{ cliente.country }}</td>
          <td>{{ cliente.state }}</td>
          <td>{{ cliente.city }}</td>
          <td>{{ cliente.is_active ? 'Activo' : 'No activo' }}</td>
          <td>
            <button mat-raised-button routerLink="/edit-user/{{ cliente.id }}">
              Editar
            </button>
          </td>
          <td>
            <button mat-raised-button (click)="deleteUser(cliente.id)">
              Eliminar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- </div> -->
