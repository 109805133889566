import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data/data-service.service';

@Component({
  selector: 'app-chargersdata',
  templateUrl: './chargersdata.component.html',
  styleUrls: ['./chargersdata.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargersdataComponent implements OnInit, OnChanges, OnDestroy {
  cual = 1;
  multi: any = [];
  initMulti: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  datosaccum: any = [];

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = '';

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#008000', '#FF0000'],
  };

  ancho = '';
  alto = '';
  icono = true;


  axisInferior = [
    'Potencia (kW)',
    'Energía (kWh)',
  ];
  uno: any;
  dos: any;

  nombreacumulado = 'Acumulado Potencia'
  cargadorseleccionado :any;
  datasourcethedata6:any;

  public primaryXAxis: object = {};
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;
  public animation: object = { enable: false };
  public majorGridLines: Object;
  public lineStyle: Object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @Input() grande: boolean;

  @ViewChild('chart13a')
  public charta!: ChartComponent;
  @ViewChild('chart13b')
  public chartb!: ChartComponent;

  changeAnimation: Subscription | undefined;
  changeData: Subscription | undefined;

  constructor(
    public dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + '';
    // this.state.currentChart = 11;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }
  print() {
    if (this.cual === 1) {
      this.charta.print();
    } else {
      ;
    }
  }

  async ngOnInit(): Promise<void> {
    this.cargadorseleccionado = this.state.selectedCharger
    this.datasourcethedata6 = this.state.dataChargersInfo[this.cargadorseleccionado]

    console.log("Datos para Infomracion de Cargadores ", this.dataSer.chargersimpactpower)
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.primaryYAxis = {
      labelFormat: '{value}',
      title: 'Potencia (kW)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
    };
    // const hoy = new Date();
    this.primaryXAxis = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      title: 'Hora del día',
      // rangePadding: 'Auto',
      interval: 4,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: 'HH:mm'
    };
    this.majorGridLines = { width: 0 };
    this.lineStyle = { width: 0 };
    this.tooltip = { enable: true };
    // this.state.THEDATA = [];
    this.marker = { visible: true };
    this.subscribeConfiguration();
    this.state.currentStats = 1;
    // console.log('Termine ongin: ', this.state.THEDATA[9][this.state.selectedCharger]);

    // this.buildAccumData();
  }

  /* buildAccumData(): void {
    //console.log('normal:: ', this.state.THEDATA[9][1][this.state.selectedCharger]);
    this.state.processing = true;
    const salida: any = [];
    try {


      const ordenado = this.state.THEDATA[9][1][this.state.selectedCharger].sort((v1: any, v2: any) => {

        if (v1.datos.length < v2.datos.length) {
          return 1;
        }

        if (v1.datos.length > v2.datos.length) {
          return -1;
        }

        return 0;
      });
      ordenado[0].datos.forEach((tiempo: any, index: number) => {
        // console.log('Fecha: ', tiempo.name);
        let accumulatedNow = 0;
        this.state.THEDATA[9][1][this.state.selectedCharger].forEach((cname: any) => {
          if (cname.datos[index] !== undefined) {
            accumulatedNow += cname.datos[index].value
          } else {
            accumulatedNow += cname.datos[cname.datos.length - 1].value
          }
        });
        salida.push(
          {
            name: tiempo.name,
            value: accumulatedNow
          }
        );
      });
      // console.log('accumulated data is: ', salida);
      this.datosaccum = [{
        nombre: 'Acumulado en red',
        datos: salida
      }];
    } catch (error) {
      console.log('No se pudieron generar los datos acumulados');
      this.datosaccum = [];
    }
    this.state.processing = false;
  } */

  subscribeConfiguration(): void {
    this.changeAnimation = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        this.state.THEDATA[9] = [...this.state.THEDATA[9]];
        this.changeDetector.detectChanges();
        // this.chart.refresh();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  getData(whichOne: number): void {
    // console.log('my data en power time', this.state.currentRoute, ' whichone: ', whichOne);
    if (whichOne === -1) {
      whichOne = this.cual;
    }
    if (whichOne === 2 || whichOne === 3){
      this.primaryYAxis['title'] = 'Energía (kWh)';
    } else {
      this.primaryYAxis['title'] = 'Potencia (kW)';
    }
    this.cual = whichOne;
    this.state.currentStats = whichOne;
    this.changeDetector.detectChanges();
  }


  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.changeAnimation) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeAnimation.unsubscribe();
      this.changeAnimation = undefined;
    }
    if (this.changeData) {
      // console.log('Unsubscribing from routes in destroy');
      this.changeData.unsubscribe();
      this.changeData = undefined;
    }
    // console.log('MAPS component destroyed');
  }
}
