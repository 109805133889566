import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-bus-animation-list',
  templateUrl: './bus-animation-list.component.html',
  styleUrls: ['./bus-animation-list.component.scss']
})
export class BusAnimationListComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
    this.state.showSingleBusAnimation = '';
  }

  checkBus(bus: any, index: number): void {

    console.log('Showing bus ', bus, ' at index ', index);
    if (this.state.showSingleBusAnimation === bus) {
      this.state.showSingleBusAnimation = '';
    } else {
      if (this.state.showAllBusesAnimation){
        this.state.showAllBusesAnimation = false;
      }
      this.state.showSingleBusAnimation = '';
      setTimeout(() => {
        this.state.showSingleBusAnimation = bus;
      }, 50);

    }
  }

  showAllBusesAnimation(): void {
    this.state.showSingleBusAnimation = '';
    this.state.showAllBusesAnimation = !this.state.showAllBusesAnimation;
  }

}
