<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">


  <ejs-chart #chart11a *ngIf="cual === 1" id="powercont1" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip' (pointClick)="pointClick($event)" [animations]="animations">
    <e-series-collection>

      <e-series *ngFor="let chargerName of state.currentChargersNames" [dataSource]="state.THEDATA[9][0][chargerName][0].datos" type="Line" xName="name" yName="value"
        [name]="chargerName" legendShape='Rectangle' [animation]="animation"></e-series>
      
        <!--
          <e-series [dataSource]="state.THEDATA[11][1].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[11][1].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[11][2].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[11][2].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
        -->


      <!--
        <e-series [dataSource]='state.THEDATA[8][3].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[8][4].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Patio <b>${point.x}</b>'> </e-series>
      -->
    </e-series-collection>
  </ejs-chart>

  <!-- <ejs-chart *ngIf="cual === 2" id="powercont2" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip' (pointClick)="pointClick($event)" [animations]="animations">

    <e-series-collection>

      <e-series [dataSource]="state.THEDATA[8][8].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][8].nombre" legendShape='Rectangle' [animation]="animation">
      </e-series>
      <e-series [dataSource]="state.THEDATA[8][9].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][9].nombre" legendShape='Rectangle' [animation]="animation">
      </e-series>
      <e-series [dataSource]="state.THEDATA[8][10].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[8][10].nombre" legendShape='Rectangle' [animation]="animation">
      </e-series>


      <e-series [dataSource]='state.THEDATA[8][3].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[8][4].datos' type='Bubble' xName='x' yName='y' size='0.1'
        tooltipFormat='Patio <b>${point.x}</b>'> </e-series>
    </e-series-collection>
  </ejs-chart> -->



</div>
