<div #myDOMElement fxLayout="row">
  <!--   <div class="laNueve">
    <h2 >
      ENERGÍA DISPONIBLE
    </h2>
  </div> -->

  <ejs-chart *ngIf="graphicSelected == 1" id="chart-container" [primaryXAxis]="state.axis3[0]" [primaryYAxis]="state.axis3[1]" [height]="alto"
    (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
    (pointClick)="pointClick($event)" [animations]="animations" #chart3>
    <e-series-collection>

      <e-series *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as i;"
        [dataSource]="state.THEDATA[2][3].datos[busname]" type="Line" xName="name" yName="value"
        [name]="'100% SOH '+busname" legendShape='Rectangle' [animation]="animation"></e-series>


      <!--
        <e-series *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as j;"
        [dataSource]="state.THEDATA[2][4].datos[busname]" type="Line" xName="name" yName="value"
        [name]="'FDV '+busname" legendShape='Rectangle' [animation]="animation"></e-series>


      -->

        <e-series [dataSource]="state.THEDATA[2][2].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series>

      <!-- <e-series [dataSource]="state.THEDATA[2][0].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][0].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[2][1].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][1].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[2][2].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series> -->
      <!-- <e-series [dataSource]='state.THEDATA[2][3].datos' type='Bubble'
      xName='x' yName='y' size='0.1' tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[2][4].datos' type='Bubble'
      xName='x' yName='y' size='0.1' tooltipFormat='Patio <b>${point.x}</b>'> </e-series> -->
    </e-series-collection>
  </ejs-chart>

  <ejs-chart *ngIf="graphicSelected == 2" id="chart-finDeVida" [primaryXAxis]="state.axis3[0]" [primaryYAxis]="state.axis3[1]" [height]="alto"
    (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
    (pointClick)="pointClick($event)" [animations]="animations" #chart3>
    <e-series-collection>

      <e-series *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as j;"
        [dataSource]="state.THEDATA[2][4].datos[busname]" type="Line" xName="name" yName="value"
        [name]="'FDV '+busname" legendShape='Rectangle' [animation]="animation"></e-series>



        <e-series [dataSource]="state.THEDATA[2][2].datos" type="Line" xName="name" yName="value"
        [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series>

      <!-- <e-series [dataSource]="state.THEDATA[2][0].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][0].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[2][1].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][1].nombre" legendShape='Rectangle' [animation]="animation"></e-series>
      <e-series [dataSource]="state.THEDATA[2][2].datos" type="Spline" xName="name" yName="value"
        [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle' dashArray="5" [animation]="animation"></e-series> -->
      <!-- <e-series [dataSource]='state.THEDATA[2][3].datos' type='Bubble'
      xName='x' yName='y' size='0.1' tooltipFormat='Cabecera <b>${point.x}</b>'> </e-series>
      <e-series [dataSource]='state.THEDATA[2][4].datos' type='Bubble'
      xName='x' yName='y' size='0.1' tooltipFormat='Patio <b>${point.x}</b>'> </e-series> -->
    </e-series-collection>
  </ejs-chart>
</div>
