import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-fin-carga',
  templateUrl: './fin-carga.component.html',
  styleUrls: ['./fin-carga.component.scss']
})
export class FinCargaComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
  }

}
