import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MapsComponent } from '../maps/maps.component';
import { RouteGuardService } from '../../shared/guards/route-guard.service';
import { DashOverlayComponent } from '../dash-overlay/dash-overlay.component';

const routes: Routes = [
  {
    path: 'maps',
    canActivate: [RouteGuardService],
    component: MapsComponent
  },
  {
    path: 'dash-overlay',
    canActivate: [RouteGuardService],
    component: DashOverlayComponent
  },
];

@NgModule({
  declarations: [],
  exports: [
    RouterModule
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class VisualRoutingModule { }
