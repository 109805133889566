<!-- <div
  class="app-splash-screen"
  [ngStyle]="{ left: windowWidth }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-loader"></div>
  </div>
</div>
 -->

<div  *ngIf="showSplash" >
  <div class="myBack"></div>
  <video
    (ended)="cambiar()"
    id="myVideos"
    [autoplay]="'true'"
    autoplay
    muted
    [muted]="true"
  >
    <!-- <source src="../../../assets/webm2intro.webm" type="video/webm" /> -->
    <source src="../../../assets/intro.mp4" type="video/mp4" />
    Browser not supported
  </video>
</div>
