import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
// import { DataService } from '../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ChartComponent, IAccTooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { DataService } from 'src/app/shared/data/data-service.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  multi: any = [];
  
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 400];
  grafica = 0;
  docDefinition: any;
  icono = true;

  // options
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Ruta';
  yAxisLabel = 'Consumo (kWh)';
  timeline = true;
  legendTitle = '';
  elTitulo = 'COMPARATIVA CONSUMO CRÍTICO POR RUTA';

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;

  // public colors: ColorHelper | undefined;
  colorScheme = {
    domain: ['#000096'],
  };
  colorScheme2 = {
    domain: ['#000096', '#F5821E', '#4A7DBE'],
  };
  titulos = [
    'Comparativa del impacto de HVAC',
    'Comparativa del impacto de pasajeros',
    'COMPARATIVA CONSUMO CRÍTICO POR RUTA',
    'COMPARATIVA LONGITUD',
    'Comparativa de la duración de la ruta',
    'CONSUMO CRÍTICO POR RUTA'
  ];
  axisInferior = [
    'Porcentaje del consumo (%)',
    'Porcentaje del consumo (%)',
    'Energía (kWh)',
    'Distancia (km)',
    'Duración (minutos)',
    'Energía (kWh)'
  ];
  uno: any;
  dos: any;
  tres: any;
  cuatro: any;
  cinco: any;
  imagenes: any;
  cual = 6;
  public chartNames: string[] = [];
  public title!: string;
  public primaryXAxis!: object;
  public primaryYAxis!: object;
  public tooltip!: object;
  ancho = '';
  alto = '';


  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chart6a')
  public charta!: ChartComponent;
  @ViewChild('chart6b')
  public chartb!: ChartComponent;

  ChartsSubs: Subscription | undefined;

  @Input() grande: boolean;

  public ontooltipRender: any;
  public legendSettings: any;

  constructor(
    // private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    public dataSer: DataService,
  ) {
    /* this.view = [innerWidth / 2.5, innerHeight / 3.95];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.75 + ''; */
    // this.state.currentChart = 6;
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
    this.ChartsSubs = this.state.currentUpdateStatsChange.subscribe(async (value: any) => {
      this.getData(-1);
    });
  }

  print(){
    if (this.cual === 4){
      this.charta.print();
    } else {
      this.chartb.print();
    }

  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit statistics: ', this.state.THEDATA[6]);

    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 75) + '';
  }


  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 75) + '';
  }

  /*   private delay(ms: number): Promise<void> {
      return new Promise((resolve) => setTimeout(resolve, ms));
    } */

  async ngOnInit(): Promise<void> {
    // this.state.currentStats = 6;
    console.log("THEDATA[5]",this.state.THEDATA[5])
    this.legendSettings = {

      position: 'Custom',
      location: { x: 100, y: 5 },
      labelStyle: {
        fontFamily: 'Poppins',
        size:'1.1vm'
      }
    };
    this.primaryYAxis = {
      labelFormat: '{value}',
      title: 'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      }
    };
    this.primaryXAxis = {
      valueType: 'Category',
      title: 'Ruta',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      }
    };
    this.tooltip = { enable: true };

    this.uno = {
      'Caso de mayor impacto': this.state.getMaxBusAtIndex(0),
      'Impacto de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(0),
    };
    this.dos = {
      'Caso de mayor impacto': this.state.getMaxBusAtIndex(1),
      'Impacto de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(1),
    };
    this.tres = {
      'Caso de mayor consumo': this.state.getMaxBusAtIndex(2),
      'Consumo de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(2),
    };
    this.cuatro = {
      'Ruta de mayor longitud': this.state.getMaxBusAtIndex(3),
      'Longitud de ruta actual': 'Actual: ' + this.state.currentRoute,
      'Ruta de menor longitud': this.state.getMinBusAtIndex(3),
    };
    this.cinco = {
      'Ruta de mayor duración': 'C938',
      'Duración de ruta actual': 'Actual: ' + this.state.currentRoute,
      'Ruta de menor duración': 'E489_I',
    };
    this.imagenes = {
      1: 'hvac.png',
      2: 'pasajeros.png',
      3: 'consumo.png',
      4: 'longitud.png',
      5: 'duracion.png',
      6: 'comparativaConsumo.png'
    };

    this.ontooltipRender = this.elrendertooltop;
  }

  elrendertooltop = (args: IAccTooltipRenderEventArgs): void => {
    const losdatos = this.state.THEDATA[5].find((c: any) => {
      return c.ruta === args.point.x;
    });
    /* console.log('losdatos: ', losdatos);
    console.log('args.point.y: ', args.point); */
    // This is temporary as it only works when the first, second and third value are different
    if (losdatos.value2 === args.point.y){
      args.text = args.point.x + '' + ':' + (args.point.y + losdatos.value1)+ '';
    }
    if (losdatos.value3 === args.point.y){
      args.text = args.point.x + '' + ':' + (args.point.y + losdatos.value1 + losdatos.value2)+ '';
    }

  };

  formatXAxis = (val: string) => {
    // console.log('La tal data de X axis es: ', val);
    if (this.cual === 1) {
      return this.uno[val];
    } else if (this.cual === 2) {
      return this.dos[val];
    } else if (this.cual === 3) {
      return this.tres[val];
    } else if (this.cual === 4) {
      return this.cuatro[val];
    } else if (this.cual === 5) {
      return this.cinco[val];
    }
  }

  getData(whichOne: number): void {
    // console.log('my data', this.state.currentRoute);
    if (whichOne === -1) {
      whichOne = this.cual;
    }
    this.uno = {
      'Caso de mayor impacto': this.state.getMaxBusAtIndex(0),
      'Impacto de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(0),
    };
    this.dos = {
      'Caso de mayor impacto': this.state.getMaxBusAtIndex(1),
      'Impacto de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(1),
    };
    this.tres = {
      'Caso de mayor consumo': this.state.getMaxBusAtIndex(2),
      'Consumo de la ruta actual': 'Actual: ' + this.state.currentRoute,
      'Caso de menor impacto': this.state.getMinBusAtIndex(2),
    };
    this.cuatro = {
      'Ruta de mayor longitud': this.state.getMaxBusAtIndex(3),
      'Longitud de ruta actual': 'Actual: ' + this.state.currentRoute,
      'Ruta de menor longitud': this.state.getMinBusAtIndex(3),
    };

    this.cual = whichOne;
    this.state.currentStats = whichOne;
    this.elTitulo = this.titulos[whichOne - 1];
    this.yAxisLabel = this.axisInferior[whichOne - 1];
    this.primaryXAxis = {
      valueType: 'Category',
      title: 'Ruta',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };
    this.primaryYAxis = {
      labelFormat: '{value}',
      title: this.axisInferior[whichOne - 1],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    // this.state.selectedPoint =
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  get chartsSubs(): any {
    return this.ChartsSubs;
  }

  set chartsSubs(x: any) {
    this.ChartsSubs = x;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.chartsSubs) {
      // console.log('Unsubscribing from charts in destroy');
      this.chartsSubs.unsubscribe();
      this.chartsSubs = undefined;
    }
    // console.log('Charts-navigation component destroyed');
  }
}
