import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
// import { Component, NgModule } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
// import html2canvas from 'html2canvas';
/* import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs; */
import { IMouseEventArgs, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'app-consumption-distance',
  templateUrl: './consumption-distance.component.html',
  styleUrls: ['./consumption-distance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionDistanceComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  multi: any = [];
  initMulti: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  // colors: ColorHelper | undefined;
  chartNames: any[] = [];
  icono = true;
  _grande = false;
  // public marker: any = {};
  previousTarget = null;
  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Distancia (km)';
  yAxisLabel = 'Energía (kWh)';
  timeline = true;
  legendTitle = 'Years';
  roundDomains = true;
  autoScale = true;
  tooltipDisabled = false;

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;

  //variables consumeKwhCycle
  valueEnergyConsumeKwhCycle = 0;
  indexValueEnergyConsumeKwhCycle = 0;

  colorScheme = {
    domain: [],
  };

  public crosshair: Object = { enable: true, lineType: "Vertical" };
  public annotations: Object = [
    {
      content:
        '<div id="chart_annotation" style="font-family:Segoe UI; font-size:13px; height:100%"></div>',
      x: "125%",
      y: "21%"
    }
  ];
  public animation: object = { enable: false };

  cadena = 'CONSUMO DE ENERGÍA';
  cadena2 = '';

  ancho = '';
  alto = '';

  public primaryXAxis!: object;
  public chartData!: object[];
  public chartData2!: object[];
  public title!: string;
  public primaryYAxis!: object;
  public zoom!: object;
  public tooltip!: object;
  public marker!: object;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chart1')
  public chart!: ChartComponent;

  @Input() grande: boolean;

  subscription0: Subscription | undefined;
  subscription1: Subscription | undefined;
  subscription2: Subscription | undefined;
  subscription3: Subscription | undefined;
  subscription4: Subscription | undefined;
  subscription5: Subscription | undefined;
  subscription6: Subscription | undefined;
  subscriptions: Subscription[] = [];

  visible: any = [];

  /*   @Input()
    set grande(g: boolean) {
      this._grande = g;
    }
    get grande(): boolean { return this._grande; } */

  constructor(
    private dataSer: DataService,
    public state: StateService,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    // this.state.currentChart = 1;
    const colores = ['#F5821E', '#88898C', '#000096'];
    for (let i = 0; i < this.state.THEDATA[0].length; i++) {
      this.colorScheme.domain.push(colores[i % 3]);
    }
    for (const gr of this.state.THEDATA[0]) {
      // if (gr.redpoints.length > 0){
      this.colorScheme.domain.push('#FF0000');
      // }
    }

    for (const gr of this.state.THEDATA[0]) {
      /* console.log('Datos de organgePoints', gr.orangepoints); */
      this.colorScheme.domain.push('#FFA500');
      // }
    }
    // console.log('Los colores son: ', this.colorScheme.domain);
  }

  ngOnInit(): void {
    // console.log('Init consumption distance');
    // this.state.currentChart = 1;
    this.subscribeConfiguration();
    this.marker = { visible: true };
    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    this.marker = { visible: true };
    this.tooltip = { enable: true, format: '<b>${point.x} : ${point.y}</b>' };
    const arre = this.state.THEDATA[0];
    this.visible = new Array(arre.length).fill(false);
    // arre.forEach(
    //   e=>{
    //     console.log(e);
    //   }
    // );
    console.log("dataSer.completeDay",this.dataSer.completeDay)
    var contador=0;
    this.dataSer.completeDay.forEach(
      (e,index)=>{

        contador++;
        if(e.energyconsumekwhcycle > this.valueEnergyConsumeKwhCycle){
          this.valueEnergyConsumeKwhCycle = e.energyconsumekwhcycle;
          this.indexValueEnergyConsumeKwhCycle = index;

        }
      }
    );
    console.log("Termino recorrido de consumption-distance")
    this.visible[this.indexValueEnergyConsumeKwhCycle] = true;
    this.state.chosenSeries = this.indexValueEnergyConsumeKwhCycle;
    // if (this.state.currentCollection === 'Panama') {
    // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
    this.previousTarget = this.indexValueEnergyConsumeKwhCycle - 1;
    /* } else {
      this.visible[0] = this.visible[1] = this.visible[2] = true;
    } */
    // console.log('YA EN INIT: ', this.visible);
  }

  subscribeConfiguration(): void {
    // if this chart is not been visualized then do nothing
    if (this.state.currentChart !== 1) {
      return;
    }
    this.subscription0 = this.state.allSeriesUp.subscribe(
      async (value: any) => {
        // console.log('Showing all series');
        for (let i=0; i< this.chart.series.length; i++){
          this.chart.series[i].visible = true;
        }
        for (let j=0; j < this.visible.length; j++){
          this.visible[j] = true;
        }
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription1 = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        this.visible[this.indexValueEnergyConsumeKwhCycle] = true;

        // console.log('Detectied changes in route');
        const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        }
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    /* this.subscription2 = this.state.currentTypeChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        // this.visible = new Array(arre.length).fill(false);

        // this.chart.renderSeries();
        // console.log('Detected changes in type');
        const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        }
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    ); */
    this.subscription3 = this.state.currentPaxChange.subscribe(
      async (value: string) => {
        if (value === 'nav'){
          const arre = this.state.THEDATA[0];
          this.visible = new Array(arre.length).fill(false);
          this.visible[this.indexValueEnergyConsumeKwhCycle] = true;
        }

        //console.log('Detected changes in Pax ', this.state.chosenSeries);
        const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        }
        this.state.THEDATA[0] = [...this.state.THEDATA[0]];
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription4 = this.state.currentHVACChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        // this.visible = new Array(arre.length).fill(false);
        // this.chart.renderSeries();
        // console.log('Detected changes in HVAC');
        const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        }
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription5 = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        this.visible[this.indexValueEnergyConsumeKwhCycle] = true;

        // this.chart.renderSeries();
        // console.log('Detected changes in Battery');
        const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        }
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription6 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
        // console.log('Previous target: ', this.previousTarget);
        this.visible[this.previousTarget] = true;

        // this.chart.renderSeries();
        // console.log('Detected changes in BusSize');
        /* const colores = ['#F5821E', '#88898C', '#000096'];
        this.colorScheme.domain = [];
        for (let i = 0; i < this.state.THEDATA[0].length; i++) {
          this.colorScheme.domain.push(colores[i % 3]);
        }
        for (const gr of this.state.THEDATA[0]) {
          // if (gr.redpoints.length > 0){
          this.colorScheme.domain.push('#FF0000');
          // }
        }
        for (const gr of this.state.THEDATA[0]) {
          this.colorScheme.domain.push('#FFA500');
          // }
        } */
        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
  }

  get laSubs0(): any {
    return this.subscription0;
  }

  get laSubs1(): any {
    return this.subscription1;
  }

  get laSubs2(): any {
    return this.subscription2;
  }
  get laSubs3(): any {
    return this.subscription3;
  }
  get laSubs4(): any {
    return this.subscription4;
  }
  get laSubs5(): any {
    return this.subscription5;
  }
  get laSubs6(): any {
    return this.subscription6;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    // console.log('unsuscribing to type');
    if (this.laSubs1)
      this.laSubs1.unsubscribe();
    if (this.laSubs3)
      this.laSubs3.unsubscribe();
    if (this.laSubs4)
      this.laSubs4.unsubscribe();
    if (this.laSubs5)
      this.laSubs5.unsubscribe();
    if (this.laSubs6)
      this.laSubs6.unsubscribe();
    if (this.laSubs0)
      this.laSubs0.unsubscribe();
  }

  ngAfterViewInit(): void {

    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];

      const suma = parseInt(ids) + Math.floor(this.chart.series.length / 2);
      // const suma2 = parseInt(ids) + (Math.floor(this.chart.series.length / 2) * 1);
      this.visible[ids] = !this.visible[ids];
      this.chart.series[suma].visible = this.visible[ids];
      // this.chart.series[suma2].visible = this.visible[ids];

      let cuantosVisibles = 0;
      let whichSerie = -1;
      //this.chart.series.forEach((current: any, index: number) => {
      this.visible.forEach((current: any, index: number) => {
        if (current){
          cuantosVisibles++;
          whichSerie = index;
          // console.log('Visible serie: ', index);
        }

      });

      if (cuantosVisibles === 1){
        this.state.chosenSeries = whichSerie;
        // this.previousTarget = whichSerie;
        // console.log('La serie a animar es: ', this.state.chosenSeries);
      } else {
        this.state.chosenSeries = -1;
      }

    }
    this.chart.refresh();

  }

  pointClick(args: IPointEventArgs): void {
    try {
      const lalon = this.state.chartMapLongitudeCycles[args.seriesIndex][args.pointIndex];
      const lalat = this.state.chartMapLatitudeCycles[args.seriesIndex][args.pointIndex];
      /* console.log(
        'lalon : ' +
        lalon +
        ' . ' +
        'lalat : ' +
        lalat
      ); */
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }

  async print(){
    await this.chart.print();
  }
  /*  public trackItem(index: number, item: any): any {
    return item.trackId;
  } */

}

