import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService, SortService, GridComponent,ResizeService  } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { StateService } from 'src/app/shared/state/state.service';
import { IotaService } from 'src/app/shared/iota/iota.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/shared/data/data-service.service';
import { FilterSettingsModel } from '@syncfusion/ej2-angular-grids';


@Component({
  selector: 'app-chargers-table',
  templateUrl: './chargers-table.component.html',
  styleUrls: ['./chargers-table.component.scss'],
  providers: [GroupService, SortService,ResizeService]
})
export class ChargersTableComponent implements OnInit {
  actionFailure(e: any): void {

    console.log(e.error)
  }


  constructor(public state: StateService, private iota: IotaService, private http: HttpClient, public dataSer: DataService) {

  }

  public loscargadores = [...this.state.currentChargersNames];
  //public datasource = this.data.datasourceriota
  public data = {}
  public groupOptions: Object;
  public pageSettings: Object;
  public refresh: Boolean;
  @ViewChild('grid')
  public grid: GridComponent;
  @ViewChild('alertDialog')
  public alertDialog: DialogComponent;
  public alertHeader: string = 'Grouping';
  public hidden: Boolean = false;
  public target: string = '.control-section';
  public alertWidth: string = '300px';
  public alertContent: string = 'Grouping is disabled for this column';
  public showCloseIcon: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public alertDlgBtnClick = () => {
    this.alertDialog.hide();
  }
  public alertDlgButtons: Object[] = [{ click: this.alertDlgBtnClick.bind(this), buttonModel: { content: 'OK', isPrimary: true } }];
  public auxdata  = []
  ngOnInit(): void {
    this.data = this.dataSer.datasourceriota[0]
    console.log("this.data", this.data)

    this.groupOptions = { showGroupedColumn: true, columns: ['loadlocation'] };
    //this.pageSettings = { pageCount: 10 };
  }

  dataBound(args) {
    //this.grid.autoFitColumns()
    if (this.refresh) {
      this.grid.groupColumn('loadlocation');
      this.refresh = false;
    }
  }
  load() {
    this.refresh = (<any>this.grid).refreshing;
  }
  created() {
    this.grid.on("columnDragStart", this.columnDragStart, this);
  }
  public columnDragStart(args: any) {
    if (args.column.field === "Mainfieldsofinvention") {
      this.alertDialog.show();
    }
  }
}
