import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { data } from '../charger-comparison/data';

@Component({
  selector: 'app-dashboard-estrategiacarga',
  templateUrl: './dashboard-estrategiacarga.component.html',
  styleUrls: ['./dashboard-estrategiacarga.component.scss']
})
export class DashboardEstrategiacargaComponent implements OnInit {
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#800000', '#4bc8fe'],
  };
  //
  public dataTEC = this.state.dictTranslate.DashboardsGraph.estrategiacarga;
  public seleccionchart = 0
  public dataComparison = this.state.listaEstrategiaCargaData["results"]
  public listaPotencia = this.dataComparison["potencias"]
  public listaPuntos = this.dataComparison["puntoscarga"];
  public dataEstrategiaCarga = this.state.listaEstrategiaCargaData["results"]
  public listaPotenciaEC = this.dataEstrategiaCarga["potencias"]
  public listaBuses = []
  public listaBusesName = []
  public listaPotenciaTiempoCarga = []
  public listaPlotDescargaRecarga = []
  public listaPlotDescargaRecargaCrit = []
  public listaTiempoCarga = []
  public line = []
  public linecritic = []
  public linetamaniobateria =[]
  public linetamaniobateriacritic =[]
  public leyendasoc=""
  public valorsoclimite = this.state.bateria_bus;
  public selectedFiltroBoton
  public mostrarFiltroBoton
  public maxValueTR = 0

  //Layout Dashboard
  public cellSpacing: number[] = [20, 20]
  public allowDragging: boolean = true;
  public cellAspectRatio: number = 80 / 85;
  //Propieadades Graficos
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };
  public axisxTiempoCarga =
    {
      title: this.dataTEC.graficotr.ejex[this.state.indiceTranslate],//"Hora del Día",
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      interval: 1,
      minimum: 0,
      maximum: 24,

    };
  public axisyTiempoCarga = {
    // interval: 10,
    title: this.dataTEC.graficotr.ejey[this.state.indiceTranslate],//"Tiempo de Recarga (minutos)",
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    maximum: 400
  };
  //public datosgrafico = this.state.THEDATA[13][0].todoslostiemposdecarga
  //public listcargadores = this.state.THEDATA[13][0].loscargadores
  ancho = '';
  alto = '';
  public cargo = false;
  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }
  public markerRectangle = { visible: true, width: 10, height: 5, shape: 'Rectangle' }
  public markerBurbuja = { visible: true, width: 10, height: 10 }
  public sohnew = true;

  public cargadorespaletas = ["#010097", "#f4831e", "#7e7e83", "#d9e0f2", "#4bc8fe"];
  visible: any = [];
  previousTarget = null;


  @ViewChild('charTiempoCarga')
  public chart!: ChartComponent;

  @ViewChild('myDOMElement1')
  public chartvel1!: ChartComponent;

  @ViewChild('myDOMElement2')
  public chartvel2!: ChartComponent;

  @ViewChild('myDOMElement4')
  public chartcrit1!: ChartComponent;

  @ViewChild('myDOMElement5')
  public chartcrit2!: ChartComponent;
  constructor(private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) {

  }

  public axisxDescargaRecarga =
    {
      valueType: 'DateTime',
      title: this.dataTEC.graficodc100.ejex[this.state.indiceTranslate],//'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      // rangePadding: 'Auto',
      labelFormat: 'HH:mm',
      //minimum: 0
      lineBreakAlignment: 'Center'
    };
  public axisyDescargaRecarga = {
    // interval: 10,
    title: this.dataTEC.graficodc100.ejey[this.state.indiceTranslate],//'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: '{value}',
    minimum: 0,
    //maximum: 400,
    rangePadding: 'Auto',
  };
  public listaCargaNueva = []
  public listaCargaNuevaCritic = []
  public estaCompleto = true
  ngOnInit(): void {
    this.estaCompleto = this.state.listaEstadosDashboard["estrategiacarga"]
    this.mostrarFiltroBoton = true
    this.selectedFiltroBoton = 0
    this.cargaDatosDescargaRecarga(0)
    // this.cargarTiempoCarga()
    this.cargarTiempoCargaversiondos(0)
    this.soniguales()
    this.valorMaximoTR()
  }
  valorMaximoTR() {
    var lstMaximu = []
    //console.log("cargarTiempoCargaversiondos ", this.listaPuntos, this.listaPotenciaEC)
    for (let k = 0; k < this.listaPotencia.length; k++) {
      var auxCarga = []
      for (let i = 0; i < this.listaBuses.length; i++) {
        var listadatacarganueva = []
        for (let j = 0; j < this.listaPuntos.length; j++) {
          var datosFiltrados = this.dataEstrategiaCarga[this.listaPuntos[j]]
          var datosFiltradoBus = datosFiltrados[this.listaPotencia[k]];
          var element = datosFiltradoBus[this.listaBuses[i]]
          var listadatacargar = element["tiempocarga"]
          //console.log("cargarTiempoCargaversiondos", listadatacargar)
          var data = listadatacargar[listadatacargar.length - 1];
          listadatacarganueva.push(data)
        }
        auxCarga.push(listadatacarganueva)
      }
      let flatList = [].concat(...auxCarga);
      let maxX = Math.max(...flatList.map(item => item.axisy));
      lstMaximu.push(maxX)
    }

    this.maxValueTR = Math.max(...lstMaximu)

    this.axisyTiempoCarga = {
      title: this.dataTEC.graficotr.ejey[this.state.indiceTranslate],//"Tiempo de Recarga (minutos)",
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      maximum: this.maxValueTR + 20
    };
  }
  cargarDataNueva() {
    this.sohnew = true
    this.seleccionchart = 1
    //cargar datos
  }
  cargarDataFinvida() {
    this.sohnew = false
    this.seleccionchart = 2
    //cargar datos
  }
  soniguales() {
    console.log(this.listaPuntos, this.listaPotenciaEC, this.listaBuses)
    var datosFiltrados = this.dataEstrategiaCarga[this.listaPuntos[0]]
    var datosFiltradoBus = datosFiltrados[this.listaPotenciaEC[0]];
    //var datosFiltradodos = datosFiltradoBus[this.listaPotenciaEC[0]]
    var element = datosFiltradoBus[this.listaBuses[0]]
    var element2 = datosFiltradoBus[this.listaBuses[1]]
    if (element["descargarecarga"] == element2["descargarecarga"]) {
      console.log("Son iguales")
    } else {
      console.log("No Son iguales")
    }
  }
  cargarTiempoCargaversiondos(numero: any) {
    this.listaCargaNueva = []
    this.listaCargaNuevaCritic = []
    for (let i = 0; i < this.listaBuses.length; i++) {
      var listadatacarganueva = []
      var listadatacarganuevacritico = []
      for (let j = 0; j < this.listaPuntos.length; j++) {
        var datosFiltrados = this.dataEstrategiaCarga[this.listaPuntos[j]]
        var datosFiltradoBus = datosFiltrados[this.listaPotencia[numero]];
        var element = datosFiltradoBus[this.listaBuses[i]]

        //if (this.state.currentType == 'Opportunity') {

          var listadatacargar = element["tiempocarga"]
          for (let l = 0; l < listadatacargar.length; l++) {
            var data = listadatacargar[l];
            listadatacarganueva.push(data)
          }

          var listadatacargarcritic = element["tiempocargacritico"]
          for (let h = 0; h < listadatacargarcritic.length; h++) {
            var datacritic = listadatacargarcritic[h];
            listadatacarganuevacritico.push(datacritic)
          }
        //} else {
        //  var listadatacargar = element["tiempocarga"]
        //  var data = listadatacargar[listadatacargar.length - 1];
        //  listadatacarganueva.push(data)
        //  var listadatacargarcritic = element["tiempocargacritico"]
        //  var datacritic = listadatacargarcritic[listadatacargarcritic.length - 1];
        //  listadatacarganuevacritico.push(datacritic)
        //}

      }
      this.listaCargaNueva.push(listadatacarganueva)
      this.listaCargaNuevaCritic.push(listadatacarganuevacritico)
    }
    console.log("cargarTiempoCargaversiondos Critico", this.listaCargaNuevaCritic)
    console.log("cargarTiempoCargaversiondos", this.listaCargaNueva, this.listaBusesName)

  }
  cargarTiempoCarga() {
    console.log("Lista Puntos ", this.listaPuntos, this.listaPotenciaEC)
    for (let i = 0; i < this.listaPuntos.length; i++) {
      //const element = this.listaPuntos[i];
      var datosFiltrados = this.dataEstrategiaCarga[this.listaPuntos[i]]
      for (let j = 0; j < this.listaPotenciaEC.length; j++) {
        this.listaPotenciaTiempoCarga.push(this.listaPotenciaEC[j])
        const element = datosFiltrados[this.listaPotenciaEC[j]];
        this.listaTiempoCarga.push(element["tiempocarga"])
      }
    }
    console.log("Tiempo de Carga", this.listaTiempoCarga)
  }
  cargaDatosDescargaRecarga(numero: any) {
    this.listaPlotDescargaRecarga = []
    this.listaPlotDescargaRecargaCrit = []
    this.line = []
    this.linecritic = []
    this.linetamaniobateria=[]
    this.linetamaniobateriacritic=[]
    console.log("Lista Puntos ", this.listaPuntos, this.dataEstrategiaCarga)
    var punto_primero = this.listaPuntos[0]
    var datosFiltrados = this.dataEstrategiaCarga[punto_primero]
    var datosFiltradosBus = datosFiltrados[this.listaPotenciaEC[numero]]
    this.listaBuses = datosFiltradosBus["BusesId"]
    this.listaBusesName = datosFiltradosBus["BusesId"].map(idbus => idbus.split("_")[0])

    console.log("datos filtrados Bus", this.listaPotenciaEC[numero], datosFiltradosBus, this.listaBuses)
    for (let i = 0; i < this.listaBuses.length; i++) {
      var element = datosFiltradosBus[this.listaBuses[i]];
      this.listaPlotDescargaRecarga.push(element["descargarecarga"])
      this.listaPlotDescargaRecargaCrit.push(element["descargarecargacritico"])
      //this.listaTiempoCarga.push(element["tiempocarga"])
      this.line.push(element["line"])
      this.linecritic.push(element["linecritic"])
    }
    //leyenda SoC critico: value/capacidadpack*100
    var aux = (this.line[0][0]["axisy"]/this.state.tamaniobateriabus * 100).toFixed(0)
    this.leyendasoc = aux + "% SoC"
    console.log("lineas criticas",this.line,this.linecritic)
    this.linetamaniobateria.push({
      "axisx" : this.line[0][0]["axisx"],
      "axisy": this.state.tamaniobateriabus
    })
    this.linetamaniobateria.push({
      "axisx" : this.line[0][1]["axisx"],
      "axisy": this.state.tamaniobateriabus
    })
    this.linetamaniobateriacritic.push({
      "axisx" : this.linecritic[0][0]["axisx"],
      "axisy": this.state.tamaniobateriabus
    })
    this.linetamaniobateriacritic.push({
      "axisx" : this.linecritic[0][1]["axisx"],
      "axisy": this.state.tamaniobateriabus
    })
    console.log("Tiempo de Carga", this.dataEstrategiaCarga)
    console.log("datos Descarga Recarga", this.listaPlotDescargaRecarga)

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.seleccionchart = 1
    }, 2500)

  }
  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }


  irEnergiaDisponible() {
    this.seleccionchart = 3
    this.mostrarFiltroBoton = false

  }
  irEnergiaDisponibleCritico() {
    this.seleccionchart = 4
    this.mostrarFiltroBoton = false

  }
  irDescargaRecarga() {
    this.seleccionchart = 1
    this.mostrarFiltroBoton = true

  }
  irDescargaRecargaCritico() {
    this.seleccionchart = 2
    this.mostrarFiltroBoton = true

  }
  irTiempoRecarga() {
    this.seleccionchart = 5
    this.mostrarFiltroBoton = true
  }
  irTiempoRecargaCritico() {
    this.seleccionchart = 6
    this.mostrarFiltroBoton = true
  }
  cambiarFiltroPotencia(numero: any) {
    console.log(numero)
    this.selectedFiltroBoton = numero
    this.cargaDatosDescargaRecarga(numero)
    this.cargarTiempoCargaversiondos(numero)
  }

  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      for (var i = 0; i < this.chartvel1.series.length; i++) {
        this.chartvel1.series[i].visible = false;
        if (parseInt(ids) !== i) {
          this.visible[i] = false;
        } else {
          this.visible[i] = true;
        }
      }

      if (ids == this.previousTarget) {
        for (var j = 0; j < this.chartvel1.series.length; j++) {
          this.chartvel1.series[j].visible = true;
          this.visible[i] = true;
        }

        this.chartvel1.series[ids].visible = false;
        // this.visible[ids]= false;
        this.previousTarget = null;
        flag = true;
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };
  public chartMouseClick2(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      for (var i = 0; i < this.chartvel2.series.length; i++) {
        this.chartvel2.series[i].visible = false;
        if (parseInt(ids) !== i) {
          this.visible[i] = false;
        } else {
          this.visible[i] = true;
        }

      }

      if (ids == this.previousTarget) {
        for (var j = 0; j < this.chartvel2.series.length; j++) {
          this.chartvel2.series[j].visible = true;
          this.visible[i] = true;
        }

        this.chartvel2.series[ids].visible = false;
        // this.visible[ids]= false;
        this.previousTarget = null;
        flag = true;
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };
  public chartMouseClickcritic1(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      for (var i = 0; i < this.chartcrit1.series.length; i++) {
        this.chartcrit1.series[i].visible = false;
        if (parseInt(ids) !== i) {
          this.visible[i] = false;
        } else {
          this.visible[i] = true;
        }

      }

      if (ids == this.previousTarget) {
        for (var j = 0; j < this.chartcrit1.series.length; j++) {
          this.chartcrit1.series[j].visible = true;
          this.visible[i] = true;
        }

        this.chartcrit1.series[ids].visible = false;
        // this.visible[ids]= false;
        this.previousTarget = null;
        flag = true;
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };

  public chartMouseClickcritic2(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      for (var i = 0; i < this.chartcrit2.series.length; i++) {
        this.chartcrit2.series[i].visible = false;
        if (parseInt(ids) !== i) {
          this.visible[i] = false;
        } else {
          this.visible[i] = true;
        }

      }

      if (ids == this.previousTarget) {
        for (var j = 0; j < this.chartcrit2.series.length; j++) {
          this.chartcrit2.series[j].visible = true;
          this.visible[i] = true;
        }

        this.chartcrit2.series[ids].visible = false;
        // this.visible[ids]= false;
        this.previousTarget = null;
        flag = true;
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };
}
