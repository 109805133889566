<div #myDOMElementCK fxLayout="row" style="margin-top: 0px; padding-top: 0px;">
  <!-- <div class="laNueve">
    <h2 >
      CONSUMO POR KILÓMETRO
    </h2>
  </div> -->
  <ejs-chart
    #chart2
    id="chart-container"
    [primaryXAxis]="state.axis5[0]"
    [primaryYAxis]="state.axis5[1]"
    [height]='alto'
    (window:resize)="onResize($event)"
    [palettes]='colorScheme.domain'
    [tooltip]='tooltip'
  >
    <e-series-collection>
      <e-series
        [dataSource]="state.THEDATA[1]"
        type="Column"
        xName="hora"
        yName="type"
      ></e-series>
<!--       <e-series
        [dataSource]="state.THEDATA[1]"
        type="Column"
        xName="hora"
        yName="nocturno"
        name="Nocturno"
      ></e-series> -->
    </e-series-collection>
  </ejs-chart>


</div>
