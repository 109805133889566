import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, IPointEventArgs,StackingAreaSeriesService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';


@Component({
  selector: 'app-dashboard-rendimiento',
  templateUrl: './dashboard-rendimiento.component.html',
  styleUrls: ['./dashboard-rendimiento.component.scss']
})
export class DashboardRendimientoComponent implements OnInit {
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E','#4BC3F2'],
  };
  domain2: any = [];
  public dataTRend=this.state.dictTranslate.DashboardsGraph.rendimiento;
  public label1CD = this.dataTRend.graficocd.label1[this.state.indiceTranslate];
  public label2CD = this.dataTRend.graficocd.label2[this.state.indiceTranslate];
  public label3CD = this.dataTRend.graficocd.label3[this.state.indiceTranslate];
  public seleccionchart
  public dataComparison = this.state.listaComparacionesData["results"]
  public listaPotencia = this.dataComparison["potencias"]
  public listaPuntos = this.dataComparison["puntoscarga"];
  //Layout Dashboard
  public cellSpacing: number[] = [20, 20]
  public allowDragging: boolean = true;
  public cellAspectRatio: number = 80 / 85;
  //Propieadades Graficos
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };

  ancho = '';
  alto = '';
  public cargo = false;
  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }
  public cargadorespaletas = ["#010097", "#f4831e", "#7e7e83", "#d9e0f2",];
  public animations = false;
  public animation: object = { enable: false };
  public previousTarget = null;


  //
  public axis: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    title: this.dataTRend.graficoryp.ejey2[this.state.indiceTranslate],//'Pendiente (°)',
    name: 'yAxis',
    labelFormat: 'n1',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw',
    },
    labelStyle: {
      labelFormat: 'n1',
      size: '1.1vw',
      fontFamily: 'Poppins',
    }
  }];

  //axis4[0] Consumption Stops
  public axisCeroCS = {
    // interval: 10,
    title: 'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'Category',
  };
  //axis4[1] Consumption Stop
  public axisUno = {

    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
    title: 'Consumo (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    // maximum: this.state.maxVelocity,
    /* interval: elvalorin, */
  };
  public axisyComparativaLong = {
    labelFormat: '{value}',
    title: this.dataTRend.graficocco.ejey[this.state.indiceTranslate],//'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    }
  };
  public axisxComparativaLong = {
    valueType: 'Category',
    title: this.dataTRend.graficocco.ejex[this.state.indiceTranslate],//'Operación',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    }

  };
  public legendSettings = {

    position: 'Custom',
    location: { x: 100, y: 5 },
    labelStyle: {
      fontFamily: 'Poppins',
      size:'1.1vm'
    }
  };

  public axisxVP = {
    // interval: 10,
    title: 'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'Category',
  };

  //axis4[1] Consumption Stop
  public axisyVP = {

    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
    title: 'Velocidad (km/h)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  xAxis = true;
  yAxis = true;
  public axisCeroVP = {
    // interval: 10,
    title: 'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
  };
  //axis4[1] Velocity Profile
  public axisUnoVP = {
    labelFormat: '{value}',
    title: 'Velocidad (km/h)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
    //maximum:100,
    //maximum: this.state.maxVelocity,
    /* interval: elvalorin, */
  };
  public axisxCP = {
    // interval: 10,
    title: this.dataTRend.graficocp.ejex[this.state.indiceTranslate],//'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'Category',
  };
  public axisyCP = {

    //labelFormat: "{value.toFixed(2)}",
    labelFormat: 'n1',
    minimum: 0,
    rangePadding: 'Auto',
    title: this.dataTRend.graficocp.ejey[this.state.indiceTranslate],//'Consumo (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    
    // maximum: this.state.maxVelocity,
    /* interval: elvalorin, */
  };

  @ViewChild('chart10')
  chart: ChartComponent;

  subscription1: Subscription | undefined;
  subscription2: Subscription | undefined;
  subscription3: Subscription | undefined;
  subscription4: Subscription | undefined;
  subscription5: Subscription | undefined;
  subscription6: Subscription | undefined;
  subscriptions: Subscription[] = [];
  visible: any = [];
  visibleRegen: any = [];
  public marker2
  public estaCompleto = true
  constructor(private changeDetector: ChangeDetectorRef, private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) {

  }
  ngOnInit(): void {
    console.log("data 0 :", this.state.THEDATA[7])
    this.estaCompleto = this.state.listaEstadosDashboard["rendimiento"]
    this.marker2 = { visible: false };
    this.marker2 = { visible: true };
    const arre = this.state.THEDATA[0];
    this.visible = new Array(arre.length).fill(false);
    this.visibleRegen = new Array(arre.length).fill(false);
    this.subscribeConfiguration();
  
  }

  ngAfterViewInit(): void {

    console.log("data 0 :", this.state.THEDATA[0])

    // console.log('Afterviewinit');
    setTimeout(() => {
      this.seleccionchart = 2
    }, 1500)
    //const cuantos = Math.floor(this.chart.series.length / 3);
    //// ['#F5821E', '#88898C', '#000096']
    //for (let i = 0; i < cuantos; i++) {
    //  this.domain2.push('#F5821E');
    //}
    //for (let i = 0; i < cuantos; i++) {
    //  this.domain2.push('#000096');
    //}
    //for (let i = 0; i < cuantos; i++) {
    //  this.domain2.push('#88898C');
    //}

    /* this.chart.series[this.dataSer.dailyStatistics.maxconsumCycle - 1].visible = true;
    this.chart.series[(this.dataSer.dailyStatistics.maxconsumCycle - 1) + cuantos].visible = true;
    this.chart.series[(this.dataSer.dailyStatistics.maxconsumCycle - 1) + (cuantos * 2)].visible = true;
    this.previousTarget = this.dataSer.dailyStatistics.maxconsumCycle - 1;
    this.state.chosenSeries = this.dataSer.dailyStatistics.maxconsumCycle - 1; */
    //this.chart.series[this.dataSer.completeDay[0].maxconsumcycle - 1].visible = true;
    //this.chart.series[(this.dataSer.completeDay[0].maxconsumcycle - 1) + cuantos].visible = true;
    //this.chart.series[(this.dataSer.completeDay[0].maxconsumcycle - 1) + (cuantos * 2)].visible = true;
    //this.previousTarget = this.dataSer.completeDay[0].maxconsumcycle - 1;
    //this.state.chosenSeries = this.dataSer.completeDay[0].maxconsumcycle - 1;

  }

  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
  irConsumoCritRuta() {
    this.seleccionchart = 1
  }
  irConsumoEnergia() {
    this.seleccionchart = 2
  }
  irRegePendCambio() {
    this.seleccionchart = 4
  }
  irConsumoDiscriminado() {
    this.seleccionchart = 3
  }
  irConsumoParada() {
    this.seleccionchart = 5
  }
  irVelocidadPromedioServicio(){
    this.seleccionchart=6
  }

  subscribeConfiguration(): void {
    // if this chart is not been visualized then do nothing
    if (this.state.currentChart !== 10) {
      return;
    }
    this.subscription1 = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        // this.visible[0] = this.visible[1] = this.visible[2] = true;

        this.changeDetector.detectChanges();
        // console.log('Detectied changes in route');
        this.chart.refresh();
      }
    );
    this.subscription2 = this.state.currentTypeChange.subscribe(
      async (value: any) => {

        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription3 = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        // const arre = this.state.THEDATA[0];
        const cuantos = Math.floor(this.chart.series.length / 3);
        if (!this.chart.series[this.state.chosenSeries].visible) {
          this.chart.series[this.state.chosenSeries].visible = true;
          this.chart.series[(this.state.chosenSeries) + cuantos].visible = true;
          this.chart.series[(this.state.chosenSeries) + (cuantos * 2)].visible = true;
        }
        // this.visible = new Array(arre.length).fill(false);
        this.domain2 = [];

        for (let i = 0; i < cuantos; i++) {
          this.domain2.push('#F5821E');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain2.push('#000096');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain2.push('#88898C');
        }

        this.changeDetector.detectChanges();
        this.chart.refresh();
      }
    );
    this.subscription4 = this.state.currentHVACChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in HVAC');
        this.chart.refresh();
      }
    );
    this.subscription5 = this.state.currentBatteryChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[0];
        this.visible = new Array(arre.length).fill(false);
        /* if (this.state.currentCollection === 'Panama') {
          this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
          this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        } else {
          this.visible[0] = this.visible[1] = this.visible[2] = true;
        } */
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in Battery');
        this.chart.refresh();
      }
    );
    this.subscription6 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        /* console.log('Buszie in regen');
        const cuantos = Math.floor(this.chart.series.length / 3);
        this.chart.series[this.state.chosenSeries].visible = true;
        this.chart.series[(this.state.chosenSeries) + cuantos].visible = true;
        this.chart.series[(this.state.chosenSeries) + (cuantos * 2)].visible = true;
        this.domain = [];
 
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#F5821E');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#000096');
        }
        for (let i = 0; i < cuantos; i++) {
          this.domain.push('#88898C');
        } */
        this.changeDetector.detectChanges();

        this.chart.refresh();
      }
    );
  }
  pointClick(args: IPointEventArgs): void {
    try {
      const lalon = this.state.chartMapLongitudeCycles[args.seriesIndex][args.pointIndex];
      const lalat = this.state.chartMapLatitudeCycles[args.seriesIndex][args.pointIndex];
      /* console.log(
        'lalon : ' +
        lalon +
        ' . ' +
        'lalat : ' +
        lalat
      ); */
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }
  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      for (var i = 0; i < this.chart.series.length; i++) {
        this.chart.series[i].visible = false;
      }
      const suma = parseInt(ids) + Math.floor(this.chart.series.length / 3);
      const suma2 = parseInt(ids) + (Math.floor(this.chart.series.length / 3) * 2);
      this.chart.series[suma].visible = true;
      this.chart.series[suma2].visible = true;
      // this.chart.series[ids].visible = true;

      let cuantosVisibles = 0;
      this.chart.series.forEach((current: any, index: number) => {
        // console.log('El valor es: ', current.visible, ' para index ', index);
        if (current.visible) {
          cuantosVisibles++;

        }
      });
      // console.log('Hay ', cuantosVisibles);
      if (!flag)
        this.previousTarget = ids;
      this.state.chosenSeries = parseInt(ids);;
    } /// */
    this.chart.refresh();


  }
  pointClickByBusStop(args: IPointEventArgs) {
    let latitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].latitude;
    let longitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].longitude;
    let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].indexstops;
    //console.log('this.state.cyclesListOnlyCC[args.seriesIndex]: ',this.state.cyclesListOnlyCC[args.seriesIndex])
    //console.log('paradasindexone: ', paradasindexone);

    const lontotal = longitudeone;
    const lattotal = latitudeone;
    const lalon = lontotal[paradasindexone[args.pointIndex]];
    const lalat = lattotal[paradasindexone[args.pointIndex]];
    this.state.changePointIndex([lalon, lalat]);
  }

}
