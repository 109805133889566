import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DexieService } from 'src/app/shared/dexie/dexie.service';
import { IotaService } from 'src/app/shared/iota/iota.service';
// import { IotaService } from 'src/app/shared/iota/iota.service';

@Component({
  selector: 'app-lateral-bar',
  templateUrl: './lateral-bar.component.html',
  styleUrls: ['./lateral-bar.component.scss'],
})
export class LateralBarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  // abierto = false;
  public dictMenuLateral :any
  names = [
    'manu-gris',
    'menu-blanco',
    'filtros-gris',
    'filtros-blanco',
    'menu-gris',
    'analisis-gris',
    'analisis-azul',
    'perfil de velocidad_azul',
    'perfil de velocidad_blanco',
    'estadisticas_azul',
    'estadisticas_blanco',
    'ruta_azul',
    'ruta',
    'play_azul',
    'play_blanco',
    'animarruta_blanco'

  ];
  elMenu = 'Menú';
  // one = ['#D6D8E1', '#D6D8E1'];
  // two = '#D6D8E1';
  constructor(
    public myState: StateService,
    private router: Router,
    public authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    // private dexie: DexieService
    private iota: IotaService
  ) {
    for (const entry of this.names) {
      this.matIconRegistry.addSvgIcon(
        entry,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/SGV/' + entry + '.svg'
        )
      );
    }
    this.matIconRegistry.addSvgIcon(
      'logoLat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/simbolo-LOGIOS.svg'
      )
    );
  }

  ngOnInit(): void {
    this.myState.isEventsON = false;
    this.dictMenuLateral = this.myState.dictTranslate.Menu
  }

  public cambiarSide(x: number) {
    if (this.myState.currentMenu == x) {
      this.myState.abierto = false;
    }
    this.myState.collectionSelected = true;
    this.myState.currentMenu == 3
    this.myState.abierto = true;
    this.myState.seleccionNav = 2;
    this.sidenavToggle.emit()
  }
  public onToggleSidenav = (x: number) => {


    if (this.myState.currentMenu === x) {
      if (x == 5) {
        this.myState.currentChart = 0
        this.myState.mostrarPortafolio = false
        this.myState.one[x - 1] = '#D6D8E1';
        this.myState.currentMenu = 0;
      }
      else {
        this.myState.currentMenu = 0;
        this.myState.one[x - 1] = '#D6D8E1';
        this.sidenavToggle.emit();
        this.myState.abierto = false;
        this.myState.seleccionNav = 2
        this.myState.currentChart = 0
      }
    } else if (this.myState.currentMenu === 0) {
      if (x == 5) {
        this.myState.currentChart = 22
        this.myState.mostrarPortafolio = true
        this.myState.one[x - 1] = '#000096';
        this.myState.currentMenu = 5;
      } else {
        this.myState.currentMenu = x;
        this.myState.one[x - 1] = '#000096';
        this.myState.abierto = true;
        // console.log('abierto');
        this.sidenavToggle.emit();
      }
    } else {

      if(x==5){
        this.sidenavToggle.emit()
        this.myState.currentChart=0
      }
      if(this.myState.currentMenu==5){
        this.sidenavToggle.emit()
      }
      this.myState.currentMenu = x;
      
      if (x === 1) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#000096';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#D6D8E1';
      }
      if (x === 2) {
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[1] = '#000096';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#D6D8E1';

      } if (x == 3) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[2] = '#000096';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#D6D8E1';
        //this.myState.seleccionNav = 2
      }
      if (x == 4) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#000096';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#D6D8E1';
        //this.myState.seleccionNav = 2
      }
      if (x == 5) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#000096';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#D6D8E1';

        //this.sidenavToggle.emit;
        this.myState.currentChart=22
      }
      if (x == 6) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#000096';
        this.myState.one[6] = '#D6D8E1';
        //this.myState.seleccionNav = 2
      }
      if (x == 7) {
        this.myState.one[1] = '#D6D8E1';
        this.myState.one[0] = '#D6D8E1';
        this.myState.one[2] = '#D6D8E1';
        this.myState.one[3] = '#D6D8E1';
        this.myState.one[4] = '#D6D8E1';
        this.myState.one[5] = '#D6D8E1';
        this.myState.one[6] = '#000096';
        //this.myState.seleccionNav = 2
      }
      //this.myState.abierto = true;
      // console.log('abierto');
    }
  }
  public abrirPortafolio() {
    if (this.myState.currentMenu == 0) {
      this.myState.one[1] = '#D6D8E1';
      this.myState.one[0] = '#D6D8E1';
      this.myState.one[2] = '#D6D8E1';
      this.myState.one[3] = '#D6D8E1';
      this.myState.one[4] = '#000096';
      this.myState.one[5] = '#D6D8E1';
      this.myState.mostrarPortafolio = true;
      this.myState.currentChart = 22;
      this.myState.currentMenu = 5
      return;
    }
    if (this.myState.currentMenu != 5) {
      this.sidenavToggle.emit()
      this.myState.one[1] = '#D6D8E1';
      this.myState.one[0] = '#D6D8E1';
      this.myState.one[2] = '#D6D8E1';
      this.myState.one[3] = '#D6D8E1';
      this.myState.one[4] = '#000096';
      this.myState.one[5] = '#D6D8E1';
      this.myState.mostrarPortafolio = true;
      this.myState.currentChart = 22;
      this.myState.currentMenu = 5
    } else {
      this.myState.mostrarPortafolio = false;
      this.myState.currentChart = 0;
      this.myState.one[4] = '#D6D8E1';
      this.myState.currentMenu = 0
    }

    //console.log("currentmenu", this.myState.currentMenu, this.myState.currentChart)
    //if (this.myState.currentMenu == 5) {
    //  this.myState.currentChart=100
    //  this.myState.currentMenu=0
    //  //this.onToggleSidenav(0)
    //  this.myState.one[5 - 1] = '#D6D8E1';
    //  this.myState.mostrarPortafolio = false;
    //  this.myState.abierto = false;
    //
    //} else {
    //  this.myState.currentChart = 22
    //  this.myState.abierto = false;
    //  this.myState.currentMenu= 5
    //  this.myState.one[1] = '#D6D8E1';
    //  this.myState.one[0] = '#D6D8E1';
    //  this.myState.one[2] = '#D6D8E1';
    //  this.myState.one[3] = '#D6D8E1';
    //  this.myState.one[4] = '#000096';
    //  this.myState.one[5] = '#D6D8E1';
    //}
  }
  public async salir(): Promise<void> {
    this.myState.currentMenu = 0;
    this.myState.collectionSelected = false;

    this.myState.inMap = false;
    // console.log('isloggedin1? ', this.authService.isLoggedIn);
    await this.authService.SignOut();
    // console.log('isloggedin2? ', this.authService.isLoggedIn);
    this.router.navigate(['login']);
    if (this.myState.abierto) {
      this.sidenavToggle.emit();
    }
    this.iota.disconnect();

  }


}
