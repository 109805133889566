<div class="nueva">
  <form fxLayout="column" [formGroup]="form">
    <h2 class="cadena">ACTUALIZAR USUARIO</h2>
    <h1 *ngIf="actualizado">¡Actualización exitosa!</h1>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Nombre de contacto"
          formControlName="contactname"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Organización"
          formControlName="organization"          
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Correo"
          formControlName="email"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Repita el correo"
          formControlName="email2"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="password"
          placeholder="Contraseña"
          formControlName="password1"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="password"
          placeholder="Repita la contraseña"
          formControlName="password2"
        />
      </mat-form-field>
    </div>

    <!-- <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Correo secundario"
          formControlName="emailBackUp"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Repita el correo secundario"
          formControlName="emailBackUp2"
        />
      </mat-form-field>
    </div> -->

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Teléfono"
          formControlName="phone"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Dirección"
          formControlName="address"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="País"
          formControlName="country"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Estado"
          formControlName="state"
        />
      </mat-form-field>

      <mat-form-field fxFlex="30%" style="width: 150px;">
        <input
          matInput
          type="text"
          placeholder="Ciudad"
          formControlName="city"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <!-- <h3>Colecciones visibles:</h3>
      <ul>
        <li *ngFor="let element of this.userData.authCollections">{{ element }}</li>
      </ul> -->

      <mat-form-field fxFlex>
        <mat-label>Colecciones visibles</mat-label>
        <mat-select formControlName="currentCollection">
          <mat-option
            *ngFor="let element of this.userData.authCollections"
            [value]="element"
          >
            {{ element }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-raised-button
        style="
          font-family: 'Poppins' !important;
          border-radius: 25px;
          margin-top: 40px;
          margin-bottom: 20px;
          height: 35px;
        "
        (click)="onSubmit('a')"
      >
        Eliminar
      </button>

      <mat-form-field fxFlex>
        <mat-label>Colectiones en base de datos</mat-label>
        <mat-select formControlName="addedCollection">
          <mat-option *ngFor="let colect of collections" [value]="colect.name">
            {{ colect.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-raised-button
        style="
          font-family: 'Poppins' !important;
          border-radius: 25px;
          margin-top: 40px;
          margin-bottom: 20px;
          height: 35px;
        "
        (click)="onSubmit('b')"
      >
        Adicionar
      </button>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="30px"
      fxLayoutGap.lt-md="0px"
    >
      <!-- <mat-checkbox>Administrador</mat-checkbox> -->
      <mat-label>Tipo: </mat-label>
      <mat-radio-group
        formControlName="super"
        fxFlex="33%"
        style="width: 150px;"
      >
        <mat-radio-button class="example-radio-button" value="1"
          >Administrador</mat-radio-button
        >
        <mat-radio-button class="example-radio-button" value="0"
          >Cliente</mat-radio-button
        >
      </mat-radio-group>

      <mat-label>Activo plan: </mat-label>
      <mat-radio-group
        class="example-radio-group"
        formControlName="activoPlan"
        fxFlex="33%"
        style="width: 150px;"
      >
        <mat-radio-button class="example-radio-button" value="activoPlan"
          >Sí</mat-radio-button
        >
        <mat-radio-button class="example-radio-button" value="noActivoPlan"
          >No</mat-radio-button
        >
      </mat-radio-group>

      <mat-label>Activo track: </mat-label>
      <mat-radio-group
        class="example-radio-group"
        formControlName="activoTrack"
        fxFlex="33%"
        style="width: 150px;"
      >
        <mat-radio-button class="example-radio-button" value="activoTrack"
          >Sí</mat-radio-button
        >
        <mat-radio-button class="example-radio-button" value="noActivoTrack"
          >No</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <!-- <div class="input-row">
        <mat-form-field fxFlex>
          <input
            matInput
            type="text"
            placeholder="Organización"
            formControlName="organization"
          />
        </mat-form-field>
      </div> -->

    <!-- <mat-error *ngIf="loginInvalid">
        Formulario inválido
      </mat-error> -->

    <button
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        max-width: 200px;
        align-self: center;
      "
      mat-raised-button
      (click)="onSubmit('c')"
    >
      Actualizar
    </button>
  </form>
</div>
