<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">
    <ejs-chart #chart15 *ngIf="cual === 1" 
    id="powercont1" [primaryXAxis]="primaryXAxis" 
    [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" 
    [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip' (pointClick)="pointClick($event)" [animations]="animations">
      <e-series-collection>
        <e-series  *ngFor="let chargerName of state.currentChargersNames" 
        [dataSource]="state.THEDATA[9][5][chargerName][0].datos" type="Line" xName="name" yName="value"
        [name]="chargerName" legendShape='Rectangle' [animation]="animation"></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
  