import { Component, ElementRef, OnInit, SimpleChanges, } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { data } from 'src/app/visualization/charts/charger-comparison/data'
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { StepLineSeriesService, DateTimeService } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-comparison-power',
  templateUrl: './comparison-power.component.html',
  styleUrls: ['./comparison-power.component.scss']
})
export class ComparisonPowerComponent implements OnInit {
  public listaPotencia;
  public listaDatosplot;
  ancho = '';
  alto = '';
  public axisx = {
    valueType: 'DateTime',
    title: 'Hora',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    rangePadding: 'Auto',
  }
  public axisy = {
    valueType: 'Double',
    title: 'Energía (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
  }
  public zoom!: object;
  public tooltip!: object;
  public annotations: Object = [
    {
      content:
        '<div id="chart_annotation" style="font-family:Segoe UI; font-size:13px; height:100%"></div>',
      x: "125%",
      y: "21%"
    }
  ];
  public crosshair: Object = { enable: true, lineType: "Vertical" };

  public datauno = {
    "EnergiaX": ['2022-01-01T00:00:00', '2022-01-01T12:32:59', '2022-01-01T12:45:59', '2022-01-01T13:00:00', '2022-01-01T13:20:00', '2022-01-01T13:55:00', '2022-01-01T14:27:59', '2022-01-01T14:54:00', '2022-01-01T15:12:29', '2022-01-01T15:37:58', '2022-01-01T15:54:29', '2022-01-01T16:11:29', '2022-01-01T23:02:59', '2022-01-02T00:12:05', '2022-01-02T00:27:34', '2022-01-02T00:41:52', '2022-01-02T00:57:42', '2022-01-02T01:15:23', '2022-01-02T01:35:29', '2022-01-02T01:50:12', '2022-01-02T02:06:33', '2022-01-02T02:26:34', '2022-01-02T02:40:15', '2022-01-02T02:43:14', '2022-01-02T03:51:52', '2022-01-02T04:04:47', '2022-01-02T04:17:23', '2022-01-02T04:31:11', '2022-01-02T04:46:22', '2022-01-02T05:03:20', '2022-01-02T05:19:09', '2022-01-02T05:36:56', '2022-01-02T05:57:06', '2022-01-02T06:20:30', '2022-01-02T12:00:00'],
    "EnergiaY": [0, 0, 23719.2982456143, 87670.1754385942, 259178.947368385, 675209.356725424, 1134816.37426963, 1623129.82456240, 2126222.22222338, 2692353.21637153, 2948977.77777326, 3110421.05262595, 3110421.05262595, 5073617.71671304, 5439796.93396552, 5689891.64472117, 5894057.02631943, 6056444.50137597, 6182314.11002110, 6246752.92766845, 6298962.91182791, 6343183.45342663, 6364542.39198508, 6364542.39198508, 6797939.25357061, 6868178.31419598, 6921113.10119985, 6965505.45650295, 7001922.52393169, 7031299.11399750, 7050902.21681999, 7066572.63587821, 7078662.07080788, 7087680.46739577, 7087680.46739577]
  }
  public datos: any;
  public primaryXAxis: Object = {
    valueType: 'DateTime',

  };
  public data: Object[] = [
    { x: [new Date(1975, 0, 1), new Date(1980, 0, 1)], y: [16, 12.5] }
  ];

  public datav1: Object[] = [{ x: new Date(1975, 0, 1), y: 16 }, { x: new Date(1980, 0, 1), y: 12.5 }]

  constructor(public state: StateService, private elRef: ElementRef) {

  }
  ngOnInit(): void {
    this.tooltip = { enable: true, format: '<b>${point.x} : ${point.y}</b>' };

    this.zoom = {
      enableSelectionZooming: true,
      mode: 'X',
      enableMouseWheelZooming: true,
      enablePinchZooming: true,
      enableScrollbar: true
    };
    var listaprocesada: any[] = [];
    var template: any
    this.listaPotencia=["104","150","180","210"]
    this.listaDatosplot=[]
    console.log("Procesando datos")
    this.datos = this.state.listaComparacionesData["results"];
    console.log(this.datos)
    for (let i = 0; i < this.listaPotencia.length; i++) {
      console.log(this.listaPotencia[i])
      var element = this.datos[this.listaPotencia[i]];
      this.listaDatosplot.push(element["potencia"])
      //console.log("datos prueba", element)      
    }
    console.log(this.listaDatosplot)
    //console.log("prueba datos",this.datos["results"]);
console.log("termina init ")
  }
  ngOnChanges(changes: SimpleChanges) {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
}
