<div #myDOMElement  class="eldiv">
 <!--  (chartMouseClick)='chartMouseClick($event)' -->
  <ejs-chart
    #chartvel1
    id="chart-container"
    [primaryXAxis]="state.axis4[0]"
    [primaryYAxis]="state.axis4[1]"
    [height]="alto"
    (window:resize)="onResize($event)"
    [zoomSettings]="zoom"
    [palettes]="colorScheme.domain"
    [tooltip]="tooltip"
    (pointClick)="pointClick($event)"

    [animations]="animations"
  >
    <e-series-collection>
      <e-series
        *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
        [dataSource]='this.state.THEDATA[10]' type='Column' xName='name' [yName]='elchart' [name]='elchart' [visible]="visible[i]" 
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>


</div>
