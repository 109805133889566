<div style="display: flex; ">
  <div style="display: flex; flex-direction: column; padding-left: 10px; width: 100px;">
    <div>Hora</div>
    <div> {{state.currentAnimationTime}} </div>
  </div>
  <div style="display: flex; flex-direction: column; padding-left: 20px;  width: 200px;" *ngIf="state.showAllChargersAnimation">
    <div>Energía consumida</div>
    <div> {{state.totalAccumEnergy}} </div>
  </div>
  <div style="display: flex; flex-direction: column; padding-left: 20px;  width: 100px;" *ngIf="state.showAllChargersAnimation">
    <div>Costo total</div>
    <div> {{state.totalCostEnergy}}  </div>
  </div>
  <div style="display: flex; flex-direction: column; padding-left: 20px; position: absolute; right: 10px !important;  width: 200px;">
    <div>Mirati</div>
    <div> Powered by LOGIOS </div>
  </div>
</div>
