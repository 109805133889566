import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../shared/data/data-service.service';
import { MapsComponent } from '../maps/maps.component';

@Component({
  selector: 'app-dash-overlay',
  templateUrl: './dash-overlay.component.html',
  styleUrls: ['./dash-overlay.component.scss'],
})
export class DashOverlayComponent implements OnInit {
  @ViewChild('childMap') childMap: MapsComponent;

  constructor(private dataSer: DataService, public state: StateService,
    public auth: AuthService) { }

  async ngOnInit(): Promise<void> {
    // console.log('Dentro o no: ', this.auth.dentro);
    if (this.auth.userData.user.authCollections.length > 1) {
      this.state.currentCollection = '';
    }
    this.state.currentRoute = 'None';
    this.state.showDetails = false;
    this.state.showDetailsVel = false;
    this.state.showDetailsEne = false;
    this.dataSer.allCyclesInputs = {};
    if (!this.auth.dentro) {
      // console.log('obteniendo busesnames : ');
      this.auth.restartUserData();

      if (this.auth.userData.user.authCollections.length === 1) {
        await this.dataSer.setBusNames(this.auth.userData.user.authCollections[0]);
      } else {
        await this.dataSer.setBusNames();
      }


      if (this.auth.userData.user.authCollections.length === 1) {
        this.childMap.moveLocation();
      }

      this.auth.getBusConfig().subscribe(async (res: any) => {
        // console.log('El usuario tiene las selecciones: ', JSON.parse(res.busconfigselections));
        if (res.busconfigselections !== '') {
          this.auth.currentBusConfigurations = JSON.parse(res.busconfigselections);
          this.auth.restoreBusOptions(res.busconfigselections);
        } else {
          this.auth.restoreBusOptions('');
        }
      });
      /* if (this.auth.userData.user.authCollections.length > 1){
        this.state.changeAllLocations('');
      } */
    }
    this.dataSer.listEndQuery = []
    this.dataSer.listStartQuery = []
    console.log("bus from dash.overlay", this.auth.currentBusConfigurations)

 
  }
}
