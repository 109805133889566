import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
@Component({
  selector: 'app-dashboard-microsimulaciones',
  templateUrl: './dashboard-microsimulaciones.component.html',
  styleUrls: ['./dashboard-microsimulaciones.component.scss']
})

export class DashboardMicrosimulacionesComponent implements OnInit {
  public tooltip = { enable: true };
  public dataTMS = this.state.dictTranslate.DashboardsGraph.microsimulacion;

  colorScheme = {
    domain: ['#000096', '#F5821E', '#88898C', '#4bc8fe'],
  };
  public axisxEstadoBus =
    {
      valueType: 'DateTime',
      title: this.dataTMS.graficoeb.ejex[this.state.indiceTranslate],//'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      // rangePadding: 'Auto',
      labelFormat: 'HH:mm:ss',
      //minimum: 0
      lineBreakAlignment: 'Center',
      //intervalType: 'Hours',
      //interval: 1
    };
  public axisyEstadoBus = {
    // interval: 10,
    //rangePadding: 'None',
    //valueType: 'Category',
    title: this.dataTMS.graficoeb.ejey[this.state.indiceTranslate],//'Bus ID',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    //minimum: 0,
    //rangePadding: 'Auto',
  };
  public axisxCargadores =
    {
      valueType: 'DateTime',
      title: this.dataTMS.graficoec.ejex[this.state.indiceTranslate],//'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      // rangePadding: 'Auto',
      labelFormat: 'HH:mm',
      //minimum: 0
      lineBreakAlignment: 'Center',
      intervalType: 'Hours',
      //interval: 1
    };
  public axisyCargadores = {
    //rangePadding: 'None',
    //valueType: 'Category',
    title: this.dataTMS.graficoec.ejey[this.state.indiceTranslate],//'Cargador ID',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    //minimum: 0,
    //rangePadding: 'Auto',
  };
  public axisxEquipos =
    {
      valueType: 'Category',
      title: this.dataTMS.graficouc.ejex[this.state.indiceTranslate],//'Cargador ID',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      // rangePadding: 'Auto',
      //minimum: 0
      lineBreakAlignment: 'Center',
      intervalType: 'Hours',
      //interval: 1
    };
  public axisyEquipos = {
    interval: 1,
    maximum: 24,
    //rangePadding: 'None',
    //valueType: 'Category',
    title: this.dataTMS.graficouc.ejey[this.state.indiceTranslate],//'Horas de uso',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '0.7vw'
    },
    //minimum: 0,
    //rangePadding: 'Auto',
  };
  public seleccionchart
  public listapotencia = this.state.listaMicroSimulacion["potencia"].sort()
  public mostrarFiltroBoton
  public selectedFiltroBoton
  public yacargo = false;
  public datos = this.state.listaMicroSimulacion["results"]
  public datosEquipos = this.state.listaMicroSimulacion["cargadores"]
  public datosUsabilidad = this.state.listaMicroSimulacion["usabilidad"]
  public listaEquipos = this.state.listaMicroSimulacion["conectoresname"]
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };
  constructor(private elRef: ElementRef, public dataSer: DataService, public authService: AuthService, public state: StateService) { }
  public potenciaselected = this.state.listaMicroSimulacion["potencia"]
  public dataEstadoBus = []
  public dataCargadores = []
  public dataUsabilidad = []

  public marker!: object;
  public markerEquipos!: object;
  public estaCompleto = true
  ngOnInit(): void {
    console.log("datos equipos", this.datosEquipos)
    this.estaCompleto = this.state.listaEstadosDashboard["microsimulacion"]
    this.marker = { shape: 'Rectangle', visible: true, width: 3, height: 8 };
    this.markerEquipos = { visible: true, width: 7, height: 10, isFilled: true }
    this.mostrarFiltroBoton = true
    this.selectedFiltroBoton = 0;
    this.yacargo = true
    this.cargarDatosEstadoBusinit(0)
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.seleccionchart = 1
    }, 500)

  }
  generarDatosCargadores(num:any){
    var potencianame = this.potenciaselected[num].toString()
    this.dataCargadores = []
    this.dataCargadores = this.datosEquipos[potencianame]  
    
    
    console.log("datos cargadores", this.dataCargadores)
  }
  cargarDatosEstadoBusinit(num: any) {
    this.dataEstadoBus = []
    this.dataCargadores = []
    this.dataUsabilidad = []
    console.log("Cargando Datos ", num)
    
    var potencianame = this.potenciaselected[num].toString()
    this.dataEstadoBus = this.datos[potencianame]
    console.log(this.dataEstadoBus)

    //this.dataCargadores = this.datosEquipos[potencianame]
    this.generarDatosCargadores(num)

    this.dataUsabilidad = this.datosUsabilidad[potencianame]
    console.log(this.dataUsabilidad)
    //this.dataEstadoBus = datosprocesar
    //this.dataCargadores = datosprocesarestado
    //this.dataUsabilidad = datosprocesarequipos
  }
  cargarDatosEstadoBus(num: any) {
    this.dataEstadoBus = []
    this.dataCargadores = []
    this.dataUsabilidad = []
    console.log("Cargando Datos ", num)
    
    var potencianame = this.potenciaselected[num].toString()
    this.dataEstadoBus = this.datos[potencianame]
    console.log(this.dataEstadoBus)

    //this.dataCargadores = this.datosEquipos[potencianame]
    this.generarDatosCargadores(num)

    this.dataUsabilidad = this.datosUsabilidad[potencianame]
    console.log(this.dataUsabilidad)
    //this.dataEstadoBus = datosprocesar
    //this.dataCargadores = datosprocesarestado
    //this.dataUsabilidad = datosprocesarequipos
  }
  irEstadoBus() {
    this.seleccionchart = 1
    this.mostrarFiltroBoton = true
  }
  irEstadoCargadores() {
    this.seleccionchart = 2
    this.mostrarFiltroBoton = true
  }
  cambiarFiltroPotenciaSim(numero: any) {
    this.selectedFiltroBoton = numero
    this.cargarDatosEstadoBus(numero)

  }
  irUsabilidadEquipos() {
    this.seleccionchart = 3
    this.mostrarFiltroBoton = true
  }

}
