import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StateService } from 'src/app/shared/state/state.service';
import { LocalstorageService } from 'src/app/shared/storage/localstorage.service';
import { AuthService } from '../../../shared/auth/auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    username: new FormControl('', [Validators.email]),
    password: new FormControl('', [Validators.required])
  });
  public loginInvalid = false;
  public dataTIS = this.state.dictTranslate.InicioSesion;
  public dataTimg = this.state.dictTranslate.urlimages;
  public id = this.state.indiceTranslate
  public idimg=this.state.indiceTranslate
  idioma = "Español"
  urlimagent = ""
  private formSubmitAttempt = false;
  private returnUrl = '';
  // public scriptText  = '';

  constructor(
    private fb: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private state: StateService,
    private lStore: LocalstorageService
  ) {
    /* console.log('Iniciando login token: ', this.authService.getToken());
    console.log('Iniciando login userStore: ', JSON.parse(this.authService.getUserStore())); */
  }

  ngOnInit(): void {
    this.returnUrl = 'animation';

    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });
    if (this.state.indiceTranslate==1) {
      this.id=this.state.indiceTranslate 
      this.idioma='Español'
      this.idimg = 0
      this.urlimagent = "../../../../assets/estc.png"
    }else{
      this.idioma='English'
      this.id=this.state.indiceTranslate
      this.idimg = 1
      this.urlimagent = "../../../../assets/entc.png"
    }
    const ctrl = new FormControl('some value');
    // console.log(ctrl.value);     // 'some value'
    // console.log('Name:' + this.form.get('username').value);


    if (this.authService.isLoggedIn) {
      this.authService.restartUserData();
      this.router.navigate([this.returnUrl]);
    }
  }

  get userName(): any {
    return this.form.get('username');
  }

  get userPass(): any {
    return this.form.get('password');
  }

  /* async onSubmit(): Promise<void> {
    console.log('usuario: ', this.userName.value);
    console.log('pass: ', this.userPass.value);
    if (this.form.get('username') === null) { return; }

  }*/

  async onSubmit(tipo: string): Promise<void> {
    if (tipo === 'r'){
      this.router.navigate(['/register']);
      return;
    }
    if (tipo === 'p'){
      this.router.navigate(['/recover']);
      return;
    }
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        await this.authService.SignIn(this.userName.value, this.userPass.value);
        if (!this.authService.isLoggedIn){
          this.loginInvalid = true;
          // this.scriptText = 'Usted no está autorizado para entrar a este sitio.';
        }else {
          this.state.inMap = true;
        }
        this.router.navigate([this.returnUrl]);
      } catch (err) {
        this.loginInvalid = true;
        // this.scriptText = 'Usted no está autorizado para entrar a este sitio.';
      }
    } else {
      this.formSubmitAttempt = true;
    }
    this.state.collectionSelected=false
  }
  cambiarIdioma(){
    console.log("indice de translate",this.state.indiceTranslate)
    if (this.state.indiceTranslate==0) {
      this.state.indiceTranslate=1 
      this.id=this.state.indiceTranslate 
      this.idioma='Español'
      this.idimg = 0
    }else{
      this.state.indiceTranslate=0
      this.idioma='English'
      this.idimg = 1
      this.id=this.state.indiceTranslate
    }
    
  }
 /*  registrarse(): void{
    this.router.navigate(['/register']);
  } */
}
