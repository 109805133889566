<div [ngClass]=" mystate.currentRoute === 'None' ? 'tarjetaNuevaAlter' : 'tarjetaNueva'">

  <div style="width: 10vw !important; color: white; margin-right: 10px; " class="table-wrapper">
    <mat-grid-list cols="1" rowHeight="55px">
      <!-- <mat-grid-tile style="border-color: #000096;"> </mat-grid-tile> -->

      <mat-grid-tile>
        Ruta
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let bus of mystate.busNames; index as i" (click)="setCurrentRoute(bus, i)"
        [style.background]="routeBackground[i]" [style.color]="routeColor[i]" (mouseover)="hoverParaRuta(bus)"
        (mouseout)="hoverOutParaRuta(bus)" [style.cursor]="'pointer'">
        {{bus}}
      </mat-grid-tile>


    </mat-grid-list>
  </div>

  <div *ngIf="mystate.currentRoute !=='None'">
    <mat-grid-list cols="4" rowHeight="55px" style="width: 70vw !important">
      <mat-grid-tile style="border-color: #000096;"> </mat-grid-tile>

      <mat-grid-tile [style.background]="ninefontcolor" [style.color]="ninemcolor">
        <mat-icon *ngIf="ninemcolor !== 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-azul"> </mat-icon>
        <mat-icon *ngIf="ninemcolor === 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-blanco"> </mat-icon>
        9 metros
      </mat-grid-tile>

      <mat-grid-tile [style.background]="twelvefontcolor" [style.color]="twelvemcolor">
        <mat-icon *ngIf="twelvemcolor !== 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-azul"> </mat-icon>
        <mat-icon *ngIf="twelvemcolor === 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-blanco"> </mat-icon>
        12 metros
      </mat-grid-tile>
      <mat-grid-tile [style.background]="eighteenfontcolor" [style.color]="eighteenmcolor">
        <mat-icon *ngIf="eighteenmcolor !== 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-azul"> </mat-icon>
        <mat-icon *ngIf="eighteenmcolor === 'white'" style="align-self: center; justify-self: center;"
          svgIcon="configuracion-del-bus-blanco"> </mat-icon>
        Articulado
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="3" [style.background]="oportunosFontColor" [style.color]="oportunosColor">
        <div style="display: flex; flex-direction: column;  text-align: center; justify-content: center;">
          <mat-icon *ngIf="oportunosColor !== 'white'"
            style="align-self: center; justify-self: center; font-size: 13px;" svgIcon="Oportunidad_Azul2"> </mat-icon>
          <mat-icon *ngIf="oportunosColor === 'white'"
            style="align-self: center; justify-self: center; font-size: 13px; " svgIcon="Oportunidad_Blanco2">
          </mat-icon>
          Pantógrafo
        </div>
      </mat-grid-tile>


      <mat-grid-tile *ngFor="let tilo of oportunos; index as i" (click)="setOpportunityOption(i, 1)"
        class="mat-grid-tile opciones" [style.background]="tilo.color" [style.cursor]="'pointer'"
        [style.color]="tilo.color === '#4bc9ff' ? '#000096' : (tilo.viable ? 'white' : 'gray')">
        <div class="onetest">{{tilo.text}}</div>
        <div style="margin-left: 5px; margin-top: 10px;  width: 50% !important; text-align: end; font-size: 5px !important;"
          [style.color]="(auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute] !== undefined && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].size === tilo.size && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].battery === tilo.battery)? 'green' : 'gray'"
          *ngIf="tilo.text !== '-'">
          <!-- <mat-icon (click)="saveBusConfiguration(i, 'Opportunity')">check_circle_outline</mat-icon> -->
          <mat-icon  (click)="saveBusConfiguration(i, 'Opportunity')" [svgIcon]=" (auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute] !== undefined && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].size === tilo.size && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].battery === tilo.battery) ? 'Save1': 'Save2'">
          </mat-icon>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="7" [style.background]="nocturnosFontColor" [style.color]="nocturnosColor">
        <div style="display: flex; flex-direction: column;  text-align: center; justify-content: center;">
          <!-- <mat-icon style="align-self: center; justify-self: center;">tonality </mat-icon> -->
          <mat-icon *ngIf="nocturnosColor !== 'white'"
            style="align-self: center; justify-self: center; font-size: 13px;" svgIcon="Nocturno_Azul2"> </mat-icon>
          <mat-icon *ngIf="nocturnosColor === 'white'"
            style="align-self: center; justify-self: center; font-size: 13px;" svgIcon="Nocturno_Blanco2"> </mat-icon>
          Conector
        </div>
      </mat-grid-tile>

      <mat-grid-tile *ngFor="let tile of nocturnos; index as i" (click)="setOvernightOption(i, 1)"
        class="mat-grid-tile opciones" [style.background]="tile.color" [style.cursor]="'pointer'"
        [style.color]="tile.color === '#4bc9ff' ? '#000096' : (tile.viable ? 'white' : 'gray')">
        <div class="onetest">{{tile.text}}</div>
        <div style="margin-left: 5px; margin-top: 10px; width: 50% !important; text-align: end;"
          [style.color]="(auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute] !== undefined && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].size === tile.size && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].battery === tile.battery)? 'green' : 'gray'"
          *ngIf="tile.text !== '-'">
          <!-- <mat-icon (click)="saveBusConfiguration(i, 'Overnight')">check_circle_outline</mat-icon> -->
          <mat-icon  (click)="saveBusConfiguration(i, 'Overnight')" [svgIcon]=" (auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute] !== undefined && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].size === tile.size && auth.currentBusConfigurations[mystate.currentCollection+mystate.currentRoute].battery === tile.battery) ? 'Save1': 'Save2'">
          </mat-icon>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <div style="display: flex; justify-content: space-around; padding: 5px; margin-top: 5px; color: white !important;">

      <div style="margin-top: 10px; color: white !important; " *ngIf="!data.isHVAC">
        <mat-label style="margin-right: 30px; font-size: 16px;  font-family: 'Poppins' !important; ">HVAC: </mat-label>
        <mat-radio-group [(ngModel)]="mystate.currentHVAC" (change)="setCurrentHVAC($event)" [disabled]="data.isHVAC">
          <mat-radio-button value="hvacOFF">OFF</mat-radio-button>
          <mat-radio-button value="hvacON">ON</mat-radio-button>
        </mat-radio-group>
      </div>
      <div style="margin-top: 10px; color: white !important; " *ngIf="data.isHVAC">
        <mat-label style="margin-right: 30px; font-size: 16px;  font-family: 'Poppins' !important; ">HVAC: ON </mat-label>
      </div>
      <div style="margin-top: 10px; color: white !important; ">
        <mat-label style="margin-right: 20px; font-size: 16px;  font-family: 'Poppins' !important;">Ciclo de Manejo:
        </mat-label>
        <mat-radio-group [(ngModel)]="data.drivingCycle" (change)="setCurrentDrivingCycle($event)" >
          <mat-radio-button *ngFor="let opcion of drivingcyclelist" value="{{opcion.op}}">{{opcion.op}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div style="margin-top: 10px; color: white ; " *ngIf="!data.isBRT">
        <mat-label style="margin-right: 20px; font-size: 16px;  font-family: 'Poppins' !important;">Pasajeros:
        </mat-label>
        <mat-radio-group [(ngModel)]="mystate.currentPax" (change)="setCurrentPax($event)" [disabled]="data.isBRT" >
          <mat-radio-button *ngFor="let opcion of paxreal" value="{{opcion.op}}"  style="color: white !important;">{{opcion.op}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div style="margin-top: 10px; color: white !important; " *ngIf="data.isBRT">
        <mat-label style="margin-right: 30px; font-size: 16px;  font-family: 'Poppins' !important; ">Pasajeros: HIGH </mat-label>
      </div>
    </div>
  </div>
</div>
