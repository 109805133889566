import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { StateService } from '../state/state.service';
@Injectable({
  providedIn: 'root'
})
export class MapService {

  mapbox = (mapboxgl as typeof mapboxgl);
  map: mapboxgl.Map;
  style = 'mapbox://styles/gcoll2006/cjmmwkckvdtsj2snw561f80gj';
  lat = 0.75;
  lng = -50.41;
  zoom = 2;
  chargers = [];
  marcadoresParadas = [];
  chargersexample = [];
  constructor(private state: StateService) {
    this.mapbox.accessToken = environment.mapbox.accessToken;
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'mapita',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat]
    });
    //this.map.addControl(new mapboxgl.NavigationControl());
  }
  collectionChargers(): void {
    console.log("Entrando collecion cargadores mapa")
    console.log("cargadores location generacion mapa", this.state.allLocations)
    for (const oneStop of this.state.allLocations) {
      this.map.addLayer({
        id: oneStop.colName,
        source: oneStop.colName,
        type: 'circle',
        paint: {
          'circle-radius': 10,
          'circle-color': '#000096',
          'circle-opacity': 1,
          //'circle-color': '#D5E317',
        },
      });
      setTimeout(() => {
        this.addChargersToMap(oneStop.chargernames, oneStop.chargerlatitudes, oneStop.chargerlongitudes);

      }, 1000);
    }
  }
  deleteAllChargersToMap() {
    this.chargers.forEach(element => {
      element.remove();
    });
    this.chargers = [];

  }
  //FUNCION REAL QUE DIBUJA LOS CARGADORES
  addSelectedCharger(names: string[], latitude: number[], longitude: number[]): void {
    console.log("Entrando a addSelectedCharger")
    for (const car of this.chargers) {
      car.remove();
    }
    this.chargers = [];
    for (let jota = 0; jota < names.length; jota++) {

      const ele = document.createElement('div');
      ele.className = 'busanimado';
      ele.style.backgroundImage =
        'url(../../../assets/chargador.png)';
      ele.style.backgroundSize = '100%';
      // console.log('creando cargador para ', names[jota]);
      this.chargers.push(
        new mapboxgl.Marker(ele)
          .setLngLat([longitude[jota], latitude[jota]])
          .addTo(this.map)
      );
      //console.log("this.chargers : ", this.chargers)
      this.chargers[this.chargers.length - 1].getElement().addEventListener('click', () => {
        //this.state.showChargersData = !this.state.showChargersData;
        //this.state.selectedCharger = names[jota];
      }); // */  
    }
  }

  //ESTA FUNCION NO SE UTILIZA
  //EN LISTA PARA SU ELIMINACION
  addChargersToMap(names: string[], latitude: number[], longitude: number[]): void {
    console.log("Entrando a addChargersToMap");
    for (const car of this.chargers) {
      car.remove();
    }
    this.chargers = [];
    for (let jota = 0; jota < names.length; jota++) {

      const ele = document.createElement('div');
      ele.className = 'busanimado';
      ele.style.backgroundImage =
        'url(../../../assets/chargador.png)';
      ele.style.backgroundSize = '100%';
      // console.log('creando cargador para ', names[jota]);
      this.chargers.push(
        new mapboxgl.Marker(ele)
          .setLngLat([longitude[jota], latitude[jota]])
          .addTo(this.map)
      );
      //console.log("this.chargers : ", this.chargers)
      this.chargers[this.chargers.length - 1].getElement().addEventListener('click', () => {
        this.state.showChargersData = !this.state.showChargersData;
        this.state.selectedCharger = names[jota];
      }); // */  
    }
  }
  addSupuestoDepot() {

    var atuarea2 = [
      [-77.14933202818385, -12.06694470266739],
      [-77.14944712952821, -12.06636513062819],
      [-77.1504727897226, -12.06647036321244],
      [-77.15135168278208, -12.06673412288915],
      [-77.14933202818385, -12.06694470266739]]

    var atuarea4 = [
      [-77.14330329655319, -12.06742466564505],
      [-77.14266734481889, -12.06754282878026],
      [-77.14192209503017, -12.06767801771918],
      [-77.14216802744156, -12.06625675257229],
      [-77.14348133972248, -12.06642099944418],
      [-77.14330329655319, -12.06742466564505]]

    var atuarea5 = [
      [-77.01440045996668, -12.05680232865641],
      [-77.01429840131919, -12.05701301038981],
      [-77.0142166544551, -12.05711857767517],
      [-77.01403207593594, -12.05690677533974],
      [-77.01420408632531, -12.05674399312296],
      [-77.01403842308619, -12.05651845201909],
      [-77.01423219799418, -12.05637700087365],
      [-77.01446545854937, -12.05665349302032],
      [-77.01440045996668, -12.05680232865641]]

    var atuarea6 = [
      [-77.0136049076914, -12.05554974105916],
      [-77.01366762393199, -12.05563246534415],
      [-77.01344670123781, -12.05581575455597],
      [-77.01337378730639, -12.05572310129193],
      [-77.0136049076914, -12.05554974105916]]

    var atuarea7 = [
      [-77.01237498736083, -12.05441045838119],
      [-77.0129599803425, -12.05521203685678],
      [-77.01274430658655, -12.05537932921909],
      [-77.01217230280949, -12.05459869538279],
      [-77.01237498736083, -12.05441045838119]]

    var atuarea3 = [
      [-77.14617237759897, -12.06719797017011],
      [-77.14610224881444, -12.0672838206397],
      [-77.14594416324231, -12.06733083977794],
      [-77.14483808719662, -12.06742893561967],
      [-77.14495476862393, -12.06688486772861],
      [-77.14620761126197, -12.06704859670873],
      [-77.14617237759897, -12.06719797017011]]

    var atuPatioSIB = [
      [-76.98823473433993, -12.10191373605472],
      [-76.98833371914897, -12.1030398943769],
      [-76.98649889912754, -12.10291591375656],
      [-76.98641735178275, -12.10207509909314],
      [-76.98823473433993, -12.10191373605472]
    ]
    var listadepot = [atuarea2, atuarea3, atuarea4, atuarea5, atuarea6, atuarea7, atuPatioSIB]
    console.log("listaDepotDemo", this.state.listaDepotDemo, this.state.listaDepotDemo.length)
    if (this.state.listaDepotDemo.length != 0) {
      console.log("Entrando a listaDepotDemo 0")
      while (this.state.listaDepotDemo.length > 0) {
        var deletesourceLayer = this.state.listaDepotDemo.pop();
        var deletesourceName = deletesourceLayer.source;
        var deletelayerName = deletesourceLayer.layer;

        //this.map.removeLayer(deletelayerName);
        //this.map.removeSource(deletesourceName);
      }
    }
    this.state.listaDepotDemo = []
    for (let i = 0; i < listadepot.length; i++) {
      var sourceName = 'depotdemo-source-' + i;
      var layerName = 'depotdemo-layer-' + i;
      this.state.listaDepotDemo.push({ source: sourceName, layer: layerName });

      var element = listadepot[i];
      this.map.addSource(sourceName, {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: element,
          },
        },
      });
      this.map.addLayer({
        id: layerName,
        type: 'line',
        source: sourceName,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#F50C01 ',
          //'line-color': '#F7BD34',
          'line-width': 3,
        },
      });

    }
    console.log("listaDepotDemo", this.state.listaDepotDemo)

  }
  deleteSupuestoDepot() {
    while (this.state.listaDepotDemo.length > 0) {
      var deletesourceLayer = this.state.listaDepotDemo.pop();
      var deletesourceName = deletesourceLayer.source;
      var deletelayerName = deletesourceLayer.layer;

      this.map.removeLayer(deletelayerName);
      this.map.removeSource(deletesourceName);
    }
  }
  calcularPuntosCardinales(norte, sur, este, oeste, rotacion) {
    const puntosCardinales = [];

    if (rotacion === 0 || rotacion === 360) {
      puntosCardinales.push('norte', 'este', 'sur', 'oeste');
    } else if (rotacion === 90) {
      puntosCardinales.push('este', 'sur', 'oeste', 'norte');
    } else if (rotacion === 180) {
      puntosCardinales.push('sur', 'oeste', 'norte', 'este');
    } else if (rotacion === 270) {
      puntosCardinales.push('oeste', 'norte', 'este', 'sur');
    } else {
      const puntos = ['norte', 'este', 'sur', 'oeste'];
      const index = Math.floor(rotacion / 90) % 4;
      for (let i = 0; i < 4; i++) {
        puntosCardinales.push(puntos[(i + index) % 4]);
      }
    }
  }
  dibujaroverlay() {
    //const norte = 29.94639813196435; // Valor de latitud al norte
    //const sur = 29.94450599214739; // Valor de latitud al sur
    //const este = 31.10116783317994; // Valor de longitud al este
    //const oeste = 31.09900550737398; // Valor de longitud al oeste

    const norte = 29.945846225924930; // Valor de latitud al norte
    const sur = 29.944134063375696; // Valor de latitud al sur
    const este = 31.099521357772105; // Valor de longitud al este
    const oeste = 31.101478006657680; // Valor de longitud al oeste
    // Paso 1: Calcula el centro geográfico
    const centroLatitud = (norte + sur) / 2;
    const centroLongitud = (este + oeste) / 2;

    // Paso 2: Calcula las esquinas del área
    const distanciaLatitud = Math.abs(norte - sur) / 2;
    const distanciaLongitud = Math.abs(este - oeste) / 2;

    const esquinaSuperiorIzquierda = [centroLongitud - distanciaLongitud, centroLatitud + distanciaLatitud];
    const esquinaSuperiorDerecha = [centroLongitud + distanciaLongitud, centroLatitud + distanciaLatitud];
    const esquinaInferiorDerecha = [centroLongitud + distanciaLongitud, centroLatitud - distanciaLatitud];
    const esquinaInferiorIzquierda = [centroLongitud - distanciaLongitud, centroLatitud - distanciaLatitud];

    var imgoverlay = "Depot3Cairo.png"
    this.map.addSource('radar', {
      'type': 'image',
      'url': '../../../assets/imgdepot/Depot3Cairo.png',
      'coordinates': [
        [31.101478006657680,
          29.945846225924930],

        [31.099521357772105,

          29.946766648670030],



        [31.100672592173126,

          29.944134063375696],



        [31.098715943287555,

          29.945054486120796]
      ],
    });
    this.map.addLayer({
      id: 'radar-layer',
      'type': 'raster',
      'source': 'radar',
      'paint': {
        'raster-fade-duration': 0,
        'raster-opacity': 1,
        'raster-hue-rotate': -25,
      },

    })
  }
  dibujarimagenDepot() {
    // Crear un marcador en las coordenadas deseadas
    var listanameimg = ["depot1.png", "depot2.png", "depot3.png"]
    for (let i = 0; i < this.state.datosCurrentCollection["namedepot"].length; i++) {
      var namedepot = this.state.datosCurrentCollection["namedepot"][i];
      var latdepot = this.state.datosCurrentCollection["latitudedepot"][i];
      var longdepot = this.state.datosCurrentCollection["longitudedepot"][i];
      var eles = document.createElement('div')
      eles.className = 'markerDepotimg'
      var marker = new mapboxgl.Marker(eles)
        .setLngLat([longdepot, latdepot])
        .setPopup(
          new mapboxgl.Popup({
            offset: 25,
            closeButton: false,
            closeOnClick: true,
            className: 'custom-popup'
          }).setHTML(
            '<img src="../../../assets/imgdepot/' + listanameimg[i] + '" alt="" style="	width: 100%;height: 100%;">'
          )
        )
        .addTo(this.map)
    }

  }
  dibujarDepot(datos) {
    //this.dibujarimagenDepot()
    //this.dibujaroverlay()
    console.log("datos depot", datos)
    console.log("listaDepotMapa", this.state.listaDepotMapa)
    if (this.state.listaDepotMapa.length != 0) {
      for (let i = 0; i < this.state.listaDepotMapa.length; i++) {
        let element = this.state.listaDepotMapa[i];
        this.map.removeLayer(element.layer)
        this.map.removeSource(element.source)
        this.state.listaDepotMapa.pop()
      }
    }
    let coordDepot = []
    if (datos["longitudedepot"] == null || datos["longitudedepot"].length == 0) {
      return;
    }
    for (let i = 0; i < datos["longitudedepot"].length; i++) {
      let lgdepot = datos["longitudedepot"][i];
      let ltdepot = datos["latitudedepot"][i];
      coordDepot.push([lgdepot, ltdepot])
    }
    this.state.listaDepotMapa.push({ source: 'depotS', layer: 'depotL'})
    this.map.addSource('depotS', {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordDepot,
        },
      },
    });
    this.map.addLayer({
      id: 'depotL',
      type: 'line',
      source: 'depotS',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#F50C01 ',
        'line-width': 3,
      },
    });
  }
  addMarkerTest() {
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-77.032, 38.913]
          },
          properties: {
            title: 'Mapbox',
            description: 'Washington, D.C.'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-122.414, 37.776]
          },
          properties: {
            title: 'Mapbox',
            description: 'San Francisco, California'
          }
        }
      ]
    };


    //for (let jota = 0; jota < names.length; jota++) {
    //  const ele = document.createElement('div');
    //  ele.className = 'busanimado';
    //  ele.style.backgroundImage =
    //    'url(../../../assets/chargador.png)';
    //  ele.style.backgroundSize = '100%';
    //  // console.log('creando cargador para ', names[jota]);
    //  this.chargers.push(
    //    new mapboxgl.Marker(ele)
    //      .setLngLat([longitude[jota], latitude[jota]])
    //      .addTo(this.mapser.map)
    //  );
    //  //console.log("this.chargers : ", this.chargers)
    //  this.chargers[this.chargers.length - 1].getElement().addEventListener('click', () => {
    //    this.state.showChargersData = !this.state.showChargersData;
    //    this.state.selectedCharger = names[jota];
    //  }); // */

    for (const feature of geojson.features) {
      // create a HTML element for each feature
      const el = document.createElement('div');
      el.className = 'busanimado';
      el.style.backgroundImage = 'url(../../../assets/chargador.png)';
      el.style.backgroundSize = '100%';
      const long = feature.geometry.coordinates[0]
      const lat = feature.geometry.coordinates[1]
      this.chargersexample.push(
        new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(this.map)
      );
      this.chargersexample[this.chargersexample.length - 1].getElement().addEventListener('click', () => {
        this.state.showChargersData = !this.state.showChargersData;
        // this.state.selectedCharger = names[jota];
      });
      // make a marker for each feature and add to the map
      //[longitude[jota], latitude[jota]]
      //new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(this.map);
    }
  }

  async addCurrentRouteStopsServices(nombre, paradaslat, paradaslong, paradasdistance): Promise<void> {
    console.log("=== maps.services - F - addCurrentRouteStopsServices ===")
    //console.log(nombre, paradaslat, paradaslong, paradasdistance)
    for (const i of this.marcadoresParadas) {
      i.remove();
    }
    this.marcadoresParadas = [];

    const tam = nombre.length;
    for (let i = 0; i < tam - 1; i++) {
      const ele = document.createElement('div');
      let cabecera = 'Parada #' + i;
      if (i === 0) {
        ele.className = 'markerEnds';
        cabecera = 'Cabecera';
      } else {
        ele.className = 'marker';
      }
      const dis = (paradasdistance[i] / 1000).toFixed(2);
      this.marcadoresParadas.push(
        new mapboxgl.Marker(ele)
          .setLngLat([paradaslong[i], paradaslat[i]])
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              closeOnClick: true,
            }).setHTML(
              '<h2> ' +
              cabecera +
              '</h2>' +
              '<p>' +
              nombre[i] +
              '</p>' +
              '<p> Distancia actual <br />' +
              dis +
              ' km </p>'
            )
          )
          .addTo(this.map)
      );
      //Asi se elimina los marcadores del mapa ->
      //for (const i of this.marcadores) {
      //  i.remove();
      //}
      //this.marcadores=[]
    }

  }
  deleteRouteStopServices() {
    for (const i of this.marcadoresParadas) {
      i.remove();
    }
    this.marcadoresParadas = []
  }

  borrarRutasPreviasGenerales() {
    for (let i = 0; i < this.state.allCoordinates.length; i++) {
      if (this.map.getLayer('routeGeneral' + i) && this.map.getSource('routeGeneral' + i)) {
        this.map.removeLayer('routeGeneral' + i)
        this.map.removeSource('routeGeneral' + i)
      }
    }

  }
  dibujarSoloRutaGeneral() {

    for (let i = 0; i < this.state.allCoordinates.length; i++) {
      let coordelement = this.state.allCoordinates[i];
      if (this.map.getLayer('routeGeneral' + i) && this.map.getSource('routeGeneral' + i)) {
        this.map.removeLayer('routeGeneral' + i)
        this.map.removeSource('routeGeneral' + i)
      }
      this.map.addSource('routeGeneral' + i, {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordelement,
          },
        },
      });
      this.map.addLayer({
        id: 'routeGeneral' + i,
        type: 'line',
        source: 'routeGeneral' + i,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#4BC3F2 ',
          'line-width': 2,
        },
      });
    }

  }
  dibujarRutaPrevia(ruta: any) {
    let indexRuta = this.state.busNames.indexOf(ruta)
    let coordRuta = this.state.allCoordinates[indexRuta]


    if (this.map.getLayer('routePrevia') && this.map.getSource('routePrevia')) {
      this.map.removeLayer('routePrevia')
      this.map.removeSource('routePrevia')
    }
    this.map.addSource('routePrevia', {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordRuta,
        },
      },
    });
    this.map.addLayer({
      id: 'routePrevia',
      type: 'line',
      source: 'routePrevia',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#6495ED ',
        'line-width': 3,
      },
    });

    let dataParadasSelected = this.state.dataAllRouteProerties[ruta]
    console.log("paradas", ruta, dataParadasSelected)
    let tam = dataParadasSelected.namestops.length;
    this.deleteRouteStopServices()
    for (let i = 0; i < tam - 1; i++) {
      const ele = document.createElement('div');
      let cabecera = 'Parada #' + i;
      if (i === 0) {
        ele.className = 'markerEnds';
        cabecera = 'Cabecera';
      } else {
        ele.className = 'marker';
      }

      this.marcadoresParadas.push(
        new mapboxgl.Marker(ele)
          .setLngLat([dataParadasSelected.longitudestops[i], dataParadasSelected.latitudestops[i]])
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              closeOnClick: true,
            }).setHTML(
              '<h2> ' +
              cabecera +
              '</h2>' +
              '<p>' +
              dataParadasSelected.namestops[i] +
              '</p>'
            )
          )
          .addTo(this.map)
      );

    }

  }
}
