<div class="barra">
  <!-- <div class="imagenContainer"> -->
  <img
    style="height: 90px; text-align: center; align-items: center; z-index: 99;"
    src="./../../../../assets/logios_blanco.png"
    alt="The Logios Logo"
  />
  <!-- </div> -->
</div>

<div class="demo_wrap">
  <img src="./../../../../assets/fondoBuses.jpg" />
  <div class="myBack"></div>
</div>
<!-- (ngSubmit)="onSubmit('r')" -->
<div class="nueva">
  <form
    fxLayout="column"
    [formGroup]="form"

    style="
      display: flex;
      flex-direction: column;

      color: blue;
      width: 100%;
    "
  >
  <h2 class="cadena">{{this.dataTR.registro[this.mystate.indiceTranslate]}}</h2>
  <h1 *ngIf="registrado">{{this.dataTR.successregister[this.mystate.indiceTranslate]}}</h1>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
      style="margin-top:20px;"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.nombrecontacto[this.mystate.indiceTranslate]}}
          formControlName="contactname"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.organizacion[this.mystate.indiceTranslate]}}
          formControlName="organization"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.correo[this.mystate.indiceTranslate]}}
          formControlName="email"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.rptcorreo[this.mystate.indiceTranslate]}}
          formControlName="email2"
        />
      </mat-form-field>
    </div>
    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="password"
          placeholder={{this.dataTR.password[this.mystate.indiceTranslate]}}
          formControlName="password1"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="password"
          placeholder={{this.dataTR.rptpassword[this.mystate.indiceTranslate]}}
          formControlName="password2"
        />
      </mat-form-field>
    </div>

    <!-- <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Correo secundario"
          formControlName="emailBackUp"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Repita el correo secundario"
          formControlName="emailBackUp2"
        />
      </mat-form-field>
    </div> -->

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.cel[this.mystate.indiceTranslate]}}
          formControlName="phone"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.direccion[this.mystate.indiceTranslate]}}
          formControlName="address"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.pais[this.mystate.indiceTranslate]}}
          formControlName="country"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.estado[this.mystate.indiceTranslate]}}
          formControlName="state"
        />
      </mat-form-field>
    </div>

    <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex="50%" style="width: 150px;">
        <input
          matInput
          type="text"
          placeholder={{this.dataTR.ciudad[this.mystate.indiceTranslate]}}
          formControlName="city"
        />
      </mat-form-field>

    </div>

    <mat-error *ngIf="loginInvalid">
      {{this.dataTR.errorform[this.mystate.indiceTranslate]}}: {{ elError }}
    </mat-error>

    <mat-error *ngIf="loginInvalid">
      {{ recuerde }}
    </mat-error>

    <button (click)="onSubmit('r')" type="submit"
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        align-self:center;
        max-width: 200px;
      "
      mat-raised-button
    >
    {{this.dataTR.registrar[this.mystate.indiceTranslate]}}
    </button>

    <button (click)="onSubmit('l')" type="submit"
      style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 0px;
        margin-bottom: 20px;
        align-self:center;
        max-width: 200px;
      "
      mat-raised-button
    >
    {{this.dataTR.inicio[this.mystate.indiceTranslate]}}
    </button>
  </form>
</div>
