import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { DataService } from '../../../shared/data/data-service.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-charging-sites',
  templateUrl: './charging-sites.component.html',
  styleUrls: ['./charging-sites.component.scss']
})
export class ChargingSitesComponent implements OnInit, AfterViewInit, OnChanges {
  multi: any = [];
  chartStats1: any = [];
  chartStats2: any = [];
  chartStats3: any = [];
  chartStats4: any = [];
  view: any = [1200, 500];
  grafica = 0;
  docDefinition: any;
  chartNames: any[] = [];
  public title!: string;
  public primaryXAxis!: object;
  public primaryYAxis!: object;
  public tooltip!: object;
  ancho = '';
  alto = '';

  // options
  legend = true;
  showLabels = true;
  animations = false;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Servicio';
  yAxisLabel = 'Consumo (kWh/km)';
  timeline = false;
  legendTitle = '';
  autoScale = true;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;

  colorScheme = {
    domain: ['#88898C', '#000096', '#ff0000'],
  };

  icono: boolean = true;

  @ViewChild('chart2')
  public chart!: ChartComponent;

  @Input() grande: boolean;
  axisy: { labelFormat: string; title: string; titleStyle: { fontFamily: string; size: string; }; labelStyle: { fontFamily: string; size: string; }; };
  axisx: { valueType: string; title: string; titleStyle: { fontFamily: string; size: string; }; labelStyle: { fontFamily: string; size: string; }; };

  constructor(
    // private dataSer: DataService,
    public state: StateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef
  ) {
    /* this.view = [innerWidth / 2.5, innerHeight / 3.5];
    this.ancho = innerWidth / 2.5 + '';
    this.alto = innerHeight / 3.5 + ''; */
    this.matIconRegistry.addSvgIcon(
      'descargar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/SGV/descargar-azul.svg'
      )
    );
  }

  async ngOnInit(): Promise<void> {
    this.tooltip = { enable: true };
    this.axisy = {
      labelFormat: '{value}',
      title: 'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      }
    };
    this.axisx = {
      valueType: 'Category',
      title: 'Parada',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };
  }

  print(){
    this.chart.print();
  }

  ngAfterViewInit(): void {
    // console.log('Afterviewinit');
    // this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    // this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 60) + '';
    // this.alto = this.elRef.nativeElement.parentNode.offsetHeight - (this.elRef.nativeElement.parentNode.offsetHeight*0.5) + '';
    // this.ancho = innerWidth - this.elRef.nativeElement.parentNode.offsetHeight - 50 + ''; // */
    // console.log('offset Height', this.elRef.nativeElement.parentNode.offsetHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('En simplechanges');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  /* smallToBig(): void {
    console.log('Calling method smalltobig from consumotion dista');
    if (this.elRef.nativeElement.parentNode.offsetHeight - 85 > 0) {
      this.alto = this.elRef.nativeElement.parentNode.offsetHeight - 85 + '';
    }
  } */

  onResize(event: any): void {
    // console.log('onResize');
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  trackBy(index: any, item: any): string {
    return item.label;
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    // this.state.selectedPoint =
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)).series);
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
