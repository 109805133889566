import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StateService } from 'src/app/shared/state/state.service';
import { LocalstorageService } from 'src/app/shared/storage/localstorage.service';
import { AuthService } from '../../../shared/auth/auth-service.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    username: new FormControl('', [Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  private returnUrl = '';
  enviado = false;
  errores = false;

  constructor(
    private fb: FormBuilder,
    // private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private state: StateService,
    private lStore: LocalstorageService
  ) {
    /* console.log('Iniciando login token: ', this.authService.getToken());
    console.log('Iniciando login userStore: ', JSON.parse(this.authService.getUserStore())); */
  }

  ngOnInit(): void {
    this.returnUrl = 'animation';
    this.form = this.fb.group({
      username: ['', Validators.email]
    });

    const ctrl = new FormControl('some value');
    // console.log(ctrl.value);     // 'some value'
    // console.log('Name:' + this.form.get('username').value);


    if (this.authService.isLoggedIn) {
      this.authService.restartUserData();
      this.router.navigate([this.returnUrl]);
    }
  }

  get userName(): any {
    return this.form.get('username');
  }

  /* async onSubmit(): Promise<void> {
    console.log('usuario: ', this.userName.value);
    console.log('pass: ', this.userPass.value);
    if (this.form.get('username') === null) { return; }

  }*/

  async onSubmit(): Promise<void> {
    this.errores = false;
    if (this.form.valid) {
      try {
        const json = {email: this.userName.value};
        await this.authService.ResetPassword(json).then(() => {
          this.enviado = true;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        });
      } catch (err) {
        console.log('No se puede recupear contraseña, correo inválido');
        this.errores = true;

      }
    }
  }
}
