<div class="paradivsoc">
  <span style="color: white;  height: 50px;">SOC</span>


  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let item of items; index as i" style="padding:0px">
    <mat-grid-tile
      style="color: white; background-color: rgba(255,255,255,0.0);  border-radius: 0px; border-bottom: 1px solid white; border-top: 1px solid white;">
      <ejs-circulargauge [id]="item.id+'soc'" width="100" height="58" background="rgba(255,255,255,0.0)"
        style=" color: white;  border-radius: 0px; padding: 0px; margin: 0px;" [margin]='margin' #gaugesoc>
        <e-axes>
          <e-axis [majorTicks]="majorTicks" [minorTicks]="minorTicks" [labelStyle]="labelStyle">
            <e-annotations>
              <e-annotation zIndex='1' *ngIf="state.socGaugeValue[item.id] != -1"
              content='<div id="contento" style="color:white;font-size:15px;font-family:Segoe UI;font-weight:semibold;">{{state.socGaugeValue[item.id]}}&#37;</div>' angle=0 radius='0%'>
              </e-annotation>
            </e-annotations>
            <e-ranges>
              <e-range start=0 [end]="state.socGaugeValue[item.id]" color='#03fc1c' startWidth=5 endWidth=5>
              </e-range>
            </e-ranges>
            <e-pointers>
              <!-- <e-pointer [value]="state.powerGaugeValue[item.id]"></e-pointer> -->
            </e-pointers>
          </e-axis>
        </e-axes>
      </ejs-circulargauge>
    </mat-grid-tile>
  </mat-grid-list>
</div>
