import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, IPointEventArgs, StackingAreaSeriesService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-contable',
  templateUrl: './contable.component.html',
  styleUrls: ['./contable.component.scss']
})
export class ContableComponent implements OnInit {

  constructor(public dataSer: DataService, public state: StateService, private elRef: ElementRef) { }
  public dataTCon = this.state.dictTranslate.DashboardsGraph.contable;
  public seleccionchart = 0
  public axisyDRA: any
  public axisxDRA: any
  public axisyCAS: any
  public axisxCAS: any
  public axisyVUS: any
  public axisxVUS: any
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#4BC3F2'],
  };
  ancho = '';
  alto = '';
  public tooltip = { enable: true };
  public dataAnual:any
  public dataDescargaAnual:any
  public dataVidaUtil:any
  public listaPotencia:any = []
  public listaSitioCarga:any
  public selectedSC=0
  public cargaCompleta=true
  promciclosdescarga :any
  promvidautil :any

  @ViewChild('chartvel2')
  public chartvel2!: ChartComponent;
  visible: any = [];
  previousTarget = null;

  ngOnInit(): void {
    this.cargaCompleta = this.state.listaEstadosDashboard["contable"]
    this.dataAnual=this.state.listaDatosContable[this.state.currentOnlyRoute]
    this.dataDescargaAnual = this.state.listaDatosContable["cargaanual"]
    this.dataVidaUtil = this.state.listaDatosContable["vidautil"]
    var servicios = this.state.listaDatosContable["servicios"] + 1
    this.promciclosdescarga =[{ "x":0 ,"y":this.state.listaDatosContable["promciclosdescarga"].toFixed(2)},{ "x":servicios ,"y":this.state.listaDatosContable["promciclosdescarga"].toFixed(2)}]
    this.promvidautil =[{ "x":0 ,"y":this.state.listaDatosContable["promvidautil"].toFixed(2)},{ "x":servicios ,"y":this.state.listaDatosContable["promvidautil"].toFixed(2)}]
    //const numeros = [120, 140, 180, 200];    
    //const convertidos = numeros.map(num => `${num} kWh`);
    this.listaPotencia = this.state.listaDatosContable["potencias"].map(num=>`${num} kW`)
    console.log(this.listaPotencia, this.dataAnual)
    this.listaSitioCarga = this.state.listaDatosContable["sitioscarga"]
    this.selectedSC=0
    this.axisyDRA = {
      labelFormat: 'n0',
      title: this.dataTCon.consumte.ejey[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.0vw'
      }
    };
    this.axisxDRA = {
      //valueType: 'Category',
      labelFormat: '{value}°',      
      title:this.dataTCon.consumte.ejex[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      interval:1
    }
    this.axisyCAS={
      title:this.dataTCon.cicloanualser.ejey[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    }
    this.axisxCAS={  
      title:this.dataTCon.cicloanualser.ejex[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      interval:1
    }
    this.axisyVUS={
      title:this.dataTCon.vidautilser.ejey[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    }
    this.axisxVUS={
      title:this.dataTCon.vidautilser.ejex[this.state.indiceTranslate],
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      interval:1
    }
    setTimeout(() => {
      this.seleccionchart = 1
    }, 1500)
  }

  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }
  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
     
        for (var i = 0; i < this.chartvel2.series.length; i++) {
          this.chartvel2.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = false;
          } else {
            this.visible[i] = true;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel2.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel2.series.length; j++) {
            this.chartvel2.series[j].visible = true;
            this.visible[i] = true;
          }

          this.chartvel2.series[ids].visible = false;
          // this.visible[ids]= false;
          this.previousTarget = null;
          flag = true;
        }
      }
      if (!flag)
        this.previousTarget = ids;
    
  };
  cambiarSitioCarga(i){

  }
  irConsumoTE(){
    this.seleccionchart=1
  }
  irDescargaAnual(){
    this.seleccionchart=2
  }
  irCicloVida(){
    this.seleccionchart=3
  }
}
