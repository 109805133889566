import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-alert-sent',
  templateUrl: './alert-sent.component.html',
  styleUrls: ['./alert-sent.component.scss']
})
export class AlertSentComponent implements OnInit {
  loschargernames: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {

    this.state.allLocations.forEach((c: any) => {
      if (this.state.currentCollection === c.colName) {
        this.loschargernames = [...c.chargernames];
      }
    });
    // console.log('this.loschargernames: ', this.loschargernames);
  }


}

