import { Component, OnInit } from '@angular/core';
import { timeout } from 'rxjs';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-selected-vehicles',
  templateUrl: './selected-vehicles.component.html',
  styleUrls: ['./selected-vehicles.component.scss']
})
export class SelectedVehiclesComponent implements OnInit {
  batteries = [55, 63.5, 76, 315.07, 322, 324, 348, 350,373.4, 475, 102, 195, 216, 544, 640, 652];
  cellcolor: string[][] = [];
  auxiliar: string = "Prueba";

  constructor(public state: StateService, public data: DataService) {

  }

  ngOnInit(): void {
    // console.log('SelectedVehiclesComponent: ', this.data.allVehicles.length);
    // console.log('allVehicleProperties: ', this.data.allVehicleProperties);
    this.auxiliar = this.state.SohCritico;
    console.log("Este es el this.state.SohCritico de selected-vehicles",this.state.SohCritico)
    for (let j = 0; j < this.state.busNames.length; j++) {
      this.cellcolor[j] = [];
      // this.cellcolor[j].push('blue');
      let cadena = this.state.currentCollection + '_' + this.state.busNames[j];
      console.log("cadena seleccion vehiculo :", cadena)      
      const busselection = this.data.allVehicleProperties[cadena];
      console.log("this.data.allVehicleProperties[cadena]",this.data.allVehicleProperties)
      console.log("busselection :", busselection)      
      let salida = '';
      for (let i = 0; i < this.batteries.length; i++) {
        this.cellcolor[j].push('#000096');
        if (busselection !== undefined) {
          if (this.batteries[i] === busselection.batteryenergy) {
            if (i <= 8) {
              /// 12 m
              if (busselection.vehiclesize === 12) {
                if (i <= 2) {
                  // OPORT
                  if (busselection.chargingmethod === 'Opportunity') {
                    salida = 'X';
                    this.cellcolor[j][i] = '#4bc9ff';
                  }
                } else {
                  //NOCTUR
                  if (busselection.chargingmethod === 'Overnight') {
                    salida = 'X';
                    this.cellcolor[j][i] = '#4bc9ff';
                  }
                }
              }
            } else {
              /// ART
              if (busselection.vehiclesize === 18) {
                if (i <= 11) {
                  // OPORT
                  if (busselection.chargingmethod === 'Opportunity') {
                    salida = 'X';
                    this.cellcolor[j][i] = '#4bc9ff';
                  }
                } else {
                  if (busselection.chargingmethod === 'Overnight') {
                    salida = 'X';
                    this.cellcolor[j][i] = '#4bc9ff';
                  }
                }
              }
            }
          }
        }

        /* if (salida === 'X') {
          this.cellcolor[j].push('white');
        } else {
          this.cellcolor[j].push('blue');
        } */



      }

    }

  }

  bueno(busname: string, jota: number, batteryIndex: number): string {
    let cadena = this.state.currentCollection + '_' + busname;
    const busselection = this.data.allVehicleProperties[cadena];
    let salida = '';

    if (this.batteries[batteryIndex] === busselection.batteryenergy) {
      if (batteryIndex <= 7) {
        /// 12 m
        if (busselection.vehiclesize === 12) {
          if (batteryIndex <= 2) {
            // OPORT
            if (busselection.chargingmethod === 'Opportunity') {
              salida = 'X';
            }
          } else {
            //NOCTUR
            if (busselection.chargingmethod === 'Overnight') {
              salida = 'X';
            }
          }
        }
      } else {
        /// ART
        if (busselection.vehiclesize === 18) {
          if (batteryIndex <= 9) {
            // OPORT
            if (busselection.chargingmethod === 'Opportunity') {
              salida = 'X';
            }
          } else {
            if (busselection.chargingmethod === 'Overnight') {
              salida = 'X';
            }
          }
        }
      }
    }
    setTimeout(() => {
      if (salida === 'X') {
        this.cellcolor[jota][batteryIndex] = 'white';
      } else {
        this.cellcolor[jota][batteryIndex] = 'blue';
      }
    }, 500);

    return salida;
  }

}
