// import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkNhX35ZcXZUR2JdV0Y=');
//ORg4AjUWIQA/Gnt2VVhjQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhX35cdXZQQmBYWEY='


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
