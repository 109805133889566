import { environment } from '../../../environments/environment';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
  NgModule,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { Subscription } from 'rxjs';
import { IotaService } from 'src/app/shared/iota/iota.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Collection } from '@syncfusion/ej2-base';
import { CommonModule } from '@angular/common';
import { MapService } from 'src/app/shared/map/map.service';
// import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit, OnDestroy {
  idioma = 'Español'
  chart1 = false;
  chart2 = false;
  chart3 = false;
  chart4 = false;
  chart5 = false;
  verdad = true;
  first = false;
  whichColor = 1;
  names = [
    'layers_azul2',
    'layers_blanco2'
  ]
  whichicon = 'layers_blanco2';

  pointIndexVisible = false;
  ttt = 'cdkOverlayOrigin';
  map: any;
  style = 'mapbox://styles/gcoll2006/cjmmwkckvdtsj2snw561f80gj';
  styleNormal = true;
  lat = 37.75;
  lng = -122.41;
  public allData: number[][] = [];
  private stops: any = [];
  private chargers: any = [];
  private depots: any = [];
  private marcadores: any = [];
  private busMarker: any;
  private redPointMarker: any;
  private busesMarkers: any = [];
  private busesMarkersNames: any = {};
  private allStops: any = [];
  private allLocations: any = [];
  private popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });
  public dataTimg = this.state.dictTranslate.urlimages
  images = {
    popup: 'assets/busiconNormal.png',
    'busOrange':
      'assets/busiconOrange.png',
  };
  misimagenes = [{
    url: 'assets/busiconNormal.png',
    id: 'busnormal'
  },
  {
    url: 'assets/busiconOrange.png',
    id: 'busorange'
  },
  ];
  laMax = 0;
  loMax = 0;
  laMin = 0;
  loMin = 0;
  countPos: any = 0;
  positionBus: any = {};

  PointIndexSubs: Subscription | undefined;
  LocationSubs: Subscription | undefined;
  RouteSubs: Subscription | undefined;
  CoordinatesAll: Subscription | undefined;
  LocationsAll: Subscription | undefined;
  AnimationsSubs: Subscription | undefined;

  miTimeout: ReturnType<typeof setTimeout>;
  lastwhichRoute: any;
  allUpSubs: Subscription;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataSer: DataService,
    public state: StateService,
    private auth: AuthService, // private realTime: SocketService
    private iota: IotaService,
    public mapser: MapService,
  ) {
    for (const entry of this.names) {
      this.matIconRegistry.addSvgIcon(
        entry,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/SGV/' + entry + '.svg'
        )
      );
    }
  }

  async ngOnInit(): Promise<void> {
    // console.log('Los buses: ', this.state.busNames);
    console.log("DIBUJAR MAPA")
    this.state.inMap = true;
    this.state.currentRoute = 'None';
    this.allData = [[0, 0]];
    this.stops = [[0, 0]];
    this.chargers = [];
    this.depots = [];
    const elZoom = 2;
    //this.mapser.map = new mapboxgl.Map({
    //  accessToken: environment.mapbox.accessToken,
    //  container: 'mapita',
    //  style: this.style,
    //  // center: [-79.549658, 8.972557],
    //  center: [0, 0],
    //  zoom: elZoom,
    //});
    this.map = this.mapser.buildMap();
    const nav = new mapboxgl.NavigationControl();

    console.log("Agregando Boton de navegacion del mapa")
    this.mapser.map.addControl(nav, 'bottom-right');
    // console.log('Los buses: ',  this.state.busNames);

    console.log("Agregando Rutas")
    this.subscribeToBusRoute();

    console.log("Subscripcion de Location")
    this.subscribeLocation();

    console.log("Dibuja los cargadores 1")
    this.subscribeToAllCoordinates();

    //this.subscribeToAllLocations();
    this.subscribeToAnimation();
    this.subscribeToStopAnimation();
    this.subscribeToDetails();

    this.subscribeToPointIndex();
    await this.initRouteLayer();

    setTimeout(async () => {
      if (this.auth.userData.user.authCollections.length === 1) {
        this.state.processing = true;
        this.state.whichprocessing = 1;

        // this.state.currentCollection = this.auth.userData.user.authCollections[0];
        await this.dataSer.setBusNames(this.auth.userData.user.authCollections[0]);

        // await this.dataSer.getAllRoutesPropertiesEvo(this.auth.userData.user.authCollections[0]);
        this.dataSer.getAllCoordinates();// .then((c: any) => {
        // console.log('donegetting coor');
        this.getCenterOfCollection();
        // });

        this.state.whichprocessing = 2;
        this.state.currentRoute = 'None';
        this.state.currentType = '';
        this.state.currentBusSize = '';
        this.state.currentBattery = '';
        this.state.currentRoutePosition = -1;
        /* if (this.state.allRoutesShown) {
           this.state.allRoutesShown = false;
           this.state.changeAllCoordinates('');
         } */
        this.state.allRoutesShown = true;
        this.state.changeAllCoordinates('');
        await this.dataSer.getAllVehicles();
        const keys = Object.keys(this.auth.currentBusConfigurations);

        for (const llave of keys) {
          const datos = this.auth.currentBusConfigurations[llave];

          if (datos.collection === this.auth.userData.user.authCollections[0]) {
            const params = {
              type: datos.type,
              bus: datos.route,
              load: datos.hvac,
              pax: datos.pax,
              size: datos.size,
              battery: datos.battery,
              currentCollection: datos.collection
            }

            try {
              // console.log('Getting data of selected configurations and adding them to animations');

              await this.dataSer.getRouteProperties(datos.route, datos.collection);
              await this.dataSer.getAllCyclesInputsEvo(datos.route, datos.collection);
              await this.dataSer.getAllData(params);
              const entradas = {
                chargingmethod: datos.type,
                vehiclesize: parseInt(datos.size),
                batteryenergy: parseFloat(datos.battery),
                collection: datos.collection,
                route: datos.route
              };
              await this.dataSer.getVehicleProperties(entradas);

            } catch (error) {
              console.log('Could not get the data in maps component...');

            }
          }

        }
        this.state.processing = false;
        this.state.whichprocessing = 0;


        // */

        // console.log('Llamando a flyTo 1');
        this.mapser.map.flyTo({
          center: this.state.currentLocation,
          zoom: 12,
          speed: 0.7,
        });
        setTimeout(async () => {
          this.addDepotsToMap(this.state.allLocations[0].depotnames, this.state.allLocations[0].depotlatitudes, this.state.allLocations[0].depotlongitudes);
          //this.addChargersToMap(this.state.allLocations[0].chargernames, this.state.allLocations[0].chargerlatitudes, this.state.allLocations[0].chargerlongitudes);
        }, 10000);
      }
    }, 2000);

    if (this.state.indiceTranslate == 0) {
      this.idioma = 'Español'
    } else {
      this.idioma = 'English'
    }
  }

  cambiarIdioma() {
    console.log("indice de translate", this.state.indiceTranslate)
    if (this.state.indiceTranslate == 0) {
      this.state.indiceTranslate = 1
      this.idioma = 'English'
    } else {
      this.state.indiceTranslate = 0
      this.idioma = 'Español'
    }

  }
  async moveLocation(): Promise<void> {
    // console.log('Just moving');
    await this.dataSer.getAllCoordinates();

    this.state.allRoutesShown = true;
    this.state.changeAllCoordinates('');
    //this.addChargersToMap(this.state.allLocations[0].chargernames,
    //  this.state.allLocations[0].chargerlatitudes,
    //  this.state.allLocations[0].chargerlongitudes);
    this.getCenterOfCollection();
  }

  get pointIndexSubs(): any {
    return this.PointIndexSubs;
  }

  set pointIndexSubs(x: any) {
    this.PointIndexSubs = x;
  }

  get locationSubs(): any {
    return this.LocationSubs;
  }

  set locationSubs(x: any) {
    this.LocationSubs = x;
  }

  get routeSubs(): any {
    return this.RouteSubs;
  }

  set routeSubs(x: any) {
    this.RouteSubs = x;
  }

  get coordinatesAll(): any {
    return this.CoordinatesAll;
  }

  set coordinatesAll(x: any) {
    this.CoordinatesAll = x;
  }

  loadImages(urls: any, callback: any): void {
    const results: any = {};
    for (const name in urls) {
      if (urls.hasOwnProperty(name)) {
        this.mapser.map.loadImage(urls[name], makeCallback(name));
      }
    }

    function makeCallback(name: any): any {
      return (err: any, image: any): void => {
        results[name] = err ? null : image;
        // if all images are loaded, call the callback
        if (Object.keys(results).length === Object.keys(urls).length) {
          callback(results);
        }
      };
    }
  }

  showLayer(): void {
    this.mapser.map.setLayoutProperty('route', 'visibility', 'visible');
    // visibleTextEl.innerText = 'show'
  }

  hideLayer(): void {
    this.mapser.map.setLayoutProperty('route', 'visibility', 'none');
    // visibleTextEl.innerText = 'hide'
  }

  //EN ESTA FUNCION SE DIBUJA LAS PARADAS DE LA RUTA UNA VEZ SELECCIONADO LAS CONFIGURACIONES
  //DEL BUS
  async addCurrentRouteStops(): Promise<void> {
    console.log("=== maps.components - F - addCurrentRouteStops ===")
    this.mapser.deleteRouteStopServices()
    for (const i of this.marcadores) {
      i.remove();
    }
    this.marcadores = [];
    //if (this.state.currentRoute === 'None'){
    //  console.log("coleccion actual",this.state.currentCollection)
    //  await this.dataSer.getAllRoutesPropertiesEvo(this.state.currentCollection)
    //  console.log("routeproperties al seleccionar una coleccion", this.dataSer.allRouteProperties)
    //  console.log("la actual ruta es nula")
    //  return;
    //}
    // new mapboxgl.Marker(el).setLngLat([this.stops[0].lon, this.stops[0].lat]).addTo(this.mapser.map);
    // tslint:disable-next-line: forin
    const tam = this.stops.length;
    for (let i = 0; i < tam - 1; i++) {
      const ele = document.createElement('div');
      let cabecera = 'Parada #' + this.stops[i].sec;
      if (i === 0) {
        ele.className = 'markerEnds';
        cabecera = 'Cabecera';
      } else {
        ele.className = 'marker';
      }
      const dis = (this.stops[i].distancia / 1000).toFixed(2);
      this.marcadores.push(
        new mapboxgl.Marker(ele)
          .setLngLat([this.stops[i].lon, this.stops[i].lat])
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              closeOnClick: true,
            }).setHTML(
              '<h2> ' +
              cabecera +
              '</h2>' +
              '<p>' +
              this.stops[i].nombre +
              '</p>' +
              '<p> Distancia actual <br />' +
              dis +
              ' km </p>'
            )
          )
          .addTo(this.mapser.map)
      );
      //Asi se elimina los marcadores del mapa ->
      //for (const i of this.marcadores) {
      //  i.remove();
      //}
      //this.marcadores=[]
    }

  }


  addAllRouteStops(): void {
    for (const i of this.allStops) {
      i.remove();
    }
    console.log("Cambiando Puntos Stops Map", this.state.allStops)
    this.allStops = [];
    for (const oneStop of this.state.allStops) {
      const tam = oneStop.length;
      for (let i = 0; i < tam - 1; i++) {
        const ele = document.createElement('div');
        let cabecera = 'Parada #' + oneStop[i].sec;
        if (i === 0) {
          ele.className = 'markerEnds';
          cabecera = 'Cabecera';
        } else {
          ele.className = 'marker';
        }
        const dis = (oneStop[i].distancia / 1000).toFixed(2);
        this.allStops.push(
          new mapboxgl.Marker(ele)
            .setLngLat([oneStop[i].lon, oneStop[i].lat])
            .setPopup(
              new mapboxgl.Popup({
                offset: 25,
                closeButton: false,
                closeOnClick: true,
              }).setHTML(
                '<h2> ' +
                cabecera +
                '</h2>' +
                '<p>' +
                oneStop[i].nombre +
                '</p>' +
                '<p> Distancia actual <br />' +
                dis +
                ' km </p>'
              )
            )
            .addTo(this.mapser.map)
        );
      }
    }
  }

  subscribeToStopAnimation(): void {
    this.state.stopAnimationEvent.subscribe(() => {
      this.state.changeAnimation(['StopAnimation', 0]);
    });
  }

  removeOtherCyclesLines(whichstop: number) {
    for (let jota = 0; jota < this.state.cyclesListOnlyCC.length; jota++) {
      if (this.mapser.map.getLayer(this.state.cyclesListOnlyCC[jota] + whichstop) !== undefined) {
        this.mapser.map.removeLayer(this.state.cyclesListOnlyCC[jota] + whichstop);
        this.mapser.map.removeSource(this.state.cyclesListOnlyCC[jota] + whichstop);
      }
    }
  }

  subscribeToDetails(): void {
    this.state.showDetailsEvent.subscribe((value: any) => {
      // console.log('Data is ', value);
      if (value.whichone === 'clear') {
        this.state.cyclesListOnlyCC.forEach((cur: any, index: number) => {

          /*
          if (this.dataSer.allCyclesInputs[cur + '_1'] !== undefined) {
            let paradasindexone = this.dataSer.allCyclesInputs[cur + '_1'].indexstops;
            for (let jota = 0; jota < paradasindexone.length; jota++) {
              if (this.mapser.map.getLayer(cur + jota) !== undefined) {
                this.mapser.map.removeLayer(cur + jota);
                this.mapser.map.removeSource(cur + jota);
              }
            }
          }
          */
          if (this.dataSer.allCyclesInputs[cur] !== undefined) {
            let paradasindexone = this.dataSer.allCyclesInputs[cur].indexstops;
            for (let jota = 0; jota < paradasindexone.length; jota++) {
              if (this.mapser.map.getLayer(cur + jota) !== undefined) {
                this.mapser.map.removeLayer(cur + jota);
                this.mapser.map.removeSource(cur + jota);
              }
            }
          }

        });
        return;
      }
      // console.log('cyclesinputs: ', this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1']);
      if (this.mapser.map.getLayer('route' + this.lastwhichRoute) !== undefined) {
        this.mapser.map.removeLayer('route' + this.lastwhichRoute);
        this.mapser.map.removeSource('route' + this.lastwhichRoute);
      }
      console.log('----------------------------------------------')
      console.log('this.state.currentRoute: ', this.state.currentRoute);
      console.log('value.whichone: ', value.whichone);
      if (this.state.currentRoute != 'None' && value.whichone === 'vel') {
        //let latitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].latitude;
        //let longitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].longitude;
        /*
        let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].indexstops;
        let averagespeedstops = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].averagespeedstops;
        */
        let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].indexstops;
        let averagespeedstops = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].averagespeedstops;

        //let latitudetwo = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_2'].latitude;
        //let longitudetwo = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_2'].longitude;

        /*
        const lontotal = longitudeone.concat(longitudetwo);
        const lattotal = latitudeone.concat(latitudetwo);
        */

        const lattotal = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].latitude;
        const lontotal = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].longitude;

        console.log('paradasindexone: ', paradasindexone);

        let whichstop = 0;
        let lonlat = [];
        for (let i = 0; i < lattotal.length; i++) {
          lonlat.push([lontotal[i], lattotal[i]]);
          console.log('lonlat ', lonlat);
          if (i === paradasindexone[whichstop]) {
            console.log('paradasindex[whichstop]!!! ', paradasindexone[whichstop]);
            /* if (this.mapser.map.getLayer(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop) !== undefined) {
              this.mapser.map.removeLayer(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop);
              this.mapser.map.removeSource(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop);
            } */
            this.removeOtherCyclesLines(whichstop);
            console.log('Antes de this.mapser.map.addSource...');
            console.log(`${this.state.cyclesListOnlyCC[value.whichcycle] + whichstop}`);
            this.mapser.map.addSource(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop, {
              type: 'geojson',
              lineMetrics: true,
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: lonlat
                  // coordinates: this.allData,
                },
              },
            });
            let elcolor = '';
            if (averagespeedstops[whichstop] < 30) {
              elcolor = '#1848ED';
            } else if (averagespeedstops[whichstop] < 45) {
              elcolor = '#E2F913';
            } else {
              elcolor = '#F3080D';
            }
            console.log('AverageSpeedStops: ', averagespeedstops);
            console.log('Asignamos el color', elcolor);
            this.mapser.map.addLayer({
              id: this.state.cyclesListOnlyCC[value.whichcycle] + whichstop,
              type: 'line',
              source: this.state.cyclesListOnlyCC[value.whichcycle] + whichstop,
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': elcolor,
                'line-width': 3,
              },

            });
            lonlat = [];
            whichstop++;
          }
        }

        //NOTA 04/05/2022 -> SE DEBE CAMBIAR LO DE _1 Y _2  Y TAMBIÉN LA CADENA FORMADA QUE DICE PARTE 1.
      } else if (this.state.currentRoute != 'None' && value.whichone === 'ene') {
        // console.log('completeDay: ', this.dataSer.completeDay);
        //let latitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].latitude;
        //let longitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_1'].longitude;
        let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].indexstops;
        const cadena = this.state.currentCollection + '_' + this.state.currentRoute + '_' + this.state.cyclesListOnlyCC[value.whichcycle] + '_' +
          this.state.currentType + '_' + this.state.currentBusSize + 'm_' + this.state.currentBattery + 'kWh_' + this.state.currentHVAC + '_pass' + this.state.currentPax;
        // console.log('cadena: ', cadena);
        // let energyconsumekwhstop = this.dataSer.completeDay[cadena].energyconsumekwhstop;
        const salida = this.dataSer.completeDay.find((current: any) => {
          return current.completedayid === cadena;
        });
        // console.log('energyconsumekwhstop: ', salida.energyconsumekwhstop);
        //let latitudetwo = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_2'].latitude;
        //let longitudetwo = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle] + '_2'].longitude;

        const lontotal = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].longitude;
        const lattotal = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[value.whichcycle]].latitude;

        // console.log('lattotal: ', lattotal);

        let whichstop = 0;
        let lonlat = [];
        for (let i = 0; i < lattotal.length; i++) {
          lonlat.push([lontotal[i], lattotal[i]]);
          // console.log('lonlat ', lonlat);
          if (i === paradasindexone[whichstop]) {
            // console.log('paradasindex[whichstop]!!! ', paradasindex[whichstop]);
            /* if (this.mapser.map.getLayer(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop) !== undefined) {
              this.mapser.map.removeLayer(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop);
              this.mapser.map.removeSource(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop);
            } */
            this.removeOtherCyclesLines(whichstop);
            this.mapser.map.addSource(this.state.cyclesListOnlyCC[value.whichcycle] + whichstop, {
              type: 'geojson',
              lineMetrics: true,
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: lonlat
                  // coordinates: this.allData,
                },
              },
            });
            let elcolor = '';
            /* if (salida.energyconsumekwhstop[whichstop] < salida.energyconsumekwhcycle * 0.02) {
              elcolor = '#1848ED';
            } else if (salida.energyconsumekwhstop[whichstop] < salida.energyconsumekwhcycle * 0.035) {
              elcolor = '#E2F913';
            } else {
              elcolor = '#F3080D';
            } */
            if (salida.kwhxkmstop[whichstop] <= 0) {
              elcolor = '#18ED1B';
            } else if (salida.kwhxkmstop[whichstop] <= 0.5) {
              elcolor = '#E2F913';
            } else if (salida.kwhxkmstop[whichstop] <= 1.0) {
              elcolor = '#F99413';
            } else if (salida.kwhxkmstop[whichstop] <= 1.5) {
              elcolor = '#F91A13';
            } else {
              elcolor = '#C113F9';
            }
            this.mapser.map.addLayer({
              id: this.state.cyclesListOnlyCC[value.whichcycle] + whichstop,
              type: 'line',
              source: this.state.cyclesListOnlyCC[value.whichcycle] + whichstop,
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': elcolor,
                'line-width': 3,
              },

            });
            lonlat = [];
            whichstop++;
          }
        }
      }
      // console.log('Y tenemos ruta y collección ', this.state.currentCollection + '_' + this.state.currentRoute);
      // console.log('Y tenemos todos los inputs ', this.dataSer.allCyclesInputs);
    });

  }

  subscribeToAnimation(): void {
    this.loadImages(this.images, (loadedImages: any): any => {
      this.mapser.map.on('load', () => {


        /* this.mapser.map.addImage('busOrange', loadedImages.busOrange, {
          pixelRatio: 1,
        }); */
        this.misimagenes.forEach((img: any) => {
          this.mapser.map.loadImage(img.url, (error: any, res: any) => {
            this.mapser.map.addImage(img.id, res)
          })
        })
        this.AnimationsSubs = this.state.animationChange.subscribe(
          async (value: any) => {
            if (value[0] === 'RemoveRoute') {
              if (this.mapser.map.getLayer('route') !== undefined) {
                //console.log('Eliminando route');
                this.mapser.map.removeLayer('route');
                this.mapser.map.removeSource('route');
              }
              for (const i of this.marcadores) {
                i.remove();
              }
              return;
            } if (value[0] === 'AllRoutes') {
              // console.log('ALlRoutes map');
              this.busesMarkers.forEach((c: any) => {
                c.remove();
              });
              this.busesMarkers = [];
              this.busesMarkersNames = {};
              let longestRouteSize = 0;
              const arrprueba: any = [];
              const losdiv: any = {};
              let cuantos = 0;
              for (const oneStop of this.state.allLocations) {
                if (oneStop.colName === this.state.currentCollection) {
                  //this.addChargersToMap(oneStop.chargernames, oneStop.chargerlatitudes, oneStop.chargerlongitudes);
                }
              }
              this.state.totalAccumEnergy = 0;
              this.state.totalCostEnergy = 0;
              console.log('this.state.busNames: ', this.state.busNames);
              for (let ii = 0; ii < this.state.busNames.length; ii++) {
                const ruta = this.state.busNames[ii];

                if (this.state.routesAnimationData[this.state.currentCollection + ruta] !== undefined) {
                  this.state.animationAlerts[this.state.currentCollection + ruta].worstcolor = 'limegreen';
                  this.state.routesAnimationData[this.state.currentCollection + ruta].whichvisiting = 1;
                  this.state.routesAnimationData[this.state.currentCollection + ruta].whichcharger = 1;
                  this.state.totalAccumEnergy += this.state.routesAnimationData[this.state.currentCollection + ruta].totalEnergy;
                  arrprueba.push([this.state.routesAnimationData[this.state.currentCollection + ruta], this.state.currentCollection + ruta, 0]);
                  const ele = document.createElement('div');
                  ele.className = 'busanimado';
                  ele.style.backgroundImage =
                    'url(../../../assets/busiconNormal.png)';
                  ele.style.backgroundSize = '100%';
                  if (this.dataSer.allRouteProperties[ruta].longitudecoordinates.length > longestRouteSize) {
                    longestRouteSize = this.dataSer.allRouteProperties[ruta].longitudecoordinates.length;
                  }

                  losdiv[this.state.currentCollection + ruta] = ele;
                  const nuevopop = new mapboxgl.Popup({ closeOnClick: false, closeButton: false, offset: [0, -20] }).setText(ruta);
                  this.busesMarkers.push(
                    new mapboxgl.Marker(ele)
                      .setLngLat([this.state.routesAnimationData[this.state.currentCollection + ruta].longitude[0],
                      this.state.routesAnimationData[this.state.currentCollection + ruta].latitude[0]])
                      .setPopup(nuevopop)
                      .addTo(this.mapser.map))
                    ;
                  // nuevopop.addTo(this.mapser.map);
                  // this.busesMarkersNames[this.state.currentCollection + ruta].togglePopup();


                  this.busesMarkersNames[this.state.currentCollection + ruta] = cuantos;
                  cuantos++;

                }


                // window.requestAnimationFrame(frame);
              }
              // console.log('this.state.routesAnimationData: ', this.state.routesAnimationData);
              // console.log('this.state.totalAccumEnergy: ', this.state.totalAccumEnergy);
              const copyTotalAccumEnergy = this.state.totalAccumEnergy + 0;
              this.state.totalAccumEnergy = 0;
              let loschargernames: any;
              const allchargers: any = {};
              const allschedule: any = {};
              const allChargedEnergy: any = {};
              const allChargersLost: any = {};
              const finalschedule: any = {};
              const finalscheduleinit: any = {};
              const finalscheduleend: any = {};
              const accChargedEnergy: any = {};
              const lostChargedEnergy: any = {};
              const accChargerChange: any = {};
              const maxFinalCharge: any = {};
              this.state.allLocations.forEach((c: any) => {
                if (this.state.currentCollection === c.colName) {
                  loschargernames = c.chargernames;
                  this.state.currentChargersNames = c.chargernames;
                  c.chargernames.forEach((c: any) => {

                    allchargers[c.replace(/\s/g, "")] = {};
                    allschedule[c.replace(/\s/g, "")] = [];
                    allChargedEnergy[c.replace(/\s/g, "")] = {};
                    allChargersLost[c.replace(/\s/g, "")] = {};
                    finalschedule[c.replace(/\s/g, "")] = {};
                    finalscheduleinit[c.replace(/\s/g, "")] = {};
                    finalscheduleend[c.replace(/\s/g, "")] = {};
                    maxFinalCharge[c.replace(/\s/g, "")] = {};
                    accChargedEnergy[c.replace(/\s/g, "")] = 0;
                    lostChargedEnergy[c.replace(/\s/g, "")] = 0;
                    accChargerChange[c.replace(/\s/g, "")] = [false, new Date()];
                    this.state.lostEnergy[c.replace(/\s/g, "")] = 0;
                    this.state.powerGaugeValue[c.replace(/\s/g, "")] = 0;
                    this.state.chargerAssignedBus[c.replace(/\s/g, "")] = '';
                    this.state.chargerInit[c.replace(/\s/g, "")] = '';
                    this.state.chargerEnd[c.replace(/\s/g, "")] = '';
                    this.state.chargedEnergy[c.replace(/\s/g, "")] = [0, 0];
                    this.state.accumChargedEnergy[c.replace(/\s/g, "")] = 0;
                    this.state.totalCostChargedEnergy[c.replace(/\s/g, "")] = 0;
                  });
                }
              });
              // console.log('allchargers: ', allchargers);

              let firstDate: Date = new Date(arrprueba[0][0].datearray[0]);
              let lastDate: Date = new Date(arrprueba[0][0].datearray[arrprueba[0][0].datearray.length - 1]);
              for (const iterator of arrprueba) {
                if (iterator[0].datearray[0] < firstDate) {
                  firstDate = new Date(iterator[0].datearray[0]);
                }
                if (iterator[0].datearray[iterator[0].datearray.length - 1] > lastDate) {
                  lastDate = new Date(iterator[0].datearray[iterator[0].datearray.length - 1]);
                }
              }

              /* console.log('firstDate: ', firstDate.toLocaleString());
              console.log('lastDate: ', lastDate.toString()); */
              for (let inicio = new Date(firstDate); inicio < lastDate; inicio.setSeconds(inicio.getSeconds() + 3)) {

                loschargernames.forEach((c: any) => {
                  allchargers[c.replace(/\s/g, "")][inicio.toLocaleString()] = 0;
                  allChargedEnergy[c.replace(/\s/g, "")][inicio.toLocaleString()] = 0;
                  allChargersLost[c.replace(/\s/g, "")][inicio.toLocaleString()] = 0;
                  finalschedule[c.replace(/\s/g, "")][inicio.toLocaleString()] = '';
                  finalscheduleinit[c.replace(/\s/g, "")][inicio.toLocaleString()] = '';
                  finalscheduleend[c.replace(/\s/g, "")][inicio.toLocaleString()] = '';


                });


              }
              // console.log('allchargers: ', allchargers);
              // console.log('horaChargers: ', allchargers);
              const sortedArray: any = arrprueba.sort((obj1: any, obj2: any) => {
                if (obj1[0].datearray[0] > obj2[0].datearray[0]) {
                  return 1;
                }

                if (obj1[0].datearray[0] < obj2[0].datearray[0]) {
                  return -1;
                }

                return 0;
              });
              // console.log('sortedArray: ', sortedArray);
              // const pcarga: any = {};
              /* this.state.busNames.forEach((c: any) => {
                pcarga[this.state.currentCollection + c] = [];
              }); */
              // console.log('LOs chargers names; ', loschargernames);
              /* const loschargernamesalter = loschargernames.map((c: any) => {
                return c.replace(/\s/g, "");
              }); */
              for (const iterator of sortedArray) {
                if (iterator[0].chargingpower !== undefined && iterator[0].chargingpower.length > 1) {
                  if (iterator[0].visiting.length > 1) {


                    let cambiar = false;
                    // let cualestacion = iterator[0].namechargers[0].replace(/\s/g, "");
                    let cualestacion = iterator[0].visiting[1].replace(/\s/g, "");
                    // console.log('Estación 1 para ', iterator[1], ' es ', cualestacion, ' con punto de carga: ', iterator[0].visitingindex[1]);
                    // finalschedule[cualestacion][iterator[0].chargingpower[0][1].toLocaleString()] = iterator[1];

                    for (let i = 0; i < iterator[0].chargingpower.length; i++) {


                      // if (iterator[0].chargingpower[i][0] > 0 && iterator[0].datearray[i] !== undefined) {
                      if (iterator[0].chargingpower[i][0] > 0) {
                        // solo depende de cargadores asociados al bus en cuestión
                        allchargers[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] =
                          parseInt(allchargers[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] + iterator[0].chargingpower[i][0].toFixed(0));
                        finalschedule[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] = iterator[0].route;
                        allChargersLost[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] =
                          parseInt((allChargersLost[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] + iterator[0].lostenergy[i][0]).toFixed(0));
                        if (iterator[0].chargedenergy[i] !== undefined) {
                          allChargedEnergy[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] =
                            parseInt((allChargedEnergy[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] + iterator[0].chargedenergy[i][0]).toFixed(0));
                        }

                        if (!cambiar) {
                          finalscheduleinit[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] = iterator[0].chargingpower[i][1].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                        }
                        cambiar = true;
                      } else if (cambiar && iterator[0].whichcharger < iterator[0].visiting.length - 1) {
                        finalscheduleend[cualestacion][iterator[0].chargingpower[i][1].toLocaleString()] = iterator[0].chargingpower[i][1].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                        // console.log('iterator[0].whichcharger: ', iterator[0].whichcharger);
                        iterator[0].whichcharger = iterator[0].whichcharger + 1;
                        // cualestacion = iterator[0].namechargers[iterator[0].whichcharger % iterator[0].namechargers.length].replace(/\s/g, "");
                        cualestacion = iterator[0].visiting[iterator[0].whichcharger].replace(/\s/g, "");
                        // console.log(iterator[1], ' cambiando a estación ', cualestacion, ' con punto de carga: ', iterator[0].visitingindex[iterator[0].whichcharger]);
                        cambiar = false;
                      }
                    }
                  }
                  // console.log('NO es undefined para ', iterator[1]);
                }


              } // */
              // console.log('allChargedEnergy: ', allChargedEnergy);
              /* const newSchedule: any = {};
              loschargernames.forEach((c: any) => {
                const salida = allschedule[c.replace(/\s/g, "")].sort((uno: any, dos: any) => {
                  return uno[1] > dos[1] ? 1 : -1;
                });
                newSchedule[c.replace(/\s/g, "")] = [...salida];
              }); */

              loschargernames.forEach((c: any) => {
                let horactual = new Date(firstDate);
                let horactualinit = new Date(firstDate);
                let horactualend = new Date(firstDate);
                let firsttime = true;
                let firstinit = true;
                let firstend = true;
                for (let inicio = new Date(firstDate); inicio < lastDate; inicio.setSeconds(inicio.getSeconds() + 3)) {

                  if (finalschedule[c.replace(/\s/g, "")][inicio.toLocaleString()] !== '' && firsttime) {
                    const nombre = finalschedule[c.replace(/\s/g, "")][inicio.toLocaleString()];
                    for (let starting = new Date(horactual); starting < inicio; starting.setSeconds(starting.getSeconds() + 3)) {
                      finalschedule[c.replace(/\s/g, "")][starting.toLocaleString()] = nombre;
                    }
                    firsttime = false;
                    // horactual =
                  } else {
                    if (firsttime) {
                      ;
                    } else {
                      horactual = new Date(inicio);
                      firsttime = true;
                    }
                  }

                  if (finalscheduleinit[c.replace(/\s/g, "")][inicio.toLocaleString()] !== '' && firstinit) {
                    const nombre = finalscheduleinit[c.replace(/\s/g, "")][inicio.toLocaleString()];
                    for (let starting = new Date(horactualinit); starting < inicio; starting.setSeconds(starting.getSeconds() + 3)) {
                      finalscheduleinit[c.replace(/\s/g, "")][starting.toLocaleString()] = nombre;
                    }
                    firstinit = false;
                  } else {
                    if (firstinit) {
                      ;
                    } else {
                      horactualinit = new Date(inicio);
                      firstinit = true;
                    }
                  }

                  if (finalscheduleend[c.replace(/\s/g, "")][inicio.toLocaleString()] !== '' && firstend) {
                    const nombre = finalscheduleend[c.replace(/\s/g, "")][inicio.toLocaleString()];
                    for (let starting = new Date(horactualend); starting < inicio; starting.setSeconds(starting.getSeconds() + 3)) {
                      finalscheduleend[c.replace(/\s/g, "")][starting.toLocaleString()] = nombre;
                    }
                    firstend = false;
                  } else {
                    if (firstend) {
                      ;
                    } else {
                      horactualend = new Date(inicio);
                      firstend = true;
                    }
                  }

                }
              });
              /* console.log('finalschedule: ', finalschedule);
              console.log('finalscheduleinit: ', finalscheduleinit);
              console.log('finalscheduleend: ', finalscheduleend); */
              // data plus index
              const animandose: any = [];
              animandose.push(sortedArray.shift());
              this.state.puntoDeCarga[animandose[animandose.length - 1][1]] =
                animandose[animandose.length - 1][0].visitingindex[animandose[animandose.length - 1][0].whichvisiting] + 1;
              this.state.inicioDeCarga[animandose[animandose.length - 1][1]] =
                animandose[animandose.length - 1][0].visitingtimeinit[animandose[animandose.length - 1][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
              this.state.finDeCarga[animandose[animandose.length - 1][1]] =
                animandose[animandose.length - 1][0].visitingtimeend[animandose[animandose.length - 1][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
              /* this.state.inicioDeCarga[animandose[animandose.length - 1][1]] =
              tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit'}); */
              /*console.log('which visiting: ', animandose[animandose.length - 1][0].visitingindex[animandose[animandose.length - 1][0].whichvisiting]);
              console.log('animandose[jota][0].whichvisiting: ', animandose[animandose.length - 1][0].whichvisiting);//  */
              animandose[animandose.length - 1][0].whichvisiting = animandose[animandose.length - 1][0].whichvisiting + 1;
              animandose[animandose.length - 1][0].whichvisiting = animandose[animandose.length - 1][0].whichcharger = 1;



              const frame = (tiempoactual: Date) => {
                this.state.currentAnimationTime = tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });

                sortedArray.forEach((c: any) => {
                  if (this.state.routesAnimationData[c[1]].datearray[0].getTime() === tiempoactual.getTime()) {
                    // console.log('Adicioné ', c[1], ' en tiempo ', tiempoactual);
                    animandose.push(sortedArray.shift());
                    this.state.puntoDeCarga[animandose[animandose.length - 1][1]] =
                      animandose[animandose.length - 1][0].visitingindex[animandose[animandose.length - 1][0].whichvisiting] + 1;
                    this.state.inicioDeCarga[animandose[animandose.length - 1][1]] =
                      animandose[animandose.length - 1][0].visitingtimeinit[animandose[animandose.length - 1][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                    this.state.finDeCarga[animandose[animandose.length - 1][1]] =
                      animandose[animandose.length - 1][0].visitingtimeend[animandose[animandose.length - 1][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                    /* console.log('which visiting: ', animandose[animandose.length - 1][0].visitingindex[animandose[animandose.length - 1][0].whichvisiting]);
                    console.log('animandose[jota][0].whichvisiting: ', animandose[jota][0].whichvisiting);//  */
                    animandose[animandose.length - 1][0].whichvisiting = animandose[animandose.length - 1][0].whichvisiting + 1;
                    animandose[animandose.length - 1][0].whichvisiting = animandose[animandose.length - 1][0].whichcharger = 1;

                    // console.log('animandose: ', animandose);
                  }
                });
                let finished = true;

                loschargernames.forEach((c: any) => {
                  /* this.state.powerGaugeValue[c.replace(/\s/g, "")] =
                    parseInt(((allchargers[c.replace(/\s/g, "")][tiempoactual.toLocaleString()] * 100) / this.state.chargersMaxValue).toFixed(0)); */
                  this.state.powerGaugeValue[c.replace(/\s/g, "")] =
                    parseInt(allchargers[c.replace(/\s/g, "")][tiempoactual.toLocaleString()].toFixed(0));
                  this.state.powerGaugePercentage[c.replace(/\s/g, "")] = parseInt(((allchargers[c.replace(/\s/g, "")][tiempoactual.toLocaleString()] * 100) / this.state.chargersMaxValue).toFixed(0));
                  this.state.chargerAssignedBus[c.replace(/\s/g, "")] = finalschedule[c.replace(/\s/g, "")][tiempoactual.toLocaleString()];
                  this.state.chargerInit[c.replace(/\s/g, "")] = finalscheduleinit[c.replace(/\s/g, "")][tiempoactual.toLocaleString()];
                  this.state.chargerEnd[c.replace(/\s/g, "")] = finalscheduleend[c.replace(/\s/g, "")][tiempoactual.toLocaleString()];
                  // this.state.lostEnergy[c.replace(/\s/g, "")] = allChargersLost[c.replace(/\s/g, "")][tiempoactual.toLocaleString()] + lostChargedEnergy[c.replace(/\s/g, "")];
                  this.state.chargedEnergy[c.replace(/\s/g, "")] = [allChargedEnergy[c.replace(/\s/g, "")][tiempoactual.toLocaleString()], 0];
                  // if (accChargerChange[c.replace(/\s/g, "")] && allChargedEnergy[c.replace(/\s/g, "")][tiempoactual.toLocaleString()] == 0){
                  if (accChargerChange[c.replace(/\s/g, "")][0]) {
                    this.state.chargedEnergy[c.replace(/\s/g, "")] = [allChargedEnergy[c.replace(/\s/g, "")][tiempoactual.toLocaleString()], accChargerChange[c.replace(/\s/g, "")][2]];
                    if (allChargedEnergy[c.replace(/\s/g, "")][tiempoactual.toLocaleString()] > 0 ||
                      tiempoactual.toLocaleString() == accChargerChange[c.replace(/\s/g, "")][1].toLocaleString()) {
                      // Do nothing, looking for last value
                    } else {
                      const ndate = new Date(tiempoactual);
                      ndate.setSeconds(ndate.getSeconds() - 3);
                      /* console.log('accChargedEnergy[c.replace(/\s/g, "")]: ', c.replace(/\s/g, ""), accChargedEnergy[c.replace(/\s/g, "")]);
                      console.log('acctime: ', new Date(ndate).toLocaleString());
                      console.log('allChargedEnergy[c.replace(/\s/g, "")][new Date(ndate).toLocaleString()]: ', allChargedEnergy[c.replace(/\s/g, "")][new Date(ndate).toLocaleString()]); */
                      accChargedEnergy[c.replace(/\s/g, "")] = accChargedEnergy[c.replace(/\s/g, "")] + allChargedEnergy[c.replace(/\s/g, "")][new Date(ndate).toLocaleString()];
                      // console.log('accChargedEnergy: ', accChargedEnergy);
                      // console.log('copyTotalAccumEnergy: ', copyTotalAccumEnergy)

                      this.state.totalAccumEnergy += allChargedEnergy[c.replace(/\s/g, "")][new Date(ndate).toLocaleString()];
                      this.state.totalCostEnergy = parseFloat((this.state.totalAccumEnergy * 0.3).toFixed(1));
                      // this.state.accumChargedEnergy[c.replace(/\s/g, "")] = parseFloat(((accChargedEnergy[c.replace(/\s/g, "")] * 100) / this.state.totalAccumEnergy).toFixed(1));
                      this.state.accumChargedEnergy[c.replace(/\s/g, "")] = parseFloat(accChargedEnergy[c.replace(/\s/g, "")].toFixed(1));
                      this.state.totalCostChargedEnergy[c.replace(/\s/g, "")] = parseFloat((accChargedEnergy[c.replace(/\s/g, "")] * 0.3).toFixed(1));
                      this.state.lostEnergy[c.replace(/\s/g, "")] = parseFloat((accChargedEnergy[c.replace(/\s/g, "")] * 0.05).toFixed(2));
                      accChargerChange[c.replace(/\s/g, "")][0] = false;
                      // console.log('Enviando evento a API iota backend tracking');
                      this.iota.postChargingEvent({
                        // title: this.state.currentCollection + '_' + c + '_' +  tiempoactual.toUTCString(),
                        title: this.state.currentCollection + '_' + c,
                        consumedenergy: allChargedEnergy[c.replace(/\s/g, "")][new Date(ndate).toLocaleString()],
                        totalcost: this.state.totalCostChargedEnergy[c.replace(/\s/g, "")],
                        lostenergy: this.state.lostEnergy[c.replace(/\s/g, "")],
                        enddate: this.state.finDeCarga[animandose[animandose.length - 1][1]],
                        initdate: this.state.inicioDeCarga[animandose[animandose.length - 1][1]]
                      }, c);
                    }
                  }
                });

                for (let jota = 0; jota < animandose.length; jota++) {

                  if (animandose[jota][2] < animandose[jota][0].latitude.length) {
                    /* console.log('animandose[jota][2] A: ', animandose[jota][2]);
                    console.log('animandose[jota][1] : ', animandose[jota][1]); */
                    if (animandose[jota][0].chargingpower[animandose[jota][2]] === undefined || animandose[jota][0].chargingpower[animandose[jota][2]][0] == 0) {
                      if (animandose[jota][0].available[animandose[jota][2]] !== undefined) {
                        if (animandose[jota][0].available[animandose[jota][2]] < (parseInt(animandose[jota][0].battery) * 0.2) &&
                          this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'red') {
                          losdiv[animandose[jota][1]].style.backgroundImage =
                            'url(../../../assets/busiconRed.png)';
                          this.state.animationAlerts[animandose[jota][1]].colors.push('red');
                          this.state.animationAlerts[animandose[jota][1]].descriptions[0] = ['SOC menor al 20%', tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'red'];
                          // this.state.animationAlerts[animandose[jota][1]].type.push('SOC');
                          // this.state.animationAlerts[animandose[jota][1]].worstcolor = 'red';


                        } else if (animandose[jota][0].available[animandose[jota][2]] < (parseInt(animandose[jota][0].battery) * 0.3) &&
                          this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'orange') {
                          losdiv[animandose[jota][1]].style.backgroundImage =
                            'url(../../../assets/busiconOrange.png)';

                          if (this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'red') {
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] = 'orange';
                            this.state.animationAlerts[animandose[jota][1]].colors.push('orange');

                            this.state.animationAlerts[animandose[jota][1]].descriptions[0] = ['SOC menor al 30%', tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'orange'];
                            // this.state.animationAlerts[animandose[jota][1]].type.push('SOC');
                          }
                        } else if (animandose[jota][0].available[animandose[jota][2]] < (parseInt(animandose[jota][0].battery) * 0.5) &&
                          this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'yellow') {
                          losdiv[animandose[jota][1]].style.backgroundImage =
                            'url(../../../assets/busiconYellow.png)';

                          if (this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'red' && this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'orange') {
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] = 'yellow';
                            this.state.animationAlerts[animandose[jota][1]].colors.push('yellow');

                            this.state.animationAlerts[animandose[jota][1]].descriptions[0] = ['SOC menor al 50%', tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }), 'yellow'];
                            // this.state.animationAlerts[animandose[jota][1]].type.push('SOC');
                          }
                        } else if (animandose[jota][0].available[animandose[jota][2]] > (parseInt(animandose[jota][0].battery) * 0.5) &&
                          this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'limegreen') {
                          losdiv[animandose[jota][1]].style.backgroundImage =
                            'url(../../../assets/busiconNormal.png)';
                          this.state.animationAlerts[animandose[jota][1]].colors.push('limegreen');
                          if (this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'red' &&
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'orange' &&
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'yellow') {
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] = 'limegreen';
                          }
                        }
                      }
                    }

                    if (Math.random() > 0.999 && this.state.animationAlerts[animandose[jota][1]].descriptions[1][0] === 'OK') {
                      this.state.animationAlerts[animandose[jota][1]].descriptions[1] =
                        [
                          'Temperatura alta',
                          tiempoactual.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }),
                          'red'
                        ];
                    }

                    const ordenamiento = {
                      'red': 0,
                      'orange': 1,
                      'yellow': 2,
                      'blue': 3,
                      'limegreen': 4,
                      'none': 5,
                    };
                    // Lets set the worstcolor given all sensors (values in animationAlerts)
                    this.state.animationAlerts[animandose[jota][1]].worstcolor = 'limegreen';
                    for (let i = 0; i < 2; i++) {
                      if (ordenamiento[this.state.animationAlerts[animandose[jota][1]].descriptions[i][2]] < ordenamiento[this.state.animationAlerts[animandose[jota][1]].worstcolor]) {
                        this.state.animationAlerts[animandose[jota][1]].worstcolor = this.state.animationAlerts[animandose[jota][1]].descriptions[i][2];
                      }
                    }
                    /* if (animandose[jota][0].chargingpower[animandose[jota][2]] === undefined) {
                    console.log('animandose[jota][0].chargingpower[animandose[jota][2]]: ', animandose[jota][0].chargingpower[animandose[jota][2]]);
                    } */
                    if (animandose[jota][0].chargingpower[animandose[jota][2]] !== undefined) {

                      if (animandose[jota][0].chargingpower[animandose[jota][2]][0] > 0) {
                        losdiv[animandose[jota][1]].style.backgroundImage =
                          'url(../../../assets/busiconAzul.png)';
                        // if (this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] !== 'blue') {
                        if (this.state.animationAlerts[animandose[jota][1]].colors[this.state.animationAlerts[animandose[jota][1]].colors.length - 1] !== 'blue') {
                          this.state.animationAlerts[animandose[jota][1]].colors.push('blue');

                          if (this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] === 'limegreen' || this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] === 'none') {
                            this.state.animationAlerts[animandose[jota][1]].descriptions[0][2] = 'blue';
                          }
                          if (animandose[jota][0].visitingindex[animandose[jota][0].whichvisiting] !== undefined) {
                            this.state.puntoDeCarga[animandose[jota][1]] =
                              animandose[jota][0].visitingindex[animandose[jota][0].whichvisiting] + 1;
                            this.state.inicioDeCarga[animandose[jota][1]] =
                              animandose[jota][0].visitingtimeinit[animandose[jota][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                            this.state.finDeCarga[animandose[jota][1]] =
                              animandose[jota][0].visitingtimeend[animandose[jota][0].whichvisiting].toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
                            /*console.log('animandose[jota][0].visitingindex[animandose[jota][0].whichvisiting]: ', animandose[jota][0].visitingindex[animandose[jota][0].whichvisiting]);
                            console.log('animandose[jota][0].whichvisiting: ', animandose[jota][0].whichvisiting); // */
                            accChargerChange[animandose[jota][0].visiting[animandose[jota][0].whichcharger].replace(/\s/g, "")][0] = true;
                            const unadate = new Date(tiempoactual);
                            unadate.setSeconds(unadate.getSeconds() + 3);
                            // console.log('Starting to charge at ', new Date(unadate).toUTCString());
                            accChargerChange[animandose[jota][0].visiting[animandose[jota][0].whichcharger].replace(/\s/g, "")][1] = new Date(unadate);
                            accChargerChange[animandose[jota][0].visiting[animandose[jota][0].whichcharger].replace(/\s/g, "")][2] =
                              animandose[jota][0].finalescargadores[animandose[jota][0].whichcharger - 1];
                            // console.log('el maxumo carga actual es: ', animandose[jota][0].finalescargadores[animandose[jota][0].whichcharger - 1]);
                            animandose[jota][0].whichvisiting = animandose[jota][0].whichvisiting + 1;
                            animandose[jota][0].whichcharger = animandose[jota][0].whichcharger + 1;


                          }
                          /* if (animandose[jota][0].trajects[animandose[jota][0].whichvisiting - 1] !== undefined) {
                            // this.state.trajects[animandose[jota][1]] = animandose[jota][0].trajects[animandose[jota][0].whichvisiting - 1];
                            console.log('the this.state.trajects[animandose[jota][1]]: ', animandose[jota][0].trajects[animandose[jota][0].whichvisiting - 1]);
                          } */
                        }
                        this.state.isChargingGauge[animandose[jota][1]] = true;

                      } else {
                        this.state.isChargingGauge[animandose[jota][1]] = false;
                      }
                      // this.state.powerGaugeValuet2 = animandose[jota][0].chargingpowert2[animandose[jota][2]];
                      this.state.socGaugeValue[animandose[jota][1]] =
                        parseInt('' + ((parseInt('' + animandose[jota][0].available[animandose[jota][2]] + '') * 100) / animandose[jota][0].battery));

                      const elmaxdis = animandose[jota][0].distancekm[animandose[jota][0].distancekm.length - 1];
                      this.state.distanceGaugePercentage[animandose[jota][1]] = parseInt(((animandose[jota][0].distancekm[animandose[jota][2]] * 100) / elmaxdis).toFixed(0));
                      this.state.distanceGaugeValue[animandose[jota][1]] = parseInt(animandose[jota][0].distancekm[animandose[jota][2]].toFixed(0) + '');

                      this.state.distanceChartValue[animandose[jota][1]] = [
                        {
                          hora: 'Autonomía',
                          type: parseInt(animandose[jota][0].autonomy[animandose[jota][2]].toFixed(0)),
                          color: '#cccc00'
                        },
                        {
                          hora: 'A carga',
                          type: parseInt(animandose[jota][0].evse[animandose[jota][2]].toFixed(0)),
                          color: '#00ccff'
                        }

                      ];
                      // console.log('this.state.distanceChartValue[animandose[jota][1]]: ', this.state.distanceChartValue[animandose[jota][1]]);

                      // this.state.changeBusSize('');
                      // this.state.changeHVAC('');

                    }



                    this.busesMarkers[this.busesMarkersNames[animandose[jota][1]]].setLngLat([
                      animandose[jota][0].longitude[animandose[jota][2]],
                      animandose[jota][0].latitude[animandose[jota][2]]
                    ]
                    );

                    animandose[jota][2] = animandose[jota][2] + 1;
                    finished = false;
                  }
                }
                // console.log('this.state.animationAlerts: ', this.state.animationAlerts);
                if (sortedArray.length > 0 || !finished) {
                  this.miTimeout = setTimeout(() => {
                    //window.requestAnimationFrame(frame);
                    tiempoactual.setSeconds(tiempoactual.getSeconds() + 3);
                    frame(new Date(tiempoactual));
                  }, 30);
                } else {
                  alert('Animación finalizada');
                }
              }
              // Start with first route and its first time
              // console.log('animandose[0][0].datearray[0] : ', animandose[0][0].datearray[0]);
              frame(animandose[0][0].datearray[0]);
              return;
            } else
              if (value[0] === 'Cycles') {
                const mapLayer = this.mapser.map.getLayer('animatedBus');
                if (mapLayer !== undefined) {
                  this.mapser.map.removeLayer('animatedBus');
                  this.mapser.map.removeSource('animatedBus');
                }

                // console.log('Animaré el bus');
                if (this.busMarker !== undefined)
                  this.busMarker.remove();
                const ele = document.createElement('div');
                ele.className = 'busanimado';
                ele.style.backgroundImage =
                  'url(../../../assets/busiconNormal.png)';
                ele.style.backgroundSize = '100%';
                this.busMarker =
                  new mapboxgl.Marker(ele)
                    .setLngLat([this.state.chartMapLongitudeCycles[this.state.chosenSeries][value[1]],
                    this.state.chartMapLatitudeCycles[this.state.chosenSeries][value[1]]])
                    .setPopup(
                      new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                        closeOnClick: true,
                      }).setHTML(
                        '<p> My bus </p>'
                      )
                    )
                    .addTo(this.mapser.map);


                const cameraAltitude = 4000;
                let indice = value[1];
                const frame = (tiempo: number) => {

                  var camera = this.mapser.map.getFreeCameraOptions();

                  // set the position and altitude of the camera
                  camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
                    {
                      lng: this.state.chartMapLongitudeCycles[this.state.chosenSeries][indice] + 0.01,
                      lat: this.state.chartMapLatitudeCycles[this.state.chosenSeries][indice]
                    },
                    cameraAltitude
                  );
                  this.busMarker.setLngLat([this.state.chartMapLongitudeCycles[this.state.chosenSeries][indice],
                  this.state.chartMapLatitudeCycles[this.state.chosenSeries][indice]]);
                  if (this.state.THEDATACOPY[0][this.state.chosenSeries].available[indice] < (parseInt(this.state.currentBattery) * 0.2)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconRed.png)';
                  } else if (this.state.THEDATACOPY[0][this.state.chosenSeries].available[indice] < (parseInt(this.state.currentBattery) * 0.3)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconOrange.png)';
                  } else if (this.state.THEDATACOPY[0][this.state.chosenSeries].available[indice] < (parseInt(this.state.currentBattery) * 0.5)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconYellow.png)';
                  } else if (this.state.THEDATACOPY[0][this.state.chosenSeries].available[indice] > (parseInt(this.state.currentBattery) * 0.5)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconNormal.png)';
                  }
                  this.busMarker.addTo(this.mapser.map);
                  this.mapser.map.setFreeCameraOptions(camera);
                  indice++;
                  if (indice < this.state.chartMapLongitudeCycles[this.state.chosenSeries].length && this.state.areAnimationsDone < 2) {
                    this.miTimeout = setTimeout(() => {
                      //window.requestAnimationFrame(frame);
                      frame(tiempo);
                    }, 10);

                  } else {

                    // console.log('Termino de animar bus en mapa');
                    this.state.areAnimationsDone++;
                    const mapLayer = this.mapser.map.getLayer('animatedBus');
                    if (mapLayer !== undefined) {
                      this.mapser.map.removeLayer('animatedBus');
                      this.mapser.map.removeSource('animatedBus');
                    }
                  }
                }

                // animatedBus(value[1]);
                frame(value[1]);
                // window.requestAnimationFrame(frame);
                return;
              } else if (value[0] === 'CyclesAll') { /// Animate the bus using all cycles
                const mapLayer = this.mapser.map.getLayer('animatedBus');
                if (mapLayer !== undefined) {
                  this.mapser.map.removeLayer('animatedBus');
                  this.mapser.map.removeSource('animatedBus');
                }
                if (this.busMarker !== undefined)
                  this.busMarker.remove();

                const ele = document.createElement('div');
                ele.className = 'busanimado';
                ele.style.backgroundImage =
                  'url(../../../assets/busiconNormal.png)';
                ele.style.backgroundSize = '100%';
                this.busMarker =
                  new mapboxgl.Marker(ele)
                    .setLngLat([this.state.chartMapLongitudeCycles[this.state.chosenSeries][0],
                    this.state.chartMapLatitudeCycles[this.state.chosenSeries][0]])
                    .setPopup(
                      new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                        closeOnClick: true,
                      }).setHTML(
                        '<p> My bus </p>'
                      )
                    )
                    .addTo(this.mapser.map);
                /* this.busMarker = new mapboxgl.Marker({
                  color: '#F84C4C' // color it red
                }); */

                const cameraAltitude = 4000;
                let indice = value[1];
                const frame = (tiempo: number) => {
                  const camera = this.mapser.map.getFreeCameraOptions();
                  // set the position and altitude of the camera
                  camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
                    {
                      lng: this.state.chartMapLongitudeCycles[this.state.chosenSeries][indice] + 0.01,
                      lat: this.state.chartMapLatitudeCycles[this.state.chosenSeries][indice]
                    },
                    cameraAltitude
                  );
                  this.busMarker.setLngLat([this.state.chartMapLongitudeCycles[this.state.chosenSeries][indice],
                  this.state.chartMapLatitudeCycles[this.state.chosenSeries][indice]]);
                  /* console.log('Current data indice : ', indice);
                  console.log('Current data series: ', this.state.THEDATACOPY[this.state.currentChart - 1][this.state.chosenSeries]); */
                  /* if (this.state.THEDATACOPY[2][this.state.chosenSeries].datos[indice].value < (parseInt(this.state.currentBattery) * 0.2)){
                    ele.style.backgroundImage =
                    'url(../../../assets/busiconRed.png)';
                  } else  if (this.state.THEDATACOPY[2][this.state.chosenSeries].datos[indice].value < (parseInt(this.state.currentBattery) * 0.3)){
                    ele.style.backgroundImage =
                    'url(../../../assets/busiconOrange.png)';
                  } else if (this.state.THEDATACOPY[2][this.state.chosenSeries].datos[indice].value < (parseInt(this.state.currentBattery) * 0.5)){
                    ele.style.backgroundImage =
                    'url(../../../assets/busiconYellow.png)';
                  } else if (this.state.THEDATACOPY[2][this.state.chosenSeries].datos[indice].value < (parseInt(this.state.currentBattery) * 0.5)){
                    ele.style.backgroundImage =
                    'url(../../../assets/busiconNormal.png)';
                  }  */

                  /*
                  Ensure the marker is added to the map.
                  This is safe to call if it's already added.
                  */
                  this.busMarker.addTo(this.mapser.map);
                  this.mapser.map.setFreeCameraOptions(camera);
                  indice++;
                  if (indice < this.state.chartMapLongitudeCycles[this.state.chosenSeries].length) {
                    this.miTimeout = setTimeout(() => {
                      //window.requestAnimationFrame(frame);
                      frame(tiempo);
                    }, 0);

                  } else {
                    this.state.chosenSeries = this.state.chosenSeries + 1;
                    if (this.state.chosenSeries < this.state.THEDATA[this.state.currentChart - 1].length) {
                      // this.state.changeAnimation(['CyclesAll', 0]);
                      indice = 0;
                      frame(0);
                    } else {

                      // console.log('Termino de animar bus en mapa');
                      this.state.areAnimationsDone++;
                      // const lascoords = this.getCenterOfCoordinates();

                      if (this.busMarker !== undefined)
                        this.busMarker.remove();
                      /* const mapLayer = this.mapser.map.getLayer('animatedBus');
                      if (mapLayer !== undefined) {
                        this.mapser.map.removeLayer('animatedBus');
                        this.mapser.map.removeSource('animatedBus');
                      } */
                    }
                  }
                }

                // animatedBus(value[1]);

                /* setTimeout(() => {
                    frame(value[1]);
                }, 3000); */
                frame(value[1]);
                // window.requestAnimationFrame(frame);
                return;
              } else if (value[0] === 'CompleteDay') {
                console.log('Animating completeDay');
                const mapLayer = this.mapser.map.getLayer('animatedBus');
                if (mapLayer !== undefined) {
                  this.mapser.map.removeLayer('animatedBus');
                  this.mapser.map.removeSource('animatedBus');
                }
                if (this.busMarker !== undefined)
                  this.busMarker.remove();
                const ele = document.createElement('div');
                ele.className = 'busanimado';
                ele.style.backgroundImage =
                  'url(../../../assets/busiconNormal.png)';
                ele.style.backgroundSize = '100%';
                this.busMarker =
                  new mapboxgl.Marker(ele)
                    .setLngLat([this.state.chartMapLongitude[0][0][0],
                    this.state.chartMapLatitude[0][0][0]])
                    .setPopup(
                      new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                        closeOnClick: true,
                      }).setHTML(
                        '<p> My bus </p>'
                      )
                    )
                    .addTo(this.mapser.map);

                // console.log('Animaré el bus');
                const cameraAltitude = 4000;
                let indice = value[1];
                const animatedBusC = (valor: number) => {

                  var camera = this.mapser.map.getFreeCameraOptions();

                  // set the position and altitude of the camera
                  camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
                    {
                      lng: this.state.chartMapLongitude[0][0][indice] + 0.01,
                      lat: this.state.chartMapLatitude[0][0][indice]
                    },
                    cameraAltitude
                  );
                  this.busMarker.setLngLat([this.state.chartMapLongitude[0][0][indice],
                  this.state.chartMapLatitude[0][0][indice]]);

                  if (this.state.THEDATACOPY[0][0].datos[indice].value < (parseInt(this.state.currentBattery) * 0.2)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconRed.png)';
                  } else if (this.state.THEDATACOPY[0][0].datos[indice].value < (parseInt(this.state.currentBattery) * 0.3)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconOrange.png)';
                  } else if (this.state.THEDATACOPY[0][0].datos[indice].value < (parseInt(this.state.currentBattery) * 0.5)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconYellow.png)';
                  } else if (this.state.THEDATACOPY[0][0].datos[indice].value > (parseInt(this.state.currentBattery) * 0.5)) {
                    ele.style.backgroundImage =
                      'url(../../../assets/busiconNormal.png)';
                  }

                  this.busMarker.addTo(this.mapser.map);
                  this.mapser.map.setFreeCameraOptions(camera);
                  indice += 1;
                  if (indice < this.state.chartMapLongitude[0][0].length) {
                    this.miTimeout = setTimeout(() => {
                      animatedBusC(valor);
                    }, 10);
                  } else {
                    // console.log('Termino de animar bus en mapa');
                    this.state.areAnimationsDone++;
                    const mapLayer = this.mapser.map.getLayer('animatedBus');
                    if (mapLayer !== undefined) {
                      this.mapser.map.removeLayer('animatedBus');
                      this.mapser.map.removeSource('animatedBus');
                    }
                  }
                };
                animatedBusC(value[1]);
                return;
              } else if (value[0] === 'StopAnimation') {
                // console.log('Trying to stop animation');
                clearTimeout(this.miTimeout);
                this.state.areAnimationsDone++;

                const mapLayer = this.mapser.map.getLayer('animatedBus');
                if (mapLayer !== undefined) {
                  this.mapser.map.removeLayer('animatedBus');
                  this.mapser.map.removeSource('animatedBus');
                }
                if (this.busMarker !== undefined)
                  this.busMarker.remove();

                this.busesMarkers.forEach((c: any) => {
                  c.remove();
                });
                /* for (const car of this.chargers) {
                  car.remove();
                } */

              } else if (value[0] === 'Pause') {
                clearTimeout(this.miTimeout);
              }
          }
        );

      });
    });
  }

  subscribeToAllLocations(): void {

    this.LocationsAll = this.state.currentAllLocationsChange.subscribe(
      async (value: any) => {
        //console.log("Dibujar las locacion y cargadores?")
        // console.log('currentAllLocationsChange entry!');
        this.mapser.map.on('load', () => {

          if (value === 'None') {
            // console.log('currentAllLocationsChange NONE!');

          } else {
            // console.log('currentAllLocationsChange ELSE!: ', this.auth.userData.user.authCollections);
            for (const lacollection of this.auth.userData.user.authCollections) {
              const mapLayer = this.mapser.map.getLayer(lacollection);
              if (mapLayer !== undefined) {
                this.mapser.map.removeLayer(lacollection);
                this.mapser.map.removeSource(lacollection);
              }
            }
            // console.log('currentAllLocationsChange ELSE!: ', this.state.allLocations);
            for (const oneStop of this.state.allLocations) {
              //console.log(this.state.allLocations, "this.state.alllocations")
              const mapLayer = this.mapser.map.getLayer(oneStop.colName);
              if (mapLayer !== undefined) {
                this.mapser.map.removeLayer(oneStop.colName);
                this.mapser.map.removeSource(oneStop.colName);
              }
              // console.log('Adding cities: ', oneStop.colName);}
              //console.log("Dibujar los puntos de Colecciones")
              this.mapser.map.addSource(oneStop.colName, {
                'type': 'geojson',
                'data': {
                  'type': 'FeatureCollection',
                  'features': [
                    {
                      'type': 'Feature',
                      'properties': {
                        'description':
                          '<strong>' + oneStop.colName + '</strong><p>Bienvenido, de click para vizualizar.</p>'
                      },
                      'geometry': {
                        'type': 'Point',
                        'coordinates': [oneStop.coordinates[0], oneStop.coordinates[1]]
                      }
                    }]
                }
              });

              // Add a layer showing the places.
              this.mapser.map.addLayer({
                id: oneStop.colName,
                source: oneStop.colName,
                type: 'circle',
                paint: {
                  'circle-radius': 10,
                  'circle-color': '#000096',
                  'circle-opacity': 1,
                  //'circle-color': '#D5E317',
                },
              });
              var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
              });

              this.mapser.map.on('mouseenter', oneStop.colName, (e: any) => {
                // Change the cursor style as a UI indicator.
                this.mapser.map.getCanvas().style.cursor = 'pointer';

                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(this.mapser.map);
              });

              this.mapser.map.on('mouseleave', oneStop.colName, () => {
                this.mapser.map.getCanvas().style.cursor = '';
                popup.remove();
              });
              this.mapser.map.on('click', oneStop.colName, async (e: any) => {
                console.log("Cuando hace click en el circulo de las colecciones")
                e.preventDefault();
                // console.log('CLick en ciudad: ', oneStop.colName);
                this.state.processing = true;
                this.state.whichprocessing = 1;
                await this.dataSer.setBusNames(oneStop.colName);
                await this.dataSer.getAllRoutesPropertiesEvo(oneStop.colName);
                this.dataSer.getAllCoordinates(); // .then((c: any) => {
                this.getCenterOfCollection();
                // });
                console.log("Asigando propiedades a los atributos de configuracion de Buses")
                this.state.whichprocessing = 2;
                this.state.currentRoute = 'None';
                this.state.currentType = '';
                this.state.currentBusSize = '';
                this.state.currentBattery = '';
                this.state.currentRoutePosition = -1;
                if (this.state.allRoutesShown) {
                  this.state.allRoutesShown = false;
                  this.state.changeAllCoordinates('');
                }
                this.state.allRoutesShown = true;
                this.state.changeAllCoordinates('');
                this.state.processing = false;
                await this.dataSer.getAllVehicles();
                if (this.auth.currentBusConfigurations === undefined || this.auth.currentBusConfigurations === null)
                  this.auth.currentBusConfigurations = {}
                const keys = Object.keys(this.auth.currentBusConfigurations);

                for (const llave of keys) {
                  const datos = this.auth.currentBusConfigurations[llave];

                  if (datos.collection === oneStop.colName) {

                    const params = {
                      type: datos.type,
                      bus: datos.route,
                      load: datos.hvac,
                      pax: datos.pax,
                      size: datos.size,
                      battery: datos.battery,
                      currentCollection: datos.collection
                    }
                    try {
                      // console.log('Getting data of selected configurations and adding them to animations: ', this.state.currentCollection);
                      this.state.currentType = datos.type;
                      console.log("Obteniendo datos de RouteProperties - InputsEvo - AllData")
                      await this.dataSer.getRouteProperties(datos.route, datos.collection);
                      await this.dataSer.getAllCyclesInputsEvo(datos.route, datos.collection);
                      await this.dataSer.getAllData(params);
                      const entradas = {
                        chargingmethod: datos.type,
                        vehiclesize: parseInt(datos.size),
                        batteryenergy: parseFloat(datos.battery),
                        collection: datos.collection,
                        route: datos.route
                      };
                      await this.dataSer.getVehicleProperties(entradas);

                    } catch (error) {
                      console.log('Could not get the data in maps component...');

                    }
                  }

                }

                this.state.whichprocessing = 0;
                /* console.log('this.state.rawData: ', this.state.rawData); */

                console.log("Cambio de Coleccion")
                this.state.currentCollection = oneStop.colName;
                this.state.changeCollection(oneStop.colName);
                this.state.processing = true;
                // await this.dataSer.setBusNames(oneStop.colName);
                for (let i = 0; i < this.state.THEDATA.length; i++) {
                  if (i !== 6)
                    this.state.THEDATA[i] = [];
                }
                this.state.processing = false;
                // this.dataSer.getAllStops();


                for (const lacollection of this.auth.userData.user.authCollections) {
                  const mapLayer = this.mapser.map.getLayer(lacollection);
                  if (mapLayer !== undefined) {
                    this.mapser.map.removeLayer(lacollection);
                    this.mapser.map.removeSource(lacollection);
                  }
                }


                // console.log('calling addchargers');
                console.log("Dibujando los Cargadores 2")
                setTimeout(() => {
                  this.addDepotsToMap(oneStop.depotnames, oneStop.depotlatitudes, oneStop.depotlongitudes);
                  //this.addChargersToMap(oneStop.chargernames, oneStop.chargerlatitudes, oneStop.chargerlongitudes);

                }, 10000);


              });
            }
            /* for (const i of this.allLocations) {
              i.remove();
            }
            this.allLocations = [];
            for (const oneStop of this.state.allLocations) {
              const tam = oneStop.length;
                const ele = document.createElement('div');
                let cabecera = 'Colección ' + oneStop.colName;
                ele.className = 'marker';

                this.allLocations.push(
                  new mapboxgl.Marker(ele)
                    .setLngLat([oneStop.coordinates[0], oneStop.coordinates[1]])
                    .setPopup(
                      new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                        closeOnClick: true,

                      }).setHTML(
                        '<h2> ' +
                        cabecera +
                        '</h2>' +
                        '<p>' +
                        oneStop.colName +
                        '</p>'
                      )
                    )
                    .addTo(this.mapser.map)
                );

            }   */

          }
          // this.testGradient();
        });
      }
    );

  }



  //ESTA FUNCION NO SE USA -
  //FUNCION A ELIMINAR
  addChargersToMap(names: string[], latitude: number[], longitude: number[]): void {
    console.log("Funciones addChargersToMap - maps.component")
    // console.log(names, longitude, latitude);
    for (const car of this.chargers) {
      car.remove();
    }
    this.chargers = [];
    for (let jota = 0; jota < names.length; jota++) {

      const ele = document.createElement('div');
      ele.className = 'busanimado';
      ele.style.backgroundImage =
        'url(../../../assets/chargador.png)';
      ele.style.backgroundSize = '100%';
      // console.log('creando cargador para ', names[jota]);
      this.chargers.push(
        new mapboxgl.Marker(ele)
          .setLngLat([longitude[jota], latitude[jota]])
          .addTo(this.mapser.map)
      );
      //console.log("this.chargers : ", this.chargers)
      this.chargers[this.chargers.length - 1].getElement().addEventListener('click', () => {
        this.state.showChargersData = !this.state.showChargersData;
        this.state.selectedCharger = names[jota];
      }); // */

      /*if (this.mapser.map.getSource(names[jota])) {
        this.mapser.map.removeLayer(names[jota]);
        this.mapser.map.removeSource(names[jota]);
      }
      const doslon = longitude[jota].toFixed(2);
      const doslat = latitude[jota];
      // console.log('El cargador va en ', [longitude[jota], latitude[jota]]);
      this.mapser.map.addSource(names[jota], {
        type: 'image',
        url: 'assets/chargador.png',
        coordinates: [
          [longitude[jota] , latitude[jota] + 0.001],
          [longitude[jota] + 0.001, latitude[jota] + 0.001],
          [longitude[jota] + 0.001, latitude[jota]],
          [longitude[jota], latitude[jota]]

        ]
      });

      this.mapser.map.addLayer({
        id: names[jota],
        'type': 'raster',
        'source': names[jota],
        'paint': {
          'raster-fade-duration': 0
        }
      }); // */


    }

  }

  addDepotsToMap(names: string[], latitude: number[], longitude: number[]): void {
    console.log("Dibujar add Depots to Map")
    // console.log(names, longitude, latitude);
    for (const car of this.depots) {
      car.remove();
    }
    this.depots = [];
    for (let jota = 0; jota < names.length; jota++) {

      const ele = document.createElement('div');
      ele.className = 'busanimado2';
      ele.style.backgroundImage =
        'url(../../../assets/paradilla.png)';
      ele.style.backgroundSize = '100%';
      // console.log('creando cargador para ', names[jota]);
      /* this.depots.push(
        new mapboxgl.Marker(ele)
          .setLngLat([longitude[jota], latitude[jota]])
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              closeOnClick: true,
            }).setHTML(
              '<p> Depot: ' + names[jota] + ' </p>'
            )
          )
          .addTo(this.mapser.map)
      ); */
      if (this.mapser.map.getSource('dep' + names[jota])) {
        this.mapser.map.removeLayer('dep' + names[jota]);
        this.mapser.map.removeSource('dep' + names[jota]);
      }

      this.mapser.map.addSource('dep' + names[jota], {
        type: 'image',
        url: 'assets/paradilla.png',
        coordinates: [
          [longitude[jota] + 0.001, latitude[jota] + 0.001],
          [longitude[jota], latitude[jota] + 0.001],
          [longitude[jota], latitude[jota]],
          [longitude[jota] + 0.001, latitude[jota]]

        ]
      });

      this.mapser.map.addLayer({
        id: 'dep' + names[jota],
        'type': 'raster',
        'source': 'dep' + names[jota],
        'paint': {
          'raster-fade-duration': 0
        }
      }); // */
    }

  }

  subscribeToPointIndex(): void {
    this.PointIndexSubs = this.state.currentPointIndexChange.subscribe(
      async (value: any) => {
        // console.log('Obteniendo el valor de la POINTINDEX!!: ', value);
        if (value[0] === -1 && value[1] === -1) {
          if (this.redPointMarker !== undefined)
            this.redPointMarker.remove();
          this.pointIndexVisible = false;
          return;

        } else {

          if (this.redPointMarker !== undefined)
            this.redPointMarker.remove();

          this.pointIndexVisible = true;

          const lalon = value[0];
          const lalat = value[1];

          const ele = document.createElement('div');
          ele.className = 'busanimado';
          ele.style.backgroundImage =
            'url(../../../assets/busiconNormal.png)';
          ele.style.backgroundSize = '100%';
          this.redPointMarker =
            new mapboxgl.Marker(ele)
              .setLngLat([lalon, lalat])
              .setPopup(
                new mapboxgl.Popup({
                  offset: 25,
                  closeButton: false,
                  closeOnClick: true,
                }).setHTML(
                  '<p> ' + this.state.currentRoute + ' </p>'
                )
              )
              .addTo(this.mapser.map);


        }
        /* if (value[0] === -1 && value[1] === -1) {

          const mapLayer = this.mapser.map.getLayer('pointI');
          // console.log('Layer: ', mapLayer);
          if (mapLayer !== undefined) {
            this.mapser.map.removeLayer('pointI');
            this.mapser.map.removeSource('pointIndex');
          }
          this.pointIndexVisible = false;

          return;

        } else {

          const mapLayer = this.mapser.map.getLayer('pointI');
          if (mapLayer !== undefined) {
            this.mapser.map.removeLayer('pointI');
            this.mapser.map.removeSource('pointIndex');
          }

          this.pointIndexVisible = true;
          const lalon = value[0];
          const lalat = value[1];
          this.mapser.map.addSource('pointIndex', {
            type: 'geojson',
            data: {
              type: 'Point',
              coordinates: [lalon, lalat],
            },
          });

          // Add a layer showing the places.
          this.mapser.map.addLayer({
            id: 'pointI',
            source: 'pointIndex',
            type: 'circle',
            paint: {
              'circle-radius': 13,
              'circle-color': '#FF0000',
              'circle-opacity': 1,
              // 'circle-color': '#000000',
            },
          });

        } */
      }
    );
  }

  subscribeLocation(): void {
    console.log("currentLocationChange", this.state.currentLocationChange)
    this.LocationSubs = this.state.currentLocationChange.subscribe(
      async (value: any) => {

        // console.log('Obteniendo el valor de la location!!: ', value);
        if (this.state.allRoutesShown) {
          this.state.allRoutesShown = false;
          this.state.changeAllCoordinates('');
          /* for (let i = 0; i < this.state.allCoordinates.length; i++) {
            this.mapser.map.removeLayer('route' + i);
            this.mapser.map.removeSource('route' + i);
          } */

        }
        for (const lacollection of this.auth.userData.user.authCollections) {
          const mapLayer = this.mapser.map.getLayer(lacollection);
          if (mapLayer !== undefined) {
            this.mapser.map.removeLayer(lacollection);
            this.mapser.map.removeSource(lacollection);
          }
        }
        this.allData = [[0, 0]];
      }
    );
  }

  subscribeToBusRoute(): void {
    //this.deleteAllChargersToMap()
    console.log("state.currentRouteChange", this.state.currentRouteChange)
    this.RouteSubs = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        // console.log('Obteniendo el valor del bus!!: ', value);
        if (value === 'None') {
          this.allData = [[0, 0]];
          this.stops = [[0, 0]];
        } else {
          const params = {
            bus: value,
          };
          this.state.chartMapPointIndex = [-1, -1];
          this.state.changePointIndex([-1, -1]);
          // await this.dataSer.updateTHEDATA();
          this.allData = await this.dataSer.getCoordinates(params);
          this.stops = await this.dataSer.getStops();
        }
        // console.log('obtuve data de nuevo: ', this.allData);
        this.changeRoute(value);
        this.addCurrentRouteStops();
        // this.unafunction2();
      }
    );
  }

  getRandomColor(): any {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  //ESTA FUNCION DIBUJA LAS RUTAS UNA VEZ SELECCIONADO LAS CONF DEL BUSES 
  recreateCoordinates() {
    console.log("recreateCoordinates")
    for (let i = 0; i < this.state.allCoordinates.length; i++) {
      if (i !== this.state.currentRoutePosition) {
        if (this.mapser.map.getLayer('route' + i) !== undefined) {
          this.mapser.map.removeLayer('route' + i);
          this.mapser.map.removeSource('route' + i);
        }
      }
    }
    for (const i of this.allStops) {
      i.remove();
    }
    console.log("las coordenadas de las rutas en seleccion conf", this.state.allCoordinates)
    for (let i = 0; i < this.state.allCoordinates.length; i++) {
      if (i !== this.state.currentRoutePosition) {
        this.mapser.map.addSource('route' + i, {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: this.state.allCoordinates[i],
            },
          },
        });
        this.mapser.map.addLayer({
          id: 'route' + i,
          type: 'line',
          source: 'route' + i,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            //'line-color': '#B600E0 ',
            'line-color': '#4bc9ff ',
            'line-width': 3,
          },
        });
        this.mapser.map.on('mouseenter', 'route' + i, () => {
          this.mapser.map.getCanvas().style.cursor = 'pointer';
        });
      }
    }
    // this.addAllRouteStops();
  }
  // ESTA FUNCION DIBUJA EL TOTAL DE LAS RUTAS DE LA COLECCION POR MEDIO DE /routeproperties
  // Este dibuja la ruta una vez seleccionado la colección correspondiente.
  subscribeToAllCoordinates(): void {

    //this.addCurrentRouteStops();
    console.log("subscribeToAllCoordinates")
    console.log("subscribeToAllCoordinates", this.state.currentAllCoordinatesChange)
    this.coordinatesAll = this.state.currentAllCoordinatesChange.subscribe(
      async (value: any) => {
        console.log('Llamando a todas las coords', this.CoordinatesAll);
        console.log("allcoordinates", this.state.allCoordinates, value)
        if (this.state.allRoutesShown) {
          for (let i = 0; i < this.state.allCoordinates.length; i++) {
            if (i !== this.state.currentRoutePosition) {
              if (this.mapser.map.getLayer('route' + i) !== undefined) {
                this.mapser.map.removeLayer('route' + i);
                this.mapser.map.removeSource('route' + i);
              }
              /**this.mapser.map.addSource('route' + i, {
                type: 'geojson',
                lineMetrics: true,
                data: {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: this.state.allCoordinates[i],
                  },
                },
              });
              this.mapser.map.addLayer({
                id: 'route' + i,
                type: 'line',
                source: 'route' + i,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#4bc9ff ',
                  //'line-color': '#F7BD34',
                  'line-width': 3,
                },
              });*/
              this.mapser.map.on('mouseenter', 'route' + i, () => {
                this.mapser.map.getCanvas().style.cursor = 'pointer';
              });
            }
          }
          // this.addAllRouteStops();

          this.getCenterOfCollection();

        } else {
          for (let i = 0; i < this.state.allCoordinates.length; i++) {
            if (i !== this.state.currentRoutePosition) {
              if (this.mapser.map.getLayer('route' + i) !== undefined) {
                this.mapser.map.removeLayer('route' + i);
                this.mapser.map.removeSource('route' + i);
              }
            }
          }
          for (const i of this.allStops) {
            i.remove();
          }

        }
      }
      
    );
    console.log("this.state.allLocations: ", this.state.allLocations)
    console.log("coordinatesAll", this.coordinatesAll)
    this.allUpSubs = this.state.allSeriesUp.subscribe(
      async (value: any) => {
        // console.log('Todos arriba!: ', this.state.allLocations);
        for (const oneStop of this.state.allLocations) {
          if (oneStop.colName === this.state.currentCollection) {
            setTimeout(async () => {
              this.addDepotsToMap(this.state.allLocations[0].depotnames, this.state.allLocations[0].depotlatitudes, this.state.allLocations[0].depotlongitudes);
              //this.addChargersToMap(this.state.allLocations[0].chargernames, this.state.allLocations[0].chargerlatitudes, this.state.allLocations[0].chargerlongitudes);
            }, 10000);
          }
        }
      });
  }

  getPositionBus(): any {
    /*console.log('Getting values for ', this.countPos);
    console.log('Getting values of ', this.positionBus.Longitude); // */
    return {
      type: 'Point',
      coordinates: [
        this.positionBus.Longitude[this.countPos],
        this.positionBus.Latitude[this.countPos],
      ],
    };
  }

  testGradient() {
    const geojson = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'coordinates': [
              [-77.044211, 38.852924],
              [-77.045659, 38.860158],
              [-77.044232, 38.862326],
              [-77.040879, 38.865454],
              [-77.039936, 38.867698],
              [-77.040338, 38.86943],
              [-77.04264, 38.872528],
              [-77.03696, 38.878424],
              [-77.032309, 38.87937],
              [-77.030056, 38.880945],
              [-77.027645, 38.881779],
              [-77.026946, 38.882645],
              [-77.026942, 38.885502],
              [-77.028054, 38.887449],
              [-77.02806, 38.892088],
              [-77.03364, 38.892108],
              [-77.033643, 38.899926]
            ],
            'type': 'LineString'
          }
        }
      ]
    };
    this.map.addSource('line', {
      type: 'geojson',
      lineMetrics: true,
      data: geojson
    });
    this.mapser.map.addLayer({
      type: 'line',
      source: 'line',
      id: 'line',
      paint: {
        'line-color': 'red',
        'line-width': 14,
        // 'line-gradient' must be specified using an expression
        // with the special 'line-progress' property
        'line-gradient': [
          // "interpolate-hcl",
          'interpolate',
          ['linear'],
          ['line-progress'],
          0, 'blue',
          0.1, 'royalblue',
          0.2, 'cyan',
          0.3, 'lime',
          0.4, 'yellow',
          0.5, 'red'
        ]
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      }
    });
    this.mapser.map.flyTo({
      center: [-77.044211, 38.852924],
      zoom: 10,
    });
    this.mapser.map.on('mouseenter', 'route', () => {
      this.mapser.map.getCanvas().style.cursor = 'pointer';
    });
  }

  async initRouteLayer(): Promise<void> {
    await this.mapser.map.on('load', () => {
      this.mapser.map.resize();
      if (this.mapser.map.getLayer('bus') !== undefined) {
        this.mapser.map.removeLayer('bus');
        this.mapser.map.removeSource('bus');
      }

      //this.mapser.map.addSource('bus', {
      //  type: 'geojson',
      //  data: {
      //    type: 'Point',
      //    coordinates: [-79.549658, 8.972557],
      //  },
      //});

      this.mapser.map.addLayer({
        id: 'point',
        source: 'bus',
        type: 'circle',
        paint: {
          'circle-radius': 10,
          'circle-color': '#ffffff',
          'circle-opacity': 0,
          // 'circle-color': '#000000',
        },
      });

      this.mapser.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      });
    });



    // let icon = document.getElementById('icon');

    // console.log('Yes, going o alllocations: ', this.state.allLocations);
    if (this.auth.userData.user.authCollections.length > 1) {
      this.state.changeAllLocations('');
    }
  }

  async changeRoute(whichRoute: any): Promise<void> {
    // this.hideLayer();
    // console.log('Pintare changeRoute');

    if (this.mapser.map.getLayer('route') !== undefined) {
      //console.log('Eliminando route');
      this.mapser.map.removeLayer('route');
      this.mapser.map.removeSource('route');
    }

    if (this.state.currentRoute !== 'None') {
      if (this.state.allRoutesShown) {
        //this.recreateCoordinates();
      }
      let lapos = 0;
      this.state.busNames.forEach((c: any, index: number) => {
        if (c === this.state.currentRoute)
          lapos = index;
      });
      //console.log('lapos: ', lapos);
      /*this.mapser.map.addSource('route', {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: this.state.allCoordinates[lapos]
            // coordinates: this.allData,
          },
        },
      });
      this.mapser.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#1546FB',
          'line-width': 3,
        },

      });  // */
      // const mit = Math.floor(this.allData.length / 2);
      /* this.mapser.map.getSource('route').setData({
       type: 'Feature',
       properties: {},
       geometry: {
         type: 'LineString',
         coordinates: this.allData,
       },
     }); */
    } else {

      if (whichRoute !== 'EndHover' && whichRoute !== 'None') {
        this.lastwhichRoute = whichRoute;
        let lapos = 0;
        this.state.busNames.forEach((c: any, index: number) => {
          if (c === whichRoute)
            lapos = index;
        });
        // console.log('lapos: ', lapos);
        if (this.mapser.map.getLayer('route' + whichRoute) !== undefined) {
          this.mapser.map.removeLayer('route' + whichRoute);
          this.mapser.map.removeSource('route' + whichRoute);
        }
        this.mapser.map.addSource('route' + whichRoute, {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: this.state.allCoordinates[lapos]
              // coordinates: this.allData,
            },
          },
        });
        this.mapser.map.addLayer({
          id: 'route' + whichRoute,
          type: 'line',
          source: 'route' + whichRoute,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#1546FB',
            'line-width': 3,
          },

        });
      } else {
        if (this.mapser.map.getLayer('route' + this.lastwhichRoute) !== undefined) {
          this.mapser.map.removeLayer('route' + this.lastwhichRoute);
          this.mapser.map.removeSource('route' + this.lastwhichRoute);
        }
      }

    }



    /* this.mapser.map.getSource('points').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.loMin, this.laMax],
            },
            properties: {
              'image-name': 'popup',
              name: this.state.currentRoute,
            },
          },
        ],
    }); */


  }

  // Be careful with this function. Currently it works in our hemisphere only...
  getCenterOfCoordinates(): any {
    console.log(this.allData);
    const latitudes = [];
    const longitudes = [];
    for (const i of this.allData) {
      longitudes.push(i[0]);
      latitudes.push(i[1]);
    }
    this.laMax = Math.max(...latitudes);
    this.loMax = Math.max(...longitudes);
    this.laMin = Math.min(...latitudes);
    this.loMin = Math.min(...longitudes);

    let newZoom = 12;
    const ancho = Math.abs(this.loMax - this.loMin);
    const alto = Math.abs(this.laMax - this.laMin);
    if (ancho < 0.02 && alto < 0.02) {
      newZoom = 14;
    }
    if (ancho > 0.15 || alto > 0.15) {
      newZoom = 11;
    }
    if (ancho > 0.2 || alto > 0.2) {
      newZoom = 10;
    }

    /* console.log('Ancho de ruta: ', ancho);
    console.log('ALto de ruta: ', alto); */

    const myLon = (this.loMin + this.loMax) / 2; // + 0.03;
    const myLat = (this.laMin + this.laMax) / 2;
    return [myLon, myLat, newZoom];
  }

  getCenterOfCollection(): void {
    console.log('this.state.allCoordinates: ', this.state.allCoordinates);

    const latitudes = [];
    const longitudes = [];
    for (const ruta of this.state.allCoordinates) {
      for (const coordis of ruta) {
        longitudes.push(coordis[0]);
        latitudes.push(coordis[1]);
      }
    }
    /* for (const i of this.allData) {
      longitudes.push(i[0]);
      latitudes.push(i[1]);
    }*/
    this.laMax = Math.max(...latitudes);
    this.loMax = Math.max(...longitudes);
    this.laMin = Math.min(...latitudes);
    this.loMin = Math.min(...longitudes);

    let newZoom = 12;
    const ancho = Math.abs(this.loMax - this.loMin);
    const alto = Math.abs(this.laMax - this.laMin);
    if (ancho < 0.02 && alto < 0.02) {
      newZoom = 15;
    }
    if (ancho > 0.15 || alto > 0.15) {
      newZoom = 12;
    }
    if (ancho > 0.2 || alto > 0.2) {
      newZoom = 11.5;
    }

    /* if (window.screen.width * window.devicePixelRatio <= 800) {
      console.log('high camera');
    } else if (window.screen.width * window.devicePixelRatio <= 1280) {
      console.log('medium high camera');
    } else if (window.screen.width * window.devicePixelRatio <= 1366) {
      console.log('low camera');
    } else {
      console.log('really low camera');
    } */

    /* console.log('Ancho de ruta: ', ancho);
    console.log('Alto de ruta: ', alto);
    console.log('ZooM . ', newZoom); */
    /* alert("Your screen resolution is: " + window.screen.width * window.devicePixelRatio + "x" + window.screen.height * window.devicePixelRatio +
    " and its size is " + window.screen.width + "x" + window.screen.height); */
    const myLon = (this.loMin + this.loMax) / 2; // + 0.03;
    const myLat = (this.laMin + this.laMax) / 2;

    console.log('Llamando a flyTo 2', myLon, myLat);
    this.mapser.map.flyTo({
      center: [myLon, myLat],
      zoom: newZoom,
      speed: 0.7,
    });
    return;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.pointIndexSubs) {
      // console.log('Unsubscribing from pointIndex in destroy');
      this.pointIndexSubs.unsubscribe();
      this.pointIndexSubs = undefined;
    }
    if (this.locationSubs) {
      // console.log('Unsubscribing from locations in destroy');
      this.locationSubs.unsubscribe();
      this.locationSubs = undefined;
    }
    if (this.routeSubs) {
      // console.log('Unsubscribing from routes in destroy');
      this.routeSubs.unsubscribe();
      this.routeSubs = undefined;
    }
    if (this.CoordinatesAll) {
      // console.log('Unsubscribing from routes in destroy');
      this.CoordinatesAll.unsubscribe();
      this.CoordinatesAll = undefined;
    }
    if (this.LocationsAll) {
      // console.log('Unsubscribing from routes in destroy');
      this.LocationsAll.unsubscribe();
      this.LocationsAll = undefined;
    }
    if (this.AnimationsSubs) {
      // console.log('Unsubscribing from routes in destroy');
      this.AnimationsSubs.unsubscribe();
      this.AnimationsSubs = undefined;
    }
    // console.log('MAPS component destroyed');

  }
  collectionChargers(): void {
    console.log("Entrando collecion cargadores mapa")
    for (const oneStop of this.state.allLocations) {
      this.mapser.map.addLayer({
        id: oneStop.colName,
        source: oneStop.colName,
        type: 'circle',
        paint: {
          'circle-radius': 10,
          'circle-color': '#000096',
          'circle-opacity': 1,
          //'circle-color': '#D5E317',
        },
      });
      setTimeout(() => {
        //this.addChargersToMap(oneStop.chargernames, oneStop.chargerlatitudes, oneStop.chargerlongitudes);

      }, 1000);
    }
  }
  nuevosetSatellite() {
    if (this.styleNormal) {
      // this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-v9');
      this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      this.whichicon = 'layers_azul2';
      this.styleNormal = !this.styleNormal;
    } else {
      this.mapser.map.setStyle('mapbox://styles/gcoll2006/cjmmwkckvdtsj2snw561f80gj');
      this.whichicon = 'layers_blanco2';
      this.styleNormal = !this.styleNormal;
    }
  }

  changeSatelliteView(): void {
    console.log('Changing view: ', this.styleNormal);
    if (this.styleNormal) {
      // this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-v9');
      this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      this.whichicon = 'layers_azul2';

    } else {
      this.mapser.map.setStyle('mapbox://styles/gcoll2006/cjmmwkckvdtsj2snw561f80gj');
      this.whichicon = 'layers_blanco2';
    }
    this.styleNormal = !this.styleNormal;
  }
  satelliteClick(): void {
    try {
      if (this.mapser.map.getLayer('route') !== undefined) {
        this.mapser.map.removeLayer('route');
        this.mapser.map.removeSource('route');
      }

    } catch (error) {
      console.log('No es neceario eliminar la ruta');
    }
    console.log('Changing view: ', this.styleNormal);
    if (this.styleNormal) {
      // this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-v9');
      this.mapser.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      this.whichicon = 'layers_azul2';

    } else {
      this.mapser.map.setStyle('mapbox://styles/gcoll2006/cjmmwkckvdtsj2snw561f80gj');
      this.whichicon = 'layers_blanco2';
    }
    this.styleNormal = !this.styleNormal;
    setTimeout(async () => {
           // ########
           console.log("Dibujar Contornos de Depots")
           var atuarea2 = [
             [-77.14933202818385, -12.06694470266739],
             [-77.14944712952821, -12.06636513062819],
             [-77.1504727897226, -12.06647036321244],
             [-77.15135168278208, -12.06673412288915],
             [-77.14933202818385, -12.06694470266739]]

           var atuarea4 = [
             [-77.14330329655319, -12.06742466564505],
             [-77.14266734481889, -12.06754282878026],
             [-77.14192209503017, -12.06767801771918],
             [-77.14216802744156, -12.06625675257229],
             [-77.14348133972248, -12.06642099944418],
             [-77.14330329655319, -12.06742466564505]]

           var atuarea5 = [
             [-77.01440045996668, -12.05680232865641],
             [-77.01429840131919, -12.05701301038981],
             [-77.0142166544551, -12.05711857767517],
             [-77.01403207593594, -12.05690677533974],
             [-77.01420408632531, -12.05674399312296],
             [-77.01403842308619, -12.05651845201909],
             [-77.01423219799418, -12.05637700087365],
             [-77.01446545854937, -12.05665349302032],
             [-77.01440045996668, -12.05680232865641]]

           var atuarea6 = [
             [-77.0136049076914, -12.05554974105916],
             [-77.01366762393199, -12.05563246534415],
             [-77.01344670123781, -12.05581575455597],
             [-77.01337378730639, -12.05572310129193],
             [-77.0136049076914, -12.05554974105916]]

           var atuarea7 = [
             [-77.01237498736083, -12.05441045838119],
             [-77.0129599803425, -12.05521203685678],
             [-77.01274430658655, -12.05537932921909],
             [-77.01217230280949, -12.05459869538279],
             [-77.01237498736083, -12.05441045838119]]

           var atuarea3 = [
             [-77.14617237759897, -12.06719797017011],
             [-77.14610224881444, -12.0672838206397],
             [-77.14594416324231, -12.06733083977794],
             [-77.14483808719662, -12.06742893561967],
             [-77.14495476862393, -12.06688486772861],
             [-77.14620761126197, -12.06704859670873],
             [-77.14617237759897, -12.06719797017011]]

           var atuPatioSIB = [
             [-76.98823473433993, -12.10191373605472],
             [-76.98833371914897, -12.1030398943769],
             [-76.98649889912754, -12.10291591375656],
             [-76.98641735178275, -12.10207509909314],
             [-76.98823473433993, -12.10191373605472]
           ]
           var listadepot = [atuarea2, atuarea3, atuarea4, atuarea5, atuarea6, atuarea7, atuPatioSIB]
           console.log("listaDepotDemo", this.state.listaDepotDemo, this.state.listaDepotDemo.length)
           if (this.state.listaDepotDemo.length != 0) {
             console.log("Entrando a listaDepotDemo 0")
             while (this.state.listaDepotDemo.length > 0) {
               var deletesourceLayer = this.state.listaDepotDemo.pop();
               var deletesourceName = deletesourceLayer.source;
               var deletelayerName = deletesourceLayer.layer;

               //this.map.removeLayer(deletelayerName);
               //this.map.removeSource(deletesourceName);
             }
           }
           //this.state.listaDepotDemo = []
           for (let i = 0; i < listadepot.length; i++) {
             console.log("dibujando rutas")
             var sourceName = 'depotdemo-source-' + i;
             var layerName = 'depotdemo-layer-' + i;
             this.state.listaDepotDemo.push({ source: sourceName, layer: layerName });

             var element = listadepot[i];
             console.log(element)
             this.mapser.map.addSource(sourceName, {
               type: 'geojson',
               lineMetrics: true,
               data: {
                 type: 'Feature',
                 properties: {},
                 geometry: {
                   type: 'LineString',
                   coordinates: element,
                 },
               },
             });
             this.mapser.map.addLayer({
               id: layerName,
               type: 'line',
               source: sourceName,
               layout: {
                 'line-join': 'round',
                 'line-cap': 'round',
               },
               paint: {
                 'line-color': '#F50C01 ',
                 //'line-color': '#F7BD34',
                 'line-width': 3,
               },
             });

           }
           console.log("listaDepotDemo", this.state.listaDepotDemo)
      //await this.dataSer.dibujarTodosLosCargadores(this.state.currentCollection)
      if (this.state.currentCollection === '') {
        // console.log('this.state.currentCollection: ', this.state.currentCollection);
        for (const lacollection of this.auth.userData.user.authCollections) {
          const mapLayer = this.mapser.map.getLayer(lacollection);
          if (mapLayer !== undefined) {
            this.mapser.map.removeLayer(lacollection);
            this.mapser.map.removeSource(lacollection);
          }
        }
        for (const oneStop of this.state.allLocations) {
          const mapLayer = this.mapser.map.getLayer(oneStop.colName);
          if (mapLayer !== undefined) {
            this.mapser.map.removeLayer(oneStop.colName);
            this.mapser.map.removeSource(oneStop.colName);
          }
          //await this.dataSer.dibujarTodosLosCargadores(this.state.currentCollection)
          this.mapser.map.addSource(oneStop.colName, {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'properties': {
                    'description':
                      '<strong>' + oneStop.colName + '</strong><p>Bienvenido, de click para vizualizar.</p>'
                  },
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [oneStop.coordinates[0], oneStop.coordinates[1]]
                  }
                }]
            }
          });

          // Add a layer showing the places.
          this.mapser.map.addLayer({
            id: oneStop.colName,
            source: oneStop.colName,
            type: 'circle',
            paint: {
              'circle-radius': 10,
              'circle-color': '#000096',
              'circle-opacity': 1,
              //'circle-color': '#D5E317',
            },
          });
          var popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          this.mapser.map.on('mouseenter', oneStop.colName, (e: any) => {
            // Change the cursor style as a UI indicator.
            this.mapser.map.getCanvas().style.cursor = 'pointer';

            var coordinates = e.features[0].geometry.coordinates.slice();
            var description = e.features[0].properties.description;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(this.mapser.map);
          });

          this.mapser.map.on('mouseleave', oneStop.colName, () => {
            this.mapser.map.getCanvas().style.cursor = '';
            popup.remove();
          });
          this.mapser.map.on('click', oneStop.colName, async (e: any) => {
            e.preventDefault();
            // console.log('Los chargers son: ', oneStop.chargernames);

            /* const keys = Object.keys(this.auth.currentBusConfigurations);
            for (const llave of keys) {
              const datos = this.auth.currentBusConfigurations[llave];

              if (datos.collection === oneStop.colName) {

                const query1 = datos.collection + '_' + datos.route;
                const query2 = datos.type + '_' + datos.size + 'm_' + datos.battery + 'kWh_' + datos.hvac + '_pass' + datos.pax;


              }

            } */
            /* console.log('this.state.rawData: ', this.state.rawData); */
            this.state.currentRoute = 'None';
            this.state.currentType = '';
            this.state.currentBusSize = '';
            this.state.currentBattery = '';
            this.state.currentRoutePosition = -1;
            if (this.state.allRoutesShown) {
              this.state.allRoutesShown = false;
              this.state.changeAllCoordinates('');
            }
            this.state.currentCollection = oneStop.colName;
            this.state.changeCollection(oneStop.colName);
            this.state.processing = true;
            await this.dataSer.setBusNames(oneStop.colName);
            for (let i = 0; i < this.state.THEDATA.length; i++) {
              if (i !== 6)
                this.state.THEDATA[i] = [];
            }
            this.state.processing = false;
            // this.dataSer.getAllStops();
            this.dataSer.getAllCoordinates(); // .then((c: any) => {
            this.getCenterOfCollection();
            // });

            for (const lacollection of this.auth.userData.user.authCollections) {
              const mapLayer = this.mapser.map.getLayer(lacollection);
              if (mapLayer !== undefined) {
                this.mapser.map.removeLayer(lacollection);
                this.mapser.map.removeSource(lacollection);
              }
            }
            this.state.allRoutesShown = true;
            this.state.changeAllCoordinates('');
       
            // console.log('calling addchargers');
            setTimeout(() => {
              //this.addChargersToMap(oneStop.chargernames, oneStop.chargerlatitudes, oneStop.chargerlongitudes);

            }, 1000);


          });
        }
      }
      if (this.state.allRoutesShown) {
        /* for (const car of this.chargers) {
          car.remove();
        } */
        for (let i = 0; i < this.state.allCoordinates.length; i++) {
          if (i !== this.state.currentRoutePosition) {
            if (this.mapser.map.getLayer('route' + i) !== undefined) {
              this.mapser.map.removeLayer('route' + i);
              this.mapser.map.removeSource('route' + i);
            }
          }
        }
        for (let i = 0; i < this.state.allCoordinates.length; i++) {
          if (i !== this.state.currentRoutePosition) {
            this.mapser.map.addSource('route' + i, {
              type: 'geojson',
              lineMetrics: true,
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: this.state.allCoordinates[i],
                },
              },
            });
            this.mapser.map.addLayer({
              id: 'route' + i,
              type: 'line',
              source: 'route' + i,
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#4bc9ff',
                'line-width': 3,
              },
            });
            this.mapser.map.on('mouseenter', 'route' + i, () => {
              this.mapser.map.getCanvas().style.cursor = 'pointer';
            });
          }
        }
        this.addAllRouteStops();
      }

      if (this.state.currentRoute !== 'None') {
        // console.log('adding new route');
        if (this.mapser.map.getLayer('route') !== undefined) {
          this.mapser.map.removeLayer('route');
          this.mapser.map.removeSource('route');
        }
        this.mapser.map.addSource('route', {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: this.allData,
            },
          },
        });
        this.mapser.map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#1546FB',
            'line-width': 3,
          },

        });
      }

      /* if (this.state.areAnimationsDone < 2) {
        const mapLayer = this.mapser.map.getLayer('animatedBus');
        if (mapLayer !== undefined) {
          this.mapser.map.removeLayer('animatedBus');
          this.mapser.map.removeSource('animatedBus');
        }
        clearTimeout(this.miTimeout);
      } */



    }, 500);
  }

  showInfoBus(){
    this.state.show_info_bus = !this.state.show_info_bus 
  }
}
