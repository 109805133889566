import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LinearGaugeComponent } from '@syncfusion/ej2-angular-lineargauge';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-gauge-distance-single',
  templateUrl: './gauge-distance-single.component.html',
  styleUrls: ['./gauge-distance-single.component.scss']
})
export class GaugeDistanceSingleComponent implements OnInit {
  public animation: object;
  public pointers5: Object[];
  public items: any = [];
  public container: Object;
  public majorTicks: object;
  public minorTicks: object;
  public margin: Object;
  public line: Object;
  public label: Object;
  @ViewChild('gaugedisingle')
  public gaugesoc!: LinearGaugeComponent;
  subscription0: Subscription | undefined;
  labelStyle: { font: { color: string; size: string; fontWeight: string; }; };

  constructor(public state: StateService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.animation = {
      enable: false
    };
    this.majorTicks = {
      //height: 10,
      color: 'white'
      // interval: 0
    };
    this.minorTicks = {
      // height: 5
      color: 'white'
    };
    this.container = {
      width: 50,
      type: "RoundedRectangle",
      backgroundColor: "#ff0000"
    };
    this.labelStyle = {
      font: {
        color: 'white',
        size: '0px',
        fontWeight: 'Bold'
      }
    };
    this.line = {
      // height: 150,
      width: 2,
      color: '#4286f4',
      offset: 2
    };
    this.label = {
      position: "Inside"
    };
    this.margin = { left: 0, top: 0, right: 0, bottom: 0 }

    this.state.busNames.forEach((c: any, index: number) => {
      let maximum = 0;

      if (this.state.routesAnimationData[this.state.currentCollection + c] !== undefined) {
        /* console.log('this.state.routesAnimationData[this.state.currentCollection + c].distancekm: ',
          this.state.routesAnimationData[this.state.currentCollection + c].distancekm); */
        const a = parseInt(this.state.routesAnimationData[this.state.currentCollection + c].distancekm[this.state.routesAnimationData[this.state.currentCollection + c].distancekm.length - 1].toFixed(0));

        maximum = parseInt(this.state.routesAnimationData[this.state.currentCollection + c].distancekm[this.state.routesAnimationData[this.state.currentCollection + c].distancekm.length - 1].toFixed(0));
      }
      this.items.push({
        id: this.state.currentCollection + c,
        color: '#ff0000',
        maximum
      });
    });
  }

  subscribeConfiguration(): void {
    this.subscription0 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        // console.log('updating gauhe soc');
        this.changeDetector.detectChanges();
        this.gaugesoc.refresh();
      }
    );
  }

  get laSubs(): any {
    return this.subscription0;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    if (this.laSubs)
      this.laSubs.unsubscribe();

  }
}
