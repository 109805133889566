<div class="paradiv">
  <span  style="color: white;height: 50px;">Alertas</span>

  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let bus of sortBy(); index as i" style="padding:0px">
  <!-- <mat-grid-list cols="1" rowHeight="65px" *ngFor="let bus of state.busNames; index as i" style="padding:0px"> -->
    <mat-grid-tile (click)="checkBus(bus, i)"
      style="color: white; background-color: rgba(255,255,255,0.0);border-bottom: 1px solid white; border-top: 1px solid white; border-radius: 0px;">
<!--
      <div
        *ngIf="this.state.socGaugeValue[state.currentCollection+bus] >= 50 && this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon style="color: limegreen">fiber_manual_record</mat-icon>
      </div>
      <div *ngIf="this.state.socGaugeValue[state.currentCollection+bus] < 50 && this.state.socGaugeValue[state.currentCollection+bus] >= 30 &&
        this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon style="color: yellow">fiber_manual_record</mat-icon>
      </div>
      <div *ngIf="this.state.socGaugeValue[state.currentCollection+bus] < 30 && this.state.socGaugeValue[state.currentCollection+bus] >= 20 &&
       this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon style="color: orangered">fiber_manual_record</mat-icon>
      </div>
      <div *ngIf="this.state.socGaugeValue[state.currentCollection+bus] < 20 && this.state.socGaugeValue[state.currentCollection+bus] >= 0 &&
      this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon style="color: red">fiber_manual_record</mat-icon>
      </div>
      <div *ngIf="this.state.socGaugeValue[state.currentCollection+bus] < 0 &&
        this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon style="color: violet">fiber_manual_record</mat-icon>
      </div> -->
      <div *ngIf=" this.state.socGaugeValue[state.currentCollection+bus] != -1">
        <mat-icon [style.color]="state.animationAlerts[state.currentCollection+bus].worstcolor">fiber_manual_record</mat-icon>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
