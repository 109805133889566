<div class="tarjetaNueva">
  <div class="control-section">
    <ejs-grid #grid [dataSource]='data'  allowSorting="true" [allowGrouping]="true"
      [groupSettings]="groupOptions" height="450" (dataBound)='dataBound($event)'
      (created)='created()' (load)='load()' >
      <e-columns>
        <e-column #columnaData field='chargerpointid' headerText='Punto de Carga' width="200" textAlign="Center"></e-column>
        <e-column field='energysource' headerText='Usuario' width='150' textAlign="Center"></e-column>
        <e-column field='connectorid' headerText='Conector' width='150' textAlign="Center"></e-column>
        <e-column field='loadlocation' headerText='Locación' width='175' textAlign="Center"></e-column>
        <e-column field='startdate' headerText='Fecha de Carga' width='185' textAlign="Center"></e-column>
        <e-column field='starttime' headerText='Hora Carga Iniciada' width='220' textAlign="Center"></e-column>
        <e-column field='endtime' headerText='Hora Carga Finalizada' width='220' textAlign="Center"></e-column>
        <e-column field='pago' headerText='Pago Total' width='150' textAlign="Center"></e-column>
        <e-column field='finalchargevalue' headerText='Carga Final (kWh)' width='250' textAlign='Center'></e-column>
        <e-column field='carbonintensity' headerText='Intensidad de Carbono (g/CO2)' width='275' textAlign="Center"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
