import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { StateService } from '../state/state.service';
import { AuthService } from '../auth/auth-service.service';
import { ConnectableObservable, throwError } from 'rxjs';
import { CollectionsService } from '../collections/collections-service.service';
// import { LocalstorageService } from '../storage/localstorage.service';
import { DexieService } from '../dexie/dexie.service';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/overlay-directives';
import { element } from 'protractor';
import { IotaService } from '../iota/iota.service';
import { MapService } from 'src/app/shared/map/map.service';


@Injectable({
  providedIn: 'root'
})
export class CalidadService {
  private mainUrl = this.state.backendURL
  constructor(
    private http: HttpClient,
    private state: StateService,
    private auth: AuthService,
    private collec: CollectionsService,
    // private lStore: LocalstorageService,
    private dexieDB: DexieService,
    private iota: IotaService,
    public mapser: MapService
  ) {
  }
  
  handleError(error: HttpErrorResponse): any {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  public async getEstadosDashboard(cadena1: string): Promise<void> {
    console.log("Entrando getEstadosDashboard", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'estadodashboardquery/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaEstadosDashboard = results[0]
    console.log("Resultado EstadosDashboard", this.state.listaEstadosDashboard)
    return;
  }

  public async getCatalogoBateria(cadena1: string): Promise<void> {
    console.log("Entrando getCatalogoBateria", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'getcatalogobateria/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaCatalogoBateria = results
    console.log("Resultado CatalogoBateria", this.state.listaCatalogoBateria)
    return;
  }

  public async getRatingData(cadena1: string): Promise<void> {
    console.log("Entrando getRatingData", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "id": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'ratingbuses/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.dataRating = results
    console.log("Resultado getRatingData", this.state.dataRating)
    return;
  }
}
