import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/shared/data/data-service.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CollectionsService } from 'src/app/shared/collections/collections-service.service';

@Component({
  selector: 'app-edit-collection',
  templateUrl: './edit-collection.component.html',
  styleUrls: ['./edit-collection.component.scss'],
})
export class EditCollectionComponent implements OnInit {
  entradaid = this.actRoute.snapshot.params.id;
  datos: any = {};
  form = this.fb.group({
    id: new FormControl({ value: '', disabled: true }, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    owner: new FormControl('', [Validators.email, Validators.required]),
    longitud: new FormControl('', [Validators.required]),
    latitud: new FormControl('', [Validators.required]),
  });
  actualizado = false;
  currentRoute = '';
  currentCharger = '';
  nuevoBus = '';
  nuevoCargador = '';
  currentRelation = '';
  nuevaRelacionA = '';
  nuevaRelacionB = '';

  constructor(
    private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private collec: CollectionsService
  ) {}

  async ngOnInit(): Promise<void> {
    const data = await this.collec
      .getSingleCollection(this.entradaid)
      .toPromise();
    // console.log();
    this.datos = data;
    if (this.datos.buses.length > 0) {
      this.currentRoute = this.datos.buses[0];
      this.currentRelation = this.datos.gpsidandbuses[0];
    }
    this.form = this.fb.group({
      /*  id: new FormControl({ value: this.datos.id, disabled: true }, [
          Validators.required,
        ]), */
      name: new FormControl(this.datos.name, [Validators.required]),
      owner: new FormControl(this.datos.owner, [
        Validators.email,
        Validators.required,
      ]),
      longitud: new FormControl(this.datos.longitud, [Validators.required]),
      latitud: new FormControl(this.datos.latitud, [Validators.required]),
    });
    // });
  }

  get name(): any {
    return this.form.get('name');
  }

  get owner(): any {
    return this.form.get('owner');
  }

  get longitud(): any {
    return this.form.get('longitud');
  }
  get latitud(): any {
    return this.form.get('latitud');
  }

  async onSubmit(): Promise<void> {
    // console.log('submit edit collection');
    const query = {
      name: this.name.value,
      owner: this.owner.value,
      latitud: this.latitud.value,
      longitud: this.longitud.value,
    };
    try {
      const res = await this.collec
        .updateCollectionData(this.entradaid, query)
        .toPromise();
      // console.log('Actualizado: ', res);
    } catch (error) {
      console.log('No se pudo actualizar colección: ', error);
    }
  }

  async addBus(): Promise<void> {
    // console.log('Adicionando bus ', this.nuevoBus);
    if (this.nuevoBus.trim() !== '') {
      this.datos.buses.push(this.nuevoBus);
      const query = {
        buses: this.datos.buses,
      };
      try {
        const res = await this.collec
          .updateCollectionBuses(this.entradaid, query)
          .toPromise();
        // console.log('Actualizado: ', res);
      } catch (error) {
        console.log('No se pudo adicionar buses: ', error);
      }
    }
  }

  async deleteBus(): Promise<void> {
    // console.log('Delete bus ', this.currentRoute);
    if (this.currentRoute.trim() !== '') {
      this.datos.buses = this.datos.buses.filter(
        (value: any, index: number, arr: any) => {
          return value !== this.currentRoute;
        }
      );
      const query = {
        buses: this.datos.buses,
      };
      try {
        const res = await this.collec
          .updateCollectionBuses(this.entradaid, query)
          .toPromise();
        // console.log('Actualizado: ', res);
      } catch (error) {
        console.log('No se pudo eliminar buses: ', error);
      }
    }
  }

  async addCargador(): Promise<void> {
    console.log('Adicionando cargador ', this.nuevoCargador);
    if (this.nuevoCargador.trim() !== '') {
      this.datos.chargersid.push(this.nuevoCargador);
      const query = {
        chargersid: this.datos.chargersid,
      };
      try {
        const res = await this.collec
          .updateCollectionBuses(this.entradaid, query)
          .toPromise();
        console.log('Actualizado: ', res);
      } catch (error) {
        console.log('No se pudo adicionar buses: ', error);
      }
    }
  }

  async deleteCargador(): Promise<void> {
    console.log('Delete cargador ', this.currentRoute);
    if (this.currentRoute.trim() !== '') {
      this.datos.buses = this.datos.buses.filter(
        (value: any, index: number, arr: any) => {
          return value !== this.currentRoute;
        }
      );
      const query = {
        buses: this.datos.buses,
      };
      try {
        const res = await this.collec
          .updateCollectionBuses(this.entradaid, query)
          .toPromise();
        // console.log('Actualizado: ', res);
      } catch (error) {
        console.log('No se pudo eliminar buses: ', error);
      }
    }
  }

  selectRoute(ruta: any): void {
    console.log('Selected route: ', ruta.value);
  }
}
