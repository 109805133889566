import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ILoadedEventArgs, ChartComponent, ChartTheme, IMouseEventArgs, ChartAnnotationService } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
@Component({
  selector: 'app-dashboard-energiarenovable',
  templateUrl: './dashboard-energiarenovable.component.html',
  styleUrls: ['./dashboard-energiarenovable.component.scss']
})
export class DashboardEnergiarenovableComponent implements OnInit {
  public dataTer = this.state.dictTranslate.DashboardsGraph.energiarenovable;
  public estaCompleto = this.state.listaEstadosDashboard["energiarenovable"]
  seleccionchart = 0
  cargo = true
  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }
  public cargadorespaletas = ["#010097", "#f4831e", "#7e7e83", "#800000"];
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };
  colorScheme = {
    domain: ['#000096', '#88898C', '#F5821E', '#800000', '#4bc8fe'],
  };
  public dataDED = []
  public dataDvsPcharger = []
  public dataDvsPremanente = []
  public dataDvsEcharger = []
  public dataDvsEremanente = []
  public lineEnergiaNeta:any
  axisxDED={
    title: this.dataTer.energianeta.ejex[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  axisyDED={
    title: this.dataTer.energianeta.ejey[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }  
  axisxDvsP={
    valueType: 'DateTime',
    title: this.dataTer.disponibilidadpot.ejex[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  axisyDvsP={
    title:this.dataTer.disponibilidadpot.ejey[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum :0,    
  }
    axisxDvsE={
    valueType: 'DateTime',
    title: this.dataTer.energia.ejex[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  axisyDvsE={
    title:this.dataTer.energia.ejey[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    interval:1,
    minimum :0,
    labelFormat: 'n0',
  }
  constructor(public state: StateService) { }
  ngAfterViewInit() {
    setTimeout(() => {
      this.seleccionchart = 1
    }, 2500)

  }
  ngOnInit(): void {
    this.estaCompleto = this.state.listaEstadosDashboard["energiarenovable"]
    this.cargarDatosDED()
    this.cargarDatosDvsP()
    this.cargarDatosDvsE()
  }
  cargarDatosDED() {
    var data = this.state.listaDatosEnergiaRenovable
    for (let i = 0; i < data["energydiference"].length; i++) {
      var energydiff = data["energydiference"][i];
      var horadiff = data["hora"][i];
      var template = {
        x: horadiff,
        y: energydiff.toFixed(0)
      }
      this.dataDED.push(template)
      this.lineEnergiaNeta=[
        {
          y:0,
          x:0
        },
        {
          y:0,
          x:24
        }
      ]

    }
  }
  cargarDatosDvsP() {
    var data = this.state.listaDatosEnergiaRenovable
    for (let i = 0; i < data["powertocharge"].length; i++) {
      var powertocharge = data["powertocharge"][i]
      var powertochargetime = data["powertochargetime"][i]
      var template1 = {
        x: powertochargetime,
        y: powertocharge
      }
      this.dataDvsPcharger.push(template1)
    }
    for (let i = 0; i < data["powerremanent"].length; i++) {
      var powerremanent = data["powerremanent"][i]
      var powerremanenttime = data["powerremanenttime"][i]
      var template2 = {
        x: powerremanenttime,
        y: powerremanent
      }
      this.dataDvsPremanente.push(template2)
    }
    console.log("Carga de Datos Terminada")
  }
  cargarDatosDvsE() {
    //energytocharge	array[double]
    //energytochargetime	array[char]
    //energyremanent	array[double]
    //energyremanenttime	
    var data = this.state.listaDatosEnergiaRenovable
    for (let i = 0; i < data["energytocharge"].length; i++) {
      var energytocharge = data["energytocharge"][i]
      var energytochargetime = data["energytochargetime"][i]
      var template1 = {
        x: energytochargetime,
        y: energytocharge
      }
      this.dataDvsEcharger.push(template1)
    }
    for (let i = 0; i < data["energyremanent"].length; i++) {
      var energyremanent = data["energyremanent"][i]
      var energyremanenttime = data["energyremanenttime"][i]
      var template2 = {
        x: energyremanenttime,
        y: energyremanent
      }
      this.dataDvsEremanente.push(template2)
    }
    console.log("Carga de Datos Terminada")
  }
  irDED() {
    this.seleccionchart = 1
  }
  irPvsT() {
    this.seleccionchart = 2
  }
  irEvsT(){
    this.seleccionchart=3
  }
}
