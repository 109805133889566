<div #myDOMElement class="eldiv">
  <!-- <div class="laNueve">
    <h2>
      {{ cadena }}
    </h2>
  </div> -->
  <!-- <ejs-chart
      (pointClick)="pointClick($event)"
      [annotations]="annotations">
    </ejs-chart>

  -->
  <ejs-chart #chart1 id="chart-container" [primaryXAxis]="state.axis1[0]" [primaryYAxis]="state.axis1[1]"
    [height]="alto" (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
    [tooltip]="tooltip" (pointClick)="pointClick($event)" [annotations]="annotations" [crosshair]="crosshair"
    (chartMouseClick)='chartMouseClick($event)' [animations]="animations">
    <!-- let item of list;let i = index; -->
    <e-series-collection>
      <e-series *ngFor="let chart of state.THEDATA[0]; index as i;" [dataSource]="chart.datos" type="Line" xName="name"
        yName="value" [name]="chart.nombre" legendShape="Rectangle" [visible]="visible[i]" [animation]="animation">
      </e-series>
      <!--<e-series *ngFor="let chart of state.THEDATA[0]; index as i;" [dataSource]="chart.datos" type="Spline" xName="name"
        yName="value" [name]="chart.nombre" legendShape="Rectangle" [visible]="visible[i]" [animation]="animation">
      </e-series>-->
      <!-- <e-series *ngFor="let grafico of state.THEDATA[0]; index as j;" [dataSource]='grafico.redpoints' type='Bubble'
        xName='x' yName='y' size='0.1' tooltipFormat='Batería descargada' [visible]="visible[j]"> </e-series> -->
      <e-series *ngFor="let naranjas of state.THEDATA[0]; index as k;" [dataSource]='naranjas.orangepoints'
        type='Bubble' xName='x' yName='y' size='0.1' tooltipFormat='Batería al 20%' [visible]="visible[k]" >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
