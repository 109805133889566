<div class="nueva">
  <form fxLayout="column" [formGroup]="form" >
    <h2 class="cadena">Actualizar datos</h2>
    <h1 *ngIf="actualizado">¡Actualización exitosa!</h1>
    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Nombre de contacto" formControlName="contactname" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Organización" formControlName="organization" [disabled]="true" />
      </mat-form-field>
    </div>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Correo" formControlName="email" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Repita el correo" formControlName="email2" />
      </mat-form-field>
    </div>



    <!-- <div
      class="input-row"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Correo secundario"
          formControlName="emailBackUp"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Repita el correo secundario"
          formControlName="emailBackUp2"
        />
      </mat-form-field>
    </div> -->

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Teléfono" formControlName="phone" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Dirección" formControlName="address" />
      </mat-form-field>
    </div>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="País" formControlName="country" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="Estado" formControlName="state" />
      </mat-form-field>

      <mat-form-field fxFlex="30%" style="width: 150px;">
        <input matInput type="text" placeholder="Ciudad" formControlName="city" />
      </mat-form-field>
    </div>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <h3>Colecciones visibles: </h3>
      <ul>
        <li *ngFor="let element of collections">{{ element }}</li>
      </ul>

      <button style="
      font-family: 'Poppins' !important;
      border-radius: 25px;
      margin-top: 40px;
      margin-bottom: 20px;
      max-width: 200px;
      align-self: center;
    " mat-raised-button
    (click)="onSubmit()">
        Actualizar datos
      </button>
    </div>


    <mat-error *ngIf="loginInvalid">
      Formulario inválido: {{ elError }}
    </mat-error>





    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <mat-form-field fxFlex>
        <input matInput type="password" placeholder="Contraseña" formControlName="password1" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <input matInput type="password" placeholder="Repita la contraseña" formControlName="password2" />
      </mat-form-field>

      <button style="
        font-family: 'Poppins' !important;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
        max-width: 200px;
        align-self: center;
      " mat-raised-button
      (click)="updatePassword()">
        Actualizar contraseña
      </button>
    </div>

    <mat-error *ngIf="loginInvalid">
      ¡Las contraseñas difieren!
    </mat-error>



  </form>
</div>