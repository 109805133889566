import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss']
})
export class CostComponent implements OnInit {
  loschargernames: any = [];
  items: any = [];
  constructor(public state: StateService) { }

  ngOnInit(): void {
    this.state.currentChargersNames.forEach((c: any) => {
      this.items.push(
        {
          id: c.replace(/\s/g, ""),
          color: '#ff0000',
          maximum: 1000,
        }
      );
    });
  }


}
