<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <!--Energía de Red vs Hora del Día-->
                    <div *ngIf="this.seleccionchart == 1 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement1
                                title={{this.dataTIR.graficode.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                style="width:100%;height:100%; " [primaryXAxis]='axisxEnergia'
                                [primaryYAxis]="axisyEnergia" [zoomSettings]='zoom' [palettes]='colorScheme.domain'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                        [dataSource]="this.listaPlotEnergia[i]" type="Line" xName="axisx" yName="axisy"
                                        name="{{potencia}} kW" legendShape='Rectangle' style="width:100%;height:100%; ">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!--Buses Cargando vs Hora del Día-->
                    <div *ngIf="this.seleccionchart == 2 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement2 id="chartBusesHora"
                                title={{this.dataTIR.graficobc.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                [primaryXAxis]='axisxBus' [primaryYAxis]="axisyBus" (window:resize)="onResize($event)"
                                [zoomSettings]='zoom' style="width:100%;height:100%; " [palettes]='colorScheme.domain'>
                                <e-series-collection>
                                    <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                        [dataSource]="this.listaPlotBus[i]" type="Line" xName="axisx" yName="axisy"
                                        name="{{potencia}} kW" legendShape='Rectangle'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!--Potencia vs Hora del día-->
                    <div *ngIf="this.seleccionchart == 3 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement3 id="chartPotenciaHora"
                                title={{this.dataTIR.graficodp.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                [primaryXAxis]='axisxPotencia' [primaryYAxis]="axisyPotencia"
                                (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                style="width:100%;height:100%; ">
                                <e-series-collection>
                                    <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                        [dataSource]="this.listaPlotPotencia[i]" type="Line" xName="axisx" yName="axisy"
                                        name="{{potencia}} kW" legendShape='Rectangle'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!--Número Cargadores vs Potencia-->
                    <div *ngIf="this.seleccionchart == 4 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement4 id="chartCargadoresPotencia"
                                title={{this.dataTIR.graficocr.titulo[this.state.indiceTranslate]}}
                                style='display:block;' [primaryXAxis]='axisxCargadores' [primaryYAxis]='axisyCargadores'
                                [zoomSettings]='zoom' [tooltip]='tooltip' [palettes]='cargadorespaletas'
                                style="width:100%;height:100%; ">
                                <e-series-collection>
                                    <e-series groupName="A" [dataSource]='this.listaOportunidad' type='Column' xName='x'
                                        yName='y' name={{this.dataTIR.graficocr.label1[this.state.indiceTranslate]}}
                                        columnWidth="0.4" [marker]='marker'> </e-series>
                                    <e-series groupName="B" [dataSource]='this.listaNocturno' type='Column'
                                        columnWidth='0.6' xName='x' yName='y'
                                        name={{this.dataTIR.graficocr.label2[this.state.indiceTranslate]}}
                                        columnWidth="0.4" [marker]='marker'> </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <!--Demanda de Energía por Hora-->
                    <div *ngIf="this.seleccionchart == 5 && this.cargo==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement5 id="chartDemandaHora"
                                title={{this.dataTIR.graficodeh.titulo[this.state.indiceTranslate]}}
                                style='display:block;' [primaryXAxis]='axisxDemandaEH' [primaryYAxis]='axisyDemandaEH'
                                [zoomSettings]='zoom' [tooltip]='tooltip' [palettes]='colorScheme.domain'
                                style="width:100%;height:100%; ">
                                <e-series-collection>
                                    <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                        [dataSource]="this.listaDemandaEnergia[i]" type="Column" xName="axisx"
                                        yName="axisy" name="{{potencia}} kW" legendShape='Rectangle'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTIR.menu.titulothrid[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTIR.menu.sohnew[this.state.indiceTranslate]}} name="filtroSOH"
                            style="margin-bottom: 10px;" (click)="cargarDataNueva()" checked="true"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton class="radiobuttonseleccion"
                            label={{this.dataTIR.menu.sohend[this.state.indiceTranslate]}} name="filtroSOH"
                            style="margin-bottom: 10px;" (click)="cargarDataFinvida()"
                            cssClass="e-info"></ejs-radiobutton>

                        <h2 style="text-align: center;">{{this.dataTIR.menu.titulosecond[this.state.indiceTranslate]}}
                        </h2>
                        <div style="display: flex;align-items: center;flex-direction: row;"
                            *ngFor="let potencia of this.listaPuntos; index as i">
                            <ejs-radiobutton label={{potencia}} name="radioSitioCarga"
                                [checked]="this.selectedFiltroBoton==i? true : false " style="margin-bottom: 10px;"
                                (click)="cambiarFiltro(i)" cssClass="e-info"></ejs-radiobutton>
                        </div>
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTIR.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTIR.menu.demandenergia[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irEnergia()" checked="true"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton class="radiobuttonseleccion"
                            label={{this.dataTIR.menu.demandpotencia[this.state.indiceTranslate]}} name="default"
                            style="margin-bottom: 10px;" (click)="irPotencia()" cssClass="e-info"></ejs-radiobutton>

                        <ejs-radiobutton label={{this.dataTIR.menu.numerobusescargand[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irBus()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTIR.menu.cargadoresreque[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irCargadores()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTIR.menu.demandenergiahora[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irDemandaEnergia()"
                            cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>