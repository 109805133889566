import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, retry, empty } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class IotaService {
  socket: any;
  //url = 'http://localhost:3000/';
  url = 'https://logiosiotbackend.com/';
  // url = 'https://logios-dev.club/';
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8'
  });
  connected = false;
  constructor(private http: HttpClient,) {
    // console.log('Socket service constructor');

  }

  listen(Eventname: string): any {
    if (!this.connected) {
      console.log('connectando a iota streaming');
      this.socket = io(this.url, {
        transports: ['websocket'],
        // transports: ['websocket'],
        upgrade: false,
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: 10
        // secure: true
      });
      this.socket.open();
      this.connected = true;
    }
    // console.log('Subscribing to socket');
    return new Observable((subscriber) => {
      this.socket.on(Eventname, (data: any) => {
        subscriber.next(data);
      });
    });
  }

  postChargingEvent(valores: any, cargador: string): void {
    console.log("===== ENVIANDO DATOS A EVENTO DE CARGA ======")
    console.log('haciendo post en iota de ', valores, ' para cargador: ', cargador);
    try {

      const dateins = {
        "idtittle": valores.title,
        "datos": {
          "DataCharge": {
            "ChargePointId": valores.title,
            "ConnectorId": "CN-001",
            "LoadLocation": cargador,
            "StartTimeCharge": valores.initdate,
            "EndTimeCharge": valores.enddate,
            "IntervalCharge": [
              1.00,
              2.03,
              3.01,
              4.05,
              5.02
            ],
            "CumulativeIntervalCharge": [
              1.00,
              3.03,
              6.04,
              10,
              9,
              15.11
            ],
            "FinalChargeValue": valores.consumedenergy,
            "EnergySource": "Company01",
            "CarbonIntensity": 10.06
          },
          "DataBus": {
            "VehicleId": "Bus-001",
            "RequiredEnergy": 40.65,
            "InitialSoC": 12.00,
            "FinalSoC": 6.00,
            "IntervalEnergy": [
              1.00,
              2.03,
              3.01,
              4.05,
              5.02
            ],
            "CumulativeEnergy": [
              1.00,
              3.03,
              6.04,
              10,
              9,
              15.11
            ],
            "FinalEnergy": 60.00,
            "IntervalCellTemp": 10.20,
            "Odometer": 100.20
          },
          "DataGrid": {
            "IntervalKWPrice": 5.60,
            "IntervalkWhPrice": 6.84
          }
        }

      };
      console.log("Imprimiendo datesin");
      console.log(dateins);
      // const url = 'https://logios-dev.club/api/iotazenter/sendMessage';
      // const url = 'http://localhost:3000/api/iotazenter/sendMessage';
      this.http.post(this.url + 'api/iotazenter/sendMessage', dateins, {
        responseType: 'json',
        headers: this.headers,
      }).pipe(
        map((response: any) => {
          console.log("Se envio de forma correcta el POST Iota")
          console.log('en map res: ', response);
          console.log("===================================")

            ;
        })
      ).subscribe((_: any) => {
        // console.log('subscribe: ', r);
      });
    } catch (error) {
      console.log('post error: ', error)
      // return null;
    }
  }

  //Recibe los registros por Charger Id 
  getAllEventsFromCharger(chargerid: string): Observable<any> {
    console.log("===== Funcion iota - getAllEventsFromCharger ===== ")
    console.log("charger id ", chargerid);
    const query = {
      "charger_id": chargerid
    };
    // const url = 'https://logios-dev.club/api/iotazenter/listchargerid';
    return this.http.post(this.url + 'api/iotazenter/listchargerid', query, {
      responseType: 'json',
      headers: this.headers,
    });

  }

  //Recibe los registros por Locacion de la Estacion de Carga 
  getAllEventsFromLocation(lalocation: string): Observable<any> {
    console.log("===== Funcion iota - getAllEventsFromLocation ===== ")
    console.log("lalocation ", lalocation);
    const query = {
      "loadlocation": lalocation
    };
    // const url = 'https://logios-dev.club/api/iotazenter/datacompletebylocation';
    return this.http.post(this.url + 'api/iotazenter/listLoadLocation', query, {
      responseType: 'json',
      headers: this.headers,
    });
  }

  //Recibe los Datos de los registros por Locacion de la Estacion de Carga
  getAllDataFromEventsbyLocation(lalocation: string): Observable<any> {
    console.log("===== Funcion iota - getAllDataFromEvents ===== ")
    console.log("lalocation ", lalocation);
    const query = {
      "loadlocation": lalocation
    };
    var result;
    var auxlist = []
    var result2;

    // const url = 'https://logios-dev.club/api/iotazenter/datacompletebylocation';
    result = this.http.post(this.url + 'api/iotazenter/datacompletebylocation', query, {
      responseType: 'json',
      headers: this.headers,
    });
    result.subscribe(data => { auxlist.push(data) })
    result2 = auxlist
    console.log("Resultado primero iota", result2)
    return result2;
  }

  getAllDataFromEventsbyLocationList(lalocations: any): Observable<any> {
    console.log("===== Funcion iota - getAllDataFromEvents ===== ")
    console.log("lalocation iota", lalocations);
    var result;
    var auxlist = []
    var result2;

    const query = {
      "loadlocation": lalocations
    };
    console.log("Entrando iota alldatafromlocation")
    result = this.http.post(this.url + 'api/iotazenter/datacompletebylocation', query, {
      responseType: 'json',
      headers: this.headers,
    });
    result.subscribe(data => { auxlist.push(data) })
    result2 = auxlist
    console.log("Resultado primero iota", result2)
    return result2;
  }



  getSingleEvent(messageid: string): Observable<any> {
    // const url = 'https://logios-dev.club/api/iotazenter/datamessage';
    console.log("===== Funcion iota - getSingleEvent ===== ")
    console.log("messageid ", messageid);
    const query = {
      idmessage: messageid
    };
    return this.http.post(this.url + 'api/iotazenter/datamessage', query, {
      responseType: 'json',
      headers: this.headers,
    });
  }

  disconnect(): void {
    // console.log('Disconnecting form socket.io');
    if (this.connected) {
      this.socket.disconnect();
      this.socket.removeAllListeners();
      this.socket.close();
      this.connected = false;
    }
  }



}