import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-bus-type',
  templateUrl: './bus-type.component.html',
  styleUrls: ['./bus-type.component.scss']
})
export class BusTypeComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
  }

  parseType(s: string){
    return s === 'Opportunity' ? 'Oportunidad' : 'Nocturno';
  }
}
