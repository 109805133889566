<div #myDOMElement  class="eldiv">

  <ejs-chart
    #chartvel1
    id="chart-container"
    [primaryXAxis]="state.axis4[0]"
    [primaryYAxis]="state.axis4[1]"
    [height]="alto"
    (window:resize)="onResize($event)"
    [zoomSettings]="zoom"
    [palettes]="colorScheme.domain"
    [tooltip]="tooltip"
    (pointClick)="pointClickByBusStop($event)"
    (chartMouseClick)='chartMouseClick($event)'
    [animations]="animations"
    *ngIf="state.currentVelocity === 1"
  >
    <e-series-collection>
      <e-series
        *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
        [dataSource]='this.state.dataForSpeedStops' type='Column' xName='name' [yName]='elchart' [name]='elchart' [visible]="visible[i]"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>

  <ejs-chart
    #chartvel2
    id="chart-container"
    [primaryXAxis]="state.axis4[2]"
    [primaryYAxis]="state.axis4[1]"
    [height]="alto"
    (window:resize)="onResize($event)"
    [zoomSettings]="zoom"
    [palettes]="colorScheme.domain"
    [tooltip]="tooltip"
    (pointClick)="pointClick($event)"
    (chartMouseClick)='chartMouseClick($event)'
    [animations]="animations"
    *ngIf="state.currentVelocity === 2"
  >
    <e-series-collection>
      <e-series
        *ngFor="let chart of state.THEDATA[3]; index as i;"
        [dataSource]="chart.averagespeed"
        type="Spline"
        xName="name"
        yName="value"
        legendShape="Rectangle"
        [animation]="animation"
        [name]="chart.nombre"
        
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
