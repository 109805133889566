<div
  [ngClass]="{ tarjetaNueva: true }"
  cdkDrag
  (cdkDragEnded)="dragEnd($event)"
  [cdkDragFreeDragPosition]="mystate.cardPosition"
>
  <div fxFlex="15%" class="wrapper">
    <div class="one">
      <!-- <mat-icon> directions_bus</mat-icon> -->
      <p>Ruta</p>

      <p>{{ mystate.currentRoute }}</p>
    </div>
    <div class="two">
      <br />
    Carga <br>

    {{ mystate.currentType === "Opportunity" ? "Oportunidad" : "Nocturna" }}
  </div>

  <div class="three">
    <br />
    Batería: {{ mystate.currentBattery }} kWh <br />

    Tamaño: {{ mystate.currentBusSize }} m <br />

    Recorrido: {{ mystate.currentBusDistance }} km <br />

  </div>
  <div class="four">
    <br />
    <p>
      Consumo crítico <br />

      {{ mystate.maximumConsumption }} kWh/km
    </p>
  </div>
  <div class="five">

    <p>
      Autonomía crítica <br />

      {{ mystate.endAuto }} km
      </p>
    </div>
    <!-- <div class="six">Six</div> -->
  </div>
</div>
