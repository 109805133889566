import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { LocalstorageService } from 'src/app/shared/storage/localstorage.service';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit {
  windowWidth = '';
  showSplash = true;

  @ViewChild('videoPlayer', { static: false })
  videoplayer!: ElementRef;

  constructor(private state: StateService, private router: Router,
    private data: DataService, private auth: AuthService, private lStore: LocalstorageService){}

  async ngOnInit(): Promise<void> {
    // console.log('Llamando a setbusnames animation');
    await this.data.setBusNames();
    /* console.log('INNN');
    const keys = Object.keys(this.auth.currentBusConfigurations);
    for (const llave of keys) {
      const datos = this.auth.currentBusConfigurations[llave];
      const query1 = datos.collection + '_' + datos.route;
      const query2 = datos.type + '_' + datos.size + 'm_' + datos.battery + 'kWh_' + datos.hvac + '_pass' + datos.pax;
      await this.data.getCompleteSelected(query1, query2 + '_part1', query2 + '_part2');
      //

    }
    console.log('this.state.rawData: ', this.state.rawData); */
    // this.lStore.setItem('completeday', JSON.stringify(this.state.rawData));
    // this.data.getAllData();
    // this.cambiar();
    /*setTimeout(() => {
      this.windowWidth = '-' + window.innerWidth + 'px';

      setTimeout(() => {
        this.showSplash = !this.showSplash;
        this.router.navigate(['/visualization/dash-overlay']);

      }, 500);
    }, 1000); // */
  }

  cambiar(): void{
    this.windowWidth = '-' + window.innerWidth + 'px';
    // console.log('Termine');
    this.showSplash = !this.showSplash;
    this.router.navigate(['/visualization/dash-overlay']);
  }
}
