import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-collection',
  templateUrl: './register-collection.component.html',
  styleUrls: ['./register-collection.component.scss']
})
export class RegisterCollectionComponent implements OnInit {
  simulaciones = [
    { id: 'simulacion1', nombre: 'Collection Creation', valor: 'collectionCreation', marcado: false },
    { id: 'simulacion2', nombre: 'Driving Cycles', valor: 'drivingCycles', marcado: false },
    { id: 'simulacion3', nombre: 'Energy Consumption', valor: 'energyConsumption', marcado: false },
    { id: 'simulacion4', nombre: 'Charging Optimization', valor: 'chargingOptimization', marcado: false },
    { id: 'simulacion5', nombre: 'Renowables', valor: 'Renowables', marcado: false },
    { id: 'simulacion5', nombre: 'Degradability', valor: 'degradability', marcado: false },
    { id: 'simulacion5', nombre: 'Battery Optimization', valor: 'batteryOptimization', marcado: false }
  ];
  cargadores = [
    { id: 'cargadores1', nombre: '180', valor: 180, marcado: false },
    { id: 'cargadores2', nombre: '350', valor: 350, marcado: false },
    { id: 'cargadores3', nombre: '450', valor: 450, marcado: false },
    
  ];
  constructor() { }

  ngOnInit(): void {
  }
  getSimulationCheck(){
    let rst = this.simulaciones.filter(x=>x.marcado)
    return rst;
  }
  cargarCronograma(){

  }

}
