<div

  *ngIf="authService.isLoggedIn"
  style="
    height: 100%;
    align-items: left;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: #D6D8E1;
  "
>
  <div class="container">
    <div class="item" [style.background-color]="myState.one[0]">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 2 || myState.currentMenu === 3 || myState.currentMenu === 4|| myState.currentMenu === 5|| myState.currentMenu === 6|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(1)"
        inline="true"
        svgIcon="manu-gris"
        [matTooltip]=this.dictMenuLateral.MenuInicio.titulohover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 1"
        style="width: 35px;"
        (click)="onToggleSidenav(1)"
        inline="true"
        svgIcon="menu-blanco"
        [matTooltip]=this.dictMenuLateral.MenuInicio.titulohover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
    <div class="item" [style.background-color]="myState.one[1]" *ngIf="myState.inMap">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 3 || myState.currentMenu === 4|| myState.currentMenu === 5|| myState.currentMenu === 6|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(2)"
        inline="true"
        svgIcon="filtros-gris"
        [matTooltip]=this.dictMenuLateral.Coleccion.titulohover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 2"
        style="width: 35px;"
        (click)="onToggleSidenav(2)"
        inline="true"
        svgIcon="filtros-blanco"
        [matTooltip]=this.dictMenuLateral.Coleccion.titulohover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
    <div class="item" [style.background-color]="myState.one[4]" *ngIf="myState.inMap  && myState.collectionSelected == true">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 2 || myState.currentMenu === 3 || myState.currentMenu === 4|| myState.currentMenu === 6|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(5)"
        inline="true"
        svgIcon="ruta_azul"
        [matTooltip]=this.dictMenuLateral.Portafolio.titlehover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 5"
        style="width: 35px;"
        (click)="onToggleSidenav(5)"
        inline="true"
        svgIcon="ruta"
        [matTooltip]=this.dictMenuLateral.Portafolio.titlehover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
    <div class="item" [style.background-color]="myState.one[2]" *ngIf="myState.inMap  && myState.collectionSelected == true">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 2 || myState.currentMenu === 4|| myState.currentMenu === 5|| myState.currentMenu === 6|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(3)"
        inline="true"
        svgIcon="perfil de velocidad_azul"
        [matTooltip]=this.dictMenuLateral.Menuconfiguracionanalizis.titlehover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 3"
        style="width: 35px;"
        (click)="onToggleSidenav(3)"
        inline="true"
        svgIcon="perfil de velocidad_blanco"
        [matTooltip]=this.dictMenuLateral.Menuconfiguracionanalizis.titlehover[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
    <div class="item" [style.background-color]="myState.one[3]" *ngIf="myState.inMap  && myState.confbusSelected == true">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 2 || myState.currentMenu === 3 || myState.currentMenu === 5|| myState.currentMenu === 6|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(4)"
        inline="true"
        svgIcon="estadisticas_azul"
        [matTooltip]=this.dictMenuLateral.Dashboard.titledashboard[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 4"
        style="width: 35px;"
        (click)="onToggleSidenav(4)"
        inline="true"
        svgIcon="estadisticas_blanco"
        [matTooltip]=this.dictMenuLateral.Dashboard.titledashboard[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>

    <div class="item" [style.background-color]="myState.one[5]" *ngIf="myState.inMap  && myState.confbusSelected == true">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 2 || myState.currentMenu === 3 || myState.currentMenu === 4|| myState.currentMenu === 5|| myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(6)"
        inline="true"
        svgIcon="play_azul"
        [matTooltip]=this.dictMenuLateral.Animacion.titleanimacion[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 6"
        style="width: 35px;"
        (click)="onToggleSidenav(6)"
        inline="true"
        svgIcon="animarruta_blanco"
        [matTooltip]=this.dictMenuLateral.Animacion.titleanimacion[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
    
    <div hidden="true" class="item" [style.background-color]="myState.one[6]" *ngIf="myState.inMap && myState.confbusSelected == true">
      <mat-icon
        *ngIf="myState.currentMenu === 0 || myState.currentMenu === 1 || myState.currentMenu === 2 || myState.currentMenu === 3 || myState.currentMenu === 4|| myState.currentMenu === 5|| myState.currentMenu === 6"
        style="width: 35px;"
        (click)="onToggleSidenav(7)"
        inline="true"
        svgIcon="energia-disponible_azul"
        [matTooltip]=this.dictMenuLateral.Optimizacionredcarga.titleoptredcarga[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
      <mat-icon
        *ngIf="myState.currentMenu === 7"
        style="width: 35px;"
        (click)="onToggleSidenav(7)"
        inline="true"
        svgIcon="energia-disponible_blanco"
        [matTooltip]=this.dictMenuLateral.Optimizacionredcarga.titleoptredcarga[this.myState.indiceTranslate]
        matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
      ></mat-icon>
    </div>
  </div>
  


  <div class="logo">
    <mat-icon
      style="width: 80px; height: 100px; justify-content: space-around;"
      inline="true"
      svgIcon="logoLat"
    ></mat-icon>
  </div>
</div>
