import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-is-charging-icons',
  templateUrl: './is-charging-icons.component.html',
  styleUrls: ['./is-charging-icons.component.scss']
})
export class IsChargingIconsComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
  }

}
