import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/data/data-service.service';
import { CollectionsService } from 'src/app/shared/collections/collections-service.service';

@Component({
  selector: 'app-collections-list',
  templateUrl: './collections-list.component.html',
  styleUrls: ['./collections-list.component.scss'],
})
export class CollectionsListComponent implements OnInit {
  collections: any = [];
  form = this.fb.group({
    name: new FormControl('', [Validators.required]),
    owner: new FormControl('', [Validators.email, Validators.required]),
    rutas: new FormControl('', [Validators.required]),
    longitud: new FormControl('', [Validators.required]),
    latitud: new FormControl('', [Validators.required]),
  });
  invalidCollection = false;
  actualizado = false;

  CollectionBusesSubsDel: Subscription | undefined;
  CollectionsSubs: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private collec: CollectionsService
  ) { }

  ngOnInit(): void {
    this.iniciar();
  }

  async iniciar(): Promise<void> {
    this.form = this.fb.group({
      name: new FormControl('', [Validators.required]),
      owner: new FormControl('', [Validators.email, Validators.required]),
      rutas: new FormControl('', [Validators.required]),
      longitud: new FormControl('', [Validators.required]),
      latitud: new FormControl('', [Validators.required]),
    });
    if (this.collec.collections !== null) {
      // console.log('collections ya en cache (collection list)');
      this.collections = this.collec.collections;
    } else {
      try {
        const response = await this.collec.getAllCollections().toPromise();
        // const response = await this.collec.getAllCollections();
        // console.log('collections received');
        // console.log(response);
        this.collections = response;
      } catch (error) {
        console.error('Request falló al traer usuarios');
      }
    }

  }

  get collectionName(): any {
    return this.form.get('name');
  }
  get collectionOwner(): any {
    return this.form.get('owner');
  }
  get collectionLongitud(): any {
    return this.form.get('longitud');
  }
  get collectionLatitud(): any {
    return this.form.get('latitud');
  }
  get collectionRutas(): any {
    return this.form.get('rutas');
  }

  async deleteCollection(colId: number): Promise<void> {
    try {
      await this.collec.deleteCollection(colId).toPromise();
      console.log('Usuario eliminado');
      this.collections = this.collections.filter(
        (value: any, index: number, arr: any) => {
          return value.id !== colId;
        }
      );
    } catch (error) {
      console.log('No se puede eliminar colección: ', error);
    }
    //});
  }

  isFixedString = (s: string) => !isNaN(+s) && isFinite(+s) && !/e/i.test(s);

  async onSubmit(): Promise<void> {
    console.log('submit');
    if (
      this.collectionName.value.trim() !== '' &&
      this.collectionOwner.value.trim() !== '' &&
      this.collectionRutas.value.trim() !== '' &&
      this.isFixedString(this.collectionLatitud.value.trim()) &&
      this.isFixedString(this.collectionLongitud.value.trim())
    ) {
      const rutas = this.collectionRutas.value.split(',');
      const query = {
        name: this.collectionName.value,
        owner: this.collectionOwner.value,
        buses: rutas,
        longitud: this.collectionLongitud.value.trim(),
        latitud: this.collectionLatitud.value.trim(),
      };
      console.log('Adding collection: ', query);
      try {
        const res = await this.collec.addNewCollection(query).toPromise();
        console.log('Se adicionó collection', res);
        this.collections.push(res);
        this.form.reset();
        // });
      } catch (error) {
        console.log('No se pudo adicionar collection: ', error);
      }
    } else {
      this.invalidCollection = true;
    }
  }

  get collectionsSubs(): any {
    return this.CollectionsSubs;
  }

  set collectionsSubs(x: any) {
    this.CollectionsSubs = x;
  }

}
