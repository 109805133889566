<div #myDOMElement class="eldiv">

    <ejs-chart #chart10 id="chart-container" [primaryXAxis]="state.axis1[0]" [primaryYAxis]="state.axis1[1]"
      [height]="alto" (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]='domain'
      [tooltip]="tooltip" (pointClick)="pointClick($event)" (chartMouseClick)='chartMouseClick($event)' [axes]='axis' [animations]="animations">
      <e-series-collection>
        <e-series *ngFor="let chart of state.THEDATA[0]; index as i;" [dataSource]="chart.datos" type="Line" xName="name"
          yName="value" [name]="chart.nombre" legendShape="Rectangle" [visible]="false" [animation]="animation" tooltipFormat='Consumo <br /> <b>${point.x} : ${point.y}</b>' >
        </e-series>
        <e-series *ngFor="let chart2 of state.THEDATA[0]; index as j;" [dataSource]="chart2.regen"
         type="Line" xName="name"
          yName="value" [visible]="false" [animation]="animation" 
           tooltipFormat='Regeneración <br /> <b>${point.x} : ${point.y}</b>'>
        </e-series>
        <e-series  *ngFor="let chart3 of state.THEDATA[0]; index as k;" [visible]="false" 
        [dataSource]="chart3.slope"  yAxisName="yAxis" type='Area' xName='name' yName='value' 
        opacity=0.5 border-color='transparent'  tooltipFormat='Pendiente <br /> <b>${point.x} : ${point.y}</b>'>
        </e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
  