import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-info-route',
  templateUrl: './info-route.component.html',
  styleUrls: ['./info-route.component.scss']
})
export class InfoRouteComponent implements OnInit {
  break = '\n';
  positionX = 0;
  positionY = 0;


  constructor(public mystate: StateService) { }

  ngOnInit(): void {
    // console.log('inicio tarjeta');
  }

  dragEnd($event: CdkDragEnd): void {
    const { offsetLeft, offsetTop } = $event.source.element.nativeElement;
    const { x, y } = $event.distance;
    this.positionX = offsetLeft + x;
    this.positionY = offsetTop + y;
    // this.showPopup = true;
    // console.log({ x: this.positionX, y: this.positionY });
    const lax = this.mystate.cardPosition.x + x;
    const lay = this.mystate.cardPosition.y + y;
    this.mystate.cardPosition = { x: lax, y: lay };
  }


}
