<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">


  <ejs-chart *ngIf="state.currentStats === 1"  #chart13a id="chargersdata1" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip'  [animations]="animations">
      <e-series-collection>
        <e-series          
          *ngFor="let chart of dataSer.chargersimpactpower[state.selectedCharger]; index as i;"
          [dataSource]="chart.datos"
          type="Line"
          xName="name"
          yName="value"
          [name]="chart.nombre"
          legendShape="Rectangle"
          [animation]="animation"
        >
        </e-series>

        <e-series         
        [dataSource]="state.dataChargersInfo[state.selectedCharger]"
        type="Line"
        xName="name"
        yName="value"
        [name]="nombreacumulado"
        legendShape="Rectangle"
        [animation]="animation"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>


  <ejs-chart *ngIf="dataSer.chargersimpactenergy === 2" #chart13b id="chargersdata2" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip'  [animations]="animations">
      <e-series-collection>
        <e-series
          *ngFor="let chart of state.dataChargerEnergia[state.selectedCharger]; index as i;"
          [dataSource]="chart.datos"
          type="Line"
          xName="name"
          yName="value"
          [name]="chart.nombre"
          legendShape="Rectangle"
          [animation]="animation"
        >
        </e-series>

    </e-series-collection>
  </ejs-chart>

  <ejs-chart *ngIf="state.currentStats === 3" #chart13b id="chargersdata3" [primaryXAxis]="primaryXAxis" [primaryYAxis]="primaryYAxis"
    [height]='alto' (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
    [tooltip]='tooltip'  [animations]="animations">
      <e-series-collection>
        <e-series
          *ngFor="let chart of state.THEDATA[9][2][state.selectedCharger]; index as i;"
          [dataSource]="chart.datos"
          type="Line"
          xName="name"
          yName="value"
          [name]="chart.nombre"
          legendShape="Triangle"
          [animation]="animation"
        >
        </e-series>

    </e-series-collection>
  </ejs-chart>


</div>
