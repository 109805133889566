<div class="paradivpower">
  <span style="color: white; height: 50px;">Energía cargada</span>


  <mat-grid-list cols="1" rowHeight="65px" *ngFor="let item of items" style="padding:0px">
    <mat-grid-tile
      style="color: white; background-color: rgba(255,255,255,0.0);  border-radius: 0px; border-bottom: 1px solid white; border-top: 1px solid white;">

      <ejs-circulargauge id="{{item.id}}+chener" width="100" height="58" background="rgba(255,255,255,0.0)"
        style=" color: white;  border-radius: 0px; padding: 0px; margin: 0px;" [margin]='margin' #gaugepower>
        <e-axes>
          <e-axis [majorTicks]="majorTicks" [minorTicks]="minorTicks" [labelStyle]="labelStyle" [maximum]="state.chargedEnergy[item.id][1]">
            <e-annotations>
              <e-annotation zIndex='1' *ngIf="state.powerGaugeValue[item.id] != -1" content='<div id="contentpow" style="display:flex">
                  <div id="contentpowi1" style="color:white;font-size:13px;font-family:Segoe UI;font-weight:semibold; margin-right: 3px">{{state.chargedEnergy[item.id][0]}}</div>
              </div>' angle=0 radius='0%'>
              </e-annotation>
            </e-annotations>
            <e-ranges>
              <e-range start=0 [end]="state.chargedEnergy[item.id][0]" color='#03fc1c' startWidth=5 endWidth=5>
              </e-range>
            </e-ranges>
            <e-pointers>
              <!-- <e-pointer [value]="state.powerGaugeValue[item.id]"></e-pointer> -->
            </e-pointers>
          </e-axis>
        </e-axes>
      </ejs-circulargauge>

    </mat-grid-tile>
  </mat-grid-list>


</div>
