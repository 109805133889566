<div #myDOMElement style="visibility: visible; display: flex; flex-direction: column;">
    <ejs-chart #chart1 [primaryXAxis]='axisx' [primaryYAxis]="axisy" [height]='alto'
    (window:resize)="onResize($event)">
        <e-series-collection>
            <!--<e-series [dataSource]='datos' type='Line' xName='x' yName='y'legendShape="Rectangle"> </e-series>-->
            <e-series *ngFor="let potencia of this.listaPotencia; index as i" [dataSource]="this.listaDatosplot[i]"
                type="Line" xName="axisx" yName="axisy" [name]="potencia" legendShape='Rectangle'>
            </e-series>
        </e-series-collection>
    </ejs-chart>
</div>