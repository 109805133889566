import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AccumulationTheme, Chart, chartDoubleClick, IAccLoadedEventArgs, ILoadEventArgs } from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutComponent, PanelModel, ResizeEventArgs } from '@syncfusion/ej2-angular-layouts';
import { AuthService } from 'src/app/shared/auth/auth-service.service';
import { DataService } from 'src/app/shared/data/data-service.service';
import { StateService } from 'src/app/shared/state/state.service';
import { ChartComponent, ChartTheme, IPointEventArgs, IMouseEventArgs,TooltipSettingsModel  } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { element } from 'protractor';
import { ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { Subscription } from 'rxjs';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FilterSettingsModel } from '@syncfusion/ej2-angular-grids';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { GroupService, SortService, GridComponent, ResizeService } from '@syncfusion/ej2-angular-grids';
import { ToolbarService } from '@syncfusion/ej2-angular-grids';
import { ToolbarItems, SearchSettingsModel } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-dashboard-operaciones',
  templateUrl: './dashboard-operaciones.component.html',
  styleUrls: ['./dashboard-operaciones.component.scss'],
  providers: [GroupService, SortService, ResizeService]

})
export class DashboardOperacionesComponent implements OnInit {
  colorScheme = {
    domain: ['#000096', '#88898C', '#f4831e', '#800000'],
  };
  //
  public dictHeader = this.state.dictTranslate.Menu.Portafolio.tablaportafolio
  public databus = []
  public dataTOp = this.state.dictTranslate.DashboardsGraph.operaciones;
  previousTarget = null;
  public cargo = false;
  public tooltip = { enable: true };
  public tooltipPPC:any;
  public marker = { visible: true, width: 10, height: 10 }
  public cargadorespaletas = ["#010097", "#f4831e", "#7e7e83", "#800000"];
  public seleccionchart
  public dataComparison = this.state.listaComparacionesData["results"]
  public listaPotencia = this.dataComparison["potencias"]
  public listaPuntos = this.dataComparison["puntoscarga"];
  //Layout Dashboard
  public cellSpacing: number[] = [20, 20]
  public allowDragging: boolean = true;
  public cellAspectRatio: number = 80 / 85;
  visible: any = [];
  animations = false;
  public animation: object = { enable: false };
  public groupOptions: Object;
  @ViewChild('grid')
  public grid: GridComponent;
  public refresh: Boolean;
  @ViewChild('alertDialog')
  public alertDialog: DialogComponent;
  public toolbar: string[];
  public searchSettings: SearchSettingsModel;

  //Propieadades Graficos
  public zoom = {
    enableSelectionZooming: true,
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableScrollbar: true
  };

  ancho = '';
  alto = '';


  //axis4[0] Consumption Stop
  public axisCeroCS = {
    // interval: 10,
    title: 'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'Category',
  };

  //axis4[1] Consumption Stop
  public axisUnoCS = {

    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
    title: 'Consumo (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
  }
  //axis4[0]  Velocity Profile
  public axisCeroVP = {
    // interval: 10,
    title: this.dataTOp.graficovp.ejex[this.state.indiceTranslate],//'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
  };
  //axis4[1] Velocity Profile
  public axisUnoVP = {
    labelFormat: '{value}',
    title: this.dataTOp.graficovp.ejey[this.state.indiceTranslate],//'Velocidad (km/h)',//
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    rangePadding: 'Auto',
    //maximum:100,
    //maximum: this.state.maxVelocity,
    /* interval: elvalorin, */
  };

  //axis4[2] Velocity Profile
  public axisDosVP = {
    valueType: 'DateTime',//this.dataTOp.graficocm.ejex[this.state.indiceTranslate],//'DateTime',
    intervalType: 'Hours',
    // interval: 10,
    title: this.dataTOp.graficocm.ejex[this.state.indiceTranslate],//'DateTime',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    rangePadding: 'Auto',
    labelFormat: 'HH:mm'
  };
  public axisyConsumoCritRuta = {
    labelFormat: '{value}',
    title: this.dataTOp.graficocl.ejey[this.state.indiceTranslate],//'Distancia (km)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    }
  };
  public axisxConsumoCritRuta = {
    valueType: 'Category',
    title: this.dataTOp.graficocl.ejex[this.state.indiceTranslate],//'Ruta',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    }
  };

  public axisxCP = {
    // interval: 10,
    title: 'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'Category',
  };
  public axisyCP = {

    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
    title: 'Consumo (kWh)',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    // maximum: this.state.maxVelocity,
    /* interval: elvalorin, */
  };
  public axisXPP = {
    // interval: 10,
    title: this.dataTOp.graficopp.ejex[this.state.indiceTranslate],//'Paradas',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    labelFormat: '{value}',
    minimum: 0,
    rangePadding: 'Auto',
  };
  public axisYPP = {
    labelFormat: '{value}',
    title: this.dataTOp.graficopp.ejey[this.state.indiceTranslate],//'Velocidad (km/h)',//
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum: 0,
    maximum:100,
    rangePadding: 'Auto',
  };
  public axisXAmb = {
    // interval: 10,
    title:this.dataTOp.graficoenv.ejex[this.state.indiceTranslate],
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    valueType: 'DateTime',
    labelFormat: 'HH:mm', 
  };
  public axisYAmb = {
    title: this.dataTOp.graficoenv.ejey[this.state.indiceTranslate],
    labelFormat: 'n2',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    rangePadding: 'Auto',
  };
  yAxis = true;
  public axis: Object = {
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    title: "Radiacion",//'Pendiente (°)',
    name: 'yAxis',
    labelFormat: 'n1',
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw',
    },
    labelStyle: {
      labelFormat: 'n1',
      size: '1.1vw',
      fontFamily: 'Poppins',
    }
  };
  public axisY2Amb = {
    name:'axisY2Amb2',
    title: "Radiación",//'Velocidad (km/h)',//
    titleStyle: {
      fontFamily: 'Poppins',
      size: '0.8vw'
    },
    labelStyle: {
      fontFamily: 'Poppins',
      size: '1.1vw'
    },
    minimum:0,
  };
  public labelStyle:{
    fontFamily: 'Poppins',
    size: '1.1vw'
  }
  subscription0: Subscription | undefined;
  subscription1: Subscription | undefined;
  subscription2: Subscription | undefined;
  subscription3: Subscription | undefined;
  subscription4: Subscription | undefined;
  subscription5: Subscription | undefined;
  subscription6: Subscription | undefined;

  @ViewChild('myDOMElement')
  MyDOMElement!: ElementRef;

  @ViewChild('chartvel1')
  public chartvel1!: ChartComponent;

  @ViewChild('chartvel2')
  public chartvel2!: ChartComponent;

  constructor(private elRef: ElementRef,
    public dataSer: DataService, public authService: AuthService, public state: StateService, private changeDetector: ChangeDetectorRef) {

  }
  get laSubs0(): any {
    return this.subscription0;
  }

  get laSubs1(): any {
    return this.subscription1;
  }

  get laSubs2(): any {
    return this.subscription2;
  }
  get laSubs3(): any {
    return this.subscription3;
  }
  get laSubs4(): any {
    return this.subscription4;
  }
  get laSubs5(): any {
    return this.subscription5;
  }
  get laSubs6(): any {
    return this.subscription6;
  }  
  public customGroupComparer = (a: any, b: any) => {
    if (a.key < b.key) {
      return -1;
    } else if (a.key > b.key) {
      return 1;
    } else {
      return 0;
    }
  };
  public sortComparer = (a: any, b: any) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  };

  // Función de comparación personalizada para la agrupación
  public groupComparer = (a: any, b: any) => {
    if (a.key < b.key) {
      return -1;
    } else if (a.key > b.key) {
      return 1;
    } else {
      return 0;
    }
  };
  public secondaryYAxis: Object = {
    title: 'Radiación',
    opposedPosition: true

  };
  public estaCompleto =true
  ngOnInit(): void {
    //this.subscribeConfiguration();
    //datos bus
    this.estaCompleto= this.state.listaEstadosDashboard["operaciones"]
    this.toolbar = ['Search'];
    this.searchSettings = { fields: ['Depot', 'BusID', 'Service'], operator: 'equal' };
    for (let j = 0; j < this.state.listaDataCronograma["busid"].length; j++) {
      var template = {
        "BusID": this.state.listaDataCronograma["busid"][j],
        "Service": this.state.listaDataCronograma["servicio"][j],
        "Depot": this.state.listaDataCronograma["depot"][j],
        "Leaves": this.state.listaDataCronograma["salida"][j],
        "Arrives": this.state.listaDataCronograma["llegada"][j],
      }
      this.databus.push(template)
    }

    this.groupOptions = { showGroupedColumn: true, columns: ['BusID'] ,groupComparer: this.customGroupComparer};

    console.log("operaciones", this.databus)
  }
  getTooltipSettings(i: number): TooltipSettingsModel {
    return {
      enable: true,
      format: '${point.x}: ${point.y} - ' + this.dataSer.nameStops[i] 
    };
  }
  ngOnDestroy(): void {
    // console.log('unsuscribing to type');
    //this.laSubs1.unsubscribe();
    // this.laSubs2.unsubscribe();
    //this.laSubs3.unsubscribe();
    //this.laSubs4.unsubscribe();
    // this.laSubs5.unsubscribe();
    //this.laSubs6.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.seleccionchart = 3
    }, 500)
  }
  onResize(event: any): void {
    this.ancho = (this.elRef.nativeElement.offsetParent.clientWidth - 40) + '';
    this.alto = (this.elRef.nativeElement.offsetParent.clientHeight - 70) + '';
  }

  irComparativaLong() {
    this.seleccionchart = 1
  }
  irVelocidadStop() {
    this.seleccionchart = 2
  }
  irPromVelocidad() {
    this.seleccionchart = 3
  }
  irPromVelocidadServicio() {
    this.seleccionchart = 4
  }
  irCronograma() {
    this.seleccionchart = 5
  }
  irPasajerosCiclos(){
    this.seleccionchart = 6
  }
  irAmbiente(){
    this.seleccionchart = 7

  }
  activateAll() {
    if (this.state.currentVelocity === 1) {
      for (let i = 0; i < this.chartvel1.series.length; i++) {
        // this.visible[i] = true;
        this.chartvel1.series[i].visible = true;
      }
    } else {
      for (let i = 0; i < this.chartvel2.series.length; i++) {
        // this.visible[i] = true;
        this.chartvel2.series[i].visible = true;
      }
    }
  }

  pointClickByBusStop(args: IPointEventArgs) {
    let latitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].latitude;
    let longitudeone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].longitude;
    let paradasindexone = this.dataSer.allCyclesInputs[this.state.cyclesListOnlyCC[args.seriesIndex]].indexstops;
    //console.log('this.state.cyclesListOnlyCC[args.seriesIndex]: ',this.state.cyclesListOnlyCC[args.seriesIndex])
    //console.log('paradasindexone: ', paradasindexone);

    const lontotal = longitudeone;
    const lattotal = latitudeone;
    const lalon = lontotal[paradasindexone[args.pointIndex]];
    const lalat = lattotal[paradasindexone[args.pointIndex]];
    this.state.changePointIndex([lalon, lalat]);
  }

  pointClick(args: IPointEventArgs): void {

    try {
      let latitudeone = this.dataSer.allCyclesInputs[this.dataSer.hours[args.seriesIndex]].latitude;
      let longitudeone = this.dataSer.allCyclesInputs[this.dataSer.hours[args.seriesIndex]].longitude;

      //const lontotal = longitudeone.concat(longitudetwo);
      //const lattotal = latitudeone.concat(latitudetwo);
      const lontotal = longitudeone;
      const lattotal = latitudeone;

      //const lalon = lontotal[paradasindexone[args.pointIndex]];
      //const lalat = lattotal[paradasindexone[args.pointIndex]];
      const lalon = lontotal[args.pointIndex];
      const lalat = lattotal[args.pointIndex];
      this.state.changePointIndex([lalon, lalat]);
    } catch (error) {
      console.log('No hay posiciones de latlon: ', error);
    }
  }
  public chartMouseClick(args: IMouseEventArgs): void {
    var flag = false;
    if (((args.target).indexOf('chart_legend_text') > -1) || ((args.target).indexOf('chart_legend_shape') > -1) ||
      (args.target).indexOf('chart_legend_shape_marker_') && !(args.target).indexOf('chart_legend_element')) {
      var ids = ((args.target).indexOf('chart_legend_text') > -1) ?
        (args.target).split('chart_legend_text_')[1] : args.target.split('chart_legend_shape_marker_')[1] || args.target.split('chart_legend_shape_')[1];
      // console.log('ids: ', ids);
      if (this.state.currentVelocity === 1) {
        for (var i = 0; i < this.chartvel1.series.length; i++) {
          this.chartvel1.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = false;
          } else {
            this.visible[i] = true;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel1.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel1.series.length; j++) {
            this.chartvel1.series[j].visible = true;
            this.visible[i] = true;
          }

          this.chartvel1.series[ids].visible = false;
          // this.visible[ids]= false;

          this.previousTarget = null;
          flag = true;
        }
      } else {
        for (var i = 0; i < this.chartvel2.series.length; i++) {
          this.chartvel2.series[i].visible = false;
          if (parseInt(ids) !== i) {
            this.visible[i] = false;
          } else {
            this.visible[i] = true;
          }
          /* if (i == parseInt(ids) + this.state.THEDATA[3].length) {
            this.chartvel2.series[i].visible = true;
          } */
        }

        if (ids == this.previousTarget) {
          for (var j = 0; j < this.chartvel2.series.length; j++) {
            this.chartvel2.series[j].visible = true;
            this.visible[i] = true;
          }

          this.chartvel2.series[ids].visible = false;
          // this.visible[ids]= false;
          this.previousTarget = null;
          flag = true;
        }
      }
      if (!flag)
        this.previousTarget = ids;
    }
  };

  subscribeConfiguration(): void {
    // if this chart is not been visualized then do nothing
    if (this.state.currentChart !== 4) {
      return;
    }
    this.subscription0 = this.state.allSeriesUp.subscribe(
      async (value: any) => {
        // console.log('Showing all series');
        if (this.state.currentVelocity === 1) {
          for (let i = 0; i < this.chartvel1.series.length; i++) {
            this.chartvel1.series[i].visible = true;
          }
          for (let j = 0; j < this.visible.length; j++) {
            this.visible[j] = true;
          }
          this.changeDetector.detectChanges();
          this.chartvel1.refresh();
        } else {
          for (let i = 0; i < this.chartvel2.series.length; i++) {
            this.chartvel2.series[i].visible = true;
          }
          for (let j = 0; j < this.visible.length; j++) {
            this.visible[j] = true;
          }
          this.changeDetector.detectChanges();
          this.chartvel2.refresh();
        }
      }
    );
    this.subscription1 = this.state.currentRouteChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        this.visible = new Array(arre.length).fill(false);
        this.visible[this.dataSer.completeDay[0].maxconsumcycle - 1] = true;
        this.changeDetector.detectChanges();
        // console.log('Detectied changes in route');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );

    this.subscription3 = this.state.currentPaxChange.subscribe(
      async (value: any) => {
        if (value === 'nav') {
          const arre = this.state.THEDATA[3];
          this.visible = new Array(arre.length).fill(false);
          this.visible[this.dataSer.completeDay[0].maxconsumcycle - 1] = true;
          this.state.chosenSeries = this.dataSer.completeDay[0].maxconsumcycle - 1;
        }

        // this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // console.log('Detected changes in Pax');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
    this.subscription4 = this.state.currentHVACChange.subscribe(
      async (value: any) => {
        const arre = this.state.THEDATA[3];
        // this.visible = new Array(arre.length).fill(false);
        // this.visible[this.dataSer.dailyStatistics.minconsumCycle - 1] = true;
        // this.visible[this.dataSer.dailyStatistics.maxconsumCycle - 1] = true;
        this.changeDetector.detectChanges();
        // this.chart.renderSeries();
        // console.log('Detected changes in HVAC');
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );

    this.subscription6 = this.state.currentBusSizeChange.subscribe(
      async (value: any) => {
        // const arre = this.state.THEDATA[3];

        //this.visible = new Array(arre.length).fill(false);
        // console.log('Previous target: ', this.previousTarget);
        // this.visible[this.previousTarget] = true;
        this.changeDetector.detectChanges();
        if (this.state.currentVelocity === 1) {
          this.chartvel1.refresh();
        } else {
          this.chartvel2.refresh();
        }
      }
    );
  }
  dataBound(args) {
    //this.grid.autoFitColumns()
    if (this.refresh) {
      this.grid.groupColumn('Depot');
      this.refresh = false;
    }
  }
  load() {
    this.refresh = (<any>this.grid).refreshing;
  }
  created() {
    this.grid.on("columnDragStart", this.columnDragStart, this);
  }
  public columnDragStart(args: any) {
    if (args.column.field === "Mainfieldsofinvention") {
      this.alertDialog.show();
    }
  }
  onBarClick(args: any): void {
    // Lógica a ejecutar cuando se hace clic en una barra
    console.log('Barra clickeada:',args);
  }

}
