import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';

@Component({
  selector: 'app-animation-bar',
  templateUrl: './animation-bar.component.html',
  styleUrls: ['./animation-bar.component.scss']
})
export class AnimationBarComponent implements OnInit {

  constructor(public state: StateService) { }

  ngOnInit(): void {
  }

}
