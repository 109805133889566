<div class="paradiv">

  <span style="color: white; height: 15px; justify-content: center;
  align-items: center; text-align: center; vertical-align: middle;">Pendientes de ruta:</span>
  <br />
  <span
    style="color: white; height: 50px; justify-content: center; font-family: 'Poppins';
  align-items: center; text-align: center; vertical-align: middle; font-size: large;">{{this.state.showSingleBusAnimation}}</span>


  <!-- <div >
    <mat-grid-list *ngIf="state.animationAlerts[items[0].id].descriptions[state.animationAlerts[items[0].id].descriptions.length - 1] != 'OK'" cols="1" rowHeight="65px" style="padding:0px">

      <mat-grid-tile  class="eltile">
        {{state.animationAlerts[items[0].id].descriptions[state.animationAlerts[items[0].id].descriptions.length - 1]}}
      </mat-grid-tile>
    </mat-grid-list>
  </div> -->

  <div>
    <mat-grid-list *ngIf="state.animationAlerts[items[0].id].descriptions[0][0] != 'OK'" cols="1" rowHeight="65px"
      style="padding:0px" (click)="removeAlert(0, items[0].id)">

      <mat-grid-tile class="eltile">
        <span [style.color]="state.animationAlerts[items[0].id].descriptions[0][2]">
          {{state.animationAlerts[items[0].id].descriptions[0][0]}},
          {{state.animationAlerts[items[0].id].descriptions[0][1]}}
        </span>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div>
    <mat-grid-list *ngIf="state.animationAlerts[items[0].id].descriptions[1][0] != 'OK'" cols="1" rowHeight="65px"
      style="padding:0px" (click)="removeAlert(1, items[0].id)">

      <mat-grid-tile class="eltile">
        <span [style.color]="state.animationAlerts[items[0].id].descriptions[1][2]">
          {{state.animationAlerts[items[0].id].descriptions[1][0]}},
          {{state.animationAlerts[items[0].id].descriptions[1][1]}}
        </span>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <!-- <div >
    <mat-grid-list *ngIf="state.animationAlerts[items[0].id].descriptions[2] != 'OK'" cols="1" rowHeight="65px" style="padding:0px">

      <mat-grid-tile  class="eltile">
        {{state.animationAlerts[items[0].id].descriptions[state.animationAlerts[items[0].id].descriptions.length - 1]}}
      </mat-grid-tile>
    </mat-grid-list>
  </div> -->


</div>
